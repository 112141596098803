import { IFeature } from '../../models/IFeature';

export interface IFeatureDataState {
  featuresList: IFeature[];
  loaded: boolean;
}

export const initialFeatureDataState: IFeatureDataState = {
  featuresList: [],
  loaded: false
};

export function getInitialFeatureDataState(): IFeatureDataState {
  return initialFeatureDataState;
}
