import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { IAgentReport } from '../models/IAgentReport';

@Directive({
  selector: '[appSocialBlock]'
})
export class SocialBlockDirective {

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
  }

  @Input() set appSocialBlock(val: IAgentReport) {
    if (this.checkSocialBlock(val)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  checkSocialBlock(d: IAgentReport) {
    const links = Object.keys(d).filter(key => key.toLocaleLowerCase().includes('link'));
    return links.some(key => {
      return d[key];
    });
  }
}
