import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataColumns, ITrendsTableData } from '../../../../../models/ITrendsTableData';
import { MarketTrendsService, TableHeaderColumn } from '../../../../../services/market-trends.service';
import {
  selectTrendsTableData,
  selectTrendsTableDataType
} from '../../../../../store/selectors/market-trends.selector';
import { IAppState } from '../../../../../store/state/app.state';

@Component({
  selector: 'app-trend-table',
  templateUrl: './trend-table.component.html',
  styleUrls: ['./trend-table.component.scss']
})
export class TrendTableComponent implements OnInit {
  DataColumns = DataColumns;
  tableData$ = this.store.select(selectTrendsTableData);
  tableDataType$ = this.store.select(selectTrendsTableDataType);
  data$: Observable<{ headers: TableHeaderColumn[]; formattedData: ITrendsTableData[] }>;
  @Input() typographySize: ('small' | 'medium' | 'large') = 'medium';

  constructor(private store: Store<IAppState>,
              private marketTrendsService: MarketTrendsService) {
  }

  ngOnInit(): void {
    this.data$ = combineLatest([this.tableData$, this.tableDataType$]).pipe(map(([tableData, tableType]) => {
      const headers = this.marketTrendsService.tableHeaderColumns;
      const formattedData = this.marketTrendsService.formatTrendsTableArray(tableData, tableType);
      return {headers, formattedData};
    }));
  }
}
