import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { FileSaverService } from 'ngx-filesaver';
import { Subscription, throwError } from 'rxjs';
import { SocialImageDirective } from '../../../../directives/social-image.directive';
import { IAssetDownloadData } from '../../../../models/IAssetDownloadData';
import { IMarketAssetCard } from '../../../../models/IMarketAssetCard';
import { IReportModalData } from '../../../../models/IReportModalData';
import { EncryptedReportData } from '../../../../models/IReportPageData';
import { DownloadService } from '../../../../services/download.service';
import { ModalService } from '../../../../services/modal.service';
import { ThemeService } from '../../../../services/theme.service';
import { selectBrandCode } from '../../../../store/selectors/applicationData.selector';
import { IAppState } from '../../../../store/state/app.state';
import { AssetDownloadComponent } from './asset-download/asset-download.component';

@Component({
  selector: 'app-marketing-assets-card',
  templateUrl: './marketing-assets-card.component.html',
  styleUrls: ['./marketing-assets-card.component.scss']
})
export class MarketingAssetsCardComponent implements OnInit, OnDestroy {
  @ViewChild('assetYouTubePlayer') assetYouTubePlayer: ElementRef<HTMLDivElement>;
  @Output() copyUrlActionSuccess = new EventEmitter<boolean>();
  @Input() marketData: IMarketAssetCard;
  @Input() targetSocialContainer: SocialImageDirective;
  @Input() showHomeSpotter = true;
  @Input() reportData: EncryptedReportData;
  public videoFileName: string;
  public shareLink: string;
  public description: string;
  private isTabletPlus: boolean;
  private isDesktop: boolean;
  subs = new Subscription();
  isSIR: boolean;

  constructor(
    public modalService: ModalService,
    private breakpointObserver: BreakpointObserver,
    private downloadService: DownloadService,
    private fileSaverService: FileSaverService,
    private store: Store<IAppState>,
    private themeService: ThemeService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.marketData?.type === 'video') {
      this.videoFileName = `${environment.apiBaseUrl}/api/video/getvideo?videoFile=${this.marketData.videoFileName}`;
      this.shareLink = 'https:/youtu.be/' + this.marketData.youTubeId;
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
    }
    this.subs.add(
      this.breakpointObserver.observe('(min-width: 599px)').subscribe(size => {
        this.isTabletPlus = size.matches;
      })
    );
    this.subs.add(
      this.breakpointObserver.observe('(min-width: 960px)').subscribe(size => {
        this.isDesktop = size.matches;
      })
    );
    this.subs.add(
      this.store
        .select(selectBrandCode)
        .pipe()
        .subscribe(() => {
          this.isSIR = this.themeService.isSIR();
        })
    );
    this.description = this.marketData?.description;
  }

  downloadVideo() {
    this.subs.add(
      this.downloadService.downloadFile(this.videoFileName).subscribe({
        next: response => {
          const blob: any = new Blob([response], { type: 'video/mp4' });
          this.fileSaverService.save(blob, 'marketquest.mp4');
        },
        error: err => {
          throwError(err);
        }
      })
    );
  }

  notify() {
    this.copyUrlActionSuccess.emit(true);
  }

  openSocialModal(type: 'hs' | 'social') {
    const isSocial = type === 'social';
    const hasGraph = this.marketData.hasGraph;
    const number1 = !this.reportData.reportType;
    let prefix = `${number1 ? '#1 ' : ''}${isSocial ? 'Social' : 'Homespotter Ad'}`;
    prefix = prefix + ' ' + (hasGraph ? 'Graph' : 'Image');
    const modalData: IReportModalData = {
      cbposition: this.reportData.cbposition,
      imagePath: '',
      loading: false,
      decryptedId: '',
      disclaimer: '',
      encryptedId: this.reportData.encryptedData,
      imageData: undefined,
      oktaId: this.reportData.oktaId,
      logoData: undefined,
      reportDetail: this.reportData.reportDetail,
      msUnoReportId: this.reportData.msUnoReportId,
      withChart: hasGraph
    };
    this.modalService.open(AssetDownloadComponent, {
      data: {
        report: modalData,
        title: prefix,
        target: this.targetSocialContainer,
        graph: hasGraph,
        isSocial: isSocial,
        image: this.marketData.imageURL,
        isSIR: this.themeService.isSIR(),
        reportType: this.reportData.reportType
      } as IAssetDownloadData,
      height: this.isTabletPlus ? (this.isDesktop ? '663px' : '543px') : '100vh',
      width: this.isTabletPlus ? (this.isDesktop ? '752px' : '526px') : '100vw'
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
