import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserSettingsService } from '../../services/user-settings.service';
import { FetchUsersGalleryImages } from '../actions/gallery.action';
import {
  FetchContactData,
  FetchContactDataSuccess,
  SetBrandingTypeRemote,
  ToggleUserContactFieldStatus,
  ToggleUserContactFieldStatusSuccess,
  UpdateOfficeInfo,
  UpdateTeamInfo,
  UserSettingsActionsActionType
} from '../actions/user-settings.action';
import { FetchUserDefaultImage, UpdateUserDefaultOffice, UpdateUserDefaultTeam } from '../actions/userData.action';
import { IAppState } from '../state/app.state';

@Injectable()
export class UserSettingsEffect {
  updateUserContactField = createEffect(() =>
    this._actions$.pipe(
      ofType<ToggleUserContactFieldStatus>(UserSettingsActionsActionType.ToggleUserContactFieldStatus),
      switchMap(obv => {
        return this.userSettingSvc.updateUserContactField(obv.payload);
      }),
      switchMap(data => {
        const [apiResp, payload] = data;
        return [new ToggleUserContactFieldStatusSuccess(payload)];
      }),
      catchError(err => throwError(err))
    )
  );

  updateUsersDefaultOffice = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateOfficeInfo>(UserSettingsActionsActionType.UpdateOfficeInfo),
      switchMap(action => {
        this.store.dispatch(new UpdateUserDefaultOffice(action.payload));
        return this.userSettingSvc.getOfficeDetails(action.payload);
      }),
      switchMap(response => {
        return [
          new FetchContactDataSuccess({
            social: response.socialData,
            contact: response.contactData,
            photo: response.photoData
          }),
          new FetchUsersGalleryImages(),
          new FetchUserDefaultImage()
        ];
      }),
      catchError(err => throwError(err))
    )
  );

  updateUsersDefaultTeam = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateTeamInfo>(UserSettingsActionsActionType.UpdateTeamInfo),
      switchMap(action => {
        this.store.dispatch(new UpdateUserDefaultTeam(action.payload));
        return this.userSettingSvc.getTeamDetails(action.payload);
      }),
      switchMap(response => {
        return [
          new FetchContactDataSuccess({
            social: response.socialData,
            contact: response.contactData,
            photo: response.photoData
          }),
          new FetchUsersGalleryImages(),
          new FetchUserDefaultImage()
        ];
      }),
      catchError(err => throwError(err))
    )
  );

  getUserFields = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContactData>(UserSettingsActionsActionType.FetchContactData),
      switchMap(action => {
        return this.userSettingSvc.getFieldsData(action.payload);
      }),
      switchMap(response => {
        return [
          new FetchContactDataSuccess({
            social: response.social,
            contact: response.contact,
            photo: response.photo
          })
        ];
      }),
      catchError(err => throwError(err))
    )
  );

  updateContactType = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SetBrandingTypeRemote>(UserSettingsActionsActionType.SetBrandingTypeRemote),
        switchMap(action => {
          return this.userSettingSvc.updateContactTypeToAPI(action.payload);
        }),
        catchError(err => throwError(err))
      ),
    { dispatch: false }
  );

  constructor(private _actions$: Actions, private store: Store<IAppState>, private userSettingSvc: UserSettingsService) {}
}
