import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OptionsService } from '../../../../../services/options.service';
import { pdfExportData } from '../../../../../store/selectors/pdf.selector';
import { IAppState } from '../../../../../store/state/app.state';

@Component({
  selector: 'app-export-preview-header',
  templateUrl: './export-preview-header.component.html',
  styleUrls: ['./export-preview-header.component.scss']
})
export class ExportPreviewHeaderComponent implements OnInit {
  @Input() coverPage = false;
  exportPreviewData$: Observable<{ displayDate: string; logo: string; title: string }>;

  constructor(private store: Store<IAppState>, private optionsSvc: OptionsService) {}

  ngOnInit(): void {
    this.exportPreviewData$ = this.store.select(pdfExportData).pipe(
      map(data => {
        const d = new Date(data.displayDate);
        const displayDate = format(d, 'MMMM yyyy');
        const logo: string = this.optionsSvc.getImagePathForReport() + data.logo.imageURL;
        const title = data.reportTitle;
        return { logo, displayDate, title };
      })
    );
  }
}
