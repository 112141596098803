<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header
    [ngClass]="{ active: reportFilterFormGroup?.value }"
    class="border-bottom px-3"
    collapsedHeight="80px"
    expandedHeight="80px"
    data-label="Report Filters"
  >
    <mat-panel-title class="fw-bold mat-panel-header">
      Report Filters&nbsp;
      <app-chip-label
        *ngIf="reportFilterFormGroup"
        [callbackFunction]="clearReportFilters"
        [chipClass]="(reportFilterFormGroup?.dirty ? 'on' : 'off') + ' clear'"
        [label]="'Reset'"
        class="ps-4"
      ></app-chip-label>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div [formGroup]="reportFilterFormGroup">
    <div class="panel-section" *ngIf="activeReportSections['propType']">
      <mat-panel-title class="mat-panel-title">
        Property Type
      </mat-panel-title>
      <div class="checkbox-container viewport">
        <mat-selection-list [formControl]="$any(reportFilterFormGroup?.get('propType'))">
          <mat-list-option *ngFor="let type of propertyTypes" [checkboxPosition]="'before'" [value]="type.value">
            <span>{{ type.label }}</span>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>

    <div class="panel-section" *ngIf="activeReportSections['msTypeName']">
      <mat-panel-title class="mat-panel-title">
        Transaction Side
      </mat-panel-title>

      <mat-radio-group [formControlName]="'msTypeName'">
        <mat-radio-button class="locations-option" [value]="'Total'" disableRipple>
          Total
        </mat-radio-button>
        <mat-radio-button class="locations-option" [value]="'List'" disableRipple>
          List Side
        </mat-radio-button>
        <mat-radio-button class="locations-option" [value]="'Buy'" disableRipple>
          Buy Side
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="panel-section" *ngIf="activeReportSections['unitType']">
      <mat-panel-title class="mat-panel-title">
        Sales Type
      </mat-panel-title>
      <mat-radio-group [formControlName]="'unitType'">
        <mat-radio-button class="locations-option" [value]="'Units'" disableRipple>
          Units
        </mat-radio-button>
        <mat-radio-button class="locations-option" [value]="'Volume'" disableRipple>
          Volume
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <app-time-period *ngIf="activeReportSections['dateRange']" [control]="$any(reportFilterFormGroup?.get('dateRange'))"></app-time-period>
    <app-time-period-multi
      *ngIf="activeReportSections['dateRangeMulti']"
      [isCustomReport]="isCustomReport"
      [hideCustomMonthlyDate]="hideCustomMonthlyDate"
      [group]="$any(reportFilterFormGroup?.get('dateRangeMulti'))"
    ></app-time-period-multi>
    <div class="panel-section" *ngIf="activeReportSections['timeFrame']">
      <mat-panel-title class="mat-panel-title">
        {{ customDateRange ? 'Date Range' : 'Time Period' }}
      </mat-panel-title>
      <mat-radio-group [formControlName]="'timeFrame'">
        <mat-radio-button class="locations-option" [value]="filtersV2TimeEnum.lastYear" disableRipple>
          Last Year
        </mat-radio-button>
        <mat-radio-button class="locations-option" [value]="filtersV2TimeEnum.lastQuarter" disableRipple>
          Last Quarter
        </mat-radio-button>
        <mat-radio-button class="locations-option" [value]="filtersV2TimeEnum.ytd" disableRipple>
          Year To Date
        </mat-radio-button>
        <mat-radio-button *ngIf="customDateRange" class="locations-option" [value]="'Custom'" disableRipple>
          Custom
        </mat-radio-button>
      </mat-radio-group>

      <div *ngIf="reportFilterFormGroup.get('timeFrame').value === 'Custom'" class="inputs-group">
        <mat-form-field appearance="outline">
          <mat-label>Start Date</mat-label>
          <input
            (click)="picker.open()"
            [matDatepicker]="picker"
            [max]="reportFilterFormGroup.get('endDate').value || customMaxDate"
            [readonly]="true"
            class="market-input"
            formControlName="startDate"
            matInput
          />
          <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #picker (opened)="calOpen()" [touchUi]="!tabletPlus"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>End Date</mat-label>
          <input
            (click)="picker2.open()"
            [matDatepicker]="picker2"
            [max]="customMaxDate"
            [min]="reportFilterFormGroup.get('startDate').value"
            [readonly]="true"
            class="market-input"
            formControlName="endDate"
            matInput
          />
          <mat-datepicker-toggle [disableRipple]="true" [for]="picker2" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #picker2 (opened)="calOpen()" [touchUi]="!tabletPlus"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="p-3 panel-section" *ngIf="monthsBack">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Number of Months Back</mat-label>
        <mat-select [formControlName]="'monthsBack'">
          <mat-option *ngFor="let monthBack of monthsBack" [value]="monthBack.value">
            {{ monthBack.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="p-3 panel-section" *ngIf="priceBands">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Price Bands</mat-label>
        <mat-select [formControlName]="'priceBand'">
          <mat-option *ngFor="let priceBand of priceBands" [value]="priceBand.labelValue">
            {{ priceBand.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-price-range
      *ngIf="activeReportSections['priceRange']"
      [control]="$any(reportFilterFormGroup?.get('priceRange'))"
    ></app-price-range>

    <div class="panel-section" *ngIf="activeReportSections['priceType']">
      <mat-panel-title class="mat-panel-title">
        Price Range
      </mat-panel-title>

      <mat-radio-group [formControlName]="'priceType'">
        <mat-radio-button
          *ngFor="let priceRange of priceRanges"
          class="locations-option"
          [class.hidden]="priceRange.sirOnly && !isSIR"
          [value]="priceRange.value"
          disableRipple
        >
          {{ priceRange.value }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="panel-section" *ngIf="activeReportSections['groupByLevel']">
      <mat-panel-title class="mat-panel-title">
        View By
      </mat-panel-title>

      <mat-radio-group [formControlName]="'groupByLevel'">
        <mat-radio-button class="locations-option" [value]="'Brand'" disableRipple>
          Brand
        </mat-radio-button>
        <mat-radio-button class="locations-option" [value]="'Brokerage'" disableRipple>
          Brokerage
        </mat-radio-button>
        <mat-radio-button class="locations-option" [value]="'Office'" disableRipple>
          Office
        </mat-radio-button>
        <mat-radio-button class="locations-option" [value]="'Agent'" disableRipple *ngIf="sortByAgent">
          Agent
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="panel-section" *ngIf="activeReportSections['isReo']">
      <mat-panel-title class="mat-panel-title">
        Real Estate Owned Property
      </mat-panel-title>

      <div class="checkbox-container">
        <mat-checkbox formControlName="isReo">
          Real Estate Owned Properties Only
        </mat-checkbox>
      </div>
    </div>
  </div>
</mat-expansion-panel>
