<div *ngIf="data$ | async as data" class="content mb-auto d-flex justify-content-between">
  <div class="left d-flex gold-border align-items-end">
    <div
      *ngFor="let i of data.graphData; let idx = index"
      [attr.h]="getHeight(i.reportMeasureValue, data.maxValue) | number: '1.0-0'"
      [attr.leader]="!idx ? true : null"
      class="series d-flex"
    >
      <div class="series-label">
        <span class="w-100 overflow-hidden entityName">
          {{ i.levelEntityName }}
        </span>
        <span class="d-block mb-3 w-100">
          {{ i.formattedValue }}
        </span>
      </div>
      <div [ngClass]="{ leader: !idx }" class="chart-col h-100 flex-shrink-0"></div>
    </div>
  </div>
  <div class="right d-flex">
    <div [ngStyle]="{ visibility: (pdfOpts$ | async).showBrandLogo ? 'visible' : 'hidden' }" class="logo">
      <img [src]="data.logo" alt="Sotheby's logo" class="h-100" />
    </div>
    <div class="date">{{ data.data.reportDetail.reportHeaderEntity.displayDateRange }}</div>
    <div class="divider my-4"></div>
    <div class="number-one">
      <div class="lower mb-4">
        <span class="claims-title upper">Number</span>
        <span class="claims-title lower">One</span>
      </div>
    </div>
    <div class="type text-uppercase">
      {{ data.data.reportDetail.reportHeaderEntity.groupByLevel }}
      By {{ data.data.reportDetail.reportHeaderEntity.msTypeName }}
    </div>
    <div class="location text-uppercase mb-2">
      {{ data.data.reportDetail.reportHeaderEntity.areaLocalName }}
    </div>
    <div class="price text-uppercase">{{ data.data.reportDetail.reportHeaderEntity.priceType }}</div>
  </div>
</div>
<div *ngIf="data$ | async as data" class="footer">
  <div [innerHTML]="data.disclaimer"></div>
</div>
