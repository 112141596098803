export class GalleryImage {
  imageURL: string;
  isActive: boolean;
  msUnoBackGroundImageID: number;
  entityID: number;
  dbName: string;
  imageSizeId: number;
  imageSize: string;

  constructor(imageURL: string = '', isActive: boolean = false, msUnoBackGroundImageID: number = 0) {
    this.imageURL = imageURL;
    this.isActive = isActive;
    this.msUnoBackGroundImageID = msUnoBackGroundImageID;
  }
}
