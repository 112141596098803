import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { NoResultsComponentModule } from 'app/components/no-results/no-results.module';
import { ReportsTemplateComponent } from 'app/pages/layouts/reports-template/reports-template.component';
import { CopyClipboardDirective } from '../../../directives/clipboard.directive';
import { ExportPaneDirective } from '../../../directives/export-pane.directive';
import { FeatureFlagDirective } from '../../../directives/feature-flag.directive';
import { InsertComponentDirective } from '../../../directives/insert-component.directive';
import { SocialImageDirective } from '../../../directives/social-image.directive';
import { NavButtonBarComponent } from '../../../pages/layoutNav/nav-button-bar/nav-button-bar.component';
import { NavButtonComponent } from '../../../pages/layoutNav/nav-button-bar/nav-button/nav-button.component';
import { ChipTooltipPipe } from '../../../pipes/chipTooltip.pipe';
import { CountyStringPipe } from '../../../pipes/countyString.pipe';
import { LocationDataNamePipe } from '../../../pipes/locationDataName.pipe';
import { MarketQuestPhonePipe } from '../../../pipes/marketquestPhone.pipe';
import { ReportLocationNamePipe } from '../../../pipes/report-location-name.pipe';
import { SchoolStringPipe } from '../../../pipes/schoolDistrictString.pipe';
import { DisclaimerBannerContainerComponent } from '../../disclaimers/disclaimer-banner/disclaimer-banner-container/disclaimer-banner-container.component';
import { DisclaimerBannerComponent } from '../../disclaimers/disclaimer-banner/disclaimer-banner.component';
import { SavedSearchModalModule } from '../../modals/saved-search-modal/saved-search-modal.module';
import { MqFooterModule } from '../../mq-footer/mq-footer.module';
import { AvatarImageComponent } from '../../mq-header/avatar-menu/avatar-image/avatar-image.component';
import { CardControlsComponent } from '../claims-no1/claims-search-page/card-container/card-controls-container/card-controls/card-controls.component';
import { ChipsContainerComponent } from '../filter-panel/chips-container/chips-container.component';
import { ChipsGroupedComponent } from '../filter-panel/chips-container/chips-grouped/chips-grouped.component';
import { ChipsUngroupedComponent } from '../filter-panel/chips-container/chips-ungrouped/chips-ungrouped.component';
import { FilterPanelLocationsComponent } from '../filter-panel/filter-panel-locations/filter-panel-locations.component';
import { FilterPanelReportComponent } from '../filter-panel/filter-panel-report/filter-panel-report.component';
import { FilterPanelComponent } from '../filter-panel/filter-panel.component';
import { MlsListSelectComponent } from '../filter-panel/mls-list-select/mls-list-select.component';
import { PriceRangeComponent } from '../filter-panel/price-range/price-range.component';
import { SearchInputComponent } from '../filter-panel/search-input/search-input.component';
import { MultiDateRangeSelectionComponent } from '../filter-panel/time-period-multi/multi-date-range-selection/multi-date-range-selection.component';
import { TimePeriodMultiComponent } from '../filter-panel/time-period-multi/time-period-multi.component';
import { TimePeriodComponent } from '../filter-panel/time-period/time-period.component';
import { PositionStatsComponent } from '../market/position-stats/position-stats.component';
import { MarketingAssetsCardComponent } from '../report-page/marketing-assets-card/marketing-assets-card.component';
import { PreviewTileSothebysComponent } from '../report-page/marketing-assets-card/preview-tile-sothebys/preview-tile-sothebys.component';
import { PreviewTileComponent } from '../report-page/marketing-assets-card/preview-tile/preview-tile.component';
import { ReportDownloadAreaComponent } from '../report-page/report-download-area/report-download-area.component';
import { ActionButtonComponent } from '../report-page/report-stats/action-buttons/action-button-responsive/action-button.component';
import { ActionButtonsComponent } from '../report-page/report-stats/action-buttons/action-buttons.component';
import { ChipLabelComponent } from './chip-label/chip-label.component';
import { DownloadableImageComponent } from './downloadable-image/downloadable-image.component';
import { SearchComponent } from './filters/search/search.component';
import { MarketQuestChartComponent } from './market-quest-chart/market-quest-chart.component';
import { SaveDefaultsComponent } from './save-defaults/save-defaults.component';
import { SaveFiltersDialogComponent } from './save-defaults/save-filters-dialog/save-filters-dialog.component';
import { SmallChartTableComponent } from './small-chart-table/small-chart-table.component';
import { SocialSvgModule } from './social-svg/social-svg.module';

@NgModule({
  declarations: [
    CardControlsComponent,
    ChipLabelComponent,
    CopyClipboardDirective,
    InsertComponentDirective,
    DownloadableImageComponent,
    MarketQuestChartComponent,
    ReportLocationNamePipe,
    SearchComponent,
    SocialImageDirective,
    MarketingAssetsCardComponent,
    ActionButtonsComponent,
    PreviewTileComponent,
    PreviewTileSothebysComponent,
    PositionStatsComponent,
    ActionButtonComponent,
    LocationDataNamePipe,
    SearchComponent,
    DisclaimerBannerComponent,
    ChipTooltipPipe,
    CountyStringPipe,
    SchoolStringPipe,
    MarketQuestPhonePipe,
    NavButtonBarComponent,
    NavButtonComponent,
    SaveDefaultsComponent,
    SaveFiltersDialogComponent,
    SmallChartTableComponent,
    DisclaimerBannerContainerComponent,
    FilterPanelComponent,
    MlsListSelectComponent,
    ChipsGroupedComponent,
    ChipsContainerComponent,
    ChipsUngroupedComponent,
    SearchInputComponent,
    PriceRangeComponent,
    TimePeriodComponent,
    TimePeriodMultiComponent,
    MultiDateRangeSelectionComponent,
    ReportsTemplateComponent,
    FilterPanelLocationsComponent,
    FilterPanelReportComponent,
    ExportPaneDirective,
    FeatureFlagDirective,
    ReportDownloadAreaComponent,
    AvatarImageComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    ChartModule,
    MatIconModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatMenuModule,
    YouTubePlayerModule,
    MatButtonModule,
    MatTableModule,
    MatSidenavModule,
    RouterModule,
    MqFooterModule,
    MatDialogModule,
    MatExpansionModule,
    NoResultsComponentModule,
    ScrollingModule,
    MatCheckboxModule,
    MatListModule,
    MatChipsModule,
    SocialSvgModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatOptionModule,
    MatSnackBarModule,
    MatProgressBarModule,
    SavedSearchModalModule
  ],
  exports: [
    MqFooterModule,
    CardControlsComponent,
    ChartModule,
    ChipLabelComponent,
    CopyClipboardDirective,
    FormsModule,
    MarketQuestChartComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatRadioModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatTableModule,
    MatSidenavModule,
    NoResultsComponentModule,
    ReactiveFormsModule,
    ReportLocationNamePipe,
    ScrollingModule,
    SearchComponent,
    SocialImageDirective,
    InsertComponentDirective,
    MarketingAssetsCardComponent,
    ActionButtonsComponent,
    PreviewTileComponent,
    YouTubePlayerModule,
    PositionStatsComponent,
    ActionButtonComponent,
    LocationDataNamePipe,
    DisclaimerBannerComponent,
    ChipTooltipPipe,
    CountyStringPipe,
    SchoolStringPipe,
    MarketQuestPhonePipe,
    NavButtonBarComponent,
    NavButtonComponent,
    SaveDefaultsComponent,
    SaveFiltersDialogComponent,
    SmallChartTableComponent,
    DisclaimerBannerContainerComponent,
    SocialSvgModule,
    FilterPanelComponent,
    MlsListSelectComponent,
    ChipsGroupedComponent,
    ChipsContainerComponent,
    ChipsUngroupedComponent,
    SearchInputComponent,
    PriceRangeComponent,
    TimePeriodComponent,
    TimePeriodMultiComponent,
    MultiDateRangeSelectionComponent,
    ReportsTemplateComponent,
    FilterPanelLocationsComponent,
    FilterPanelReportComponent,
    MatSlideToggleModule,
    MatSliderModule,
    MatOptionModule,
    MatSnackBarModule,
    MatNativeDateModule,
    PreviewTileSothebysComponent,
    ExportPaneDirective,
    FeatureFlagDirective,
    ReportDownloadAreaComponent,
    AvatarImageComponent
  ]
})
export class SharedComponentsModule {}
