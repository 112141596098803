import { routerReducer } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { IAppState } from '../state/app.state';
import { ApplicationDataReducer } from './applicationData.reducer';
import { BrowserDataReducer } from './browser.reducer';
import { CustomReportReducer } from './custom-report.reducer';
import { FeatureDataReducer } from './featureData.reducer';
import { filterDataReducer } from './filters.reducer';
import { GalleryReducer } from './gallery.reducer';
import { LocationsReducer } from './locations.reducer';
import { MarketPositionDataReducer } from './market-position.reducer';
import { MarketSnapshotDataReducer } from './market-snapshot.reducer';
import { MarketTrendsDataReducer } from './market-trends.reducer';
import { MenusReducer } from './menus.reducer';
import { MLSReducer } from './mls.reducer';
import { PdfReducer } from './pdf.reducer';
import { no1ClaimsReportsDataReducer } from './reports.reducer';
import { SavedSearchReducer } from './saved-search.reducer';
import { UserSettingsReducer } from './user-settings.reducer';
import { userDataReducer } from './userData.reducer';
import { WootricReducer } from './wootric.reducer';
import { AspTrendsReducer } from './asp-trends.reducer';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['userData', 'featuresData', 'filtersData', 'wootric'] })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

export const reducers: ActionReducerMap<IAppState, any> = {
  router: routerReducer,
  userData: userDataReducer,
  filterData: filterDataReducer,
  no1Claims: no1ClaimsReportsDataReducer,
  wootric: WootricReducer,
  mls: MLSReducer,
  locations: LocationsReducer,
  gallery: GalleryReducer,
  browser: BrowserDataReducer,
  marketPosition: MarketPositionDataReducer,
  applicationData: ApplicationDataReducer,
  menus: MenusReducer,
  marketTrends: MarketTrendsDataReducer,
  marketSnapshot: MarketSnapshotDataReducer,
  pdf: PdfReducer,
  userSettings: UserSettingsReducer,
  savedSearch: SavedSearchReducer,
  customReport: CustomReportReducer,
  features: FeatureDataReducer,
  aspTrends: AspTrendsReducer
};
