<div *ngIf="(bannerStatus$ | async)?.show" class="consolidated-disclaimer">
  <div class="icon_frame">
    <mat-icon>info</mat-icon>
  </div>
  <div class="messaging">
    <span>{{ data.message }}</span>
    <div class="action d-flex flex-row">
      <a *ngIf="data.url" (click)="goToHelpPage(data.url)" class="help">Learn More</a>
      <mat-icon *ngIf="data.closeable" (click)="hideBanner()" class="close-btn">close</mat-icon>
    </div>
  </div>
</div>
