<div *ngIf="pdfPreviewData$ | async as pdfPreviewData" class="h-100">
  <div *ngIf="loaded$ | async" class="scrollable custom-scrollbar rounded w-100">
    <app-export-preview-page
      [coverPage]="true"
      [isLandscape]="pdfIsLandscape$ | async"
      [showAgentInfo]="pdfPreviewData.showAgentInfo"
      *ngIf="pdfPreviewData.showCover"
    >
      <app-export-preview-cover [showAgentInfo]="pdfPreviewData.showAgentInfo"></app-export-preview-cover>
    </app-export-preview-page>
    <app-export-preview-page
      *ngIf="pdfPreviewData.showTable"
      @showHide
      [isLandscape]="pdfIsLandscape$ | async"
      [showAgentInfo]="pdfPreviewData.showAgentInfo"
    >
      <app-trend-data-table-container [showControls]="false" [title]="'Overview'" typographySize="small"></app-trend-data-table-container>
      <ng-container *ngIf="!(pdfIsLandscape$ | async)">
        <div *ngFor="let page of pdfPreviewData.pagedArray.pages | pagedArrayFilter: pdfPreviewData:true" class="chart-container">
          <app-trend-chart-container
            *ngFor="let chart of page"
            [chart]="chart"
            [title]="chart.label"
            typographySize="small"
          ></app-trend-chart-container>
        </div>
      </ng-container>
    </app-export-preview-page>
    <app-export-preview-page
      *ngFor="let page of pdfPreviewData.pagedArray.pages | pagedArrayFilter: pdfPreviewData:false"
      @showHide
      [isLandscape]="pdfIsLandscape$ | async"
      [showAgentInfo]="pdfPreviewData.showAgentInfo"
    >
      <div class="chart-container">
        <app-trend-chart-container
          *ngFor="let chart of page"
          [chart]="chart"
          [title]="chart.label"
          typographySize="small"
        ></app-trend-chart-container>
      </div>
    </app-export-preview-page>
  </div>
</div>
