import { createSelector } from '@ngrx/store';
import { IApplicationData } from '../../models/IApplicationData';
import { IAppState } from '../state/app.state';
import { IApplicationDataState } from '../state/applicationData.state';

const ApplicationData = (state: IAppState) => state.applicationData;

export const selectBrandInfo = (brand = '') =>
  createSelector(ApplicationData, state => {
    return state.brands[brand || state.default_brand];
  });

export const selectAllAppData = createSelector(ApplicationData, (state: IApplicationData) => state);

export const selectBrandCode = createSelector(
  ApplicationData,
  (state: IApplicationDataState) => state.brandLoaded && state.selectedBrandCode
);
