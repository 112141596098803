import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ICustomDataTypeByNumEnum, ICustomDisplayAsEnum } from '../../../../../models/ICustomDataTypeEnum';
import { CustomReportService } from '../../../../../services/custom-report.service';
import { CustomReportDataTypes, CustomReportDisplayAs, CustomReportLoading } from '../../../../../store/selectors/custom-graph.selector';
import { IAppState } from '../../../../../store/state/app.state';
import { ICustomGraphSetting, ICustomReportControl } from '../../../../../store/state/custom-report.state';

@Component({
  selector: 'app-custom-report-controls',
  templateUrl: './custom-report-controls.component.html',
  styleUrls: ['./custom-report-controls.component.scss']
})
export class CustomReportControlsComponent implements OnInit {
  @Output() threeDataPointSelected: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() dataPointSelected: EventEmitter<ICustomGraphSetting> = new EventEmitter<ICustomGraphSetting>();
  @Output() displayDataPointSelected: EventEmitter<ICustomGraphSetting> = new EventEmitter<ICustomGraphSetting>();
  dataPointControlArray: Observable<{
    dataTypes: ICustomReportControl[][];
    dataTypesSelected: [number, number];
    displayAsSelected: [number, number];
  }>;
  loading$: Observable<boolean>;
  displayAs$: Observable<[number, number]>;
  dataGraphTypes = {
    msp: ICustomDisplayAsEnum.Column,
    aopernl: ICustomDisplayAsEnum.Line,
    aoperai: ICustomDisplayAsEnum.Line,
    dom: ICustomDisplayAsEnum.Line,
    msi: ICustomDisplayAsEnum.Line,
    sppersf: ICustomDisplayAsEnum.Line,
    splp: ICustomDisplayAsEnum.Line,
    asp3: ICustomDisplayAsEnum.Column,
    alp3: ICustomDisplayAsEnum.Column,
    sppersf3: ICustomDisplayAsEnum.Line,
    splp3: ICustomDisplayAsEnum.Line,
    asp: ICustomDisplayAsEnum.Column,
    mlp: ICustomDisplayAsEnum.Column,
    alp: ICustomDisplayAsEnum.Column,
    nl: ICustomDisplayAsEnum.Line,
    ai: ICustomDisplayAsEnum.Line,
    solds: ICustomDisplayAsEnum.Line,
    expired: ICustomDisplayAsEnum.Line,
    ao: ICustomDisplayAsEnum.Line
  };
  threePointSelected = false;
  private srcArray: ICustomReportControl[][];

  constructor(private store: Store<IAppState>, private customReportSvc: CustomReportService) {}

  ngOnInit(): void {
    this.loading$ = this.store.select(CustomReportLoading);
    this.displayAs$ = this.store.select(CustomReportDisplayAs);
    this.dataPointControlArray = this.customReportSvc.getCustomReportTypes().pipe(
      switchMap(i => {
        const listOfTypes = i.map(x => {
          x.dataKey = x.dataKey.toLowerCase();
          if (x.dataKey === 'sppersqft3') {
            x.dataKey = 'sppersf3';
          } else if (x.dataKey === 'activeinventory') {
            x.dataKey = 'ai';
          }
          x.chartType = this.dataGraphTypes[x.dataKey];
          return x;
        });
        this.srcArray = Array(2).fill(listOfTypes);
        return combineLatest([this.store.select(CustomReportDataTypes), this.store.select(CustomReportDisplayAs)]);
      }),
      map(observable => {
        const [dataTypesSelected, displayAsSelected] = observable;
        const dataTypes = this.srcArray?.map((srcListOfTypes, idx) => {
          if (!srcListOfTypes) {
            return [];
          }
          return this.filterDropdownOptions(srcListOfTypes, dataTypesSelected, idx);
        });
        this.configureAxis();
        return { dataTypes, dataTypesSelected, displayAsSelected };
      })
    );
  }

  filterAvailableDataPoints(value: ICustomGraphSetting) {
    this.threePointSelected = false;
    this.dataPointSelected.emit(value);
  }

  adjustDisplayAs(value: ICustomGraphSetting) {
    this.displayDataPointSelected.emit(value);
  }

  private filterDropdownOptions(listOfTypes: ICustomReportControl[], opts: [number, number], idx: number) {
    return listOfTypes.map(x => {
      return {
        ...x,
        disabled: opts.filter((y, i) => i !== idx)?.includes(ICustomDataTypeByNumEnum[x.dataKey])
      };
    });
  }
  private configureAxis() {
    // this.store.dispatch(new SetCustomReportGraphAxis([0, 1]));
  }

  threePointHandler(dataPointIds: string[]) {
    this.threePointSelected = true;
    this.threeDataPointSelected.emit(dataPointIds);
  }
}
