<mat-expansion-panel>
  <mat-expansion-panel-header
    [ngClass]="{ active: locationGroup?.value }"
    class="border-bottom border-top px-3"
    collapsedHeight="80px"
    expandedHeight="80px"
    data-label="Location Filters"
  >
    <mat-panel-title class="fw-bold mat-panel-header align-items-center location-title">
      <span>Location Filters&nbsp;</span>
      <mat-icon
        #tooltip="matTooltip"
        (click)="tooltip.toggle()"
        *ngIf="!multiSelect"
        matTooltip="You can only select one location to run a market snapshot."
        matTooltipPosition="above"
        >info_outline
      </mat-icon>
      <app-chip-label
        *ngIf="multiSelect"
        [callbackFunction]="clearLocations"
        [chipClass]="(hasSelectedLocs ? 'on' : 'off') + ' clear'"
        [label]="'Reset'"
        class="ps-3"
      ></app-chip-label>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-accordion [multi]="false">
    <div *ngIf="multiSelect; else radioGroup">
      <mat-expansion-panel
        *ngFor="let locationType of locationBlocks"
        [disabled]="!locationType?.locations?.length"
        class="border-bottom"
        [class.hidden]="!locationType?.locations?.length"
      >
        <mat-expansion-panel-header
          class="sub-header"
          collapsedHeight="48px"
          expandedHeight="48px"
          [attr.data-label]="LocationDisplayEnum[locationType.type]"
        >
          <mat-panel-title class="text-capitalize typography-500">
            {{ LocationDisplayEnum[locationType.type] }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <cdk-virtual-scroll-viewport class="viewport" itemSize="50" [maxBufferPx]="50 * 5" [minBufferPx]="50 * 3">
          <mat-checkbox
            (change)="checkboxHandler()"
            class="locations-option"
            *cdkVirtualFor="let location of locationType?.locations; templateCacheSize: 0; trackBy: trackByFn"
            [disableRipple]="true"
            [disabled]="reportType === ReportTypeEnum.Uno && !location.hasData"
            [matTooltipDisabled]="reportType !== ReportTypeEnum.Uno || !!location.hasData"
            [(ngModel)]="location.checked"
            color="primary"
            matTooltip="This location does not have #1 claims."
            matTooltipPosition="above"
          >
            <span>{{ location.areaName }}</span>
          </mat-checkbox>
        </cdk-virtual-scroll-viewport>
      </mat-expansion-panel>
    </div>
    <ng-template #radioGroup>
      <mat-radio-group *ngIf="locationGroup?.get('locationFilters')" [formControl]="$any(locationGroup?.get('locationFilters'))">
        <mat-expansion-panel
          *ngFor="let locationType of locationBlocks"
          [disabled]="!locationType?.locations?.length"
          class="border-bottom"
        >
          <mat-expansion-panel-header
            class="sub-header"
            collapsedHeight="48px"
            expandedHeight="48px"
            [attr.data-label]="LocationDisplayEnum[locationType.type]"
          >
            <mat-panel-title class="text-capitalize typography-500">
              {{ LocationDisplayEnum[locationType.type] }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <cdk-virtual-scroll-viewport
            class="property-type-scroll viewport"
            itemSize="50"
            maxBufferPx="400"
            minBufferPx="200"
            [attr.data-label]="LocationDisplayEnum[locationType.type]"
          >
            <ng-container *cdkVirtualFor="let location of locationType?.locations; templateCacheSize: 0; trackBy: trackByFn">
              <mat-radio-button class="locations-option" [checked]="location.checked" [value]="[location]" disableRipple>
                {{ location.areaName }}
              </mat-radio-button>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </mat-expansion-panel>
      </mat-radio-group>
    </ng-template>
  </mat-accordion>
</mat-expansion-panel>
