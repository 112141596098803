import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { ICustomReportState } from '../state/custom-report.state';

const CustomReportData = (state: IAppState) => state.customReport;

export const CustomReportDataDetails = createSelector(CustomReportData, (state: ICustomReportState) => {
  return state.customReportDetails;
});

export const CustomReportDataTypes = createSelector(CustomReportData, (state: ICustomReportState) => {
  return state.customReportDataTypes;
});

export const CustomReportDisplayAs = createSelector(CustomReportData, (state: ICustomReportState) => {
  return state.customReportDisplayDataAs;
});
export const DisplayAllData = createSelector(CustomReportData, (state: ICustomReportState) => {
  return state.showAllData;
});
export const CustomReportLoading = createSelector(CustomReportData, (state: ICustomReportState) => {
  return state.loading;
});
export const CustomReportLoaded = createSelector(CustomReportData, (state: ICustomReportState) => {
  return state.loaded;
});
export const CustomReportError = createSelector(CustomReportData, (state: ICustomReportState) => {
  return state.hasError;
});
export const CustomReportAxis = createSelector(CustomReportData, (state: ICustomReportState) => {
  return state.customReportGraphAxis;
});
