<ng-container *ngIf="loaded$ | async; else loading">
  <div *ngIf="data as obj" class="custom-graph-container">
    <div class="custom-graph-content">
      <div class="cgc-container">
        <div class="export d-flex justify-content-end">
          <div>
            <button [matMenuTriggerFor]="menu" class="me-3" color="primary" mat-flat-button>
              Download Data
              <mat-icon> download</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="exportXLS()" mat-menu-item>Download .xlsx</button>
              <button (click)="exportCSV()" mat-menu-item>Download .csv</button>
            </mat-menu>
          </div>
          <button [matMenuTriggerFor]="base" color="primary" mat-flat-button>
            Export Report
            <mat-icon>share</mat-icon>
          </button>
          <mat-menu #base>
            <button (click)="exportPdf()" disableRipple="true" mat-menu-item>
              <mat-icon>file_download</mat-icon>
              Export as PDF
            </button>
          </mat-menu>
        </div>
        <div class="report-header">
          <div class="title">Custom Market Report</div>
        </div>
        <div class="d-flex flex-column">
          <app-custom-report-stats *ngIf="obj.details.header" [stats]="obj.details.header" class="d-flex"></app-custom-report-stats>
        </div>
        <div class="d-flex flex-column">
          <app-custom-report-controls
            (dataPointSelected)="dataPointSelectedHandler($event)"
            (displayDataPointSelected)="displayDataPointSelectedHandler($event)"
            (threeDataPointSelected)="threePointHandler($event)"
            class="container-space-between"
          ></app-custom-report-controls>
        </div>
      </div>
    </div>
    <ng-container>
      <app-trend-card *ngIf="cardData" [title]="graphTitle">
        <ng-container>
          <div class="custom-graph-card">
            <div class="mq-chart">
              <ng-container>
                <app-custom-chart
                  *ngIf="obj?.dataPoints && obj?.chartTypesConverted"
                  [axis]="chartAxis$ | async"
                  [chartDataObject]="obj?.dataPoints"
                  [chartTypes]="obj?.chartTypesConverted"
                  [dataTypes]="cardData.typeArr"
                  [yAxisLabels]="cardData.yAxisLabels"
                  [selectedMonth]="obj?.details.header.endDate"
                  [showTrendLines]="showTrendLines"
                ></app-custom-chart>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </app-trend-card>
    </ng-container>
    <div>
      <mat-checkbox (change)="toggleAllData($event)">Display All Data</mat-checkbox>&nbsp;
      <mat-checkbox (change)="setSelectedTrendLine($event)" [(ngModel)]="showTrendLines" [disabled]="disableTrendLines"
        >Show Trend Line</mat-checkbox
      >
    </div>
    <div class="mq-table" *ngIf="tableData$ | async as tData">
      <app-custom-report-table [tableHeaderArr]="tData.headerData" [tableRowsArr]="tData.rowData"></app-custom-report-table>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <app-trends-skeleton [height]="600"></app-trends-skeleton>
</ng-template>
<app-no-results
  (ctaClicked)="refreshPage()"
  *ngIf="customReportError$ | async"
  [buttonLabel]="'Refresh Page'"
  [noResultsMessage]="'Sorry, we\'ve timed out!'"
  [showTimeoutIcon]="true"
>
  The database connection has timed out, please refresh the page. If you need further assistance, please contact the Help Desk at
  <a href="tel:8776784357">877-678-HELP (877-678-4357).</a>
</app-no-results>
