<div class="save-search-container h-100 d-flex flex-column">
  <div class="d-flex justify-content-between saved-header-container">
    <div class="d-flex flex-column">
      <div class="pb-2 fw-bold">
        <span class="med-text">{{ this.data.title }}</span>
      </div>
      <div>
        <span class="saved-subtitle">{{ this.data.subTitle }}</span>
      </div>
    </div>
  </div>
  <div>
    <form [formGroup]="saveSearchForm">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Search Criteria Name</mat-label>
        <input matInput required placeholder="Search Criteria Name" formControlName="searchName" />
        <mat-error *ngIf="saveSearchForm.get('searchName').errors">{{ saveSearchForm.get('searchName').errors.invalid }}</mat-error>
      </mat-form-field>
      <mat-checkbox formControlName="default">Set this as your default search filter</mat-checkbox>
    </form>
  </div>
  <div class="btn-group flex-column align-items-end">
    <div>
      <button mat-button [mat-dialog-close]="false">
        Cancel
      </button>
      <button
        [disabled]="saveSearchForm.invalid"
        mat-flat-button
        color="primary"
        (click)="saveSearchHandler()"
        [mat-dialog-close]="saveSearchForm"
      >
        Save
      </button>
    </div>
  </div>
</div>
