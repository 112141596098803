import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FiltersV2Enum } from 'app/models/FilterData';
import { IMarketPositionData } from 'app/models/IMarketPositionData';
import { MarketFilters } from 'app/models/IMarketReportFilters';
import { selectUser } from 'app/store/selectors/userData.selector';
import { environment } from 'environments/environment';
import { cloneDeep, isEqual } from 'lodash-es';
import { throwError } from 'rxjs';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { ILocationsData } from '../models/ILocationsData';
import { IMarketReportDate } from '../models/IMarketReportDate';
import { selectMarketReportFilters } from '../store/selectors/market-position.selector';
import { IAppState } from '../store/state/app.state';

@Injectable()
export class MarketPositionService {
  private headers: HttpHeaders;
  private locationKeys: string[] = [
    FiltersV2Enum.city,
    FiltersV2Enum.county,
    FiltersV2Enum.zip,
    FiltersV2Enum.mlsArea,
    FiltersV2Enum.schoolDistrict,
    FiltersV2Enum.subDivision
  ];
  oktaId = '';

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string, private store: Store<IAppState>) {
    this.store
      .select(selectMarketReportFilters)
      .pipe(distinctUntilChanged((value1, value2) => isEqual(value1, value2)))
      .subscribe(d => {
        this.filters = cloneDeep(d);
      });

    this.store.select(selectUser).subscribe(user => {
      this.oktaId = user?.oktaId || '';
    });
  }

  private _filters: MarketFilters;

  get filters(): MarketFilters {
    return this._filters;
  }

  set filters(value: MarketFilters) {
    this._filters = value;
  }

  getMarketLocations(id: string) {
    return this._http.get<ILocationsData[]>(`${environment.apiBaseUrl}/api/Location/GetLocationsMarket?mlsId=${id}&oktaId=${this.oktaId}`);
  }

  getAllDateRanges() {
    return this._http.get<IMarketReportDate[]>(environment.apiBaseUrl + '/api/Report/GetMPDateRange');
  }

  getEndDateRange() {
    return this._http.get(environment.apiBaseUrl + '/api/Report/GetMPEndDate', { responseType: 'text' });
  }

  getMarketPositionDataById(reportId: string, oktaId: string) {
    return this._http.get<IMarketPositionData>(`${environment.apiBaseUrl}/api/Report/getmpreport/${reportId}?oktaId=${oktaId}`);
  }

  getMarketReportData(mlsId: string, marketFilters: MarketFilters) {
    const formattedMarketFilters = cloneDeep(marketFilters);
    Object.entries(marketFilters).map((filter, value) => {
      if (Object.values(this.locationKeys).includes(filter[0])) {
        formattedMarketFilters[filter[0]] = cloneDeep(marketFilters[filter[0]].map(String));
      }
    });

    formattedMarketFilters.propertyType = cloneDeep(marketFilters.propertyType?.map(String));
    this.filters = formattedMarketFilters;
    return this._http
      .post<IMarketPositionData>(`${environment.apiBaseUrl}/api/Report/${mlsId}/postmpreport`, formattedMarketFilters, {
        headers: this.headers
      })
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
