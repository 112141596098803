<ng-container *ngIf="!($ready | async); else loaded">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="pb-3">Generating...</div>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-container>
<ng-template #loaded>
  <div class="d-flex justify-content-end close-modal d-md-none">
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </div>
  <div>
    <h1 class="share-title" mat-dialog-title>Share Report</h1>
  </div>
  <mat-dialog-content class="share-container">
    <div class="share-container">
      <span class="share-action">Share webpage to</span>
      <div class="buttons-container">
        <button
          (click)="socialLinkGenerate()"
          [attr.data-area-name]="reportHeaderEntity?.areaLocalName"
          [attr.data-area-type]="reportHeaderEntity?.areaType"
          [attr.data-claim-id]="data.reportId"
          [attr.data-date-range]="reportHeaderEntity?.displayDateRange"
          [attr.data-level]="reportHeaderEntity?.groupByLevel"
          [attr.data-mls-id]="reportHeaderEntity?.mlsId"
          [attr.data-price-type]="reportHeaderEntity?.priceType"
          [attr.data-transaction-side]="reportTransactionSide"
          [attr.data-value-type]="reportValueType"
          [attr.data-value]="reportValue"
          [url]="metaLink"
          class="share-full-width"
          mat-stroked-button
          shareButton="facebook"
        >
          <mat-icon>facebook</mat-icon>
          <span class="share-text">Facebook</span>
        </button>
        <button #fbBtn [url]="this.metaLink" class="d-none" id="facebook" shareButton="facebook"></button>
        <button
          (click)="socialLinkGenerate()"
          [attr.data-area-name]="reportHeaderEntity?.areaLocalName"
          [attr.data-area-type]="reportHeaderEntity?.areaType"
          [attr.data-claim-id]="data.reportId"
          [attr.data-date-range]="reportHeaderEntity?.displayDateRange"
          [attr.data-level]="reportHeaderEntity?.groupByLevel"
          [attr.data-mls-id]="reportHeaderEntity?.mlsId"
          [attr.data-price-type]="reportHeaderEntity?.priceType"
          [attr.data-transaction-side]="reportTransactionSide"
          [attr.data-value-type]="reportValueType"
          [attr.data-value]="reportValue"
          [url]="metaLink"
          class="share-full-width"
          id="linkedin"
          mat-stroked-button
          shareButton="linkedin"
        >
          <app-linkedin-svg></app-linkedin-svg>
          <span class="share-text">LinkedIn</span>
        </button>
        <button
          (click)="socialLinkGenerate()"
          [attr.data-area-name]="reportHeaderEntity?.areaLocalName"
          [attr.data-area-type]="reportHeaderEntity?.areaType"
          [attr.data-claim-id]="data.reportId"
          [attr.data-date-range]="reportHeaderEntity?.displayDateRange"
          [attr.data-level]="reportHeaderEntity?.groupByLevel"
          [attr.data-mls-id]="reportHeaderEntity?.mlsId"
          [attr.data-price-type]="reportHeaderEntity?.priceType"
          [attr.data-transaction-side]="reportTransactionSide"
          [attr.data-value-type]="reportValueType"
          [attr.data-value]="reportValue"
          [description]="data.description"
          [image]="data.image"
          [url]="shareLink"
          class="share-full-width"
          id="pinterest"
          mat-stroked-button
          shareButton="pinterest"
        >
          <app-pinterest-svg></app-pinterest-svg>
          <span class="share-text">Pinterest</span>
        </button>
        <button
          (click)="socialLinkGenerate()"
          [attr.data-area-name]="reportHeaderEntity?.areaLocalName"
          [attr.data-area-type]="reportHeaderEntity?.areaType"
          [attr.data-claim-id]="data.reportId"
          [attr.data-date-range]="reportHeaderEntity?.displayDateRange"
          [attr.data-level]="reportHeaderEntity?.groupByLevel"
          [attr.data-mls-id]="reportHeaderEntity?.mlsId"
          [attr.data-price-type]="reportHeaderEntity?.priceType"
          [attr.data-transaction-side]="reportTransactionSide"
          [attr.data-value-type]="reportValueType"
          [attr.data-value]="reportValue"
          [tags]="data.tags"
          [url]="metaLink"
          id="twitter"
          mat-stroked-button
          shareButton="twitter"
        >
          <app-twitter-svg></app-twitter-svg>
          <span class="share-text">Twitter</span>
        </button>
        <button (click)="onCopyURL()" id="copy" mat-stroked-button>
          <mat-icon>link</mat-icon>
          <span class="share-text">Copy URL</span>
        </button>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="social-close justify-content-md-end justify-content-center m-0 p-0">
    <button (click)="closeMe()" id="cancel" mat-button>Cancel</button>
  </mat-dialog-actions>
</ng-template>
