import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { ICustomDataTypeByNumEnum } from '../../../../../../models/ICustomDataTypeEnum';
import { ICustomGraphSetting, ICustomReportControl } from '../../../../../../store/state/custom-report.state';

@Component({
  selector: 'app-custom-graph-group-by-dropdown',
  templateUrl: './custom-graph-group-by-dropdown.component.html',
  styleUrls: ['./custom-graph-group-by-dropdown.component.scss']
})
export class CustomGraphGroupByDropdownComponent implements OnInit {
  ICustomDataTypeByNumEnum = ICustomDataTypeByNumEnum;
  @Input() dataPoints: ICustomReportControl[];
  @Input() controlIndex: number;
  @Input() dataTypeValue: number;
  @Input() displayAsValue: number;
  @Input() disabled = false;
  @Input() showThreePoints = false;
  @Output() selectedDataPoint: EventEmitter<ICustomGraphSetting> = new EventEmitter<ICustomGraphSetting>();
  @Output() selectedDisplayAs: EventEmitter<ICustomGraphSetting> = new EventEmitter<ICustomGraphSetting>();
  @Output() threeDataPointChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  selectedDataType: string;

  displayTypes;

  ngOnInit(): void {}

  emitDataTypeChange(event: MatSelectChange, idx: number) {
    if (event.value.length === 3) {
      this.emitThreeDataPointChange(event);
    } else {
      this.selectedDataPoint.emit(this.extracted(event, idx));
      const selectedDataPoint = this.dataPoints.find(dataPoint => {
        return ICustomDataTypeByNumEnum[dataPoint.dataKey] === event.value;
      });
      this.selectedDisplayAs.emit(this.extracted({ value: selectedDataPoint.chartType } as any, idx));
    }
  }

  emitThreeDataPointChange(event: MatSelectChange): void {
    this.threeDataPointChange.emit(event.value);
  }

  private extracted(event: MatSelectChange | MatRadioChange, idx: number) {
    const value = event.value;
    return {
      idx,
      value
    };
  }
}
