<mat-card>
  <div class="icon-container">
    <svg
      class="illustration"
      *ngIf="!showTimeoutIcon"
      width="349"
      height="146"
      viewBox="0 0 349 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="163.729" cy="139.958" rx="138.354" ry="6.04167" fill="#E2E2E2" />
      <path
        opacity="0.2"
        d="M314.712 69.6034C331.4 132.786 276.032 132.441 250.239 138.75H79.5087C53.0058 130.491 0.000335693 128.771 0.000335693 84.0349C0.000335693 -4.40428 61.0914 26.0565 102.265 26.0565C143.44 26.0565 152.225 29.4218 195.734 9.70719C236.97 -8.97775 295.986 -1.29943 314.712 69.6034Z"
        fill="#E2E2E2"
        fill-opacity="0.2"
      />
      <path
        d="M134.125 21.0832C134.125 16.665 137.707 13.0833 142.125 13.0833H153.458V37.2499H134.125V21.0832Z"
        fill="white"
        stroke="#E2E2E2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M250.125 1H153.458V37.25V127.417V129.083V130.75C153.458 135.168 149.876 138.75 145.458 138.75H153.458H158.291H172.791H242.125C246.543 138.75 250.125 135.168 250.125 130.75V1Z"
        fill="white"
      />
      <path
        d="M153.458 1V0.5H152.958V1H153.458ZM250.125 1H250.625V0.5H250.125V1ZM153.458 1.5H250.125V0.5H153.458V1.5ZM153.958 37.25V1H152.958V37.25H153.958ZM153.958 127.417V37.25H152.958V127.417H153.958ZM153.958 129.083V127.417H152.958V129.083H153.958ZM153.958 130.75V129.083H152.958V130.75H153.958ZM145.458 139.25C150.152 139.25 153.958 135.444 153.958 130.75H152.958C152.958 134.892 149.6 138.25 145.458 138.25V139.25ZM153.458 138.25H145.458V139.25H153.458V138.25ZM158.291 138.25H153.458V139.25H158.291V138.25ZM172.791 138.25H158.291V139.25H172.791V138.25ZM242.125 138.25H172.791V139.25H242.125V138.25ZM249.625 130.75C249.625 134.892 246.267 138.25 242.125 138.25V139.25C246.819 139.25 250.625 135.444 250.625 130.75H249.625ZM249.625 1V130.75H250.625V1H249.625Z"
        fill="#939393"
      />
      <path d="M95.4587 83.1667H86.542C82.1237 83.1667 78.542 86.7485 78.542 91.1667V138.75H95.4587" stroke="#1F69FF" />
      <path
        d="M114.791 61.4167H103.458C99.0397 61.4167 95.458 64.9985 95.458 69.4168V138.75H143.791C137.025 138.75 134.527 132.708 134.125 129.688V74.6672C134.125 69.6417 128.124 67.0421 124.458 70.4792C120.792 73.9164 114.791 71.3168 114.791 66.2913V61.4167Z"
        fill="white"
        stroke="#939393"
      />
      <path
        d="M163.125 126.25C163.125 125.145 164.02 124.25 165.125 124.25H197.375C198.48 124.25 199.375 125.145 199.375 126.25V127.083C199.375 128.188 198.48 129.083 197.375 129.083H165.125C164.02 129.083 163.125 128.188 163.125 127.083V126.25Z"
        stroke="#F4B124"
      />
      <path
        d="M163.125 116.583C163.125 115.479 164.02 114.583 165.125 114.583H238.458C239.563 114.583 240.458 115.479 240.458 116.583V117.417C240.458 118.521 239.563 119.417 238.458 119.417H165.125C164.02 119.417 163.125 118.521 163.125 117.417V116.583Z"
        stroke="#1F69FF"
      />
      <path
        d="M93.8478 74.9373L91.7852 79.3194L86.9695 79.6961C86.0527 79.7682 85.7701 80.3096 85.6936 80.5381C85.6152 80.7677 85.505 81.3724 86.1764 82.0238L89.6981 85.4369L88.6484 90.1894C88.4474 91.0954 88.8763 91.5421 89.0702 91.6911C89.2633 91.8429 89.8011 92.1453 90.6082 91.7129L94.8471 89.4403L99.0139 92.0022C99.8064 92.4902 100.354 92.2246 100.551 92.0869C100.663 92.0099 100.869 91.8311 100.98 91.5037C101.068 91.245 101.081 90.9476 101.018 90.6153L100.116 85.7976L103.74 82.6275C103.989 82.4095 104.159 82.1679 104.248 81.911C104.36 81.5826 104.309 81.3107 104.268 81.1788C104.198 80.9439 103.933 80.3834 103.018 80.2483L98.2213 79.5416L96.2946 75.0233C95.9286 74.1621 95.3253 74.0507 95.0836 74.0419C94.8453 74.031 94.24 74.1028 93.8478 74.9373Z"
        fill="white"
        stroke="#DE342B"
        stroke-miterlimit="10"
      />
      <path
        d="M55.1814 46.2374L54.106 40.2522L58.6229 36.3658C59.4843 35.6266 59.3766 34.8651 59.2894 34.5753C59.2051 34.2838 58.8818 33.5853 57.7505 33.4066L51.815 32.4731L49.4693 26.8443C49.0216 25.7709 48.2781 25.6264 47.9792 25.6145C47.6786 25.5996 46.9289 25.68 46.4358 26.7105L43.8412 32.1181L37.871 32.5242C36.7357 32.6018 36.3812 33.2696 36.2808 33.553C36.2248 33.7123 36.148 34.0436 36.2709 34.4643C36.3687 34.7931 36.567 35.1089 36.8653 35.4038L41.1985 39.6803L39.8542 45.5626C39.7638 45.9673 39.7663 46.3388 39.8593 46.6662C39.9804 47.0839 40.2265 47.323 40.3603 47.4301C40.5983 47.6206 41.2614 48 42.2656 47.4735L47.5378 44.7082L52.6763 47.9337C53.6554 48.5486 54.3337 48.2267 54.5811 48.0593C54.8344 47.8884 55.3871 47.3767 55.1814 46.2374Z"
        stroke="#1F69FF"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M29.6042 73.5001C31.9399 73.5001 33.8333 71.6066 33.8333 69.2709C33.8333 66.9352 31.9399 65.0417 29.6042 65.0417C27.2685 65.0417 25.375 66.9352 25.375 69.2709C25.375 71.6066 27.2685 73.5001 29.6042 73.5001Z"
        stroke="#F4B124"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M100.291 46.9167C104.963 46.9167 108.75 43.1297 108.75 38.4583C108.75 33.7869 104.963 30 100.291 30C95.6199 30 91.833 33.7869 91.833 38.4583C91.833 43.1297 95.6199 46.9167 100.291 46.9167Z"
        stroke="#E96084"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M259.23 20.8525L253.546 21.1207L250.409 16.3689C249.813 15.4649 249.094 15.4716 248.812 15.5146C248.531 15.561 247.848 15.7762 247.56 16.8226L246.06 22.3095L240.571 23.8227C239.524 24.1108 239.309 24.7963 239.266 25.0777C239.219 25.3592 239.216 26.0777 240.12 26.6705L244.877 29.7931L244.622 35.4786C244.572 36.5614 245.159 36.9786 245.41 37.1078C245.553 37.1806 245.854 37.2965 246.255 37.2303C246.57 37.1806 246.884 37.0316 247.192 36.7866L251.634 33.2302L256.964 35.2302C257.332 35.366 257.676 35.4124 257.991 35.3627C258.392 35.2998 258.644 35.0978 258.756 34.9819C258.958 34.7799 259.382 34.2037 259.001 33.1872L256.987 27.8659L260.538 23.4187C261.214 22.571 260.985 21.8889 260.856 21.6373C260.73 21.3823 260.313 20.7995 259.23 20.8525Z"
        fill="white"
        stroke="#DE342B"
        stroke-miterlimit="10"
      />
      <path
        d="M304.443 11.5493L300.171 16.8885L293.481 15.8739C292.206 15.6792 291.643 16.3344 291.46 16.6227C291.276 16.911 290.927 17.7048 291.625 18.8093L295.294 24.5979L292.279 30.7683C291.706 31.944 292.14 32.6928 292.353 32.9587C292.566 33.2283 293.198 33.8124 294.444 33.4754L300.98 31.7119L305.81 36.5381C306.729 37.4592 307.564 37.2683 307.876 37.1447C308.056 37.0736 308.395 36.8938 308.656 36.482C308.861 36.1562 308.979 35.7519 309.005 35.2801L309.38 28.402L315.379 25.2157C315.79 24.9947 316.103 24.7214 316.309 24.3957C316.57 23.9838 316.592 23.5944 316.581 23.3997C316.562 23.0552 316.39 22.2053 315.188 21.7298L308.88 19.2437L307.759 12.4479C307.545 11.1524 306.762 10.8079 306.435 10.7218C306.104 10.6282 305.255 10.5309 304.443 11.5493Z"
        stroke="#1F69FF"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M326.25 108.542C328.92 108.542 331.084 106.378 331.084 103.708C331.084 101.039 328.92 98.875 326.25 98.875C323.581 98.875 321.417 101.039 321.417 103.708C321.417 106.378 323.581 108.542 326.25 108.542Z"
        stroke="#F4B124"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M270.667 110.958C276.673 110.958 281.542 106.089 281.542 100.083C281.542 94.0772 276.673 89.2083 270.667 89.2083C264.661 89.2083 259.792 94.0772 259.792 100.083C259.792 106.089 264.661 110.958 270.667 110.958Z"
        stroke="#E96084"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M337.729 71.0834C343.401 71.0834 348 66.485 348 60.8126C348 55.1402 343.401 50.5417 337.729 50.5417C332.056 50.5417 327.458 55.1402 327.458 60.8126C327.458 66.485 332.056 71.0834 337.729 71.0834Z"
        stroke="#DE342B"
        stroke-miterlimit="10"
      />
      <path
        d="M114.791 79.5417L134.124 73.5L134.124 45.25C134.124 40.8317 130.543 37.25 126.124 37.25L122.791 37.25C118.373 37.25 114.791 40.8317 114.791 45.25L114.791 79.5417Z"
        fill="white"
      />
      <path
        d="M114.791 79.5417H114.291L114.291 80.2218L114.94 80.0189L114.791 79.5417ZM134.124 73.5L134.273 73.9772L134.624 73.8676V73.5H134.124ZM122.791 37.25V37.75V37.25ZM114.791 45.25H115.291H114.791ZM134.124 45.25L133.624 45.25L134.124 45.25ZM126.124 37.25V36.75V37.25ZM114.94 80.0189L134.273 73.9772L133.975 73.0228L114.642 79.0644L114.94 80.0189ZM134.624 73.5L134.624 45.25L133.624 45.25L133.624 73.5H134.624ZM126.124 36.75L122.791 36.75V37.75L126.124 37.75L126.124 36.75ZM114.291 45.25L114.291 79.5417H115.291L115.291 45.25H114.291ZM122.791 36.75C118.097 36.75 114.291 40.5556 114.291 45.25H115.291C115.291 41.1079 118.649 37.75 122.791 37.75V36.75ZM134.624 45.25C134.624 40.5556 130.819 36.75 126.124 36.75L126.124 37.75C130.266 37.75 133.624 41.1079 133.624 45.25L134.624 45.25Z"
        fill="#1F69FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M126.125 37.25C130.543 37.25 134.125 40.8317 134.125 45.25V46.9167H134.125L134.125 48.5833L134.125 130.75C134.125 135.168 137.707 138.75 142.125 138.75H145.458C149.877 138.75 153.458 135.168 153.458 130.75L153.458 37.25H138.958H134.125H126.125Z"
        fill="white"
      />
      <path
        d="M134.125 46.9167H133.625V47.4167H134.125V46.9167ZM134.125 46.9167L134.625 46.9167L134.625 46.4167H134.125V46.9167ZM134.125 48.5833L133.625 48.5833V48.5833H134.125ZM134.125 130.75H134.625H134.125ZM153.458 130.75H152.958H153.458ZM153.458 37.25L153.958 37.25V36.75H153.458V37.25ZM134.625 45.25C134.625 40.5556 130.819 36.75 126.125 36.75V37.75C130.267 37.75 133.625 41.1079 133.625 45.25H134.625ZM134.625 46.9167V45.25H133.625V46.9167H134.625ZM134.125 46.4167H134.125V47.4167H134.125V46.4167ZM134.625 48.5833L134.625 46.9167L133.625 46.9167L133.625 48.5833L134.625 48.5833ZM134.625 130.75L134.625 48.5833H133.625L133.625 130.75H134.625ZM142.125 138.25C137.983 138.25 134.625 134.892 134.625 130.75H133.625C133.625 135.444 137.431 139.25 142.125 139.25V138.25ZM145.458 138.25H142.125V139.25H145.458V138.25ZM152.958 130.75C152.958 134.892 149.6 138.25 145.458 138.25V139.25C150.153 139.25 153.958 135.444 153.958 130.75H152.958ZM152.958 37.25L152.958 130.75H153.958L153.958 37.25L152.958 37.25ZM138.958 37.75H153.458V36.75H138.958V37.75ZM134.125 37.75H138.958V36.75H134.125V37.75ZM126.125 37.75H134.125V36.75H126.125V37.75Z"
        fill="#F4B124"
      />
      <path
        d="M63.3568 119.483L60.3391 118.444L59.7079 115.367C59.5886 114.782 59.2126 114.636 59.0564 114.601C58.9002 114.566 58.4989 114.535 58.128 115.01L56.1886 117.504L53.0097 117.141C52.4039 117.071 52.1474 117.378 52.0648 117.512C51.9823 117.647 51.8273 118.013 52.174 118.504L53.9927 121.086L52.6592 123.94C52.4052 124.483 52.6224 124.817 52.7265 124.935C52.7862 125.003 52.9183 125.123 53.1405 125.173C53.3158 125.213 53.5114 125.202 53.7222 125.14L56.7868 124.24L59.1415 126.367C59.3041 126.513 59.4742 126.607 59.6495 126.646C59.8718 126.696 60.0458 126.645 60.1283 126.61C60.2756 126.549 60.6198 126.342 60.6338 125.744L60.7087 122.607L63.4965 121.067C64.0273 120.774 64.0527 120.378 64.0388 120.222C64.0261 120.066 63.9321 119.681 63.3568 119.483Z"
        stroke="#E96084"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M133.521 114.583C135.857 114.583 137.75 112.69 137.75 110.354C137.75 108.018 135.857 106.125 133.521 106.125C131.185 106.125 129.292 108.018 129.292 110.354C129.292 112.69 131.185 114.583 133.521 114.583Z"
        fill="white"
        stroke="#DE342B"
        stroke-miterlimit="10"
      />
      <path
        d="M204.978 72.1375C204.945 72.8112 204.39 73.3407 203.715 73.3407H200.434C199.76 73.3407 199.204 72.8112 199.172 72.1375L197.872 45.3225C197.837 44.601 198.412 43.9968 199.135 43.9968H205.015C205.737 43.9968 206.313 44.601 206.278 45.3225L204.978 72.1375ZM206.058 84.1424C206.058 84.8407 205.492 85.4068 204.793 85.4068H199.356C198.658 85.4068 198.092 84.8407 198.092 84.1424V78.2306C198.092 77.5323 198.658 76.9662 199.356 76.9662H204.793C205.492 76.9662 206.058 77.5323 206.058 78.2306V84.1424Z"
        fill="#FBE7E6"
      />
      <path
        d="M203.019 73.2084C203.819 73.2084 204.479 72.5802 204.517 71.781L205.843 44.4371C205.884 43.5812 205.202 42.8645 204.345 42.8645H197.909C197.052 42.8645 196.369 43.5812 196.411 44.4371L197.736 71.781C197.775 72.5803 198.434 73.2084 199.235 73.2084H203.019ZM204.11 85.2745C204.938 85.2745 205.61 84.6029 205.61 83.7745V77.3339C205.61 76.5054 204.938 75.8339 204.11 75.8339H198.144C197.315 75.8339 196.644 76.5054 196.644 77.3339V83.7745C196.644 84.6029 197.315 85.2745 198.144 85.2745H204.11Z"
        stroke="#DE342B"
      />
      <path
        d="M200.701 23.25C201.278 22.25 202.722 22.25 203.299 23.25L243.136 92.25C243.714 93.25 242.992 94.5 241.837 94.5H162.163C161.008 94.5 160.286 93.25 160.864 92.25L200.701 23.25Z"
        stroke="#DE342B"
      />
    </svg>

    <svg
      class="illustration"
      *ngIf="showTimeoutIcon"
      width="400"
      height="204"
      viewBox="0 0 400 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M209.598 114.018C209.537 115.285 208.492 116.28 207.224 116.28H201.056C199.788 116.28 198.743 115.285 198.682 114.018L196.238 63.606C196.172 62.2495 197.254 61.1137 198.613 61.1137H209.668C211.026 61.1137 212.108 62.2495 212.042 63.606L209.598 114.018ZM211.628 136.587C211.628 137.9 210.564 138.964 209.251 138.964H199.029C197.717 138.964 196.652 137.9 196.652 136.587V125.473C196.652 124.16 197.717 123.096 199.029 123.096H209.251C210.564 123.096 211.628 124.16 211.628 125.473V136.587Z"
        fill="#FBE7E6"
      />
      <path
        d="M206.755 115.592C207.555 115.592 208.214 114.963 208.253 114.164L210.83 60.9978C210.872 60.1418 210.189 59.4251 209.332 59.4251H195.386C194.529 59.4251 193.846 60.1418 193.887 60.9978L196.465 114.164C196.503 114.963 197.163 115.592 197.963 115.592H206.755ZM208.847 138.276C209.675 138.276 210.347 137.604 210.347 136.776V122.908C210.347 122.079 209.675 121.408 208.847 121.408H195.871C195.042 121.408 194.371 122.079 194.371 122.908V136.776C194.371 137.604 195.042 138.276 195.871 138.276H208.847Z"
        stroke="#DE342B"
      />
      <path
        d="M202.701 19.2499C203.278 18.2499 204.722 18.2499 205.299 19.2499L283.241 154.25C283.819 155.25 283.097 156.5 281.942 156.5H126.058C124.903 156.5 124.181 155.25 124.759 154.25L202.701 19.2499Z"
        stroke="#DE342B"
      />
      <path
        d="M261.5 65.9999C265.09 65.9999 268 63.0897 268 59.4999C268 55.91 265.09 52.9999 261.5 52.9999C257.91 52.9999 255 55.91 255 59.4999C255 63.0897 257.91 65.9999 261.5 65.9999Z"
        stroke="#1F69FF"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M139 70.9999C144.523 70.9999 149 66.5227 149 60.9999C149 55.477 144.523 50.9999 139 50.9999C133.477 50.9999 129 55.477 129 60.9999C129 66.5227 133.477 70.9999 139 70.9999Z"
        stroke="#1F69FF"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        opacity="0.4"
        d="M168.998 182.161C191.089 182.161 208.998 177.012 208.998 170.661C208.998 164.31 191.089 159.161 168.998 159.161C146.907 159.161 128.998 164.31 128.998 170.661C128.998 177.012 146.907 182.161 168.998 182.161Z"
        fill="#E2E2E2"
      />
      <path
        opacity="0.9"
        d="M177.998 173.161C185.178 173.161 190.998 171.594 190.998 169.661C190.998 167.728 185.178 166.161 177.998 166.161C170.818 166.161 164.998 167.728 164.998 169.661C164.998 171.594 170.818 173.161 177.998 173.161Z"
        fill="#E2E2E2"
      />
      <path
        d="M156.416 95.098L171.316 79.1609L181.998 167.992L180.113 166.905L180.113 169.259L177.6 169.169L178.228 171.161L176.792 171.161L117.998 127.153L138.733 109.89L130.206 96.0035L156.416 95.098Z"
        fill="white"
      />
      <path
        d="M156.416 95.098L171.316 79.1609L181.998 167.992L180.113 166.905M156.416 95.098L130.206 96.0035M156.416 95.098L180.113 166.905M130.206 96.0035L147.799 102.342M130.206 96.0035L138.733 109.89M147.799 102.342L138.733 109.89M147.799 102.342L180.113 169.259M138.733 109.89L117.998 127.153L176.792 171.161L178.228 171.161L177.6 169.169L180.113 169.259M180.113 166.905L180.113 169.259"
        stroke="#F4B124"
      />
      <path
        d="M125.95 90.3361C126.891 90.682 127.436 89.3053 126.496 88.9594C123.074 87.705 120.164 85.7351 117.779 83.0333C117.119 82.2889 115.92 83.1889 116.575 83.9313C119.134 86.8293 122.281 88.9888 125.95 90.3361Z"
        fill="#E2E2E2"
      />
      <path
        d="M111.911 80.1198C112.695 80.7487 113.598 79.567 112.822 78.9465C111.129 77.5855 109.933 75.854 109.24 73.8542C108.924 72.9327 107.517 73.5115 107.832 74.4219C108.609 76.6618 110.018 78.6002 111.911 80.1198Z"
        fill="#E2E2E2"
      />
      <path
        d="M102.965 67.642C103.365 68.5333 104.773 67.9656 104.373 67.0743C103.005 64.0163 101.092 61.2627 98.6555 58.8748C97.951 58.187 96.7469 59.0851 97.4562 59.7748C99.8004 62.0728 101.641 64.6972 102.965 67.642Z"
        fill="#E2E2E2"
      />
      <path
        d="M94.9927 55.6351C95.6869 56.3348 96.8958 55.4386 96.192 54.7352C92.9705 51.4951 89.1756 49.1108 84.8011 47.6544C83.8547 47.3379 83.3046 48.7127 84.2559 49.0311C88.3595 50.3962 91.9628 52.5877 94.9927 55.6351Z"
        fill="#E2E2E2"
      />
      <path
        d="M82.433 61.1613C82.2777 62.1032 83.8194 62.2838 83.9729 61.3465C84.6141 57.5287 84.5457 53.6846 83.7844 49.8422C83.5951 48.8966 82.071 49.0946 82.2584 50.0447C82.9981 53.7725 83.0566 57.4586 82.433 61.1613Z"
        fill="#E2E2E2"
      />
      <path
        d="M68.2725 72.9818C74.4764 74.3294 80.0226 70.7554 81.3392 64.9223C81.5512 63.9923 80.0077 63.8163 79.7993 64.7371C78.6498 69.8241 73.6956 72.6505 68.4166 71.5047C67.435 71.2911 67.3054 72.774 68.2725 72.9818Z"
        fill="#E2E2E2"
      />
      <path
        d="M81.0989 41.5002C81.4995 42.3915 82.9096 41.8192 82.5072 40.9325C81.2855 38.2243 79.7232 35.6997 77.8347 33.3645C77.2088 32.5911 76.0095 33.491 76.6306 34.2626C78.4414 36.498 79.9266 38.9071 81.0989 41.5002Z"
        fill="#E2E2E2"
      />
      <path
        d="M78.1881 47.9871C79.1588 48.2282 79.2883 46.7453 78.3291 46.5035C75.4333 45.7813 72.4998 45.7183 69.6231 46.315C68.6627 46.5132 68.9139 47.993 69.881 47.7921C72.6538 47.218 75.4057 47.2886 78.1881 47.9871Z"
        fill="#E2E2E2"
      />
      <path
        d="M60.5198 69.9217C61.3736 70.4297 62.2849 69.2564 61.4329 68.7438C59.4268 67.5511 57.9428 65.8857 57.0431 63.7881C56.6558 62.8915 55.2524 63.4611 55.6331 64.3604C56.6224 66.6687 58.3033 68.5979 60.5198 69.9217Z"
        fill="#E2E2E2"
      />
      <path
        d="M64.6177 49.6518C65.5614 49.3832 64.9171 48.0079 63.98 48.2738C60.5953 49.2373 57.9702 51.5102 56.5268 54.591C56.123 55.4556 57.5742 56.0197 57.9744 55.1643C59.264 52.4155 61.6212 50.5089 64.6177 49.6518Z"
        fill="#E2E2E2"
      />
      <path
        d="M71.4804 27.5886C72.1335 28.3357 73.3328 27.4357 72.6797 26.6887C70.612 24.3408 68.2644 22.2909 65.623 20.56C64.7904 20.0126 63.8791 21.1859 64.7117 21.7333C67.2464 23.3954 69.494 25.3314 71.4804 27.5886Z"
        fill="#E2E2E2"
      />
      <path
        d="M57.3098 15.9828C58.1654 16.4863 59.0815 15.3149 58.221 14.8095C56.3518 13.7082 54.4086 12.8218 52.3402 12.1672C51.3865 11.8691 50.8364 13.2439 51.7949 13.5439C53.7247 14.1542 55.5617 14.9561 57.3098 15.9828Z"
        fill="#E2E2E2"
      />
      <path
        d="M45.7098 10.6136C46.6865 10.8253 46.8209 9.34429 45.8508 9.12995C42.6925 8.43649 39.5162 8.02792 36.2972 7.91035C35.3069 7.87382 35.1562 9.35367 36.1532 9.38752C39.3751 9.51159 42.5532 9.91557 45.7098 10.6136Z"
        fill="#E2E2E2"
      />
      <path
        d="M28.6434 8.02685C29.6039 7.82861 29.3526 6.34883 28.3855 6.54976C24.3196 7.38677 20.8109 9.3257 18.0387 12.2518C17.3768 12.9505 18.6362 13.8581 19.2878 13.1712C21.7783 10.5476 25.0025 8.77737 28.6434 8.02685Z"
        fill="#E2E2E2"
      />
      <path
        d="M159.833 32.6665C162.503 32.6665 164.667 30.5026 164.667 27.8332C164.667 25.1638 162.503 22.9999 159.833 22.9999C157.164 22.9999 155 25.1638 155 27.8332C155 30.5026 157.164 32.6665 159.833 32.6665Z"
        stroke="#F4B124"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M270.5 138C274.642 138 278 134.642 278 130.5C278 126.358 274.642 123 270.5 123C266.358 123 263 126.358 263 130.5C263 134.642 266.358 138 270.5 138Z"
        fill="white"
        stroke="#F4B124"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  </div>

  <h2>{{ noResultsMessage }}</h2>
  <p><ng-content></ng-content></p>
  <button *ngIf="showButton" mat-raised-button color="primary" mat-flat-button (click)="onClick()">{{ buttonLabel }}</button>
</mat-card>
