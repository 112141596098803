import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, retry, take } from 'rxjs/operators';
import { IReportDetail } from '../models/IReportDetail';
import { selectUser } from '../store/selectors/userData.selector';
import { IAppState } from '../store/state/app.state';
import { ReportDetailService } from './report-details.service';

@Injectable()
export class EncryptService {
  private headers: HttpHeaders;

  constructor(private _http: HttpClient, private reportDetailService: ReportDetailService, private store: Store<IAppState>) {}

  getEncryptedValue(msUnoReportId: number, isCardLink: boolean): Observable<any> {
    return this.store.select(selectUser).pipe(
      take(1),
      mergeMap(userClaims => {
        return this._http
          .get(
            environment.apiBaseUrl +
              '/api/encryption/getencryptedreportidvalue?reportid=' +
              msUnoReportId +
              '&isCardLink=' +
              isCardLink +
              '&oktaId=' +
              userClaims.oktaId,
            {
              headers: this.headers,
              responseType: 'text'
            }
          )
          .pipe(catchError(this.errorHandler));
      })
    );
  }

  getEncryptedAgentReportValue(oktaId: string, msUnoReportId: string, isCardLink: boolean): Observable<any> {
    return this._http
      .get(
        environment.apiBaseUrl +
          '/api/encryption/getencryptedagentreportidvalue?oktaId=' +
          oktaId +
          '&reportid=' +
          msUnoReportId +
          '&isCardLink=' +
          isCardLink,
        { headers: this.headers, responseType: 'text' }
      )
      .pipe(catchError(this.errorHandler));
  }

  getDecryptedValue(encryptedstring: string): Observable<string> {
    return this._http
      .get(environment.apiBaseUrl + '/api/encryption/getdecryptedvalue?data=' + encryptedstring, {
        headers: this.headers,
        responseType: 'text'
      })
      .pipe(retry(3), catchError(this.errorHandler));
  }

  getDecryptedReportDetails(encryptedString: string): Observable<[string, IReportDetail, string]> {
    return this.getDecryptedValue(encryptedString).pipe(
      mergeMap(decryptedValue => {
        const oktaId = decryptedValue.split('|')[0];
        const msUnoReportId = decryptedValue.split('|')[1];
        const data = {
          decryptedValue
        };
        return forkJoin([
          of(decryptedValue),
          this.reportDetailService.getReportDetails(msUnoReportId, oktaId),
          this.getEncryptedAgentReportValue(oktaId, msUnoReportId, false)
        ]);
      })
    );
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message));
  }
}
