import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { format } from 'date-fns';
import { CustomReportService } from '../../services/custom-report.service';
import { ThemeService } from '../../services/theme.service';
import { ReportService } from 'app/services/report.service';
import { FileSaverService } from 'ngx-filesaver';
import { GTMService } from '../../services/gtm.service';
import { ModalService } from '../../services/modal.service';
import {
  FetchCustomReportPdf,
  FetchCustomReportPdfError,
  FetchCustomReportPdfSuccess,
  CustomReportActionsActionTypes,
  FetchCustomReports,
  FetchCustomReportsError,
  FetchCustomReportsSuccess
} from '../actions/custom-report.action';
import { CustomReportDataDetails, CustomReportDataTypes } from '../selectors/custom-graph.selector';
import { IAppState } from '../state/app.state';

@Injectable()
export class CustomReportEffect {
  constructor(
    private actions$: Actions,
    private customReportSvc: CustomReportService,
    private store: Store<IAppState>,
    private snackBar: MatSnackBar,
    private gtmService: GTMService,
    private modalService: ModalService,
    private fileSaver: FileSaverService,
    private reportService: ReportService,
    private themeSvc: ThemeService
  ) {}

  fetchCustomReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCustomReports>(CustomReportActionsActionTypes.FetchCustomReports),
      switchMap(action => {
        return this.customReportSvc.generateCustomReportPostData(action.payload);
      }),
      switchMap(action => this.customReportSvc.getCustomReportData(null, action)),
      map(data => (!!data.reportData ? new FetchCustomReportsSuccess(data) : new FetchCustomReportsError())),
      catchError(() => of(new FetchCustomReportsError()))
    )
  );
  fetchCustomReportPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCustomReportPdf>(CustomReportActionsActionTypes.FetchCustomReportPdf),
      map(action => action),
      withLatestFrom(this.store.select(CustomReportDataDetails)),
      switchMap(data => {
        const [action, customReport] = data;
        const opts = cloneDeep(action.payload);
        return this.customReportSvc.fetchCustomReportPdf(customReport, opts).pipe(
          map(pdfData => {
            const append = format(new Date(customReport.header.endDate), 'MMM-yyyy');
            const fileName = `custom-report-${append}.pdf`;
            //this.gtmService.customReportExportGTM('exportCustomReport');
            this.fileSaver.save(pdfData, fileName);
            this.reportService.setDownloadSuccess(fileName);
            //this.gtmService.customReportExportSuccess(opts);
            return new FetchCustomReportPdfSuccess();
          }),
          catchError(err => {
            this.reportService.setDownloadSuccess('Download Error');
            return of(new FetchCustomReportPdfError(err.message));
          })
        );
      })
    )
  );

  fetchCustomReportPdfSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FetchCustomReportPdfSuccess>(CustomReportActionsActionTypes.FetchCustomReportPdfSuccess),
        switchMap(action => of(action)),
        map(() => this.modalService.close())
      ),
    { dispatch: false }
  );

  fetchCustomReportPdfError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FetchCustomReportPdfError>(CustomReportActionsActionTypes.FetchCustomReportPdfError),
        map(() =>
          this.snackBar.open('An Error Has Occurred', '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          })
        )
      ),
    { dispatch: false }
  );
}
