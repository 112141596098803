import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IWootric } from 'app/models/IWootric';
import { selectBrandCode } from 'app/store/selectors/applicationData.selector';
import { environment } from 'environments/environment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { combineLatest, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { MlsInfoModalComponent } from '../components/modals/mls-modals/mls-info-modal/mls-info-modal.component';
import {
  MlsSelectionModalComponent
} from '../components/modals/mls-modals/mls-selection-modal/mls-selection-modal.component';
import { NoMlsModalComponent } from '../components/modals/mls-modals/no-mls-modal/no-mls-modal.component';
import { Avatar } from '../models/IApplicationData';
import { IMlsData } from '../models/IMlsData';
import { IUser } from '../models/IUser';
import { PendingDataBaseFilterUpdate, SuccessUpdateV2FilterUpdates } from '../store/actions/filtersData.action';
import { UpdateMarketReportFilters } from '../store/actions/market-position.action';
import { SetMLS } from '../store/actions/mls.action';
import { filtersLoaded, selectV2FiltersStatus } from '../store/selectors/filters.selector';
import { mlsLoaded, selectAvailableMLSList, selectCurrentMLS } from '../store/selectors/mls.selector';
import { selectUser } from '../store/selectors/userData.selector';
import { IAppState } from '../store/state/app.state';
import { MlsService } from './mls.service';
import { ModalService } from './modal.service';
import { TenantCode } from './tenant.service';
import { ThemeService } from './theme.service';

export interface OnSetUserAvatarCSS {
  setUserAvatarCSS(avatar: Avatar, cssStyles: {}): void;
}

interface SelectionModalData {
  saveMLS: boolean;
  selectedMLS: IMlsData;
}

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  constructor(
    private http: HttpClient,
    private store: Store<IAppState>,
    private modalService: ModalService,
    private themeService: ThemeService,
    private mlsService: MlsService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    this.store
      .select(selectBrandCode)
      .pipe(filter(v => !!v))
      .subscribe(() => {
        this.isSIR = this.themeService.isSIR();
      });
  }

  private headers: HttpHeaders;
  private businessUnit: string = 'RBG';
  private _isSIR: boolean;

  get isSIR(): boolean {
    return this._isSIR;
  }

  set isSIR(value: boolean) {
    this._isSIR = value;
  }

  private _isCBA: boolean;

  get isCBA(): boolean {
    return this._isCBA;
  }

  set isCBA(value: boolean) {
    this._isCBA = value;
  }

  private _avatarInfo: Avatar;

  get avatarInfo(): Avatar {
    return this._avatarInfo;
  }

  set avatarInfo(value: Avatar) {
    this._avatarInfo = value;
  }

  private static generateAvatarCSS(avatar: Avatar, viewPortSize: number) {
    const picStyles = {};
    if (!avatar) {
      picStyles['background-position'] = 'top left';
      picStyles['background-size'] = 'cover';
      return picStyles;
    }

    let top = 0;
    let right = 0;
    let left = avatar.width;
    let bottom = avatar.height;

    if (avatar['face-bounding-box']) {
      const fcTop = avatar['face-bounding-box'].top;
      const fcRight = avatar['face-bounding-box'].right;
      const fcBottom = avatar['face-bounding-box'].bottom;
      const fcLeft = avatar['face-bounding-box'].left;

      if (fcTop > top && fcTop < bottom) {
        top = fcTop;
      }
      if (fcRight < right && fcRight > left) {
        right = fcRight;
      }
      if (fcBottom > top && fcRight < bottom) {
        bottom = fcBottom;
      }
      if (fcLeft < right && fcLeft > left) {
        left = fcLeft;
      }
    }

    const faceWidth = right - left;
    const faceHeight = bottom - top;
    const centerX = faceWidth / 2 + left;
    const centerY = faceHeight / 2 + top;

    const scaledImageWidth = Math.min(centerX, avatar.width - centerX);
    const scaledImageHeight = Math.min(centerY, avatar.height - centerY);
    const scaledImageDistance = Math.min(scaledImageHeight, scaledImageWidth);

    const calculatedScale = viewPortSize / (2 * scaledImageDistance);

    const rescaledWidth = avatar.width * calculatedScale;
    const recalculatedHeight = avatar.height * calculatedScale;

    const desiredLeft = Math.max(0, (centerX - scaledImageDistance) * calculatedScale);
    const desiredTop = Math.max(0, (centerY - scaledImageDistance) * calculatedScale);

    picStyles['background-position'] = '-' + desiredLeft.toString() + 'px -' + desiredTop.toString() + 'px';
    picStyles['background-size'] = rescaledWidth.toString() + 'px ' + recalculatedHeight.toString() + 'px';
    picStyles['background-image'] = `url(${avatar['image-url']})`;

    return picStyles;
  }

  public positionImage(component: OnSetUserAvatarCSS, avatar: Avatar, viewPortSize: number) {
    component.setUserAvatarCSS(avatar, UserDataService.generateAvatarCSS(avatar, viewPortSize));
  }

  getUserData(sub: string) {
    return this.http.get<IUser>(`${environment.apiBaseUrl}/api/user/getuserdata/${sub}`, { headers: this.headers });
  }

  getUserAvatar(oktaId: string) {
    return this.http.get<Avatar>(`https://avatar.mycbdesk.com/api/v1/avatar?oktaId=${oktaId}`, { headers: this.headers });
  }

  setBusinessUnit(businessUnit): void {
    this.businessUnit = businessUnit;
  }

  getBusinessUnit(): string {
    return this.businessUnit;
  }

  getWootricData() {
    return this.store.select(selectUser).pipe(
      take(1),
      mergeMap(userClaims => {
        return this.http.get<IWootric>(`${environment.apiBaseUrl}/api/user/getwootricdata/${userClaims.oktaId}`, { headers: this.headers });
      })
    );
  }

  getUserMlsData() {
    let userHasMlsFiltersSelected: string;
    return this.store
      .select(mlsLoaded)
      .pipe(
        filter(v => v),
        mergeMap(() => this.store.select(selectAvailableMLSList)),
        map(mlsAvailableToUser => {
          if (mlsAvailableToUser?.length < 1) {
            this.modalService.open(NoMlsModalComponent, {
              data: {
                isSIR: this.isSIR,
                isCBA: this.isCBA
              }
            });
            return false;
          }
          return true;
        }),
        filter(v => v),
        switchMap(() => this.store.select(filtersLoaded)),
        filter(v => v),
        mergeMap(() => this.store.select(selectV2FiltersStatus)),
        take(1),
        mergeMap(userFilterData => {
          userHasMlsFiltersSelected = userFilterData?.mlsid.length > 0 && userFilterData?.mlsid.every(Boolean) && userFilterData.mlsid[0];
          return combineLatest([this.store.select(selectAvailableMLSList), this.store.select(selectCurrentMLS)]);
        })
      )
      .subscribe(mlsData => {
        const [mlsList, currMls] = mlsData;
        this.mlsService.mlsList = mlsList;
        if (!currMls) {
          this.store.dispatch(new SetMLS(this.mlsService.mlsList.find(i => i.mlsId === userHasMlsFiltersSelected)));
        }

        if (!userHasMlsFiltersSelected) {
          switch (mlsList.length) {
            case 1:
              this.openMLSInfoModal(this.mlsService.mlsList);
              break;
            default:
              this.openMLSSelectionModal(this.mlsService.mlsList);
              break;
          }
        }
      });
  }

  openMLSSelectionModal(availableMlsList: IMlsData[], minimal = false, selectedMls?: IMlsData) {
    const dialogRefSelection = this.modalService.open(MlsSelectionModalComponent, {
      height: 'auto',
      width: '512px',
      data: { mlsList: availableMlsList, minimal, selectedMls: selectedMls, isSIR: this._isSIR }
    });

    dialogRefSelection.afterClosed().subscribe((result: SelectionModalData) => {
      this.store.dispatch(
        new SuccessUpdateV2FilterUpdates({
          mlsid: [result.selectedMLS.mlsId],
          city: [],
          county: [],
          zip: []
        })
      );
      this.store.dispatch(
        new UpdateMarketReportFilters({
          mlsid: [result.selectedMLS.mlsId],
          city: [],
          county: [],
          zip: [],
          mlsArea: [],
          schoolDistrict: [],
          subDivision: []
        })
      );
      this.store.dispatch(new SetMLS(result.selectedMLS));
      if (result.saveMLS) {
        this.store.dispatch(new PendingDataBaseFilterUpdate());
      }
    });
  }

  openMLSInfoModal(mls: IMlsData[]) {
    this.modalService.open(MlsInfoModalComponent, {
      height: '405px',
      width: '512px',
      data: { mlsId: mls[0].mlsId, legalName: mls[0].legalName, saveAsDefault: true, isSIR: this._isSIR }
    });
    this.store.dispatch(new SetMLS(mls[0]));
    this.store.dispatch(
      new SuccessUpdateV2FilterUpdates({
        mlsid: [mls[0].mlsId],
        city: [],
        county: [],
        zip: []
      })
    );
    this.store.dispatch(
      new UpdateMarketReportFilters({
        mlsid: [mls[0].mlsId],
        city: [],
        county: [],
        zip: [],
        mlsArea: [],
        schoolDistrict: [],
        subDivision: []
      })
    );
    this.store.dispatch(new PendingDataBaseFilterUpdate());
  }

  verifyUserAvatarCache(oktaId: string) {
    const avatarCache: Avatar = this.storage.get('avatar');
    if (avatarCache?.source) {
      this.avatarInfo = avatarCache;
      return of(this.avatarInfo);
    }
    return this.getUserAvatar(oktaId);
  }

  syncUserAvatarCache(value: Avatar) {
    this.avatarInfo = value;
    this.storage.set('avatar', value);
  }

  getCannySingleSignOnToken() {
    return this.http.get(environment.apiBaseUrl + '/api/User/GetCannySSO');
  }

  processUserCBA(isNrt: boolean, brandCode: TenantCode) {
    this.isCBA = !isNrt && brandCode === 'CBR';
  }
}
