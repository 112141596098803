import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { INavButton } from '../../../../models/INavButton';
import { SetSideBarNav } from '../../../../store/actions/menus.action';
import { selectSideBarStatus } from '../../../../store/selectors/menus.selector';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss']
})
export class NavButtonComponent implements OnDestroy {
  @Input() data: INavButton;
  @Input() showDivider = true;
  sideBarOpen$ = this.store.select(selectSideBarStatus);
  subs = new Subscription();
  sideBarOpen: boolean;

  constructor(private store: Store<IAppState>) {
    this.subs.add(
      this.sideBarOpen$.subscribe(open => {
        this.sideBarOpen = open;
      })
    );
  }

  toggleSideBar() {
    this.store.dispatch(new SetSideBarNav(false));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
