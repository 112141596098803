import { Pipe, PipeTransform } from '@angular/core';
import { TrendDataFormatTypes } from '../models/ITrendsTableData';
import { MarketTrendsService } from '../services/market-trends.service';

@Pipe({
  name: 'trendDataFormat'
})
export class TrendDataFormatPipe implements PipeTransform {
  constructor(private marketTrendsService: MarketTrendsService) {
  }

  transform(value: string | number, type: TrendDataFormatTypes): string | number {
    return this.marketTrendsService.formatByType(value, type);
  }

}
