import { IContactData } from '../../models/IContactData';

export interface IUserSettingsState {
  brandingType: Branding | null;
  contact: IContactData[];
  social: IContactData[];
  photo: IContactData[];
  loading: boolean;
  loaded: boolean;
}

export type Branding = 'Office' | 'Individual' | 'Team';

export enum BrandingEnum {
  Office = 'Office',
  Individual = 'Individual',
  Team = 'Team'
}

export enum BrandingEnumByInteger {
  Individual,
  Office,
  Team
}

export const initialUserSettingsState: IUserSettingsState = {
  brandingType: null,
  contact: [],
  social: [],
  photo: [],
  loading: true,
  loaded: false
};

export function getInitialUserSettingsState(): IUserSettingsState {
  return initialUserSettingsState;
}
