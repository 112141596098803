import { Pipe, PipeTransform } from '@angular/core';
import { IChips } from '../models/IChips';
import { CountyStringPipe } from './countyString.pipe';
import { SchoolStringPipe } from './schoolDistrictString.pipe';
import { ReportTypeEnum } from '../models/ReportTypes';

@Pipe({
  name: 'ChipTooltip'
})
export class ChipTooltipPipe implements PipeTransform {
  constructor(private getCountyString: CountyStringPipe, private getSchoolString: SchoolStringPipe) {}

  transform(chip: IChips, reportType: ReportTypeEnum) {
    if (chip.hasData || reportType !== ReportTypeEnum.Uno) {
      if (chip.type === 'county') {
        return this.getCountyString.transform(chip.name);
      } else if (chip.type === 'schoolDistrict') {
        return this.getSchoolString.transform(chip.name);
      } else {
        return chip.name;
      }
    } else {
      if (chip.type === 'county') {
        return this.getCountyString.transform(chip.name) + ' does not have #1 claims';
      } else {
        return chip.name + ' does not have #1 claims';
      }
    }
  }
}
