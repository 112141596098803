import { Component, Input } from '@angular/core';
import { IAgentReport } from '../../../../../../../models/IAgentReport';

@Component({
  selector: 'app-contact-info-block',
  templateUrl: './contact-info-block.component.html',
  styleUrls: ['./contact-info-block.component.scss']
})
export class ContactInfoBlockComponent {
  @Input() data: IAgentReport;

  constructor() {}
}
