import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAspTrendsDataResponse, IAspTrendsFilter } from '../store/state/asp-trends.state';
import { environment } from 'environments/environment';
import { ILocationsData } from '../models/ILocationsData';
import { UserSettingsService } from './user-settings.service';
import { mergeMap, switchMap } from 'rxjs/operators';

interface AspFormValues {
  mls: string;
  locationFilters: ILocationsData[];
  report: {
    propType: number[];
  };
}

@Injectable({
  providedIn: 'root'
})
export class AspTrendsService {
  constructor(private http: HttpClient, private userSvc: UserSettingsService) {}

  fetchAspPDF(data: IAspTrendsDataResponse) {
    const id = this.userSvc.oktaId;
    return this.postAspTrendsToDb(data).pipe(
      switchMap(reportId => {
        return this.http.post(
          `${environment.apiBaseUrl}/api/export/PostASPTrendsPDF/${reportId}?oktaId=${id}`,
          {},
          {
            responseType: 'blob'
          }
        );
      })
    );
  }

  postAspTrendsToDb(data: IAspTrendsDataResponse) {
    const id = this.userSvc.oktaId;
    return this.http.post(`${environment.apiBaseUrl}/api/Report/${id}/PostASPTrendsToDb`, data, {
      responseType: 'text'
    });
  }

  getAspTrendsData(filters: IAspTrendsFilter) {
    return this.http.post<IAspTrendsDataResponse>(`${environment.apiBaseUrl}/api/Report/postasptrends`, filters);
  }

  generateAspTrendsFilters(data: AspFormValues): IAspTrendsFilter {
    const filter: IAspTrendsFilter = {
      oktaid: this.userSvc.oktaId,
      mlsid: [data.mls],
      propertyType: data.report.propType,
      city: [],
      zip: [],
      county: [],
      mlsArea: [],
      schoolDistrict: [],
      subDivision: []
    };
    data.locationFilters.map(location => {
      switch (location.areaType) {
        case 1:
          filter.city = [location.areaID];
          break;
        case 2:
          filter.county = [location.areaID];
          break;
        case 3:
          filter.zip = [location.areaID];
          break;
        case 4:
          filter.mlsArea = [location.areaID];
          break;
        case 5:
          filter.schoolDistrict = [location.areaID];
          break;
        case 6:
          filter.subDivision = [location.areaID];
          break;
      }
    });
    return filter;
  }
}
