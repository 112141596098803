import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GalleryImage } from '../../../../../models/GalleryImage';
import { ImageTypes } from '../../../../../models/ImageTypes';
import { IReportDetail } from '../../../../../models/IReportDetail';
import { OptionsService } from '../../../../../services/options.service';
import { selectReportPageData } from '../../../../../store/selectors/reports.selectors';
import { IAppState } from '../../../../../store/state/app.state';

@Component({
  selector: 'app-preview-tile-sothebys',
  templateUrl: './preview-tile-sothebys.component.html',
  styleUrls: ['./preview-tile-sothebys.component.scss']
})
export class PreviewTileSothebysComponent implements OnInit {
  @Input() showGraph = true;
  @Input() large = false;
  logo: GalleryImage | undefined;
  tileData$: Observable<{ imageBgWide: string; data: IReportDetail; imageBgSquare: string; logoLight: string; logoDark: string }>;
  mobile: boolean;

  constructor(private store: Store<IAppState>, private options: OptionsService) {}

  ngOnInit(): void {
    this.tileData$ = this.store.select(selectReportPageData).pipe(
      map(data => {
        const imageBgWide =
          this.options.getImagePathForReport() +
          data.reportDetail?.imagePromoEntities.find(image => {
            return image.imageSize === ImageTypes['wide'];
          }).imageURL;
        const imageBgSquare =
          this.options.getImagePathForReport() +
          data.reportDetail?.imagePromoEntities.find(image => {
            return image.imageSize === ImageTypes['square'];
          }).imageURL;
        const logoLight =
          this.options.getImagePathForReport() +
          data.reportDetail?.imagePromoEntities.find(image => {
            return image.imageSize === ImageTypes['logoLight'];
          }).imageURL;
        const logoDark =
          this.options.getImagePathForReport() +
          data.reportDetail?.imagePromoEntities.find(image => {
            return image.imageSize === ImageTypes['logoDark'];
          }).imageURL;

        return { imageBgWide, imageBgSquare, data: data.reportDetail, logoLight, logoDark };
      })
    );
  }
}
