<div class="small-table">
  <div *ngFor="let graphEntity of entityNames; let idx = index" class="entry">
    <div [ngClass]="{ leading: idx + 1 === cbPosition }" class="chip">{{ idx + 1 }}</div>
    <div class="name">{{ graphEntity.levelEntityName }}</div>
  </div>
</div>
<div class="type">
  {{
    reportType === 0
      ? (reportDetail$ | async).reportDetail.reportHeaderEntity.groupByLevel + 's'
      : marketReportDetails?.reportHeaderEntity.groupByLevel + 's'
  }}
</div>
