import { Component, Input } from '@angular/core';
import { BrandDetails } from '../../../../models/IApplicationData';

@Component({
  selector: 'app-footer-disclaimer',
  templateUrl: './footer-disclaimer.component.html',
  styleUrls: ['./footer-disclaimer.component.scss']
})
export class FooterDisclaimerComponent {
  @Input() data: BrandDetails;
}
