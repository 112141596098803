import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { FetchNavItems, FetchNavItemsSuccess, MenusActionTypes } from '../actions/menus.action';

@Injectable()
export class MenuEffect {
  fetchNavItems$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchNavItems>(MenusActionTypes.FetchNavItems),
      switchMap(action => this.naviSvc.getLeftSideNavButtons(action.payload)),
      map(navItems => new FetchNavItemsSuccess(navItems))
    )
  );

  constructor(private _actions$: Actions, private naviSvc: NavigationService) {}
}
