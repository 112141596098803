import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Subscription } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { FilterState } from '../../../../models/FilterData';
import { DefaultFilters } from '../../../../models/IFilters';
import { IMarketReportDate } from '../../../../models/IMarketReportDate';
import { MarketFilters } from '../../../../models/IMarketReportFilters';
import { FilterService } from '../../../../services/filter.service';
import { ModalService } from '../../../../services/modal.service';
import { selectV2FiltersStatus } from '../../../../store/selectors/filters.selector';
import { selectDateRanges, selectMarketReportFilters } from '../../../../store/selectors/market-position.selector';
import { IAppState } from '../../../../store/state/app.state';
import { SaveFiltersDialogComponent } from './save-filters-dialog/save-filters-dialog.component';

@Component({
  selector: 'app-save-defaults',
  templateUrl: './save-defaults.component.html',
  styleUrls: ['./save-defaults.component.scss']
})
export class SaveDefaultsComponent implements OnDestroy {
  @Input() reportType: number;
  subs = new Subscription();
  defaultDateInfo: IMarketReportDate;

  constructor(private store: Store<IAppState>, private filterService: FilterService, public modalService: ModalService) {
    this.subs.add(
      this.store.select(selectDateRanges).subscribe(dates => {
        // Find the 'Last Year' Date Settings and make it the default
        dates.map(date => {
          if (date.datePeriod === 'LY') {
            this.defaultDateInfo = cloneDeep(date);
          }
        });
      })
    );
  }

  save(reportType: number) {
    const dialogRef = this.modalService.open(SaveFiltersDialogComponent, {
      width: '512px',
      height: '280px',
      data: {
        reportType: reportType
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.subs.add(
          combineLatest([this.store.select(selectV2FiltersStatus), this.store.select(selectMarketReportFilters)])
            .pipe(
              take(1),
              map(([claimsFilters, marketFilters]) => {
                const arr: DefaultFilters[] = [];
                const filters: FilterState = cloneDeep(claimsFilters);
                const mpFilters: MarketFilters = cloneDeep(marketFilters);

                return this.filterService.buildFilterObject([filters, mpFilters], arr, reportType);
              }),
              mergeMap(defaultFiltersArray => {
                return this.filterService.updateUserFilterDefaults(defaultFiltersArray);
              })
            )
            .subscribe(() => {})
        );
      }
    });
  }

  clearDefaults = (): void => {
    this.subs.add(
      combineLatest([this.store.select(selectV2FiltersStatus), this.store.select(selectMarketReportFilters)])
        .pipe(
          take(1),
          map(([claimsFilters, marketFilters]) => {
            const arr: DefaultFilters[] = [];
            const filters: [FilterState, MarketFilters] = [
              // #1 Claims Defaults
              {
                zip: [],
                city: [],
                county: [],
                mlsid: cloneDeep(claimsFilters.mlsid),
                timeFrame: [],
                groupByLevel: [],
                priceType: [],
                msTypeName: [],
                unitType: []
              },
              // Market Position Defaults
              {
                zip: [],
                city: [],
                county: [],
                mlsArea: [],
                schoolDistrict: [],
                subDivision: [],
                mlsid: cloneDeep(marketFilters.mlsid),
                groupByLevel: ['Brokerage'],
                msTypeName: ['Total'],
                unitType: ['Volume'],
                propertyType: [1, 2, 9],
                priceMin: 0,
                priceMax: 999999999,
                dateRange: {
                  datePeriod: 'Last Year',
                  startDate: this.defaultDateInfo.startDate,
                  endDate: this.defaultDateInfo.endDate
                }
              }
            ];

            return this.filterService.buildFilterObject(filters, arr, -1);
          }),
          mergeMap(defaultFiltersArray => {
            return this.filterService.updateUserFilterDefaults(defaultFiltersArray);
          })
        )
        .subscribe(() => {})
    );
  };

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
