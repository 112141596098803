<div class="avatar-container-menu">
  <div class="avatar-block">
    <app-avatar-image *ngIf="this.nonMobile && (user$ | async)?.avatar" [size]="64" [avatar]="(user$ | async)?.avatar"></app-avatar-image>
    <div class="user">
      {{ (user$ | async)?.name }}
    </div>
  </div>
  <div class="menu-links">
    <div (click)="navigate(menuBtn)" *ngFor="let menuBtn of buttonData" class="menu-item">
      <mat-icon>{{ menuBtn.matIcon }}</mat-icon>
      <span>{{ menuBtn.label }}</span>
    </div>
  </div>
</div>
