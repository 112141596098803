import { ILocationsData } from '../../models/ILocationsData';
import { IMarketSnapshotData } from '../../models/IMarketSnapshotData';

export interface IMarketSnapshotState {
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  defaultsLoaded: boolean;
  snapshotData: IMarketSnapshotData;
  filters: {
    location: ILocationsData[];
  };
}

export const initialMarketTrendsState: IMarketSnapshotState = {
  loading: false,
  loaded: false,
  snapshotData: null,
  hasError: false,
  defaultsLoaded: false,
  filters: {
    location: []
  }
};

export function getInitialMarketSnapshotDataState(): IMarketSnapshotState {
  return initialMarketTrendsState;
}
