import { Action } from '@ngrx/store';
import { ILocationsData } from '../../models/ILocationsData';

export enum LocationsActionsActionTypes {
  FetchLocations = '[Locations] Fetch Locations Data API Call',
  FetchLocationsSuccess = '[Locations] Fetched Locations Data API Successful',
  FetchLocationsLoaded = '[Locations] Locations loaded into State',
  FetchMarketLocations = '[Market Locations] Getting Locations For Filters',
  FetchMarketLocationsSuccess = '[Market Locations] Received Locations For Filters',
  FetchMarketLocationsLoaded = '[Market Locations] Market Locations loaded into State'
}

export class FetchLocations implements Action {
  readonly type = LocationsActionsActionTypes.FetchLocations;

  constructor(public payload: string = '') {}
}

export class FetchLocationsSuccess implements Action {
  readonly type = LocationsActionsActionTypes.FetchLocationsSuccess;

  constructor(public payload: ILocationsData[]) {}
}

export class FetchLocationsLoaded implements Action {
  readonly type = LocationsActionsActionTypes.FetchLocationsLoaded;
}

export class FetchMarketLocations implements Action {
  readonly type = LocationsActionsActionTypes.FetchMarketLocations;

  constructor(public payload: string) {}
}

export class FetchMarketLocationsSuccess implements Action {
  readonly type = LocationsActionsActionTypes.FetchMarketLocationsSuccess;

  constructor(public payload: any) {}
}

export class FetchMarketLocationsLoaded implements Action {
  readonly type = LocationsActionsActionTypes.FetchMarketLocationsLoaded;
}

export type LocationsActions =
  | FetchLocations
  | FetchLocationsSuccess
  | FetchLocationsLoaded
  | FetchMarketLocations
  | FetchMarketLocationsSuccess
  | FetchMarketLocationsLoaded;
