import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { IMarketPositionState } from '../state/market-position.state';

const MarketReportData = (state: IAppState) => state.marketPosition;

export const selectMarketReportData = createSelector(MarketReportData, (state: IMarketPositionState) => state.marketReportDetails);

export const selectMarketReportFilters = createSelector(MarketReportData, (state: IMarketPositionState) => state.marketFilters.state);

export const selectDateRanges = createSelector(MarketReportData, (state: IMarketPositionState) => state.dates);

export const selectMarketAPIResultsReady = createSelector(MarketReportData, (state: IMarketPositionState) => state.loaded);

export const selectMarketAPIPositionReady = createSelector(
  MarketReportData,
  (state: IMarketPositionState) => state.marketReportDetails.realogyPosition
);

export const selectMarketAPIResultsEmpty = createSelector(
  MarketReportData,
  (state: IMarketPositionState) => !state.marketReportDetails?.topGroupTypes?.length && state.loaded
);

export const selectMarketAPILoading = createSelector(MarketReportData, (state: IMarketPositionState) => state.loading);

export const selectMarketAPIReportData = createSelector(MarketReportData, (state: IMarketPositionState) => state.marketReportDetails);

export const selectMarketReportId = createSelector(MarketReportData, (state: IMarketPositionState) => state.marketReportDetails.reportId);

export const selectMarketAPIErrors = createSelector(MarketReportData, (state: IMarketPositionState) => state.error);

export const selectMarketShowAsPercent = createSelector(MarketReportData, (state: IMarketPositionState) => state.showPercent);

export const selectMarketCountyCityZipFilter = createSelector(MarketReportData, (state: IMarketPositionState) => {
  return {
    city: state.marketFilters.state.city,
    county: state.marketFilters.state.county,
    zip: state.marketFilters.state.zip,
    mlsArea: state.marketFilters.state.mlsArea,
    schoolDistrict: state.marketFilters.state.schoolDistrict,
    subDivision: state.marketFilters.state.subDivision
  };
});

export const mpPricesDirty = createSelector(MarketReportData, (state: IMarketPositionState) => state.marketFilters.pricesDirty);
