import { Component } from '@angular/core';

@Component({
  selector: 'app-mq-footer-sir-contact',
  templateUrl: './mq-footer-sir-contact.component.html',
  styleUrls: ['./mq-footer-sir-contact.component.scss']
})
export class MqFooterSirContactComponent {
  constructor() {}
}
