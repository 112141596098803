import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICustomChartData } from '../../../../models/ICustomChartData';
import { ICustomDataTypeByNumEnum, ICustomDataTypeEnum } from '../../../../models/ICustomDataTypeEnum';
import { CustomReportService } from '../../../../services/custom-report.service';
import { CustomReportAxis, CustomReportDataTypes } from '../../../../store/selectors/custom-graph.selector';
import { pdfOptions, pdfPreviewOrientation } from '../../../../store/selectors/pdf.selector';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-custom-export-preview',
  templateUrl: './custom-export-preview.component.html',
  styleUrls: ['./custom-export-preview.component.scss']
})
export class CustomExportPreviewComponent implements OnInit {
  pdfIsLandscape$: Observable<boolean>;
  pdfOptions$: Observable<{
    isLandscape: boolean;
    showTable: boolean;
    showCover: boolean;
    showAgentInfo: boolean;
    showTrendLines: boolean;
  }>;
  chartAxis$ = this.store.select(CustomReportAxis);
  chartData: ICustomChartData;
  typesArray: string[];
  isThreePointChart = false;

  constructor(
    private store: Store<IAppState>,
    private customReportSvc: CustomReportService,
    private customReportService: CustomReportService
  ) {
    this.pdfIsLandscape$ = this.store.select(pdfPreviewOrientation);
    this.pdfOptions$ = this.store.select(pdfOptions).pipe(
      map(pdfOpts => {
        const showTable = pdfOpts.showtable;
        const isLandscape = pdfOpts.islandscape;
        const showCover = pdfOpts.showcover;
        const showAgentInfo = pdfOpts.showAgentInfo;
        const showTrendLines = pdfOpts.showTrendLines;
        return { showTable, isLandscape, showCover, showAgentInfo, showTrendLines };
      })
    );
  }

  ngOnInit(): void {
    this.initChartData();
  }

  initChartData(): void {
    this.isThreePointChart = this.customReportService.getIsThreePointChart();
    if (this.isThreePointChart) {
      const threePointData = this.customReportService.getThreePointChartData();
      this.chartData = threePointData.data;
      this.typesArray = threePointData.cardData.typeArr;
    } else {
      combineLatest([this.store.select(CustomReportDataTypes), this.getData$()]).subscribe(([typesArray, chartData]) => {
        this.chartData = chartData;
        this.typesArray = typesArray.map(d => ICustomDataTypeEnum[ICustomDataTypeByNumEnum[d]]);
      });
      this.getData$().subscribe(data => {
        this.chartData = data;
      });
    }
  }

  private getData$() {
    return this.customReportSvc.setChartDataData();
  }
}
