import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-svg-component',
  templateUrl: './blog-svg-component.component.html',
  styleUrls: ['./blog-svg-component.component.scss']
})
export class BlogSvgComponentComponent implements OnInit {
  @HostBinding('class') classes = 'market-quest-icon ';
  constructor() {}

  ngOnInit(): void {}
}
