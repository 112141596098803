import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { INo1ClaimsReportDataState } from '../state/no1ClaimsReports.state';

const ReportData = (state: IAppState) => state.no1Claims;

export const selectAllReports = createSelector(ReportData, (state: INo1ClaimsReportDataState) => state.reports);

export const filteredReports$ = createSelector(ReportData, (state: INo1ClaimsReportDataState) => state.filteredSet);

export const filteredData$ = createSelector(ReportData, (state: INo1ClaimsReportDataState) => state.filteredSet.filteredData);

export const pageData$ = createSelector(ReportData, (state: INo1ClaimsReportDataState) => state.filteredSet.pagedData);

export const filteredReportsLoaded = createSelector(ReportData, (state: INo1ClaimsReportDataState) => state.filteredSet.loaded);

export const defaultReportsLoaded = createSelector(ReportData, (state: INo1ClaimsReportDataState) => state.loaded);

export const isReportsPending = createSelector(ReportData, (state: INo1ClaimsReportDataState) => state.loading);

export const claimsReportId = createSelector(ReportData, (state: INo1ClaimsReportDataState) => state.details.msUnoReportId);

export const selectReportPageData = createSelector(ReportData, (state: INo1ClaimsReportDataState) => {
  return state?.details;
});

export const selectAllReportPageData = createSelector(ReportData, (state: INo1ClaimsReportDataState) => state.details);

export const selectReportNoResults = createSelector(ReportData, (state: INo1ClaimsReportDataState) => state.noResults);
