import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-facebook-svg',
  templateUrl: './facebook-svg.component.html',
  styleUrls: ['./facebook-svg.component.scss']
})
export class FacebookSvgComponent implements OnInit {
  @HostBinding('class') classes = 'market-quest-icon ';
  @Input() size = 24;
  @Input() fillColor = '#1f69ff';
  constructor() {}

  ngOnInit(): void {}
}
