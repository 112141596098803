import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterTileComponent } from '../export-preview/trends-export-preview/export-preview-cover/cover-filter-section/filter-tile/filter-tile.component';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { TrendCardComponent } from '../trends/trend-card/trend-card.component';
import { ChartComponent } from '../trends/trend-chart-container/chart/chart.component';
import { TrendsSkeletonComponent } from '../trends/trends-report-page/trends-skeleton/trends-skeleton.component';
import { CustomChartComponent } from './custom-chart/custom-chart.component';
import { CustomGraphPageComponent } from './custom-graph-page/custom-graph-page.component';
import { CustomGraphGroupByDropdownComponent } from './custom-graph-page/custom-report-controls/custom-graph-group-by-dropdown/custom-graph-group-by-dropdown.component';
import { CustomReportControlsComponent } from './custom-graph-page/custom-report-controls/custom-report-controls.component';
import { CustomReportStatsComponent } from './custom-graph-page/custom-report-stats/custom-report-stats.component';
import { CustomReportTableComponent } from './custom-graph-page/custom-report-table/custom-report-table.component';

@NgModule({
  declarations: [CustomGraphPageComponent, CustomReportStatsComponent, CustomReportControlsComponent, CustomGraphGroupByDropdownComponent],
  imports: [
    CommonModule,
    SharedComponentsModule,
    FilterTileComponent,
    TrendCardComponent,
    TrendsSkeletonComponent,
    CustomReportTableComponent,
    ChartComponent,
    CustomChartComponent
  ],
  exports: [CustomGraphPageComponent, CustomReportStatsComponent, CustomReportControlsComponent, CustomGraphGroupByDropdownComponent]
})
export class CustomGraphNoRoutingModule {}
