import { Injectable } from '@angular/core';
import { MqOktaAuthService } from '../mq-okta-auth.service';
import { Tenants } from '../tenant.service';

@Injectable({
  providedIn: 'root'
})
export class SirLoginService {
  constructor(private auth: MqOktaAuthService) {
  }
  login() {
    return this.auth.login(Tenants.sothebysrealty);
  }
}
