import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SchoolString'
})
export class SchoolStringPipe implements PipeTransform {
  transform(schoolName: string) {
    schoolName = schoolName + ' School District';
    return schoolName;
  }
}
