import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terradatum-logo',
  templateUrl: './terradatum-logo.component.html',
  styleUrls: ['./terradatum-logo.component.scss']
})
export class TerradatumLogoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
