import { getInitialAspTrendsState, IAspTrendsState } from '../state/asp-trends.state';
import { AspTrendsAction, AspTrendsActionsActionTypes } from '../actions/asp-trends.action';

export function AspTrendsReducer(state = getInitialAspTrendsState(), action: AspTrendsAction): IAspTrendsState {
  switch (action.type) {
    case AspTrendsActionsActionTypes.FetchAspTrends: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case AspTrendsActionsActionTypes.FetchAspTrendsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        aspTrendsReportData: action.payload
      };
    }
    case AspTrendsActionsActionTypes.FetchAspPDF: {
      return {
        ...state,
        pdfDownloadPending: true
      };
    }
    case AspTrendsActionsActionTypes.FetchAspPDFSuccess: {
      return {
        ...state,
        pdfDownloadPending: false
      };
    }
    default:
      return state;
  }
}
