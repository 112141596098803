<button
  [attr.aria-label]="data?.label"
  class="nav-btn"
  [class.divider]="showDivider"
  (click)="sideBarOpen && toggleSideBar()"
  [routerLinkActive]="['active']"
  [routerLink]="data?.urlPath"
  [disabled]="!data?.enabled"
>
  <span class="nav-btn-content">
    <span class="icon-frame">
      <mat-icon>{{ data?.matIcon }}</mat-icon>
    </span>
    <p>{{ data?.label }}</p>
  </span>
</button>
