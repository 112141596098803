import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { LocationEnum } from 'app/models/FilterData';
import { IChips } from 'app/models/IChips';
import { ILocationsData } from 'app/models/ILocationsData';
import { ReportTypeEnum } from '../../../../models/ReportTypes';

@Component({
  selector: 'app-chips-container',
  templateUrl: './chips-container.component.html',
  styleUrls: ['./chips-container.component.scss']
})
export class ChipsContainerComponent implements OnChanges {
  @Input() reportType: ReportTypeEnum;
  @Input() truncate: boolean;
  @Input() locations: ILocationsData[];
  @Output() chipRemoved: EventEmitter<IChips> = new EventEmitter<IChips>();
  chips: IChips[] = [];

  constructor() {}

  ngOnChanges(): void {
    const chipArr: IChips[] = [];
    if (this.locations?.every(Boolean)) {
      let chipCount = 1;
      this.locations?.map(location => {
        chipArr.push({
          type: LocationEnum[location.areaType],
          hasData: location.hasData,
          name: location.areaName,
          value: location.areaID.toString(),
          isGrouped: chipCount > 5
        });
        chipCount++;
      });
      this.chips = chipArr;
    }
  }

  chipRemovedHandler(chip: IChips): void {
    this.chipRemoved.emit(chip);
  }
}
