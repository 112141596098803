<div class="export-preview full-size rounded">
  <div class="preview">
    <div [ngClass]="{ 'custom-scrollbar': !opts.isComplexTemplate }" class="h-100 rounded align-items-center justify-content-center">
      <div [ngClass]="{ 'custom-scrollbar': opts.isComplexTemplate }" class="fd">
        <div [ngClass]="{ 'h-100': opts.isComplexTemplate }" class="frame d-flex" id="zoom">
          <div export-pane></div>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="{ 'v-scroll-auto': opts.reportType === 2 ? true : !opts.isComplexTemplate }" class="controls custom-scrollbar">
    <app-export-preview-controls
      [isSIR]="opts.isSIR"
      [reportType]="opts.reportType"
      [showMarketPositionTable]="opts.showMarketPositionTable"
      [showOrientation]="opts.showOrientation"
      [showComplex]="opts.isComplexTemplate"
    ></app-export-preview-controls>
  </div>
</div>
