import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { ReportPageTypeShortStrEnum, ReportTypeEnum } from '../../../../models/ReportTypes';
import { MarketTrendsService } from '../../../../services/market-trends.service';
import { ReportService } from '../../../../services/report.service';
import { ShareSocialAndEmailService } from '../../../../services/share-social-and-email.service';
import { ThemeService } from '../../../../services/theme.service';
import { selectTrendsData } from '../../../../store/selectors/market-trends.selector';
import { IAppState } from '../../../../store/state/app.state';
import { SharedComponentsModule } from '../../shared/shared-components.module';
import { aspTrendsData, aspTrendsPDFPending } from '../../../../store/selectors/asp-report.selector';
import { FetchAspPDF } from '../../../../store/actions/asp-trends.action';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, SharedComponentsModule],
  selector: 'app-trend-card',
  templateUrl: './trend-card.component.html',
  styleUrls: ['./trend-card.component.scss']
})
export class TrendCardComponent {
  @Input() title: string;
  @Input() exportReport = false;
  @Input() directPDF = false;
  @Input() showHeader = true;
  @Input() reportType: ReportTypeEnum = null;
  @Output() exportCSV: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() exportXLS: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSir: boolean;
  directPdfPending$: Observable<Boolean>;

  constructor(
    private reportServ: ReportService,
    private themeSvc: ThemeService,
    private trendsSvc: MarketTrendsService,
    private store: Store<IAppState>,
    private shareSocialAndEmailService: ShareSocialAndEmailService
  ) {
    this.isSir = this.themeSvc.isSIR();
    this.directPdfPending$ = this.store.select(aspTrendsPDFPending);
  }

  exportPdf() {
    this.reportServ.exportPdfPreview(ReportPageTypeShortStrEnum.trends, 'CBR');
  }

  openEmail() {
    this.store
      .select(selectTrendsData)
      .pipe(
        switchMap(data => {
          return this.trendsSvc.postTrendsDataToDb(data);
        })
      )
      .pipe(take(1))
      .subscribe(trendsDbString => {
        this.shareSocialAndEmailService.openTrendsEmail(trendsDbString);
      });
  }

  exportCSVClick(): void {
    this.exportCSV.emit(true);
  }

  exportXLSClick(): void {
    this.exportXLS.emit(true);
  }

  downloadDirectPdf() {
    if (this.reportType === ReportTypeEnum.ASP_Trend) {
      this.store
        .select(aspTrendsData)
        .pipe(take(1))
        .subscribe(data => this.store.dispatch(new FetchAspPDF(data)));
    }
  }
}
