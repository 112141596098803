import { Component, HostBinding, Input } from '@angular/core';
import { ThemeService } from '../../../../../services/theme.service';

@Component({
  selector: 'app-linkedin-svg',
  templateUrl: './linkedin-svg.component.html',
  styleUrls: ['./linkedin-svg.component.scss']
})
export class LinkedinSvgComponent {
  @HostBinding('class') classes = 'market-quest-icon ';
  @Input() size = 24;
  @Input() fillColor = this.cssService.colorPrimary;

  constructor(public cssService: ThemeService) {}
}
