<div *ngIf="showChart">
  <ejs-chart (axisLabelRender)="axisLabelRender($event)"
             (loaded)="downloadableImageType && chartLoaded()"
             (pointRender)='pointRender($event)'
             (textRender)="textRender($event)"
             *ngIf="!(selectChartLoading$ | async)"
             [background]="'transparent'"
             [chartArea]='chartArea'
             [isTransposed]="!transposed"
             [margin]="margin"
             [primaryXAxis]='primaryXAxis'
             [primaryYAxis]='primaryYAxis'
             [titleStyle]="titleStyle"
             [subTitleStyle]="subTitleStyle"
             [subTitle]="subTitle"
             [title]='title'
             id="chart-container{{ randomIdStr }}"
             useGroupingSeparator=true>
    <e-series-collection>
      <e-series [animation]="animation"
                [dataSource]='chartData'
                [marker]="marker"
                [cornerRadius]="cornerRadius"
                columnWidth=".55"
                type='Bar'
                xName='levelEntityName'
                [yName]='sourceType'></e-series>
    </e-series-collection>
  </ejs-chart>
</div>
