import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ISnapshotDataPointData } from 'app/models/ISnapshotDatapoint.model';
import { format, subMonths, subYears } from 'date-fns';
import { environment } from 'environments/environment';
import { FileSaverService } from 'ngx-filesaver';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { MailModalComponent } from '../components/modals/mail-modal/mail-modal.component';
import { LocationEnum } from '../models/FilterData';
import { IMarketSnapshotData } from '../models/IMarketSnapshotData';
import { IMarketSnapshotBody, IMarketSnapshotFormGroup } from '../models/IMarketSnapshotFormGroup';
import { selectSnapshotData } from '../store/selectors/market-snapshot.selector';
import { selectCurrentMLS } from '../store/selectors/mls.selector';
import { selectOktaId, selectUser } from '../store/selectors/userData.selector';
import { IAppState } from '../store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class MarketSnapshotService {
  private isDesktop: boolean;
  oktaId = '';

  constructor(
    private http: HttpClient,
    private store: Store<IAppState>,
    private fileSaver: FileSaverService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe('(min-width: 576px)').subscribe(size => {
      this.isDesktop = size.matches;
    });

    this.store.select(selectUser).subscribe(user => {
      this.oktaId = user?.oktaId || '';
    });
  }

  private _snapshotFormGroup;

  get snapshotFormGroup() {
    return this._snapshotFormGroup;
  }

  set snapshotFormGroup(value) {
    this._snapshotFormGroup = value;
  }

  private _filters: IMarketSnapshotFormGroup;

  get filters(): IMarketSnapshotFormGroup {
    return this._filters;
  }

  set filters(value: IMarketSnapshotFormGroup) {
    this._filters = value;
  }

  private _snapshotId: string;

  get snapshotId(): string {
    return this._snapshotId;
  }

  set snapshotId(value: string) {
    this._snapshotId = value;
  }

  generateDisclaimer(data: IMarketSnapshotData) {
    const longDateStart = format(new Date(data.snapshotHeader.selectedStartDate), 'MMM dd, yyyy');
    const longDateEnd = format(new Date(data.snapshotHeader.selectedEndDate), 'MMM dd, yyyy');
    const shortDateEnd = format(new Date(data.snapshotHeader.selectedEndDate), 'MMM yyyy');
    const longDateStartYearPrior = format(subYears(new Date(data.snapshotHeader.selectedStartDate), 1), 'MMM dd, yyyy');
    const longDateEndYearPrior = format(subYears(new Date(data.snapshotHeader.selectedEndDate), 1), 'MMM dd, yyyy');
    const date3MonthsPrior = format(subMonths(new Date(data.snapshotHeader.selectedEndDate), 3), 'MMM yyyy');
    const footerDate = data.snapshotHeader.isMulti ? `${longDateStart} - ${longDateEnd}` : `${date3MonthsPrior} - ${shortDateEnd}`;
    const stats = data.snapshotHeader.isMulti
      ? `${longDateStart} - ${longDateEnd} stats are compared to ${longDateStartYearPrior} - ${longDateEndYearPrior} average`
      : `${shortDateEnd} stats are compared to previous 3 months' average`;
    return (
      `Based on information from ${data.snapshotHeader.legalName} for ` +
      `${data.snapshotHeader.propertyType} in the price range ${data.snapshotHeader.priceRange} for the period ` +
      `${footerDate}. ${stats}.` +
      ` Source data is deemed reliable but not guaranteed.`
    );
  }

  generateSnapshotPostData(data: IMarketSnapshotFormGroup) {
    this.filters = data;
    const userData = this.store.select(selectUser);
    const mlsId = this.store.select(selectCurrentMLS);
    const priceMax = data?.report.priceRange?.maxPrice || 999999999;
    const priceMin = data?.report.priceRange?.minPrice || 0;

    return combineLatest([userData, mlsId]).pipe(
      take(1),
      map(([user, mls]) => {
        const isCustom = data.report.dateRangeMulti.isCustom;
        const endDate = isCustom ? data.report.dateRangeMulti.endDate : data.report.dateRangeMulti.monthlyEndDate;
        const customDateFormat = isCustom ? 'MM/dd/yyyy' : 'MM/yyyy';
        const isReo = data.report.isReo;
        let postData: IMarketSnapshotBody = {
          city: [],
          county: [],
          isCustom,
          isReo,
          startDate: format(data.report.dateRangeMulti.startDate || new Date(), 'MM/dd/yyyy'),
          endDate: format(endDate || new Date(), customDateFormat),
          mlsArea: [],
          mlsid: [mls.mlsId],
          priceMax: parseInt(priceMax.toString()?.replace(/[^0-9.]*/g, ''), 10) || 999999999,
          priceMin: parseInt(priceMin.toString()?.replace(/[^0-9.]*/g, ''), 10) || 0,
          propertyType: data.report.propType,
          schoolDistrict: [],
          subDivision: [],
          oktaId: user.oktaId,
          zip: []
        };

        if (data.locationFilters && data.locationFilters[0]) {
          postData[LocationEnum[data.locationFilters[0].areaType]].push(data.locationFilters[0].areaID.toString());
        }

        return { mls, postData, data };
      })
    );
  }

  fetchMarketSnapshot(data: IMarketSnapshotBody, mlsId: string) {
    this.resetSnapshotIdCache();
    return this.http.post<IMarketSnapshotData>(`${environment.apiBaseUrl}/api/Report/${mlsId}/postsnapshot`, data);
  }

  fetchMarketSnapshotAsset(type: 'tall' | 'wide' | 'square' | 'postcard', body: IMarketSnapshotData) {
    let id = '';
    return this.store.select(selectOktaId).pipe(
      switchMap(usrId => {
        id = usrId;
        return this.checkSnapshotIdCache() ? of(this.snapshotId) : this.postSnapshotToDb(id, body);
      }),
      switchMap(string => {
        this.snapshotId = string;

        return this.http.get(`${environment.apiBaseUrl}/api/export/GetSnapshotAsset/${string}?oktaId=${id}&asset=${type}`, {
          responseType: 'blob'
        });
      }),
      mergeMap(file => {
        const extension = type === 'postcard' ? '.pdf' : '.jpg';
        const fileName =
          `Snapshot-${body.snapshotHeader.areaLocalName.replace(', ', '-')}` + `${body.snapshotHeader.displayDateRange}-${type}`;
        this.fileSaver.save(file, `${fileName}${extension}`);

        return of(false);
      })
    );
  }

  resetSnapshotIdCache(): void {
    this.snapshotId = null;
  }

  checkSnapshotIdCache() {
    return !!this.snapshotId;
  }

  postSnapshotToDb(oktaId: string, body: IMarketSnapshotData) {
    return this.http.post(`${environment.apiBaseUrl}/api/Report/${oktaId}/postsnapshottodb`, body, {
      responseType: 'text'
    });
  }

  sendSnapshot() {
    let usr = null;
    combineLatest([this.store.select(selectSnapshotData), this.store.select(selectUser)])
      .pipe(
        take(1),
        mergeMap(([snapshot, user]) => {
          usr = user;
          if (this.checkSnapshotIdCache()) {
            return of(this.snapshotId);
          } else {
            return this.postSnapshotToDb(user.oktaId, snapshot);
          }
        })
      )
      .subscribe(idStr => {
        this.snapshotId = idStr;
        const d = {
          firstEmail: usr.email,
          reportid: this.snapshotId,
          secondEmail: '',
          senderEmail: usr.email,
          senderName: usr.name,
          thirdEmail: '',
          oktaId: usr.oktaId,
          isSnapshot: true,
          type: 'snapshot'
        };
        this.dialog.open(MailModalComponent, {
          data: d,
          width: this.isDesktop ? '450px' : '100vw',
          maxWidth: this.isDesktop ? '90vw' : 'none',
          height: this.isDesktop ? 'auto' : '100vh'
        });
      });
  }

  saveSnapshotFilterSession(value: unknown) {
    this.snapshotFormGroup = value;
  }

  restoreSnapshotFilterSession() {
    return this.snapshotFormGroup;
  }

  resetSnapshotFilterSession() {
    this.snapshotFormGroup = null;
  }

  getSnapshotDataPoints(): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/api/report/${this.oktaId}/SnapshotDataPoints`);
  }

  saveSnapshotDataPoints(dataPoints: ISnapshotDataPointData): Observable<any> {
    return this.http.post(`${environment.apiBaseUrl}/api/report/${this.oktaId}/postsnapshotdatapoints`, dataPoints);
  }
}
