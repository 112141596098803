import { IBreadCrumb } from '../../models/IBreadCrumb';
import { BrowserActionsActionType, BrowserDataActions } from '../actions/browser.action';
import { getInitialBrowserState, IBrowserState } from '../state/browser.state';

export function BrowserDataReducer(
  state = getInitialBrowserState(),
  action: BrowserDataActions
): IBrowserState {
  switch (action.type) {
    case BrowserActionsActionType.SetPrevUrls: {
      return {
        ...state,
        prevPath: action.payload
      };
    }

    case BrowserActionsActionType.RemoveLastPath: {
      let paths: IBreadCrumb[] = JSON.parse(JSON.stringify(state.prevPath));
      paths = paths.filter(path => !path.label.toLowerCase().includes('image'));

      return {
        ...state,
        prevPath: paths
      };
    }

    case BrowserActionsActionType.AddPath: {
      const paths = JSON.parse(JSON.stringify(state.prevPath));
      paths.push(action.payload);

      return {
        ...state,
        prevPath: paths
      };
    }

    default:
      return state;
  }
}
