import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AreaIdLookupPipe } from '../../../pipes/area-id-lookup.pipe';
import { SavedSearchLookupPipe } from '../../../pipes/saved-search-lookup.pipe';
import { SavedSearchInputComponent } from '../../core/filter-panel/saved-search-input/saved-search-input.component';
import { SavedSearchConfirmationModalComponent } from './saved-search-confirmation-modal/saved-search-confirmation-modal.component';
import { SavedSearchModalComponent } from './saved-search-modal.component';
import { SavedSearchesTableComponent } from './saved-searches-table/saved-searches-table.component';

@NgModule({
  declarations: [
    SavedSearchModalComponent,
    SavedSearchConfirmationModalComponent,
    SavedSearchInputComponent,
    AreaIdLookupPipe,
    SavedSearchesTableComponent,
    SavedSearchLookupPipe
  ],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule, MatTooltipModule],
  exports: [SavedSearchInputComponent, SavedSearchModalComponent, SavedSearchConfirmationModalComponent, SavedSearchLookupPipe]
})
export class SavedSearchModalModule {}
