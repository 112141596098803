import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { filter, mergeMap, take } from 'rxjs/operators';
import { GalleryImage } from '../models/GalleryImage';
import { ImageTypes } from '../models/ImageTypes';
import { FetchUserDefaultImage } from '../store/actions/userData.action';
import { selectUser, selectUserDataLoaded } from '../store/selectors/userData.selector';
import { IAppState } from '../store/state/app.state';

const ChartTypeArr = {
  background: 'Background',
  tall: 'Tall',
  square: 'Square',
  wide: 'Wide',
  HS_Web: 'HS_Web',
  HS_FB: 'HS_FB',
  email: 'Email',
  logo: 'Logo',
  emailLogo: 'EmailLogo',
  websiteDba: 'WebsiteDBA',
  pdfDba: 'PDFDBA',
  emailDba: 'EmailDBA'
} as const;

type ChartSize = typeof ChartTypeArr[keyof typeof ChartTypeArr];

@Injectable()
export class GalleryService {
  private headers: HttpHeaders;

  constructor(private _http: HttpClient, private store: Store<IAppState>) {}

  getGalleryImages(id: string) {
    return this._http.get<GalleryImage[]>(`${environment.apiBaseUrl}/api/image/getGallery/${id}`, { headers: this.headers });
  }

  getGalleryImagesForUser(id: string) {
    return this._http.get<GalleryImage[]>(`${environment.apiBaseUrl}/api/image/getUsersGallery/${id}`, { headers: this.headers });
  }

  setDefaultImage(id: string, imageId: number) {
    return this._http.post(`${environment.apiBaseUrl}/api/User/${id}/profilesettings`, [imageId], { headers: this.headers });
  }

  selectImageByType(images: GalleryImage[], size: ChartSize) {
    const i = size.toLowerCase();
    return images.find(image => image.imageSize.toLowerCase() === ImageTypes[i].toLowerCase());
  }

  fetchUserGallery() {
    return this.store
      .select(selectUserDataLoaded)
      .pipe(
        filter(v => v),
        mergeMap(() => {
          return this.store.select(selectUser);
        }),
        take(1)
      )
      .subscribe(user => {
        this.store.dispatch(new FetchUserDefaultImage());
      });
  }
}
