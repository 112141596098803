import { Component, HostBinding, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { OptionsService } from '../../../../../../services/options.service';
import { selectUserImageBackground } from '../../../../../../store/selectors/userData.selector';
import { IAppState } from '../../../../../../store/state/app.state';

@Component({
  selector: 'app-cover-main',
  templateUrl: './cover-main.component.html',
  styleUrls: ['./cover-main.component.scss']
})
export class CoverMainComponent {
  @HostBinding('class') classes = 'full-size';
  @Input() showAgentInfo = true;
  coverImage$ = this.store.select(selectUserImageBackground).pipe(
    map(image => {
      const imagePath = this.optionsServ.getImagePathForReport() + (image ? image.imageURL : '');
      return { imagePath };
    })
  );

  constructor(private store: Store<IAppState>, private optionsServ: OptionsService) {}
}
