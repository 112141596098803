import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMarketPositionData } from '../../../../../models/IMarketPositionData';
import { ITopGroupTypeData } from '../../../../../models/ITopGroupTypeData';
import { DisclaimerService } from '../../../../../services/disclaimer.service';
import { ExportService } from '../../../../../services/export.service';
import { OptionsService } from '../../../../../services/options.service';
import { ImageTypes } from '../../../../../models/ImageTypes';
import { selectMarketReportData, selectMarketShowAsPercent } from '../../../../../store/selectors/market-position.selector';
import { pdfOptions } from '../../../../../store/selectors/pdf.selector';
import { IAppState } from '../../../../../store/state/app.state';
import { IPdfOptions } from '../../../../../store/state/pdf.state';

@Component({
  selector: 'app-sir-market-export-preview',
  templateUrl: './s-i-r-market-export-preview.component.html',
  styleUrls: ['./s-i-r-market-export-preview.component.scss']
})
export class SIRMarketExportPreviewComponent implements OnInit {
  showPercent: Observable<boolean>;
  logo: string;
  data$: Observable<{
    data: IMarketPositionData;
    opts: IPdfOptions;
    disclaimer: string;
    graphData: ITopGroupTypeData[];
    arr: { height: number; value: any }[];
    showPercent: boolean;
    logo: string;
  }>;

  constructor(
    private store: Store<IAppState>,
    private exportService: ExportService,
    private optionsService: OptionsService,
    private disclaimer: DisclaimerService
  ) {}

  private static formatFirmName(opts: IPdfOptions, entity: ITopGroupTypeData, idx: number, numberOneEntityName: string) {
    entity.entityName = ExportService.trimDataAfterParenthesis(entity.entityName);
    return opts.showFirmNames
      ? entity.entityName
      : entity.entityName === numberOneEntityName
      ? numberOneEntityName
      : SIRMarketExportPreviewComponent.hideFirmName(idx);
  }

  private static hideFirmName(idx: number) {
    return 'Firm ' + (10 + idx).toString(36).toUpperCase();
  }

  ngOnInit(): void {
    this.data$ = combineLatest([
      this.store.select(selectMarketReportData),
      this.store.select(pdfOptions),
      this.store.select(selectMarketShowAsPercent)
    ]).pipe(
      map(data => {
        const arr = [];
        const [mpData, opts, showPercent] = cloneDeep(data);
        const maxValue = Math.max.apply(
          Math,
          mpData.topGroupTypes.map(o => {
            return mpData.isUnits ? o.totalUnits : o.totalVolume;
          })
        );
        mpData.topGroupTypes.map(i => {
          const suffix = showPercent ? 'Percent' : '';
          const prefix = mpData.isUnits ? 'Units' : 'Volume';
          let value: any;
          if (!mpData.isUnits) {
            value = showPercent ? i[`total${prefix}${suffix}`] + '%' : this.optionsService.numFormatter(i[`total${prefix}${suffix}`], 2);
          } else {
            value = i[`total${prefix}${suffix}`];
          }
          arr.push({
            height: (i[`total${prefix}`] / maxValue) * 100,
            value
          });
        });
        mpData.topGroupTypes = this.formatGraphData(mpData.topGroupTypes, opts, mpData.realogyPosition?.entityName || '');
        mpData.reportHeaderEntity.priceRange = this.formatAllPrice(mpData.reportHeaderEntity.priceRange);
        const disclaimer = this.disclaimer.formatDisclaimerSIR(mpData.reportHeaderEntity);
        const logo =
          this.optionsService.getImagePathForReport() +
          mpData?.imagePromoEntities.find(image => {
            return image.imageSize == ImageTypes['websiteDba'];
          }).imageURL;
        return { graphData: mpData.topGroupTypes, data: mpData, opts, arr, showPercent, disclaimer, logo };
      })
    );
  }

  formatAllPrice(value) {
    return value.indexOf('999999999') > 0 ? 'All Price Ranges' : value;
  }

  private formatGraphData(data: ITopGroupTypeData[], opts: IPdfOptions, numberOneEntityName: string) {
    return data.map((entity, idx) => {
      return {
        ...entity,
        entityName: SIRMarketExportPreviewComponent.formatFirmName(opts, entity, idx, numberOneEntityName)
      };
    });
  }
}
