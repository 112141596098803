import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FeaturesLabel, IFeature } from '../../models/IFeature';
import { FeatureService } from '../../services/feature.service';
import { FeatureDataActionsActionTypes, FetchFeaturesByOktaId, FetchFeaturesSuccess } from '../actions/featureData.action';
import { IAppState } from '../state/app.state';

@Injectable()
export class FeatureDataEffect {
  fetchFeatures$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchFeaturesByOktaId>(FeatureDataActionsActionTypes.FetchFeaturesByOktaId),
      switchMap(action => this._featureService.getAllFeaturesForOktaId(action.payload)),
      switchMap((features: IFeature[]) => {
        return of(new FetchFeaturesSuccess(features));
      })
    )
  );

  constructor(private _actions$: Actions, private _store: Store<IAppState>, private _featureService: FeatureService) {}
}
