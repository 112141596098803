import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { EncryptService } from '../services/encrypt.service';
import { OptionsService } from '../services/options.service';

@Injectable()
export class ReportPageResolver implements Resolve<any> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private encryptService: EncryptService,
    private options: OptionsService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const encryptedId = route.params.id;
    return forkJoin([this.encryptService.getDecryptedReportDetails(encryptedId), of(this.options.getImagePathForReport())]);
  }
}
