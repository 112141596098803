import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class EmailAddressStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-email-address',
  templateUrl: './email-address.component.html',
  styleUrls: ['./email-address.component.scss']
})
export class EmailAddressComponent implements OnInit {
  @Input() emailAddress: string;
  @Input() showTrash: boolean;
  @Output() trashClicked = new EventEmitter<boolean>();
  emailFormControl: UntypedFormControl;
  matcher = new EmailAddressStateMatcher();
  public black = '#000000';

  ngOnInit() {
    this.emailFormControl = new UntypedFormControl(null, [Validators.required, Validators.email]);
    this.emailFormControl.setValue(this.emailAddress);
  }

  delete(clicked: boolean): void {
    this.trashClicked.emit(clicked);
  }
}
