<ejs-chart
  (axisLabelRender)="axisLabelRender($event)"
  (pointRender)="pointRender($event)"
  (seriesRender)="seriesRender($event)"
  (textRender)="textRender($event)"
  *ngIf="!!localData.length"
  [chartArea]="chartArea"
  [legendSettings]="legend"
  [palettes]="palette"
  [primaryXAxis]="primaryXAxis"
  [primaryYAxis]="primaryYAxis"
  [tooltip]="tooltip"
  [id]="chartId"
>
  <e-axes>
    <e-axis
      [border]="{ width: 0 }"
      [lineStyle]="{ width: 0 }"
      [majorGridLines]="{ width: 0 }"
      [majorTickLines]="{ width: 0 }"
      [rangePadding]="'Normal'"
      [visible]="true"
      name="yAxis1"
      opposedPosition="true"
      rowIndex="0"
      [title]="yAxisLabels[1]"
    >
    </e-axis>
  </e-axes>
  <e-series-collection>
    <e-series
      *ngFor="let series of localData; let idx = index"
      [animation]="{ enable: false }"
      [cornerRadius]="cornerRadius"
      [dataSource]="localData[idx]"
      [legendShape]="'Square'"
      [marker]="marker"
      [name]="dataTypes[idx]"
      [type]="chartTypes[idx]"
      [zOrder]="chartTypes[idx] === 'Line' ? '10' : '1'"
      [yAxisName]="!axis[idx] ? null : 'yAxis1'"
      width="4"
      xName="xData"
      yName="yData"
    >
      <e-trendlines *ngIf="showTrendLines">
        <e-trendline
          [animation]="animation"
          [period]="3"
          type="MovingAverage"
          width="3"
          [fill]="fill[idx]"
          [legendShape]="'Square'"
          [name]="dataTypes[idx] + ' 3 Mo. Avg'"
        >
        </e-trendline>
      </e-trendlines>
    </e-series>
  </e-series-collection>
</ejs-chart>
