import { Component, Input, OnInit } from '@angular/core';
import { isString } from 'lodash-es';
import { ITrendsTableData } from '../../../../../../models/ITrendsTableData';
import { MarketTrendsService } from '../../../../../../services/market-trends.service';

@Component({
  selector: 'app-dynamic-cell',
  templateUrl: './dynamic-cell.component.html',
  styleUrls: ['./dynamic-cell.component.scss']
})
export class DynamicCellComponent implements OnInit {
  formattedVal: string | number;
  @Input() value: number;
  @Input() type: number;
  @Input() dataPoint: ITrendsTableData;
  difference: number;

  constructor(private marketTrendsService: MarketTrendsService) {}

  private static removeNonNumeric(data: string | number) {
    if (isString(data)) {
      return parseFloat(data.replace(/[,$%]/g, ''));
    }
    return data;
  }

  private static calculateDifferenceValue(dataPoint: number | string, type: number, componentValue: number) {
    let convertedDataPoint = dataPoint;

    if (type === 2) {
      convertedDataPoint = DynamicCellComponent.removeNonNumeric(dataPoint);
      return DynamicCellComponent.isNegativeOrPositive(componentValue, convertedDataPoint);
    }
    return componentValue;
  }

  private static isNegativeOrPositive(val: number, cmValue: number) {
    if (cmValue - val < 0) {
      return -1;
    }
    if (cmValue - val === 0) {
      return 0;
    }
    return 1;
  }

  ngOnInit(): void {
    this.formattedVal = this.marketTrendsService.formatByType(this.value, this.dataPoint?.formattype);
    this.difference = DynamicCellComponent.calculateDifferenceValue(this.dataPoint?.cm, this.type, this.value);
  }
}
