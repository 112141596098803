import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Avatar } from '../../../models/IApplicationData';
import { selectUser } from '../../../store/selectors/userData.selector';
import { IAppState } from '../../../store/state/app.state';

@Component({
  selector: 'app-avatar-menu',
  templateUrl: './avatar-menu.component.html',
  styleUrls: ['./avatar-menu.component.scss']
})
export class AvatarMenuComponent implements OnInit {
  avatar: Avatar;
  subs = new Subscription();

  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.subs.add(
      this.store
        .select(selectUser)
        .pipe(filter(d => !!d.avatar))
        .subscribe(userData => {
          this.avatar = userData.avatar;
        })
    );
  }
}
