<mat-radio-button [value]="true" *ngIf="!hideRadio">
  <span class="d-flex align-items-center justify-content-between"
    >Custom Date Range
    <mat-icon
      #tooltip="matTooltip"
      (click)="tooltip.toggle()"
      matTooltip="Custom date range stats are compared to same date range in the previous year."
      matTooltipPosition="above"
      >info_outline
    </mat-icon>
  </span>
</mat-radio-button>
<div class="d-flex gap-2 pb-3">
  <mat-form-field appearance="outline">
    <mat-label>Start date</mat-label>
    <input
      (click)="picker2.open()"
      [formControl]="startControl"
      [matDatepicker]="$any(picker2)"
      [max]="maxDate"
      [min]="minDate"
      [readonly]="true"
      class="date"
      matInput
    />
    <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #picker2 startView="month"></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>End date</mat-label>
    <input
      (click)="picker3.open()"
      [formControl]="endControl"
      [matDatepicker]="$any(picker3)"
      [min]="adjustedMinDate"
      [max]="lastRunDate"
      [readonly]="true"
      class="date"
      matInput
    />
    <mat-datepicker-toggle [for]="picker3" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #picker3 startView="month"></mat-datepicker>
  </mat-form-field>
</div>
