<div class="user-profile divider px-3">
  <div class="photo-mask" *ngIf="avatar$ | async as avatar; else otherPhoto">
    <app-avatar-image [size]="100" [avatar]="avatar" *ngIf="avatar.faces"></app-avatar-image>
  </div>
  <ng-template #otherPhoto>
    <div class="photo-mask" *ngIf="data.photo">
      <div class="large-agent" [ngStyle]="{ 'background-image': 'url(' + otherUrl + ')' }"></div>
    </div>
  </ng-template>
  <span class="typography-medium fw-bold ">{{ data.name }}</span>
  <div class="d-flex agent-title">
    <span class="typography-normal typography-black-grey">{{ data.title }}</span>
    <span *ngIf="data.number" class="typography-normal typography-black-grey">{{ data.number }}</span>
  </div>
</div>
