import { IMarketPositionData } from 'app/models/IMarketPositionData';
import { IMarketFiltersV2 } from '../../models/IMarketReportFilters';

export interface IMarketPositionState {
  loading: boolean;
  loaded: boolean;
  error: boolean;
  marketReportDetails: IMarketPositionData;
  marketFilters: IMarketFiltersV2;
  dates: any[];
  showPercent: boolean;
}

export const initialMarketPositionDataState: IMarketPositionState = {
  loading: false,
  loaded: false,
  error: false,
  marketReportDetails: {
    agentReportEntity: undefined,
    reportHeaderEntity: undefined,
    totalMarket: {
      entityName: '',
      listVolume: 0,
      listUnits: 0,
      soldVolume: 0,
      soldUnits: 0
    },
    topGroupTypes: [],
    realogyPosition: null,
    imagePromoEntities: undefined,
    videoEntities: undefined,
    msTypeName: '',
    isUnits: false,
    hasMultipleLocations: null,
    reportId: ''
  },
  marketFilters: new IMarketFiltersV2(),
  dates: [],
  showPercent: false
};

export function getInitialMarketPositionDataState(): IMarketPositionState {
  return initialMarketPositionDataState;
}
