import { BreakpointObserver } from '@angular/cdk/layout';
import { ComponentType, NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { cloneDeep } from 'lodash-es';

@Injectable()
export class ModalService {
  private readonly modalConfigDefaults: MatDialogConfig;

  constructor(private modal: MatDialog,
              private breakpointObserver: BreakpointObserver) {
    this.modalConfigDefaults = {
      /** Custom class for the overlay pane. */
      panelClass: 'website-preview',
      /** Whether the dialog has a backdrop. */
      hasBackdrop: true,
      /** Whether the user can use escape or clicking on the backdrop to close the modal. */
      disableClose: true,

      /** Whether the dialog should focus the first focusable element on open. */
      autoFocus: false,
      /**
       * Whether the dialog should restore focus to the
       * previously-focused element, after it's closed.
       */
      restoreFocus: true,
      /**
       * Whether the dialog should close when the user goes backwards/forwards in history.
       * Note that this usually doesn't include clicking on links (unless the user is using
       * the `HashLocationStrategy`).
       */
      closeOnNavigation: true,
      scrollStrategy: new NoopScrollStrategy()
    };
  }

  /**
   *
   * @param {ComponentType} component
   * @param {MatDialogConfig} config
   */
  open(component: ComponentType<any>, config?: MatDialogConfig) {
    let configuration = cloneDeep(this.modalConfigDefaults);
    const mobileConfig = {
      height: '100vh',
      width: '100vw',
      maxWidth: 'none',
      data: config?.data
    };
    const desktopConfig = {
      height: 'auto',
      width: '90%',
      maxWidth: '1005px'
    };
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 600px)');
    configuration = isSmallScreen ? Object.assign(configuration, mobileConfig) : Object.assign(configuration, desktopConfig);
    if (!isSmallScreen) {
      configuration = Object.assign(configuration, config);
    }
    return this.modal.open(component, configuration);
  }

  /**
   * Close all dialogs
   */
  close() {
    this.modal.closeAll();
  }
}
