import { Injectable } from '@angular/core';

export enum Tenants {
  realogy = 'realogy',
  sothebysrealty = 'sothebysrealty'
}

export enum TenantCode {
  CBR = 'CBR',
  SIR = 'SIR'
}

export enum BrandCodesEnum {
  CBR = 'cb',
  SIR = 'sothebys'
}

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  constructor() {
  }

  getTenantForHostname(hostname: string): Tenants {
    const h = hostname.split('.');
    if (h.some(urlSubStr => urlSubStr === 'localhost')) {
      return this.getTenantForHost(Tenants.realogy);
    }
    return this.getTenantForHost(h[1]);
  }

  getTenantForString(brand: string) {
    if (brand in Tenants) {
      return Tenants[brand] as Tenants;
    }
    return null;
  }

  getTenantForHost(host: string): Tenants {
    return this.getTenantForString(host);
  }

  getTenant(): Tenants {
    return this.getTenantForHostname(location.hostname);
  }

  addTenantToHeaders() {
    return { 'Tenant-ID': this.getTenant() };
  }
}
