<div class="wif-container">
  <div (click)="closeHelp()" class="return-link">
    <mat-icon>arrow_back</mat-icon>
    <span>Back to Image</span>
  </div>
  <div class="wif-title">
    <span>{{ 'Which Image Format Should I Use?' | titlecase }}</span>
  </div>
  <div [ngClass]="type === 'homespotter' ? 'hs' : ''" class="wif-guides">
    <div *ngIf="!isSIR" class="example">
      <div class="guide-box wide"></div>
      <div class="example-type">
        <span>Facebook Post</span>
        <p>Use Wide Resolution</p>
        <p>(1200 x 630)</p>
      </div>
    </div>
    <div *ngIf="type === 'social'" class="example">
      <div class="guide-box square"></div>
      <div class="example-type">
        <span>Instagram Post</span>
        <p>Use Square Resolution</p>
        <p>(1200 x 1200)</p>
      </div>
    </div>
    <div *ngIf="type === 'social'" class="example">
      <div class="guide-box tall"></div>
      <div class="example-type">
        <span>Instagram Story</span>
        <p>Use Tall Resolution</p>
        <p>(1080 x 1920)</p>
      </div>
    </div>
    <div *ngIf="type === 'homespotter'" class="example">
      <div class="guide-box web"></div>
      <div class="example-type">
        <span>Web Image</span>
        <p>Use Web Resolution</p>
        <p>(300 x 250)</p>
      </div>
    </div>
  </div>
</div>
