import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectBrandCode } from 'app/store/selectors/applicationData.selector';
import { format } from 'date-fns';
import { filter, take } from 'rxjs/operators';
import { FilterData, LocationEnum } from '../models/FilterData';
import { MarketFilters, MarketFiltersV2PropertyTypeEnum, marketFiltersV2PropertyValueEnum } from '../models/IMarketReportFilters';
import { IMarketSnapshotFormGroup } from '../models/IMarketSnapshotFormGroup';
import { TrendsFilters } from '../models/IMarketTrendsFilters';
import { ITrendsOptions } from '../models/ITrendsOptions';
import { TrendDataFormatAltTypes } from '../models/ITrendsTableData';
import { IWootric } from '../models/IWootric';
import { selectTrendsPdfOptions } from '../store/selectors/market-trends.selector';
import { IAppState } from '../store/state/app.state';
import { DataLayerObjectService } from './data-layer.service';
import { MarketPositionService } from './market-position.service';
import { MarketSnapshotService } from './market-snapshot.service';
import { MarketTrendsService } from './market-trends.service';
import { ReportService } from './report.service';
import { UserDataService } from './userData.service';
import { selectpersonMasterId, selectagentMasterId } from 'app/store/selectors/userData.selector';

@Injectable()
export class GTMService {
  brandCode = '';
  personMasterId = '';
  agentMasterId = '';

  constructor(
    private dataLayerObject: DataLayerObjectService,
    private reportService: ReportService,
    private marketService: MarketPositionService,
    private trendsService: MarketTrendsService,
    private snapshotService: MarketSnapshotService,
    private userDataService: UserDataService,
    private store: Store<IAppState>
  ) {
    this.store
      .select(selectBrandCode)
      .pipe(filter(v => !!v))
      .subscribe(brandCode => {
        this.brandCode = brandCode;
      });
    this.store
      .select(selectpersonMasterId)
      .pipe(filter(v => !!v))
      .subscribe(personId => {
        this.personMasterId = personId;
      });
    this.store
      .select(selectagentMasterId)
      .pipe(filter(v => !!v))
      .subscribe(agentMDM => {
        this.agentMasterId = agentMDM;
      });
  }

  private static pushGTMEvent(eventName: string, data: any, brandCode = '', businessUnit, personMasterId: string, agentMasterId: string) {
    (window as any).dataLayer.push({
      event: eventName,
      action: eventName,
      brand: brandCode,
      businessUnit: businessUnit,
      ['content-name']: eventName,
      personMasterId: personMasterId,
      agentMasterId: agentMasterId,
      ...data
    });
  }

  private static generateReportFilterObj(filterData: MarketFilters) {
    return {
      county: Math.min(filterData.county.length, 9),
      city: Math.min(filterData.city.length, 9),
      zipCode: Math.min(filterData.zip.length, 9),
      mlsArea: Math.min(filterData.mlsArea.length, 9),
      schoolDistrict: Math.min(filterData.schoolDistrict.length, 9),
      subDivision: Math.min(filterData.subDivision.length, 9)
    };
  }

  updateCustomSet(customSet: IWootric) {
    this.dataLayerObject.customSet = customSet;
    this.dataLayerObject.trigger();
  }

  trendsExportSuccess(options: ITrendsOptions) {
    function chartType(val) {
      switch (val) {
        case 0: {
          return 'arrow';
        }
        case 1: {
          return 'percentage';
        }
        case 2: {
          return 'numeric';
        }

        default: {
          return '';
        }
      }
    }

    (window as any).dataLayer.push({
      event: 'marketTrendsExportReport',
      action: 'marketTrendsExportReport ',
      ['content-name']: 'marketTrendsExportReport',
      brand: this.brandCode,
      businessUnit: this.userDataService.getBusinessUnit(),
      marketTrendsExportReport: {
        overview: options.showtable,
        averageMedianSP: options.showamsp,
        ratioSPLP: options.showsplp,
        propertiesSold: options.shownbrsoldar,
        averageDOM: options.showdom,
        averageSPSQFT: options.showspsqft,
        inventory: options.showaimsi,
        newListings: options.shownl,
        orientation: options.islandscape ? 'landscape' : 'portrait',
        statsIcon: chartType(options.chartdatatype)
      }
    });
  }

  generateTrendsExportGTM(filterData: TrendsFilters, trendsOption: ITrendsOptions) {
    const locationFilters = GTMService.generateReportFilterObj(filterData);
    const propTypes = this.setupPropertyTypesArray(filterData.propertyType as number[]);
    const reportFilters = {
      propertyType: propTypes || 'none',
      priceRange: [filterData.priceMin, filterData.priceMax],
      timePeriod: filterData.endDate
    };
    const allTrue = Object.keys(trendsOption)
      .filter(key => key.includes('show'))
      .every(v => !!trendsOption[v]);
    const sectionFilters = {
      allSection: allTrue,
      overview: trendsOption.showtable,
      averageMedianSP: trendsOption.showamsp,
      ratioSPLP: trendsOption.showsplp,
      propertiesSold: trendsOption.shownbrsoldar,
      averageDOM: trendsOption.showdom,
      averageSPSQFT: trendsOption.showspsqft,
      inventory: trendsOption.showaimsi,
      newListings: trendsOption.shownl,
      orientation: trendsOption.islandscape ? 'landscape' : 'portrait',
      statsIcon: TrendDataFormatAltTypes[trendsOption.chartdatatype || 0]
    };
    return { locationFilters, reportFilters, sectionFilters };
  }

  generateTrendsGTM(filterData: TrendsFilters) {
    const locationFilters = GTMService.generateReportFilterObj(filterData);
    const propTypes = this.setupPropertyTypesArray(filterData.propertyType as number[]);
    const reportFilters = {
      propertyType: propTypes || 'none',
      priceRange: [filterData.priceMin, filterData.priceMax],
      timePeriod: filterData.endDate
    };
    return { locationFilters, reportFilters };
  }

  generateMarketGTM(filterData: MarketFilters) {
    const locationFilters = GTMService.generateReportFilterObj(filterData);
    const propTypes = this.setupPropertyTypesArray(filterData.propertyType);
    const reportFilters = {
      propertyType: propTypes || 'none',
      priceRange: [filterData.priceMin, filterData.priceMax],
      dateRange: filterData.dateRange,
      transactionSide: filterData.msTypeName[0] || 'none',
      salesType: filterData.unitType[0] || 'none',
      groupBy: filterData.groupByLevel[0] || 'none'
    };
    return { locationFilters, reportFilters };
  }

  generateClaimsGTM(filterData: FilterData) {
    const locationFilters = {
      county: Math.min(filterData.state.county.length, 9),
      city: Math.min(filterData.state.city.length, 9),
      zipCode: Math.min(filterData.state.zip.length, 9)
    };
    const reportFilters = {
      transactionType: filterData.state.msTypeName[0] || 'none',
      salesType: filterData.state.unitType[0] || 'none',
      timePeriod: filterData.state.timeFrame[0] || 'none',
      groupBy: filterData.state.groupByLevel[0] || 'none',
      millionPriceFilter: `${filterData.state.priceType}`
    };
    return { locationFilters, reportFilters };
  }

  generateSnapshotGTM(filterData: IMarketSnapshotFormGroup) {
    const locationFilters = {
      mlsArea: 0,
      county: 0,
      city: 0,
      zipCode: 0,
      schoolDistrict: 0,
      subDivision: 0
    };

    if (filterData?.locationFilters) {
      if (filterData.locationFilters['areaType']) {
        locationFilters[LocationEnum[filterData.locationFilters['areaType']]] = 1;
      } else if (filterData.locationFilters.length > 0) {
        filterData.locationFilters.forEach(locationFilter => {
          locationFilters[LocationEnum[locationFilter.areaType]] = 1;
        });
      }
    }

    const propTypes = this.setupPropertyTypesArray(filterData?.report?.propType);
    const reportFilters = {
      propertyType: propTypes || 'none',
      timePeriod: format(filterData?.report?.dateRangeMulti?.endDate || new Date(), 'MM/yyyy'),
      priceRangeFilter: [filterData?.report?.priceRange?.minPrice || 'none', filterData?.report?.priceRange?.maxPrice || 'none']
    };
    return { locationFilters, reportFilters };
  }

  claimsGTM(eventName: string) {
    GTMService.pushGTMEvent(
      eventName,
      this.generateClaimsGTM(this.reportService.filters$),
      this.brandCode,
      this.userDataService.getBusinessUnit(),
      this.personMasterId,
      this.agentMasterId
    );
  }

  marketGTM(eventName: string) {
    GTMService.pushGTMEvent(
      eventName,
      this.generateMarketGTM(this.marketService.filters),
      this.brandCode,
      this.userDataService.getBusinessUnit(),
      this.personMasterId,
      this.agentMasterId
    );
  }

  trendsGTM(eventName: string) {
    GTMService.pushGTMEvent(
      eventName,
      this.generateTrendsGTM(this.trendsService.filters),
      this.brandCode,
      this.userDataService.getBusinessUnit(),
      this.personMasterId,
      this.agentMasterId
    );
  }

  snapshotGTM(eventName: string) {
    GTMService.pushGTMEvent(
      eventName,
      this.generateSnapshotGTM(this.snapshotService.filters),
      this.brandCode,
      this.userDataService.getBusinessUnit(),
      this.personMasterId,
      this.agentMasterId
    );
  }

  trendsExportGTM(eventName: string) {
    this.store
      .select(selectTrendsPdfOptions)
      .pipe(take(1))
      .subscribe(data => {
        GTMService.pushGTMEvent(
          eventName,
          this.generateTrendsExportGTM(this.trendsService.filters, data),
          this.brandCode,
          this.userDataService.getBusinessUnit(),
          this.personMasterId,
          this.agentMasterId
        );
      });
  }

  private setupPropertyTypesArray(filterData: number[]) {
    const propTypes = [];
    (filterData || []).map(propNumberValue => {
      const propValue = typeof propNumberValue === 'string' ? Number.parseInt(propNumberValue, 10) : propNumberValue;
      propTypes.push(
        MarketFiltersV2PropertyTypeEnum[
          Object.keys(marketFiltersV2PropertyValueEnum).find(key => marketFiltersV2PropertyValueEnum[key] === propValue)
        ]
      );
    });

    return propTypes;
  }
}
