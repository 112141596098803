import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { marketFiltersV2PropertyKeyEnum } from '../models/IMarketReportFilters';

@Pipe({
  name: 'SavedSearchLookup'
})
export class SavedSearchLookupPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: any, args?: string) {
    switch (args) {
      case 'msTypeName':
        return value.length > 0 ? value : 'All';
      case 'unitType':
        return value.length > 0 ? value : 'All';
      case 'propType':
        const propTypes = [];
        value.map(propType => {
          propTypes.push(marketFiltersV2PropertyKeyEnum[propType]);
        });
        return propTypes.join(', ');
      case 'timeFrame':
        return value.length > 0 ? value : 'All';
      case 'dateRange':
        return format(new Date(value), 'MM/dd/yyyy');
      case 'priceRange':
        if (!value.minPrice && !value.maxPrice) {
          return `Any Price`;
        }
        return `${this.currencyPipe.transform(value.minPrice, 'USD', 'symbol', '1.0-0') || `$0`} -
        ${this.currencyPipe.transform(value.maxPrice, 'USD', 'symbol', '1.0-0') || `Any Price`}`;
      case 'priceType':
        if (!value.minPrice && !value.maxPrice) {
          return `Any Price`;
        }
        return `${this.currencyPipe.transform(value.minPrice, 'USD', 'symbol', '1.0-0') || `$0`} -
        ${this.currencyPipe.transform(value.maxPrice, 'USD', 'symbol', '1.0-0') || `Any Price`}`;
      case 'groupByLevel':
        return value.length > 0 ? value : 'All';
      case 'dateRangeMulti':
        if (value.isCustom) {
          const start = format(new Date(value.startDate), 'MM/dd/yyyy');
          const end = format(new Date(value.endDate), 'MM/dd/yyyy');
          return `${start} - ${end}`;
        } else {
          return format(new Date(value.endDate), 'MM/yyyy');
        }
      default:
        return value;
    }
  }
}
