<div class="p-0">
  <span class="grid-right mat-panel-title">Time Period</span>
</div>
<div class="px-3 py-4 border-bottom panel-section">
  <mat-form-field appearance="outline">
    <mat-label>mm/yyyy</mat-label>
    <input
      (click)="picker.open()"
      [formControl]="control"
      [matDatepicker]="$any(picker)"
      [max]="maxDate$"
      [readonly]="true"
      class="date"
      matInput
    />
    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #picker (monthSelected)="closeDatePicker($event, picker)" startView="multi-year"></mat-datepicker>
  </mat-form-field>
</div>
