import { Injectable } from '@angular/core';
import { Tenants, TenantService } from '../tenant.service';
import { CbLoginService } from './cb-login.service';
import { SirLoginService } from './sir-login.service';

@Injectable({
  providedIn: 'root'
})
export abstract class LoginService {
  protected constructor() { }
  abstract login();
}

export function getLoginService(
  tenantService: TenantService,
  cbLoginService: CbLoginService,
  sirLoginService: SirLoginService
): LoginService {
  if (tenantService.getTenant() === Tenants.realogy) {
    return cbLoginService;
  } else if (tenantService.getTenant() === Tenants.sothebysrealty) {
    return sirLoginService;
  }

  throw new Error('Unknown tenant for login service');
}
