import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISocialFooterLink } from '../../../models/ISocialFooterLink';
import { OptionsService } from '../../../services/options.service';
import { selectBrandInfo } from '../../../store/selectors/applicationData.selector';
import { IAppState } from '../../../store/state/app.state';

@Component({
  selector: 'app-mq-sir-footer',
  templateUrl: './mq-footer.component.html',
  styleUrls: ['./mq-footer.component.scss']
})
export class MqFooterSIRComponent implements OnInit {
  brandInfo$: Observable<{ copyright: string; brandLinks: ISocialFooterLink[] }>;

  constructor(private store: Store<IAppState>, private options: OptionsService) {}

  ngOnInit(): void {
    this.brandInfo$ = this.store.select(selectBrandInfo('sothebys')).pipe(
      map(brand => {
        const brandLinks = this.options.extractSocialLinkInfo(brand, true);
        return { copyright: brand.copyright, brandLinks };
      })
    );
  }
}
