<div class="controls-description">
  <span>Choose the data points you would like to display on your Custom Market Report</span>
</div>
<div class="controls-types d-flex">
  <ng-container *ngIf="dataPointControlArray | async as controlArray">
    <app-custom-graph-group-by-dropdown
      [showThreePoints]="true"
      (selectedDataPoint)="filterAvailableDataPoints($event)"
      (selectedDisplayAs)="adjustDisplayAs($event)"
      (threeDataPointChange)="threePointHandler($event)"
      [controlIndex]="0"
      [dataPoints]="controlArray.dataTypes[0]"
      [dataTypeValue]="controlArray.dataTypesSelected[0]"
      [displayAsValue]="controlArray.displayAsSelected[0]"
    ></app-custom-graph-group-by-dropdown>
  </ng-container>

  <ng-container *ngIf="dataPointControlArray | async as controlArray">
    <app-custom-graph-group-by-dropdown
      [disabled]="threePointSelected"
      [showThreePoints]="false"
      (selectedDataPoint)="filterAvailableDataPoints($event)"
      (selectedDisplayAs)="adjustDisplayAs($event)"
      (threeDataPointChange)="threePointHandler($event)"
      [controlIndex]="1"
      [dataPoints]="controlArray.dataTypes[1]"
      [dataTypeValue]="controlArray.dataTypesSelected[1]"
      [displayAsValue]="controlArray.displayAsSelected[1]"
    ></app-custom-graph-group-by-dropdown>
  </ng-container>
</div>
