import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '../core/shared/shared-components.module';
import { AvatarImageComponent } from './avatar-menu/avatar-image/avatar-image.component';
import { AvatarMenuComponent } from './avatar-menu/avatar-menu.component';
import { MenuContentsComponent } from './avatar-menu/menu-contents/menu-contents.component';
import { BrandingComponent } from './branding/branding.component';
import { MqHeaderComponent } from './mq-header.component';
import { SideBarNavComponent } from './side-bar-nav/side-bar-nav.component';
import { TerradatumLogoComponent } from './branding/terradatum-logo/terradatum-logo.component';

@NgModule({
  declarations: [
    SideBarNavComponent,
    MqHeaderComponent,
    AvatarMenuComponent,
    BrandingComponent,
    MenuContentsComponent,
    TerradatumLogoComponent
  ],
  exports: [MqHeaderComponent, SideBarNavComponent],
  imports: [CommonModule, SharedComponentsModule, RouterModule]
})
export class MqHeaderModule {}
