import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { IMLSState } from '../state/mls.state';

const mls = (state: IAppState) => state.mls;

export const selectAvailableMLSList = createSelector(mls, (state: IMLSState) => state.mlsid);

export const mlsLoaded = createSelector(mls, (state: IMLSState) => state.loaded);

export const userHasMlsAvailable = createSelector(mls, (state: IMLSState) => {
  return state.loaded && state.mlsid.length > 0;
});

export const selectCurrentMLS = createSelector(mls, (state: IMLSState) => state.selectedMLS);

export const selectCurrentMLSLastRanDate = createSelector(mls, (state: IMLSState) => state.selectedMLS?.lastRunDate);

export const selectCurrentMlsData = createSelector(mls, (state: IMLSState) => {
  return state.mlsid.find(valMls => valMls.mlsId === state.selectedMLS?.mlsId);
});
