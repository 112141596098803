<div class="d-flex flex-row align-items-center p-3 justify-content-between saved-search-controls">
  <button
    (click)="openSaveSearchModal()"
    [disabled]="!(isActive$ | async)"
    [ngClass]="(isActive$ | async) ? 'active' : 'inactive'"
    class="save"
    mat-stroked-button
  >
    Save Search Criteria
    <mat-icon>favorite_outline</mat-icon>
  </button>
  <div
    (click)="openViewSearchModal()"
    [ngClass]="{ enabled: searchesAvailable$ | async }"
    [style.pointer-events]="(searchesAvailable$ | async) ? 'auto' : 'none'"
    class="view"
  >
    View Searches
  </div>
</div>
