import { Action } from '@ngrx/store';
import { IWootric } from '../../models/IWootric';

export enum WootricActionsActionTypes {
  FetchWootric = '[User] Init Wootric API Call',
  FetchWootricSuccess = '[User] Received Wootric API Call',
  LogoutClearWootricState = '[Logout] Clearing Wootric state',
}

export class FetchWootric implements Action {
  readonly type = WootricActionsActionTypes.FetchWootric;
}

export class FetchWootricSuccess implements Action {
  readonly type = WootricActionsActionTypes.FetchWootricSuccess;

  constructor(public payload: IWootric) {
  }
}

export class LogoutClearWootricState implements Action {
  readonly type = WootricActionsActionTypes.LogoutClearWootricState;
}


export type WootricActions =
  FetchWootric | FetchWootricSuccess | LogoutClearWootricState;
