export class IBreadCrumb {
  path: string;
  label: string;
  id?: string;

  constructor(label: string, path: string, id?: string) {
    this.path = path;
    this.label = label;
    this.id = id;
  }
}
