import { Action } from '@ngrx/store';
import { IFeature } from '../../models/IFeature';

export enum FeatureDataActionsActionTypes {
  FetchFeaturesByOktaId = '[Feature] Init FeaturesData API Call',
  FetchFeaturesSuccess = '[Feature] Received FeatureData API Call'
}

export class FetchFeaturesByOktaId implements Action {
  constructor(public payload: string) {}
  readonly type = FeatureDataActionsActionTypes.FetchFeaturesByOktaId;
}

export class FetchFeaturesSuccess implements Action {
  readonly type = FeatureDataActionsActionTypes.FetchFeaturesSuccess;

  constructor(public payload: IFeature[]) {}
}

export type FeatureDataActions = FetchFeaturesByOktaId | FetchFeaturesSuccess;
