import { ApplicationDataActions, ApplicationDataActionsActionTypes } from '../actions/applicationData.action';
import { getInitialApplicationState, IApplicationDataState } from '../state/applicationData.state';

export function ApplicationDataReducer(
  state = getInitialApplicationState(),
  action: ApplicationDataActions
): IApplicationDataState {
  switch (action.type) {
    case ApplicationDataActionsActionTypes.SetApplicationData: {
      return {
        ...state,
        ...action.payload
      };
    }

    case ApplicationDataActionsActionTypes.SetBrandCodeSuccess: {
      return {
        ...state,
        selectedBrandCode: action.payload,
        brandLoaded: true
      };
    }
    default:
      return state;
  }
}
