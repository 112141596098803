<div class="option-header">
  <span class="grid-right mat-panel-title">Price Range</span>
</div>
<div class="panel-section" [formGroup]="control" class="price-inputs border-bottom px-3 py-4">
  <div class="position-relative">
    <span class="prefix-input">$</span>
    <input
      (blur)="formatCurrency($event)"
      (focus)="hideFormatting($event)"
      [formControlName]="'minPrice'"
      [maxLength]="11"
      autocomplete="off"
      class="price input"
      placeholder="0"
    />
  </div>
  <div class="position-relative">
    <span class="prefix-input">$</span>
    <input
      (blur)="formatCurrency($event)"
      (focus)="hideFormatting($event)"
      [formControlName]="'maxPrice'"
      [maxLength]="11"
      autocomplete="off"
      class="price input"
      placeholder="Any Price"
    />
  </div>
</div>
