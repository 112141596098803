export enum ChartTypes {
  base = 'base',
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile',
  wide = 'wide',
  tall = 'tall',
  square = 'square',
  HS_FB = 'HS_FB',
  HS_Web = 'HS_Web',
  previewSmall = 'previewSmall',
  previewLarge = 'previewLarge',
  reportPage = 'reportPage',
  reportPageSm = 'reportPageSm',
  exportPreview = 'exportPreview',
  aspTrends = 'aspTrends'
}
