import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-export-preview-cover',
  templateUrl: './export-preview-cover.component.html',
  styleUrls: ['./export-preview-cover.component.scss']
})
export class ExportPreviewCoverComponent {
  @HostBinding('class') classes = 'full-size';
  @Input() showAgentInfo = true;
  constructor() {}
}
