<div class="filter-contain v-scroll-auto">
  <ng-content select="[leftSideBar]"></ng-content>
</div>

<div class="reports-container d-flex flex-column v-scroll-auto">
  <ng-content select="[mainContent]"></ng-content>
  <ng-content select="[footer]"></ng-content>
</div>

<ng-content></ng-content>
