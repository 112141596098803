import { AfterViewInit, Component, Inject, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IEmailData } from '../../../models/IEmailData';
import { IPageTypes } from '../../../models/IPageTypes';
import { EmailService } from '../../../services/email.service';
import { ModalService } from '../../../services/modal.service';
import { ExportClaimsGtm } from '../../../store/actions/gtm.action';
import { IAppState } from '../../../store/state/app.state';
import { EmailAddressComponent } from '../../email-address/email-address.component';

@Component({
  selector: 'app-mail-modal',
  templateUrl: './mail-modal.component.html',
  styleUrls: ['./mail-modal.component.scss']
})
export class MailModalComponent implements AfterViewInit, OnDestroy {
  public addSecond = false;
  public addThird = false;
  public notAllEmailsAdded = true;
  public emailData: IEmailData;
  public emailAddresses: EmailAddressComponent[];
  @ViewChildren(EmailAddressComponent) emailChildren!: QueryList<EmailAddressComponent>;
  subs = new Subscription();
  private baseurl: string;

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private dialogRef: MatDialogRef<MailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEmailData,
    public _emailService: EmailService,
    private _snackBar: MatSnackBar,
    private store: Store<IAppState>,
    private modalService: ModalService
  ) {
    this.baseurl = baseUrl;
    this.emailData = data;
  }

  public closeMe() {
    this.dialogRef.close('cancel');
  }

  email(type: IPageTypes) {
    if (this.checkEmailsValid()) {
      const post = this.generateEmailList();
      this.subs.add(
        this._emailService.sendEmail(post, type).subscribe({
          next: () => {
            this.dialogRef.close('success');
            this.openSnackBar('Email Sent Successfully.');
          },
          error: () => {
            this.dialogRef.close('error');
            this.openSnackBar('Error Sending Email.');
          }
        })
      );
    }
  }

  checkEmailsValid(): boolean {
    return this.emailAddresses.every(x => x.emailFormControl.status !== 'INVALID');
  }

  addAnother(): void {
    if (this.checkEmailsValid()) {
      if (this.addSecond) {
        this.addThird = true;
        this.notAllEmailsAdded = false;
      } else {
        this.addSecond = true;
      }
    }
  }

  deleteFirst(): void {
    if (this.addSecond) {
      this.addSecond = false;
      const secondEmail = this.emailAddresses[1].emailFormControl.value;
      this.emailAddresses[0].emailFormControl.setValue(secondEmail);
      this.removeThirdAddress();
    }
  }

  deleteSecond(): void {
    this.emailAddresses[1].emailFormControl.setValue('');
    this.addSecond = false;
    if (this.addThird) {
      this.removeThirdAddress();
    }
  }

  deleteThird(): void {
    this.emailAddresses[2].emailFormControl.setValue('');
    this.addThird = false;
    this.notAllEmailsAdded = true;
  }

  ngAfterViewInit() {
    this.getEmailAddresses();
    this.emailChildren.changes.subscribe(() => {
      this.getEmailAddresses();
    });
  }

  getEmailAddresses() {
    setTimeout(() => {
      this.emailAddresses = this.emailChildren.toArray();
    }, 0);
  }

  public openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'right'
    });
  }

  closeDialog() {
    this.modalService.close();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private removeThirdAddress() {
    if (this.emailAddresses.length === 3) {
      const thirdEmail = this.emailAddresses[2].emailFormControl.value;
      this.emailAddresses[1].emailFormControl.setValue(thirdEmail);
      this.emailAddresses[2].emailFormControl.setValue('');
      this.addSecond = true;
      this.addThird = false;
      this.notAllEmailsAdded = true;
    }
  }

  private generateEmailList() {
    const toAddresses: string[] = ['', '', ''];
    let count = 0;
    this.emailAddresses.forEach(x => {
      toAddresses[count] = x.emailFormControl.value;
      count++;
    });
    return {
      emailData: {
        OktaId: this.emailData.oktaId,
        ReportId: this.emailData.reportid,
        FromName: this.emailData.senderName,
        FromAddress: this.emailData.senderEmail,
        ToAddresses: toAddresses
      }
    };
  }
}
