<app-trend-chart-description [chartShortHand]="TrendCardTypeEnumShortHand.overview"
                             [title]="title"
                             [typographySize]="typographySize"></app-trend-chart-description>
<div [formGroup]="form">
  <div *ngIf="showControls" class="table-control d-flex justify-content-end">
    <mat-button-toggle-group aria-label="Font Style" formControlName="formatToggle">
      <mat-button-toggle [value]="0">
        <mat-icon>arrow_upward</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle [value]="1">%</mat-button-toggle>
      <mat-button-toggle [value]="2">#</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <app-trend-table [typographySize]="typographySize"></app-trend-table>
</div>
