import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep, mapKeys, upperFirst } from 'lodash-es';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FiltersV2TimeFrameEnum } from '../../models/FilterData';
import { MarketFiltersDateRangeAlternateV2Enum, MarketFiltersV2Enum } from '../../models/IMarketReportFilters';
import { IReportData } from '../../models/IReportData';
import { ReportService } from '../../services/report.service';
import {
  DownloadExportFileCSV,
  DownloadExportFileExcel,
  FetchFilteredReportDataSuccess,
  FetchReportData,
  FetchReportDataSuccess,
  FetchReportsNoResults,
  ReportDataActionsActionTypes
} from '../actions/reports.action';
import { filteredData$ } from '../selectors/reports.selectors';
import { IAppState } from '../state/app.state';

@Injectable()
export class ReportDataEffect {
  fetchReportData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchReportData>(ReportDataActionsActionTypes.FetchReportData),
      switchMap(action => this.reportService.getReportsForUserByMls(action.payload)),
      map(reports => {
        reports.forEach(report => {
          const countyRegEx = new RegExp(MarketFiltersV2Enum.county, 'ig');
          const appendedCounty = this.reportService.getCountyString(report?.areaLocalName);
          report.areaLocalName = report?.areaLocalName?.replace(countyRegEx, appendedCounty);
          const ytdRegEx = new RegExp(MarketFiltersDateRangeAlternateV2Enum['Year To Date'], 'ig');
          report.timeFrame = report?.timeFrame?.replace(ytdRegEx, FiltersV2TimeFrameEnum.ytd);
        });
        return reports;
      }),
      switchMap(reports => {
        if (reports[0].msUnoReportID === -4) {
          return [new FetchReportDataSuccess(reports)];
        }
        if (parseInt(reports[0].areaLocalID, 10) < 0) {
          return [new FetchReportsNoResults()];
        }
        return [new FetchReportDataSuccess(reports)];
      })
    )
  );

  fetchFilteredReportData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchReportData>(ReportDataActionsActionTypes.FetchFilteredReportData),
      switchMap(() => {
        const rep = this.reportService.reports$;
        const filterData = this.reportService.filters$;
        const filteredReps = this.reportService.generateFilteredReportSet(rep, filterData);
        const pagedResults = this.reportService.selectReportsByPage(filterData.perPage, filterData.paginationCurrPage, filteredReps);
        return [new FetchFilteredReportDataSuccess([filteredReps, pagedResults])];
      })
    )
  );

  downloadExternalFileCsv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DownloadExportFileCSV>(ReportDataActionsActionTypes.DownloadExportFileCSV),
        switchMap(() => {
          return this.reportService.generateJsonToCsv();
        }),
        map(resp => {
          const [data, mlsId] = resp;
          ReportService.generateCSVLink(data, mlsId);
        })
      ),
    { dispatch: false }
  );

  downloadExternalFileExcel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DownloadExportFileExcel>(ReportDataActionsActionTypes.DownloadExportFileExcel),
        withLatestFrom(this.store.select(filteredData$)),
        map(([action, data]) => {
          let formattedData: IReportData[] = cloneDeep(data);
          let selectedMls = formattedData[0].mlsid;
          let newData = formattedData.map(i => {
            Object.keys(i).map(reportKey => {
              if (['msUnoReportID', 'areaLocalID', 'propertyType', 'dbName'].includes(reportKey)) {
                delete i[reportKey];
              }
            });
            return mapKeys(i, function(value, key) {
              return upperFirst(key);
            });
          });
          ReportService.generateJsonToXlsx(newData, selectedMls);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store<IAppState>, private reportService: ReportService) {}
}
