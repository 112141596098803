export enum SavedSearchFields {
  msTypeName = 'Transaction Side',
  unitType = 'Sales Type',
  propType = 'Property Type',
  priceType = 'Price Range',
  timeFrame = 'Time Period',
  dateRange = 'Date',
  priceRange = 'Price Range',
  groupByLevel = 'View By',
  dateRangeMulti = 'Date',
  isReo = 'Real Estate Owned'
}
