import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  IAxisLabelRenderEventArgs,
  IPointRenderEventArgs,
  ISeriesRenderEventArgs,
  ITextRenderEventArgs,
  LegendSettingsModel,
  TooltipSettingsModel
} from '@syncfusion/ej2-angular-charts';
import { format, getYear } from 'date-fns';
import { ITrendsDataPoint } from '../models/IMarketTrendsGraphSet';
import { MarketTrendsChartConfiguration, TrendsChartStyles, TrendsChartStylesEnum } from '../models/MarketTrendsChartConfiguration';
import { MarketTrendsService } from './market-trends.service';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  constructor(private decimalPipe: DecimalPipe, private marketTrendsService: MarketTrendsService) {}

  monthIncluded(chartMonth: string, selectedMonth: string) {
    return chartMonth.includes(selectedMonth);
  }

  monthVars(month: string, currentMonth: string) {
    const chartMonth = month;
    const selectedMonth = currentMonth.substring(0, 3) ?? '';
    return { chartMonth, selectedMonth };
  }

  setUpLegend(legendArg: LegendSettingsModel, chart: MarketTrendsChartConfiguration) {
    return {
      ...legendArg,
      visible: chart?.chartTypes.length > 1
    };
  }

  setupTooltip(tooltipArg: TooltipSettingsModel) {
    const tooltip = {
      ...tooltipArg
    };
    if (tooltip['textStyle']) {
      tooltip['textStyle'].color = '#dcdcdd';
    } else {
      tooltip['textStyle'] = { color: '#dcdcdd' };
    }
    return tooltip;
  }

  checkAxisLabels($event: IAxisLabelRenderEventArgs, mobile: boolean, currentMonth: string, currentYear: number) {
    let newCurrentYear;
    if ($event.axis.name.toLowerCase().includes('yaxis')) {
      $event.text = this.formatYAxisNumLabels($event);
      newCurrentYear = 0;
    } else {
      const e = this.formatXAxisLabels($event, mobile, currentMonth, currentYear);
      $event.text = e.ev.text;
      $event.labelStyle = e.ev.labelStyle;
      newCurrentYear = e.currentYear;
    }
    return { $event, newCurrentYear };
  }

  formatYAxisNumLabels($event: IAxisLabelRenderEventArgs) {
    return this.decimalPipe.transform($event.value);
  }

  formatXAxisLabels($event: IAxisLabelRenderEventArgs, isMobile: boolean, currentMonth: string, currentYear: number) {
    const ev = $event;
    const dateArr = $event.text.split(' ');
    const month = dateArr[0].substring(0, 1);
    const year = dateArr[1];
    if (isMobile) {
      $event.text = this.setMobileAxisLabels($event.text, month, year, currentMonth);
    } else if (currentYear !== parseInt(year, 10)) {
      currentYear = this.setYear(year);
      $event.labelStyle.color = '#727476';
      $event.text = `${month}<br>${currentYear}`;
    } else {
      $event.text = isMobile ? '' : month;
    }
    return { ev, currentYear };
  }

  setYear(year: string) {
    return parseInt(year, 10);
  }

  private setMobileAxisLabels(str: string, month: string, year: string, currentMonth: string) {
    const { chartMonth, selectedMonth } = this.monthVars(str, currentMonth);
    if (this.monthIncluded(chartMonth, selectedMonth)) {
      return `${month}<br>${year}`;
    } else {
      return '';
    }
  }

  showSplineMarkers($event: IPointRenderEventArgs) {
    $event.point.marker.visible = true;
    $event.point.marker.height = 16;
    $event.point.marker.width = 16;
    $event.point.marker.border = {
      width: 3
    };
    return $event;
  }

  hideSplineMarkers($event: IPointRenderEventArgs) {
    $event.point.marker.height = 1;
    $event.point.marker.width = 1;
    $event.point.marker.visible = false;
    return $event;
  }

  pointRenderInit($event: IPointRenderEventArgs, currentMonth: string, isSir: boolean, primaryColor: string, isCustom = false) {
    if (!$event.point.x) {
      return;
    }
    const { chartMonth, selectedMonth } = this.monthVars($event.point.x as string, currentMonth);
    if (!this.monthIncluded(chartMonth, selectedMonth)) {
      $event = this.hideSplineMarkers($event);
    } else if ($event.series.type === TrendsChartStylesEnum.Spline) {
      $event = this.showSplineMarkers($event);
      $event.fill = '#fff';
    } else if (isSir) {
      return;
    } else if (!isCustom) {
      $event.fill = primaryColor;
    }
    return $event;
  }

  textRenderInit($event: ITextRenderEventArgs, currentMonth: string) {
    if (!!$event.point.x) {
      const { chartMonth, selectedMonth } = this.monthVars($event.point.x as string, currentMonth);
      if (this.monthIncluded(chartMonth, selectedMonth)) {
        $event.font.color = '#fff';
      }
      $event.text = this.decimalPipe.transform($event.text);
    }
    return $event;
  }

  seriesRenderInit(
    $event: ISeriesRenderEventArgs,
    showColumnMarkers: boolean,
    chartId: string,
    currentMonth: string,
    isSir: boolean,
    isCustom: boolean
  ) {
    if (showColumnMarkers && $event.series['properties'].type === 'Column' && !isCustom) {
      return this.generateColumnMarkers($event.data, chartId, currentMonth, isSir, isCustom);
    }
  }

  generateColumnMarkers(chartData, chartId: string, currentMonth: string, isSir: boolean, isCustom: boolean): void {
    let markerMarkup;
    const barContainer = document.querySelector(`#${chartId}SeriesGroup0`);
    if (barContainer && chartData) {
      const bars = barContainer.querySelectorAll(`path`);
      const existingGroup = document.querySelector(`#${chartId}-column-markers`);
      if (existingGroup) {
        existingGroup.remove();
      }
      markerMarkup = `<g id='${chartId}-column-markers'>`;
      (chartData || []).forEach((data, index) => {
        const bar = bars[index];
        if (!bar) {
          return;
        }
        if (data.yData && data.yData > 0) {
          let color = 'var(--colorPrimary)';
          const { chartMonth, selectedMonth } = this.monthVars(data.xData as string, currentMonth);
          if (chartMonth.includes(selectedMonth) && !isSir && !isCustom) {
            color = 'var(--font-white)';
          }
          const value = this.decimalPipe.transform(data.yData);
          const offset = Math.floor(9 + (bar.getBoundingClientRect().width - 9) / 2);
          markerMarkup += `
          <text class="column-marker" style="
          font-size: 11px;
          fill: ${color};
          transform: translate(-${offset}px, -8px);
          "><textPath xlink:href="#${bar.getAttribute('id')}" startOffset="30px">${value}</textPath></text>
          `;
        }
      });
      barContainer.innerHTML += markerMarkup + '</g>';
    }
  }

  fetchChartConfig(size: TrendsChartStyles[], customChart = false) {
    return this.marketTrendsService.getTrendsConfig(size, customChart);
  }

  formatDates(value: ITrendsDataPoint[]) {
    let year = 0;
    return value.map(v => {
      const d = new Date(v.xData);
      const month = format(d, 'MMMM').substring(0, 3);
      year = getYear(d);
      v.xData = `${month} ${year}`;
      return v;
    });
  }
}
