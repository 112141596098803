import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { FilterService } from '../../services/filter.service';
import { MarketSnapshotService } from '../../services/market-snapshot.service';
import {
  FetchSnapshotData,
  FetchSnapshotDataError,
  FetchSnapshotDataSuccess,
  MarketSnapshotActionsTypes,
  SetUserSnapshotDefaultLocation
} from '../actions/market-snapshot.action';

@Injectable()
export class MarketSnapshotEffect {
  fetchSnapshotData$ = createEffect(() =>
    this.actions.pipe(
      ofType<FetchSnapshotData>(MarketSnapshotActionsTypes.FetchSnapshotData),
      switchMap(action => this.snapShotService.generateSnapshotPostData(action.payload)),
      switchMap(usrData => {
        return this.snapShotService.fetchMarketSnapshot(usrData.postData, usrData.mls.mlsId).pipe(
          switchMap(dataObj => {
            return [new FetchSnapshotDataSuccess(dataObj)];
          }),
          catchError(() => of(new FetchSnapshotDataError()))
        );
      })
    )
  );
  setUserSnapshotMls$ = createEffect(
    () =>
      this.actions.pipe(
        ofType<SetUserSnapshotDefaultLocation>(MarketSnapshotActionsTypes.UpdateUserSnapshotDefaultLocationToApi),
        switchMap(action => {
          return this.filterService.updateUserFilterDefaults([action.payload]);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions: Actions, private snapShotService: MarketSnapshotService, private filterService: FilterService) {}
}
