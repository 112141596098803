import { WootricActions, WootricActionsActionTypes } from '../actions/wootric.action';
import { getInitialWootricState, IWootricState } from '../state/wootric.state';

export function WootricReducer(
  state = getInitialWootricState(),
  action: WootricActions
): IWootricState {
  switch (action.type) {
    case WootricActionsActionTypes.FetchWootric: {
      return {
        ...state,
      };
    }
    case WootricActionsActionTypes.FetchWootricSuccess: {
      return {
        ...state,
        info: action.payload,
        loaded: true
      };
    }
    case WootricActionsActionTypes.LogoutClearWootricState: {
      return getInitialWootricState();
    }
    default:
      return state;
  }
}
