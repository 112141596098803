import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { FilterService } from '../../services/filter.service';
import { MarketPositionService } from '../../services/market-position.service';
import {
  FetchLocations,
  FetchLocationsSuccess,
  FetchMarketLocations,
  FetchMarketLocationsSuccess,
  LocationsActionsActionTypes
} from '../actions/locations.action';

@Injectable()
export class LocationsEffect {
  fetchLocations$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchLocations>(LocationsActionsActionTypes.FetchLocations),
      switchMap((action: FetchLocations) => this.filterService.getLocations(action.payload)),
      map(locations => new FetchLocationsSuccess(locations))
    )
  );

  fetchMarketLocationData$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchMarketLocations>(LocationsActionsActionTypes.FetchMarketLocations),
      switchMap(action => {
        return this.marketService.getMarketLocations(action.payload);
      }),
      map(reports => new FetchMarketLocationsSuccess(reports))
    )
  );

  constructor(private _actions$: Actions, private filterService: FilterService, private marketService: MarketPositionService) {}
}
