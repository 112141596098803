import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { IChips } from '../../../../../models/IChips';
import { ReportTypeEnum } from '../../../../../models/ReportTypes';

@Component({
  selector: 'app-chips-grouped',
  templateUrl: './chips-grouped.component.html',
  styleUrls: ['./chips-grouped.component.scss']
})
export class ChipsGroupedComponent implements OnInit, OnDestroy {
  @Input() data: IChips[];
  isDesktop: boolean;
  subs = new Subscription();
  @ViewChild('chips') chips: MatSelect;
  @Input() reportType: ReportTypeEnum; // 0 for #1 claims, 1 for market position, 2 for Trends
  @Output() chipRemoved: EventEmitter<IChips> = new EventEmitter<IChips>();
  reportTypes = ReportTypeEnum;

  constructor(private breakpointObserver: BreakpointObserver, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.subs.add(
      this.breakpointObserver.observe('(min-width: 960px)').subscribe(result => {
        this.isDesktop = result.matches;
      })
    );
  }

  removeChip(chip: IChips) {
    this.chipRemoved.emit(chip);
  }

  openSnackBarToolTip(hasData: boolean) {
    if (!this.isDesktop && !hasData) {
      this.snackBar.open('This location does not have #1 claims.', '', {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
