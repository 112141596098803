<form class="email-address-form">
  <mat-form-field appearance="outline" class="email-address-full-width">
    <mat-label>Recipient</mat-label>
    <input matInput [formControl]="emailFormControl"
           [errorStateMatcher]="matcher" >
    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="emailFormControl.hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <a (click)="delete(true)" *ngIf="showTrash" class="trash-anchor">
    <mat-icon>delete_outline</mat-icon>
  </a>
</form>
