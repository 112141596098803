import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnDestroy {
  @Input() iconName: string;
  @Input() fallbackLabel: string;
  @Input() outlined = false;
  isMobile: any;
  subs = new Subscription();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.subs.add(this.breakpointObserver.observe('(min-width: 600px)').pipe(
    ).subscribe(sizeMatch => {
      this.isMobile = !sizeMatch.matches;
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
