import { ILocationsData } from './ILocationsData';

export class FilterData {
  state: FilterState;
  paginationCurrPage?: number;
  perPage: number;
  sortAsc: boolean;

  constructor(
    city: string[] = [],
    county: string[] = [],
    zip: string[] = [],
    locations: ILocationsData[] = [],
    mlsid: string[] = [],
    timeFrame: string[] = [],
    groupByLevel: string[] = [],
    msTypeName: string[] = [],
    unitType: string[] = [],
    priceType: string[] = [],
    perPage: number = 12,
    sortAsc: boolean = true,
    paginationCurrPage: number = 1
  ) {
    this.state = {
      city: city,
      zip: zip,
      county: county,
      mlsid: mlsid,
      timeFrame: timeFrame,
      groupByLevel: groupByLevel,
      priceType: priceType,
      msTypeName: msTypeName,
      unitType: unitType
    };

    this.state.unitType = unitType;
    this.state.msTypeName = msTypeName;
    this.state.locations = locations;
    this.state.mlsid = mlsid;
    this.state.timeFrame = timeFrame;
    this.state.groupByLevel = groupByLevel;
    this.state.priceType = priceType;
    this.paginationCurrPage = paginationCurrPage;
    this.perPage = perPage;
    this.sortAsc = sortAsc;
  }
}

export interface FilterState {
  city?: string[];
  county?: string[];
  zip?: string[];
  locations?: ILocationsData[];
  mlsid?: string[];
  timeFrame?: string[];
  groupByLevel?: string[];
  priceType?: string[];
  msTypeName?: string[];
  unitType?: string[];
}

export enum FiltersV2Enum {
  locations = 'locations',
  mlsid = 'mlsid',
  timeFrame = 'timeFrame',
  groupByLevel = 'groupByLevel',
  priceType = 'priceType',
  msTypeName = 'msTypeName',
  unitType = 'unitType',
  city = 'city',
  county = 'county',
  zip = 'zip',
  propertyType = 'propertyType',
  dateRange = 'dateRange',
  priceRange = 'priceRange',
  mlsArea = 'mlsArea',
  schoolDistrict = 'schoolDistrict',
  subDivision = 'subDivision'
}

export enum FiltersV2GroupByLevelEnum {
  brand = 'Brand',
  brokerage = 'Brokerage',
  office = 'Office'
}

export enum FiltersV2TimeFrameEnum {
  lastMonth = 'Last Month',
  lastQuarter = 'Last Quarter',
  lastYear = 'Last Year',
  ytd = 'Year To Date'
}

export enum FiltersV2TransTypeNameEnum {
  total = 'Total',
  list = 'List',
  buy = 'Buy'
}

export enum FiltersV2UnitTypeNameEnum {
  units = 'Units',
  volume = 'Volume'
}

export enum DefaultV2FiltersEnum {
  'city' = 1,
  'county' = 2,
  'zip' = 3,
  'timeFrame' = 4, // timeFrame
  'priceType' = 5, // priceType : boolean
  'groupByLevel' = 6, // groupByLevel
  'msTypeName' = 7, // msTypeName includes 'total', 'list', 'buy'
  'unitType' = 8, // changing to unit or volume
  'locations' = 9, // array of strings
  'mlsid' = 10,
  'mlsArea' = 11,
  'schoolDistrict' = 12,
  'subDivision' = 13,
  'priceMin' = 14,
  'priceMax' = 15,
  'dateRange' = 16,
  'propertyType' = 17,
  'mpGroupByLevel' = 18,
  'mpMsTypeName' = 19,
  'mpUnitType' = 20
}

export enum DefaultClaimsFiltersEnum {
  'city' = 1,
  'county' = 2,
  'zip' = 3,
  'timeFrame' = 4,
  'priceType' = 5,
  'groupByLevel' = 6,
  'msTypeName' = 7,
  'unitType' = 8,
  'mlsid' = 10
}

export enum DefaultMPFiltersEnum {
  'city' = 1,
  'county' = 2,
  'zip' = 3,
  'mlsid' = 10,
  'mlsArea' = 11,
  'schoolDistrict' = 12,
  'subDivision' = 13,
  'priceMin' = 14,
  'priceMax' = 15,
  'dateRange' = 16,
  'propertyType' = 17,
  'mpGroupByLevel' = 18,
  'mpMsTypeName' = 19,
  'mpUnitType' = 20
}

export const DefaultV2FiltersConst = ['city', 'county', 'zip', 'timeFrame', 'priceType', 'groupByLevel', 'msTypeName', 'unitType', 'mlsid'];

export const DefaultMPFiltersConst = [
  'city',
  'county',
  'zip',
  'mlsArea',
  'schoolDistrict',
  'subDivision',
  'propertyType',
  'priceMin',
  'priceMax',
  'dateRange',
  'groupByLevel',
  'msTypeName',
  'unitType',
  'mlsid'
];

export const locationTypes: string[] = [FiltersV2Enum.county, FiltersV2Enum.city, FiltersV2Enum.zip];

export const mpLocationTypes: string[] = [
  FiltersV2Enum.county,
  FiltersV2Enum.city,
  FiltersV2Enum.zip,
  FiltersV2Enum.mlsArea,
  FiltersV2Enum.schoolDistrict,
  FiltersV2Enum.subDivision
];

export const mpReportTypes: string[] = ['mpGroupByLevel', 'mpMsTypeName', 'mpUnitType'];

export enum LocationEnum {
  'city' = 1,
  'county' = 2,
  'zip' = 3,
  'mlsArea' = 4,
  'schoolDistrict' = 5,
  'subDivision' = 6
}

export enum LocationDisplayEnum {
  'city' = 'City',
  'county' = 'County',
  'zip' = 'Zip',
  'mlsArea' = 'MLS Area',
  'schoolDistrict' = 'School District',
  'subDivision' = 'Subdivision'
}

export enum LocationDefaultEnum {
  'city' = 1,
  'county' = 2,
  'zip' = 3,
  'mlsArea' = 11,
  'schoolDistrict' = 12,
  'subDivision' = 13
}

export enum NumbersEnum {
  'priceMin' = 14,
  'priceMax' = 15
}

export interface LocationSubPanel {
  city: boolean;
  county: boolean;
  zip: boolean;
}
