<div *ngIf="data$ | async as data" [ngClass]="typographySize" class="table-container">
  <div class="table-header">
    <div class="table-header-content">Monthly Trends</div>
  </div>
  <div class="divTable trends_table">
    <div class="divTableHeading">
      <div class="divTableRow">
        <div *ngFor="let header of data.headers" [matTooltipPosition]="'above'" [matTooltip]="header.tooltip" class="divTableHead">
          {{ header.name }}
        </div>
      </div>
    </div>
    <div class="divTableBody">
      <div *ngFor="let row of data.formattedData" class="divTableRow">
        <div class="divTableCell">
          <span [outerHTML]="row && row[DataColumns.datatype]"></span>
        </div>
        <div class="divTableCell">
          <span [outerHTML]="row && row[DataColumns.ytd] | trendDataFormat: row.formattype"></span>
        </div>
        <div class="divTableCell">
          <span [outerHTML]="row && row[DataColumns.cm] | trendDataFormat: row.formattype"></span>
        </div>
        <div class="divTableCell">
          <app-dynamic-cell [dataPoint]="row" [type]="tableDataType$ | async" [value]="row[DataColumns.lm]"></app-dynamic-cell>
        </div>
        <div class="divTableCell">
          <app-dynamic-cell [dataPoint]="row" [type]="tableDataType$ | async" [value]="row[DataColumns.l3m]"></app-dynamic-cell>
        </div>
        <div class="divTableCell">
          <app-dynamic-cell [dataPoint]="row" [type]="tableDataType$ | async" [value]="row[DataColumns.cmly]"></app-dynamic-cell>
        </div>
      </div>
    </div>
  </div>
</div>
