import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { pdfExportData } from '../../../../../../store/selectors/pdf.selector';
import { IAppState } from '../../../../../../store/state/app.state';

@Component({
  selector: 'app-cover-title',
  templateUrl: './cover-title.component.html',
  styleUrls: ['./cover-title.component.scss']
})
export class CoverTitleComponent {
  previewData: Observable<{ displayEndDate: string; title: string }>;

  constructor(private store: Store<IAppState>) {
    this.previewData = this.store.select(pdfExportData).pipe(
      map(data => {
        const d = data && data.displayDate ? new Date(data.displayDate) : new Date();
        const displayEndDate = d.toLocaleDateString('us', {
          month: 'long',
          year: 'numeric'
        });
        const title = data.reportTitle ?? '';
        return { displayEndDate, title };
      })
    );
  }
}
