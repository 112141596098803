<mat-chip class="chips-grouped" *ngIf="data?.length > 0">
  + {{ data?.length }} more
  <mat-select disableRipple panelClass="chip-select">
    <mat-option
      *ngFor="let chip of data"
      [ngClass]="!chip.hasData && reportType === reportTypes.Uno ? 'noData' : ''"
      [matTooltip]="chip | ChipTooltip: reportType"
      [matTooltipDisabled]="chip.hasData || reportType !== reportTypes.Uno"
      matTooltipPosition="above"
      (click)="openSnackBarToolTip(chip.hasData || reportType !== reportTypes.Uno)"
    >
      <span *ngIf="chip.type !== 'county'">{{ chip.name }}</span>
      <span *ngIf="chip.type === 'county'">{{ chip.name | CountyString }}</span>
      <mat-icon (click)="removeChip(chip)" [ngClass]="!chip.hasData && reportType === reportTypes.Uno ? 'noData' : ''" matPrefix
        >cancel
      </mat-icon>
    </mat-option>
  </mat-select>
</mat-chip>
