export const environment = {
  production: false,
  environment: 'stage',
  oktaApp: {
    sothebysrealty: {
      redirectUri: 'https://stg-marketquest.sothebysrealty.com/implicit/callback',
      idp: '0oad5wjjckkDyt3TU0h7'
    },
    realogy: {
      redirectUri: 'https://stg-marketquest.realogy.com/implicit/callback',
      idp: '0oadw5tql3G3KQmOS0h7'
    },
    clientId: '0oalhtdxycQ7SNMGe0h7',
    issuer: 'https://realogy.oktapreview.com/oauth2/ausdtpyw647fbrcPi0h7',
    scopes: 'https://mq-p3-api.nrtwebdev.com/api/',
    cookies: {
      secure: true
    },
    pkce: false
  },
  imagePath: 'https://abtmqsastgapps.blob.core.windows.net/',
  apiBaseUrl: ''
};
