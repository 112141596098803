import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAgentReport } from '../../../../../models/IAgentReport';
import { ChartTypes } from '../../../../../models/IChartTypes';
import { ImageTypes } from '../../../../../models/ImageTypes';
import { IReportDetail } from '../../../../../models/IReportDetail';
import { IReportHeader } from '../../../../../models/IReportHeader';
import { EncryptedReportData } from '../../../../../models/IReportPageData';
import { ITopGroupTypeData } from '../../../../../models/ITopGroupTypeData';
import { DisclaimerService } from '../../../../../services/disclaimer.service';
import { OptionsService } from '../../../../../services/options.service';
import { ReportDetailService } from '../../../../../services/report-details.service';
import { SyncFusionConfigService } from '../../../../../services/sync-fusion-config.service';
import { pdfPreviewOrientation, pdfShowAgentInfo, pdfShowHideFirmName, pdfShowLogo } from '../../../../../store/selectors/pdf.selector';
import { selectReportPageData } from '../../../../../store/selectors/reports.selectors';
import { IAppState } from '../../../../../store/state/app.state';

@Component({
  selector: 'app-cbr-claims-export-preview',
  templateUrl: './c-b-r-claims-export-preview.component.html',
  styleUrls: ['./c-b-r-claims-export-preview.component.scss']
})
export class CBRClaimsExportPreviewComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() showLogo = true;
  @Input() hideChartNames = true;
  subs = new Subscription();
  reportChartSize = ChartTypes;
  pageData$: Observable<{
    agent: IAgentReport;
    data: IReportDetail | IReportHeader;
    imageUrl: string;
    disclaimer: string;
  }>;
  islandscape: boolean;
  show = true;
  graph: EncryptedReportData;
  pdfOrientation$: Observable<boolean>;
  pdfShowName$: Observable<boolean>;
  pdfShowLogo$: Observable<boolean>;
  pdfShowAgentInfo$: Observable<boolean>;
  realogyPositionIndex: number;
  reportHeaderEntity: IReportHeader;
  backgroundImage: string;

  constructor(
    private store: Store<IAppState>,
    private opts: OptionsService,
    private disclaimerService: DisclaimerService,
    private optionSvc: OptionsService,
    private reportDetailService: ReportDetailService
  ) {}

  resetOrientation() {
    this.show = false;

    setTimeout(() => {
      this.show = true;
    }, 1);
  }

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.pdfOrientation$ = this.store.select(pdfPreviewOrientation);
    this.pdfShowName$ = this.store.select(pdfShowHideFirmName);
    this.pdfShowAgentInfo$ = this.store.select(pdfShowAgentInfo);
    this.pdfShowLogo$ = this.store.select(pdfShowLogo);

    this.subs.add(
      this.pdfOrientation$.subscribe(d => {
        if (this.islandscape !== undefined && this.islandscape !== d) {
          this.resetOrientation();
        }
        this.islandscape = d;
      })
    );

    this.pageData$ = combineLatest([this.store.select(selectReportPageData), this.pdfShowName$, this.pdfShowAgentInfo$]).pipe(
      map(d => {
        let disclaimer;
        const [claims, showFirmName] = d;

        this.realogyPositionIndex = null;
        const data = claims?.reportDetail;
        this.graph = cloneDeep(claims);
        if (!showFirmName) {
          const position = 1;
          this.graph.reportDetail.reportGraphEntities = this.reportDetailService.remapGraphEntryNames(
            this.graph.reportDetail,
            position,
            true
          );
        }
        const agent = claims?.reportDetail?.agentReportEntity;
        const path = this.opts.getImagePathForReport();
        const logo = (claims?.reportDetail?.imagePromoEntities || []).find(image => {
          return image.imageSize === ImageTypes['websiteDba'];
        });
        const imageUrl = path + logo.imageURL;
        disclaimer = this.disclaimerService.formatCBRDisclaimer(
          (data as IReportDetail).reportHeaderEntity,
          (data as IReportDetail).agentReportEntity,
          false
        );

        this.reportHeaderEntity = data['reportHeaderEntity'];
        const bgImage = ((data && data['imagePromoEntities']) || []).find(imageEntity => {
          return imageEntity.imageSize === 'Background';
        }) as any;

        this.backgroundImage = this.optionSvc.getImagePathForReport() + bgImage.imageURL;
        return { data, agent, imageUrl, disclaimer };
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
