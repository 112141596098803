import { FilterData } from '../../models/FilterData';

export interface IFilterState {
  defaultsLoaded: boolean;
  pending: boolean;
  filterState: FilterData;
}

export const initialFiltersState: IFilterState = {
  defaultsLoaded: false,
  pending: false,
  filterState: new FilterData()
};

export function getInitialFilterState(): IFilterState {
  return initialFiltersState;
}
