import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MqOktaAuthService } from '../../../services/mq-okta-auth.service';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent {
  constructor(private mqAuth: MqOktaAuthService, private ngZone: NgZone, public dialogRef: MatDialogRef<LogoutModalComponent>) {}

  signOut() {
    this.dialogRef.close();
    this.mqAuth.logout().then(r => r);
  }

  close() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }
}
