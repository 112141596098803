import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AnimationModel,
  AxisModel,
  ChartAreaModel,
  CornerRadiusModel,
  FontModel,
  IAxisLabelRenderEventArgs,
  IPointRenderEventArgs,
  ITextRenderEventArgs,
  MarginModel,
  MarkerSettingsModel,
  SubtitleModel
} from '@syncfusion/ej2-angular-charts';
import { cloneDeep } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ChartTypes } from '../../../../models/IChartTypes';
import { IReportGraph } from '../../../../models/IReportGraph';
import { EncryptedReportData } from '../../../../models/IReportPageData';
import { OptionsService } from '../../../../services/options.service';
import { ReportDetailService } from '../../../../services/report-details.service';
import { ReportService } from '../../../../services/report.service';
import { SyncFusionConfigService } from '../../../../services/sync-fusion-config.service';
import { ThemeService } from '../../../../services/theme.service';
import { selectMarketShowAsPercent } from '../../../../store/selectors/market-position.selector';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-market-quest-chart',
  templateUrl: './market-quest-chart.component.html',
  styleUrls: ['./market-quest-chart.component.scss']
})
export class MarketQuestChartComponent implements OnInit, OnDestroy, OnChanges {
  @Input() hideFirmNames = false;
  @Input() hideTitle = false;
  @Input() downloadableImageType: ChartTypes;
  @Output() chartReady = new EventEmitter<boolean>();
  @Input() chartSourceData: EncryptedReportData;
  @Input() overridePrimaryColor: string;
  @Input() previewChart = false;
  public title: string;
  public primaryYAxis: AxisModel;
  public primaryXAxis: AxisModel;
  public animation: AnimationModel;
  public chartData: IReportGraph[];
  public titleStyle: FontModel;
  public subTitleStyle: SubtitleModel;
  public chartArea: ChartAreaModel;
  public marker: MarkerSettingsModel;
  public margin: MarginModel;
  public selectChartLoading$: Observable<boolean>;
  public showChart = true;
  public transposed: boolean;
  public randomIdStr: string;
  public sourceType: string;
  public subTitle: string;
  public cornerRadius: CornerRadiusModel;
  public data: EncryptedReportData;
  private chartSizeType: ChartTypes;
  private showAsPercentage = false;
  private isConsolidatedMLS = false;
  subs = new Subscription();

  constructor(
    private store: Store<IAppState>,
    private reportService: ReportService,
    private optionsService: OptionsService,
    private syncFusionConfigService: SyncFusionConfigService,
    private breakpointObserver: BreakpointObserver,
    private cssService: ThemeService,
    private reportDetailService: ReportDetailService
  ) {
    this.randomIdStr = MarketQuestChartComponent.randomId();
    this.subs.add(
      this.store
        .select(selectMarketShowAsPercent)
        .pipe(filter(resp => resp !== undefined))
        .subscribe(bool => {
          if (this.showAsPercentage !== bool) {
            this.reloadChartFromNewData();
          }
          this.showAsPercentage = bool;
        })
    );
  }

  private static detectSize(sizeMatch: BreakpointState) {
    if (sizeMatch.breakpoints['(min-width: 600px)']) {
      return sizeMatch.breakpoints['(min-width: 960px)'] ? ChartTypes.desktop : ChartTypes.tablet;
    } else {
      return ChartTypes.mobile;
    }
  }

  private static fetchChartConfig(size: ChartTypes, target: MarketQuestChartComponent) {
    return Object.assign(target, target.syncFusionConfigService.getConfig(size));
  }

  private static getMaxY(graphData: IReportGraph[], showPercent: boolean) {
    if (showPercent) {
      return graphData.reduce(
        (max, p) => (p.marketSharePercentage > max ? p.marketSharePercentage : max),
        graphData[0].marketSharePercentage
      );
    } else {
      return graphData.reduce((max, p) => (p.reportMeasureValue > max ? p.reportMeasureValue : max), graphData[0].reportMeasureValue);
    }
  }

  private static randomId() {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substring(2, 10);
  }

  private static getMaxScaleByDeviceSize(maxVal: number, smallChat: boolean, medChart: boolean) {
    return maxVal * (smallChat ? 1.5 : medChart ? 1.4 : 1.1);
  }

  private static convertReportGraphNamesToSmall(chartData: IReportGraph[]) {
    return chartData.map((val, idx) => {
      val.levelEntityName = `${idx + 1}`;
      return val;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.chartSourceData?.firstChange || !changes.downloadableImageType?.firstChange) {
      this.showChart = false;
      this.chartInit();
      setTimeout(() => {
        this.initializeAndShowChart();
        this.showChart = true;
      }, 1);
    }
  }

  ngOnInit() {
    this.chartInit();
    this.subs.add(
      this.breakpointObserver
        .observe(['(min-width: 600px)', '(min-width: 960px)'])
        .pipe(distinctUntilChanged())
        .subscribe(sizeMatches => {
          if (this.chartSizeType !== MarketQuestChartComponent.detectSize(sizeMatches)) {
            this.reloadChartFromNewData();
          }
          this.chartSizeType = this.downloadableImageType || MarketQuestChartComponent.detectSize(sizeMatches);
          this.transposed = [ChartTypes.mobile, ChartTypes.tall].includes(this.chartSizeType);
          if (this.data?.reportDetail) {
            MarketQuestChartComponent.fetchChartConfig(this.chartSizeType, this);
          }
        })
    );
  }

  pointRender($event: IPointRenderEventArgs) {
    const barColor = this.previewChart ? '#B7B9B9' : '#dcdcdd';
    const seriesColor: string[] = Array(5).fill(barColor);
    let cbBlue = this.cssService?.colorPrimary || '#1f69ff';
    if (!!this.overridePrimaryColor) {
      cbBlue = this.overridePrimaryColor;
    }
    if (this.data.cbposition <= 5) {
      const cbPosition = this.setCBPosition(this.data.cbposition);
      seriesColor.splice(cbPosition - 1, 1, cbBlue);
    }
    $event.fill = seriesColor[$event.point.index];
  }

  axisLabelRender($event: IAxisLabelRenderEventArgs) {
    $event.text = this.reportService.trimLabels($event.text, this.chartSizeType);
  }

  textRender($event: ITextRenderEventArgs) {
    const cbPosition = this.setCBPosition(this.data.cbposition);
    if ($event.point.index !== cbPosition - 1 && this.previewChart) {
      $event.font.color = '#8B8C8F';
    }
    $event.text = this.convertUnitsPercentDataPoint(this.convertToConsolidated($event, cbPosition));
  }

  chartLoaded() {
    this.chartReady.next(true);
    this.chartReady.complete();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private convertUnitsPercentDataPoint(val: string) {
    const isUnits = this.data.reportDetail.reportHeaderEntity.msTypeName.toLowerCase().includes('units');
    const showPercentage = this.showAsPercentage;
    if (!val) {
      return '';
    }
    if (showPercentage) {
      return val + '%';
    }
    if (!isUnits) {
      return this.optionsService.numFormatter(Number(val.replace(/,/g, '')), 2);
    }
    return val;
  }

  private convertToConsolidated($event: ITextRenderEventArgs, cbPosition: number) {
    if (this.isConsolidatedMLS) {
      return ($event.text = cbPosition === $event.point.index + 1 ? $event.text : '');
    }
    return $event.text;
  }

  private setCBPosition(cbPosition: number) {
    if (!this.transposed) {
      return cbPosition;
    }
    switch (cbPosition) {
      case 1:
        return 5;
      case 2:
        return 4;
      case 3:
        return 3;
      case 4:
        return 2;
      case 5:
        return 1;
      default:
        break;
    }
  }

  private initializeAndShowChart() {
    const lg = [ChartTypes.previewLarge, ChartTypes.mobile, ChartTypes.tall].includes(this.chartSizeType);
    const sm = [ChartTypes.previewSmall].includes(this.chartSizeType);
    const isReportPage = [ChartTypes.reportPage, ChartTypes.reportPageSm].includes(this.downloadableImageType);

    if (!this.hideTitle) {
      this.setChartHeaderTitles(isReportPage);
    }

    this.primaryYAxis.maximum = MarketQuestChartComponent.getMaxScaleByDeviceSize(
      MarketQuestChartComponent.getMaxY(this.data.reportDetail.reportGraphEntities, this.showAsPercentage),
      sm,
      lg
    );
    this.sourceType = this.showAsPercentage ? 'marketSharePercentage' : 'reportMeasureValue';
    this.chartData = this.reportService.adjustChartLayoutByData(this.data.reportDetail.reportGraphEntities, this.chartSizeType);
    if (this.downloadableImageType === ChartTypes.reportPageSm) {
      this.chartData = MarketQuestChartComponent.convertReportGraphNamesToSmall(this.chartData);
    }
  }

  private setChartHeaderTitles(useSubtitle: boolean) {
    const titleWithoutSubtitle =
      `Leading ${this.data.reportDetail.reportHeaderEntity.groupByLevel} by ` + this.data.reportDetail.reportHeaderEntity.msTypeName + ' ';
    const titleWithSubtitle = titleWithoutSubtitle + '(' + this.data.reportDetail.reportHeaderEntity.displayDateRange + ')';
    this.title = useSubtitle ? titleWithoutSubtitle : titleWithSubtitle;
    this.subTitle = useSubtitle ? this.data.reportDetail.reportHeaderEntity.displayDateRange : '';
  }

  private reloadChartFromNewData() {
    this.showChart = false;
    setTimeout(() => {
      this.initializeAndShowChart();
      this.showChart = true;
    }, 1);
  }

  private chartInit() {
    this.data = cloneDeep(this.chartSourceData);
    this.chartSizeType = this.downloadableImageType || null;
    this.isConsolidatedMLS = this.data.reportDetail.reportHeaderEntity.mlsId === 'COSC';
    this.data.reportDetail.reportGraphEntities = this.reportDetailService.remapGraphEntryNames(
      this.data.reportDetail,
      this.data.cbposition
    );
    MarketQuestChartComponent.fetchChartConfig(this.chartSizeType, this);
    this.initializeAndShowChart();
    this.animation = {
      enable: this.downloadableImageType === undefined
    };
  }
}
