export enum ImageTypes {
  background = 'Background',
  tall = 'Tall',
  square = 'Square',
  wide = 'Wide',
  HS_Web = 'HS_Web',
  HS_FB = 'HS_FB',
  email = 'Email',
  logo = 'Logo',
  emailLogo = 'EmailLogo',
  websiteDba = 'WebsiteDBA',
  pdfDba = 'PDFDBA',
  emailDba = 'EmailDBA',
  logoLight = 'LogoLight',
  logoDark = 'LogoDark'
}
