import { GalleryImage } from '../../models/GalleryImage';
import { IAgentReport } from '../../models/IAgentReport';
import { AreaLocalName } from '../../models/IMarketTrendsHeader';

export interface IPdfState {
  opts: IPdfOptions;
  hasError: boolean;
  errorMessage: string;
  pending: boolean;
  previewOpen: boolean;
  pdfPreviewData: IPdfPreviewData;
}

export const initialPdfState: IPdfState = {
  opts: {
    chartdatatype: 0,
    showcover: true,
    showaimsi: true,
    showamsp: true,
    showdom: true,
    shownbrsoldar: true,
    shownl: true,
    showsplp: true,
    showspsqft: true,
    showtable: true,
    islandscape: true,
    showBrandLogo: true,
    showMarketPositionTable: true,
    showFirmNames: true,
    showAgentInfo: true,
    showTrendLines: true
  },
  pdfPreviewData: null,
  errorMessage: null,
  hasError: false,
  pending: false,
  previewOpen: false
};

export function getInitialPdfState(): any {
  return initialPdfState;
}

export interface IPdfOptions {
  chartdatatype: number;
  showcover: boolean;
  showtable: boolean;
  showamsp: boolean;
  showsplp: boolean;
  shownbrsoldar: boolean;
  showdom: boolean;
  showspsqft: boolean;
  showaimsi: boolean;
  shownl: boolean;
  islandscape: boolean;
  showBrandLogo: boolean;
  showFirmNames: boolean;
  showAgentInfo: boolean;
  showMarketPositionTable: boolean;
  showTrendLines: boolean;
}

export interface IPdfPreviewData {
  displayDate: string;
  displayStartDate?: string;
  displayEndDate?: string;
  logo: GalleryImage;
  price: string;
  propertyType: string;
  areaLocalNames: AreaLocalName[];
  agentData: IAgentReport;
  legalName: string;
  reportTitle: string;
  displayReo: string;
}
