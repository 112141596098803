import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngrx/store';
import { OktaAuth } from '@okta/okta-auth-js';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap, timeout } from 'rxjs/operators';
import { LoginService } from '../services/login/login.service';
import { IAppState } from '../store/state/app.state';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<IAppState>,
    private oktaAuth: OktaAuth,
    private loginService: LoginService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);
    return this.isAuthenticated().pipe(
      mergeMap(isAuthenticated => {
        if (!isAuthenticated) {
          return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status === 401 || error.status === 403) {
                this.loginService.login();
              }
              return throwError(error);
            })
          );
        }
        const authReq = request.clone({
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.getAccessToken()}`
          })
        });
        return next.handle(authReq).pipe(timeout(timeoutValueNumeric));
      })
    );
  }

  private isAuthenticated(): Observable<boolean> {
    return from(this.oktaAuth.isAuthenticated());
  }

  private getAccessToken() {
    return this.oktaAuth.getAccessToken();
  }
}
