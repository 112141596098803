import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { FilterState, FiltersV2Enum, LocationEnum } from 'app/models/FilterData';
import { ILocationsData } from 'app/models/ILocationsData';
import { selectCountyCityZipFilter } from 'app/store/selectors/filters.selector';
import { selectLocations, selectReportLocations } from 'app/store/selectors/locations.selector';
import { IAppState } from 'app/store/state/app.state';
import { groupBy } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { MarketFilters } from '../../../../../models/IMarketReportFilters';
import { TrendsFilters } from '../../../../../models/IMarketTrendsFilters';
import { FilterService } from '../../../../../services/filter.service';
import { selectMarketCountyCityZipFilter } from '../../../../../store/selectors/market-position.selector';
import { selectTrendsCountyCityZipFilter } from '../../../../../store/selectors/market-trends.selector';

interface SortedLocation {
  type: string;
  value: ILocationsData[];
}

interface AreaTypes {
  zip?: string[];
  city?: string[];
  county?: string[];
  mlsArea?: string;
  schoolDistrict?: string[];
  subDivision?: string[];
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() placeholderText: string;
  @Input() searchType: 'Claims' | 'Market' | 'Trends' | 'Snapshot';
  @Output() claimsFilterValues = new EventEmitter<FilterState>();
  @Output() marketFilterValues = new EventEmitter<MarketFilters>();
  @Output() trendsFilterValues = new EventEmitter<TrendsFilters>();
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  public searchControl: UntypedFormControl;
  public sortedLocs: SortedLocation[] = [];
  private subs = new Subscription();
  private locationsObservable$: Observable<ILocationsData[]>;
  private activeFiltersObservable$: Observable<any>;
  private activeFilters: any;

  constructor(private store: Store<IAppState>, private filterService: FilterService) {
    this.searchControl = new UntypedFormControl([]);
  }

  private static sortLocationByTypes(origLocationArr: ILocationsData[], searchValue: string, activeFilters: AreaTypes): SortedLocation[] {
    const finalArray: SortedLocation[] = [];
    const sortedLocArr = groupBy(origLocationArr, function(l) {
      return l.areaType;
    });
    const locationTypesByOrder = [
      LocationEnum[FiltersV2Enum.mlsArea],
      LocationEnum[FiltersV2Enum.county],
      LocationEnum[FiltersV2Enum.city],
      LocationEnum[FiltersV2Enum.zip],
      LocationEnum[FiltersV2Enum.schoolDistrict],
      LocationEnum[FiltersV2Enum.subDivision]
    ];

    function sortValidAreaTypeByKey(
      areaType:
        | LocationEnum.mlsArea
        | LocationEnum.county
        | LocationEnum.city
        | LocationEnum.zip
        | LocationEnum.schoolDistrict
        | LocationEnum.subDivision
    ) {
      const i = sortedLocArr[areaType].filter(value => {
        return (
          value.areaName.toLowerCase().startsWith(searchValue.toLowerCase()) &&
          !activeFilters[LocationEnum[areaType]].includes(value.areaID)
        );
      });
      if (i.length > 0) {
        finalArray.push({
          type: LocationEnum[areaType].toUpperCase(),
          value: i
        });
      }
    }

    locationTypesByOrder.map(areaType => {
      if (sortedLocArr[areaType]) {
        sortValidAreaTypeByKey(areaType);
      }
    });
    return finalArray;
  }

  ngOnInit() {
    switch (this.searchType) {
      case 'Claims':
        this.locationsObservable$ = this.store.select(selectLocations);
        this.activeFiltersObservable$ = this.store.select(selectCountyCityZipFilter);
        break;
      case 'Market':
        this.locationsObservable$ = this.store.select(selectReportLocations);
        this.activeFiltersObservable$ = this.store.select(selectMarketCountyCityZipFilter);
        break;
      case 'Trends':
        this.locationsObservable$ = this.store.select(selectReportLocations);
        this.activeFiltersObservable$ = this.store.select(selectTrendsCountyCityZipFilter);
        break;
    }

    let locs: ILocationsData[] = [];

    this.subs.add(
      this.activeFiltersObservable$.subscribe(filtersActive => {
        this.activeFilters = filtersActive;
      })
    );

    this.subs.add(
      this.locationsObservable$.subscribe((locationsFromState: ILocationsData[]) => {
        if (locationsFromState.length > 0) {
          this.searchControl.enable();
        } else {
          this.searchControl.disable();
        }
        locs = locationsFromState;
      })
    );

    this.subs.add(
      this.searchControl.valueChanges.subscribe((val: string) => {
        this.sortedLocs = val.length > 2 ? SearchComponent.sortLocationByTypes(locs, val, this.activeFilters) : [];
      })
    );
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.filterService.autoCompleteOptionSelected(event, this, this.activeFilters, this.searchType);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
