import { Pipe, PipeTransform } from '@angular/core';
import { replace } from 'lodash-es';
import { FiltersV2Enum } from '../models/FilterData';
import { IReportData } from '../models/IReportData';
import { IReportHeader } from '../models/IReportHeader';

@Pipe({
  name: 'reportLocationName'
})
export class ReportLocationNamePipe implements PipeTransform {
  transform(value: IReportData | IReportHeader, trimCount: number = 0) {
    if (trimCount > 0 && !value?.areaType) {
      const areaLocalName = value?.areaLocalName?.split(';').join(',;') || '';
      const areaLocalNameCount = areaLocalName.split(',').length;
      const moreText =
        areaLocalNameCount - trimCount <= 0
          ? ''
          : ` +${areaLocalNameCount - trimCount} more location${areaLocalNameCount - trimCount <= 1 ? '' : 's'}`;
      return (
        areaLocalName
          .split(',')
          .slice(0, trimCount)
          .join(',') + moreText
      )
        .split(',;')
        .join(';');
    }

    if (value?.areaType?.toLowerCase() === FiltersV2Enum.zip) {
      return 'Zip Code ' + value.areaLocalName;
    }
    if (value?.areaType?.toLowerCase() === FiltersV2Enum.county) {
      return !value.areaLocalName?.toLowerCase().includes(FiltersV2Enum.county)
        ? replace(value.areaLocalName, ',', ' County,')
        : value.areaLocalName;
    }
    return value?.areaLocalName;
  }
}
