import { Action } from '@ngrx/store';
import { INavButton } from '../../models/INavButton';
import { Banner } from '../state/menu.state';

export enum MenusActionTypes {
  SetSideBarNav = '[Menu] Setting SideBar Nav Status Manually',
  ToggleSideBarNav = '[Menu] Toggle SideBar Status',
  PushWarningBanner = '[Banners] Add banner to State',
  ToggleWarningBanner = '[Banners] Toggle banner State',
  ToggleFilterPanel = '[Filter Panel] Toggle Filter State',
  FetchNavItems = '[Nav Items] Fetch Nav Items',
  FetchNavItemsSuccess = '[Nav Items] Fetch Nav Items Success'
}

export class FetchNavItems implements Action {
  readonly type = MenusActionTypes.FetchNavItems;

  constructor(public payload: string) {}
}

export class FetchNavItemsSuccess implements Action {
  readonly type = MenusActionTypes.FetchNavItemsSuccess;

  constructor(public payload: INavButton[]) {}
}

export class SetSideBarNav implements Action {
  readonly type = MenusActionTypes.SetSideBarNav;

  constructor(public payload: boolean) {}
}

export class ToggleFilterPanel implements Action {
  readonly type = MenusActionTypes.ToggleFilterPanel;

  constructor(public payload = null) {}
}

export class ToggleSideBarNav implements Action {
  readonly type = MenusActionTypes.ToggleSideBarNav;
}

export class PushWarningBanner implements Action {
  readonly type = MenusActionTypes.PushWarningBanner;

  constructor(public payload: Banner) {}
}

export class ToggleWarningBanner implements Action {
  readonly type = MenusActionTypes.ToggleWarningBanner;

  constructor(public payload: Banner) {}
}

export type MenusAction =
  | SetSideBarNav
  | ToggleSideBarNav
  | PushWarningBanner
  | ToggleWarningBanner
  | ToggleFilterPanel
  | FetchNavItems
  | FetchNavItemsSuccess;
