import { Action } from '@ngrx/store';
import { TenantCode } from '../../services/tenant.service';
import { IApplicationDataState } from '../state/applicationData.state';

export enum ApplicationDataActionsActionTypes {
  SetApplicationData = '[App Data] Setting Application State',
  SetBrandCode = '[App Data] Setting Brand Code',
  SetBrandCodeSuccess = '[App Data] Brand Code Changed',
}

export class SetApplicationData implements Action {
  readonly type = ApplicationDataActionsActionTypes.SetApplicationData;

  constructor(public payload: IApplicationDataState) {
  }
}

export class SetBrandCode implements Action {
  readonly type = ApplicationDataActionsActionTypes.SetBrandCode;

  constructor(public payload: TenantCode) {
  }
}

export class SetBrandCodeSuccess implements Action {
  readonly type = ApplicationDataActionsActionTypes.SetBrandCodeSuccess;

  constructor(public payload: TenantCode) {
  }
}

export type ApplicationDataActions = SetApplicationData | SetBrandCode | SetBrandCodeSuccess;
