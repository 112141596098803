import { Pipe, PipeTransform } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { MarketTrendsChartConfiguration } from '../models/MarketTrendsChartConfiguration';

@Pipe({
  name: 'pagedArrayFilter'
})
export class PagedArrayFilterPipe implements PipeTransform {

  transform(
    value: MarketTrendsChartConfiguration[][],
    args: {
      showTable: boolean;
      isLandscape: boolean;
    },
    isOverviewPage: boolean
  ): MarketTrendsChartConfiguration[][] {
    if (!value || !args) {
      return value;
    }
    const isLandscape = args.isLandscape;
    const showTable = args.showTable;

    if (isOverviewPage) {
      if (isLandscape) {
        return [];
      }
      const r = cloneDeep(value);
      r.length = 1;
      return r;
    } else if (isLandscape) {
      return value;
    } else if (showTable) {
      const r = cloneDeep(value);
      r.shift();
      return r;
    }
    return value;
  }
}
