import { Component, Input, OnInit } from '@angular/core';
import { IAgentReport } from '../../../../../../../models/IAgentReport';
import { Observable } from 'rxjs';
import { Avatar } from '../../../../../../../models/IApplicationData';
import { UserDataService } from '../../../../../../../services/userData.service';

@Component({
  selector: 'app-user-info-block',
  templateUrl: './user-info-block.component.html',
  styleUrls: ['./user-info-block.component.scss']
})
export class UserInfoBlockComponent {
  @Input() data: IAgentReport;
  avatar$: Observable<Avatar>;
  otherUrl: string;

  constructor(private userDataSvc: UserDataService) { }

  ngOnInit(): void {
    if (this.data?.contactType === 0) {
      this.avatar$ = this.userDataSvc.getUserAvatar(this.data.oktaId);
    } else {
      this.otherUrl = this.data?.photo.replace('original', 'main');
    }
  }
}
