<div
  class="claims-report-preview"
  [ngClass]="islandscape ? 'landscape' : 'portrait'"
  *ngIf="pageData$ | async as reportData"
  style="background: url({{ backgroundImage }}); background-size: cover;"
>
  <div class="body-container">
    <div class="header">
      <h1>{{ reportData.agent.brokerageName }} {{ 'is Ranked #1 in ' + (reportHeaderEntity | reportLocationName) || 'All Locations' }}</h1>
      <p>
        Leading {{ reportHeaderEntity.groupByLevel }} by {{ reportHeaderEntity.msTypeName }} ({{ reportHeaderEntity.displayDateRange }})
      </p>
    </div>
    <div *ngIf="graph" class="graph-chart" [class.full-height]="!(pdfShowAgentInfo$ | async)">
      <app-market-quest-chart
        *ngIf="show"
        [chartSourceData]="graph"
        [downloadableImageType]="reportChartSize.exportPreview"
        [hideFirmNames]="pdfShowName$ | async"
        [hideTitle]="true"
        id="element"
      ></app-market-quest-chart>
    </div>
    <p class="cta">Contact me today to sell your property or find the perfect home for your changing needs.</p>
    <div class="agent-info" *ngIf="pdfShowAgentInfo$ | async">
      <div class="agent-info-photo">
        <img [src]="reportData.agent.photo" alt="agent photo" class="photo" />
      </div>
      <div class="agent-info-content">
        <div>
          <p class="agent-info--name">
            {{ reportData.agent.name }}
          </p>
          <p *ngIf="reportData.agent.title">
            {{ reportData.agent.title }}
          </p>
        </div>
        <div *ngIf="reportData.agent.number">
          <p class="agent-info--contact">{{ reportData.agent.number }}</p>
        </div>
        <div *ngIf="reportData.agent.phone">
          <p class="agent-info--contact">{{ reportData.agent.phone }}</p>
        </div>
        <div *ngIf="reportData.agent.email">
          <p class="agent-info--contact">{{ reportData.agent.email }}</p>
        </div>
        <div *ngIf="reportData.agent.websiteUrl">
          <p class="agent-info--contact">{{ reportData.agent.websiteUrl }}</p>
        </div>
        <div *ngIf="reportData.agent.address">
          <p class="agent-info--contact">{{ reportData.agent.address }}</p>
        </div>
        <div *ngIf="reportData.agent.city">
          <p class="agent-info--contact">{{ reportData.agent.city }}, {{ reportData.agent.stateCD }}</p>
        </div>
        <div *ngIf="reportData.agent.otherPhone">
          <p class="agent-info--contact">{{ reportData.agent.otherPhone }}</p>
        </div>
      </div>
      <div *ngIf="reportData.imageUrl && (pdfShowLogo$ | async)" class="corporate-logo">
        <img [src]="reportData.imageUrl" alt="logo" />
      </div>
    </div>
    <div class="disclaimer">
      <p>{{ reportData.disclaimer }}</p>
    </div>
  </div>
</div>

<!-- 
<div *ngIf="pageData$ | async as reportData" class="d-flex justify-content-center">
  <div [ngClass]="islandscape ? 'landscape' : 'portrait'" class="body-container">
    <div class="main">
      <div class="main--letterhead justify-content-between">
        <div class="main--letterhead__left d-flex">
          <div class="main--left__listing-info text-left">
            <p class="primary">{{ reportData.agent.brokerageName }}</p>
            <p class="primary">{{ isClaims ? ('is Ranked #1 in ' + (reportHeaderEntity | reportLocationName) || 'All Locations' ) : 'Market position report' }}</p>
            <div [style.width.px]="fittyWidth" class="fitty-container">
              <p class="secondary" id="my-element">
                Leading {{ reportHeaderEntity.groupByLevel }} by {{ reportHeaderEntity.msTypeName }} ({{ reportHeaderEntity.displayDateRange }})
              </p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="graph" class="market-table chart">
        <app-market-quest-chart
          *ngIf="show"
          [chartSourceData]="graph"
          [downloadableImageType]="reportChartSize.exportPreview"
          [hideFirmNames]="pdfShowName$ | async"
          id="element"
        ></app-market-quest-chart>
      </div>
      <div class="market-table table">
        <div class="">
          <img [src]="reportData.agent.photo" alt="agent photo" class="photo" />
        </div>
        <div>
          <div>
            <p class="agent-info--name">
              {{ reportData.agent.name }}
              <ng-container *ngIf="reportData.agent.title">|</ng-container>
              {{ reportData.agent.title }}
            </p>
          </div>
          <div *ngIf="reportData.agent.number">
            <p class="agent-info--contact">{{ reportData.agent.number }}</p>
          </div>
          <div *ngIf="reportData.agent.phone">
            <p class="agent-info--contact">{{ reportData.agent.phone }}</p>
          </div>
          <div *ngIf="reportData.agent.email">
            <p class="agent-info--contact">{{ reportData.agent.email }}</p>
          </div>
          <div *ngIf="reportData.agent.websiteUrl">
            <p class="agent-info--contact">{{ reportData.agent.websiteUrl }}</p>
          </div>
          <div *ngIf="reportData.agent.address">
            <p class="agent-info--contact">{{ reportData.agent.address }}</p>
          </div>
          <div *ngIf="reportData.agent.city">
            <p class="agent-info--contact">{{ reportData.agent.city }}, {{ reportData.agent.stateCD }}</p>
          </div>
          <div *ngIf="reportData.agent.otherPhone">
            <p class="agent-info--contact">{{ reportData.agent.otherPhone }}</p>
          </div>
        </div>
      </div>
      <div class="footer d-flex align-items-start">
        <div *ngIf="reportData.imageUrl && (pdfShowLogo$ | async)" class="footer--dba-logo me-3">
          <img [src]="reportData.imageUrl" alt="logo" />
        </div>
        <div class="footer--disclaimer d-flex">
          <p>{{ reportData.disclaimer }}</p>
        </div>
      </div>
    </div>
  </div>
</div> -->
