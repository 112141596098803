import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AnimationModel,
  AxisModel,
  ChartAreaModel,
  CornerRadiusModel,
  IAxisLabelRenderEventArgs,
  IPointRenderEventArgs,
  ISeriesRenderEventArgs,
  ITextRenderEventArgs,
  LegendSettingsModel,
  MarkerSettingsModel,
  TooltipSettingsModel
} from '@syncfusion/ej2-angular-charts';
import { format } from 'date-fns';
import { skip } from 'rxjs/operators';
import { ITrendsDataPoint } from '../../../../models/IMarketTrendsGraphSet';
import { ChartService } from '../../../../services/chart.service';
import { ThemeService } from '../../../../services/theme.service';
import { pdfPreviewOrientation } from '../../../../store/selectors/pdf.selector';
import { IAppState } from '../../../../store/state/app.state';
import { SharedComponentsModule } from '../../shared/shared-components.module';

@Component({
  standalone: true,
  imports: [SharedComponentsModule, CommonModule],
  selector: 'app-custom-chart',
  templateUrl: './custom-chart.component.html',
  styleUrls: ['./custom-chart.component.scss']
})
export class CustomChartComponent implements OnInit, OnChanges {
  @Input() chartDataObject: ITrendsDataPoint[][];
  @Input() chartTypes: string[];
  @Input() selectedMonth: string;
  @Input() dataTypes: string[];
  @Input() axis: (0 | 1)[];
  @Input() showTrendLines = true;
  fill: string[] = [];
  @Input() yAxisLabels: string[];
  legend: LegendSettingsModel = {
    position: 'Bottom',
    shapeHeight: 4,
    shapeWidth: 29,
    opacity: 1,
    alignment: 'Center',
    toggleVisibility: false,
    textStyle: {
      fontFamily: 'Helvetica Neue, Arial, sans-serif',
      size: '11',
      color: '#727476'
    }
  };
  chartArea: ChartAreaModel = {
    border: {
      width: 0
    }
  };
  marker: MarkerSettingsModel = {
    dataLabel: {
      labelIntersectAction: 'None',
      visible: false,
      border: {
        width: 1
      },
      margin: {
        right: 8,
        left: 8,
        top: 16,
        bottom: 8
      },
      position: 'Top',
      angle: 90,
      enableRotation: true,
      font: {
        fontFamily: 'Roboto'
      }
    }
  };
  primaryXAxis: AxisModel = {
    valueType: 'Category',
    labelIntersectAction: 'None',
    labelStyle: {
      fontFamily: 'Helvetica Neue, Arial, sans-serif',
      color: '#727476',
      size: '12',
      textOverflow: 'Trim'
    },
    maximumLabels: 36,
    lineStyle: {
      color: '#E9E9E9',
      width: 1
    },
    majorGridLines: {
      width: 0
    },
    majorTickLines: {
      width: 0
    }
  };
  primaryYAxis: AxisModel = {
    title: '',
    labelStyle: {
      fontFamily: 'Roboto, sans-serif',
      color: '#727476',
      size: '12',
      textOverflow: 'None'
    },
    lineStyle: {
      width: 0
    },
    majorTickLines: {
      width: 0
    },
    majorGridLines: {
      color: '#E9E9E9',
      width: 1
    },
    labelIntersectAction: 'Rotate90'
  };
  cornerRadius: CornerRadiusModel = {
    topRight: 0,
    topLeft: 0
  };
  palette: string[] = [];
  tooltip: TooltipSettingsModel = {
    enable: false
  };
  localData: ITrendsDataPoint[][];
  chartId: string;
  private formattedCurrentMonth: string;
  private showColumnMarkers = false;
  private year = 0;
  private smallArr: ITrendsDataPoint[][];
  animation: AnimationModel = {
    enable: false
  };

  constructor(private chartSvc: ChartService, private themeSvc: ThemeService, private store: Store<IAppState>) {
    const randomMixedId =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);
    this.chartId = `chart-container${randomMixedId}`;
    const isSir = this.themeSvc.isSIR();
    this.palette = [this.themeSvc.customChartPrimary, this.themeSvc.customChartSecondary];
    this.fill = [this.themeSvc.customChartTrendPrimary, this.themeSvc.customChartTrendSecondary];
  }

  ngOnInit(): void {
    this.drawChart();
    if (this.selectedMonth) {
      this.formattedCurrentMonth = format(new Date(this.selectedMonth), 'MMM yyyy');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.primaryYAxis.title = this.yAxisLabels[0];
    if (Object.keys(changes).some(key => !changes[key].firstChange)) {
      this.drawChart();
    }
    this.store
      .select(pdfPreviewOrientation)
      .pipe(skip(1))
      .subscribe(() => {
        this.drawChart();
      });
  }

  axisLabelRender($event: IAxisLabelRenderEventArgs) {
    const result = this.chartSvc.checkAxisLabels($event, this.isMobile(), this.formattedCurrentMonth, this.year);
    $event = result.$event;
    this.year = result.newCurrentYear ?? 0;
    return $event;
  }

  pointRender($event: IPointRenderEventArgs) {
    return this.chartSvc.pointRenderInit($event, this.formattedCurrentMonth, this.themeSvc.isSIR(), this.themeSvc.chartPrimary, true);
  }

  textRender($event: ITextRenderEventArgs) {
    return this.chartSvc.textRenderInit($event, this.formattedCurrentMonth);
  }

  seriesRender($event: ISeriesRenderEventArgs) {
    const custom = true;
    const isSir = this.themeSvc.isSIR();
    return this.chartSvc.seriesRenderInit($event, this.showColumnMarkers, this.chartId, this.formattedCurrentMonth, isSir, custom);
  }

  private drawChart() {
    this.localData = [];
    this.smallArr = this.chartDataObject?.map(dataType => dataType.filter((value, index) => index < 36));
    setTimeout(() => {
      this.localData = this.formatDateData(this.smallArr);
    }, 0);
  }

  private isMobile() {
    return window.innerWidth <= 600;
  }

  private formatDateData(chartDataObject: ITrendsDataPoint[][]) {
    return Object.values(chartDataObject).map(value => {
      value.map(v => {
        v.xData = (v.xData || '').split('.').join('-');
        return v;
      });
      return this.chartSvc.formatDates(value);
    });
  }
}
