import { DecimalPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-price-range',
  templateUrl: './price-range.component.html',
  styleUrls: ['./price-range.component.scss']
})
export class PriceRangeComponent {
  @Input() control: UntypedFormGroup;

  constructor(private decimalPipe: DecimalPipe) {}

  formatCurrency($event: any) {
    $event.target.value = this.decimalPipe.transform($event.target.value.replace('[^0-9.]', ''), '1.0-0');
  }

  hideFormatting($event: any) {
    $event.target.value = $event.target.value.replace(/\D/g, '');
  }
}
