export interface ITrendsTableData {
  datatype: string;
  ytd: number;
  cm: number;
  lm: number;
  lm_arrow?: number;
  lm_percent?: number;
  l3m: number;
  l3m_arrow?: number;
  l3m_percent?: number;
  cmly: number;
  cmly_arrow?: number;
  cmly_percent?: number;
  formattype?: TrendDataFormatTypes;
}

export enum TrendDataTypeEnum {
  solds = 'Number of Properties Sold',
  msp = 'Median Sales Price',
  ai = 'Number of Properties for Sale',
  dom = 'Average Days on Market',
  sppersf = 'Average Sales Price per Square Foot',
  splp = 'Sales Price / List Price Ratio',
  nl = 'New Listings',
  asp = 'Average Sales Price',
  alp = 'Average List Price',
  mlp = 'Median List Price',
  msi = `Month's Supply of Inventory`,
  ar = 'Absorption Rate'
}

export enum CardTypesEnum {
  'Average & Median Sales Price' = 'Average & Median Sales Price',
  'Sales Price / List Price Ratio' = 'Sales Price / List Price Ratio',
  'Number of Properties Sold & Absorption Rate' = 'Number of Properties Sold & Absorption Rate',
  'Average Days on Market' = 'Average Days on Market',
  'Average Sales Price per Square Foot' = 'Average Sales Price per Square Foot',
  'Inventory & MSI' = 'Inventory & MSI',
  'New Listings' = 'New Listings'
}

export enum TrendDataTypeEnumShortHand {
  ai = 'ai',
  alp = 'alp',
  asp = 'asp',
  dom = 'dom',
  mlp = 'mlp',
  msi = 'msi',
  msp = 'msp',
  nl = 'nl',
  period = 'period',
  solds = 'solds',
  sppersf = 'sppersf',
  splp = 'splp',
  ar = 'ar'
}

export enum TrendCardTypeEnumShortHand {
  amsp = 'amsp',
  splp = 'splp',
  nbrsoldar = 'nbrsoldar',
  dom = 'dom',
  spsqft = 'spsqft',
  aimsi = 'aimsi',
  nl = 'nl',
  overview = 'overview',
  custom = 'custom'
}

export enum TrendDataFormatTypes {
  Percent = 'Percent',
  Dollar = 'Dollar',
  Numeric = 'Numeric',
  Value = 'Value'
}

export enum TrendDataFormatAltTypes {
  Arrow,
  Percent,
  Value
}

export const FormatTypes = {
  [TrendDataTypeEnum.solds]: TrendDataFormatTypes.Numeric,
  [TrendDataTypeEnum.msp]: TrendDataFormatTypes.Dollar,
  [TrendDataTypeEnum.ai]: TrendDataFormatTypes.Numeric,
  [TrendDataTypeEnum.dom]: TrendDataFormatTypes.Value,
  [TrendDataTypeEnum.sppersf]: TrendDataFormatTypes.Dollar,
  [TrendDataTypeEnum.splp]: TrendDataFormatTypes.Percent,
  [TrendDataTypeEnum.nl]: TrendDataFormatTypes.Numeric,
  [TrendDataTypeEnum.asp]: TrendDataFormatTypes.Dollar,
  [TrendDataTypeEnum.alp]: TrendDataFormatTypes.Dollar,
  [TrendDataTypeEnum.mlp]: TrendDataFormatTypes.Dollar,
  [TrendDataTypeEnum.msi]: TrendDataFormatTypes.Value,
  [TrendDataTypeEnum.ar]: TrendDataFormatTypes.Value
} as const;

export enum DataColumns {
  datatype = 'datatype',
  cm = 'cm',
  lm = 'lm',
  l3m = 'l3m',
  cmly = 'cmly',
  ytd = 'ytd'
}

export const TableAdjustableDataColumns = ['lm', 'l3m', 'cmly'];

export const TableStaticDataColumns = ['cm', 'ytd'];
