import { LocationsActions, LocationsActionsActionTypes } from '../actions/locations.action';
import { getInitialLocationsState, ILocationsState } from '../state/locations.state';

export function LocationsReducer(state = getInitialLocationsState(), action: LocationsActions): ILocationsState {
  switch (action.type) {
    case LocationsActionsActionTypes.FetchLocationsSuccess: {
      return {
        ...state,
        locations: action.payload,
      };
    }

    case LocationsActionsActionTypes.FetchLocations: {
      return {
        ...state,
        loaded: false,
      };
    }

    case LocationsActionsActionTypes.FetchMarketLocations: {
      return {
        ...state,
        marketLoaded: false,
        marketLocations: []
      };
    }

    case LocationsActionsActionTypes.FetchMarketLocationsSuccess: {
      return {
        ...state,
        marketLoaded: true,
        marketLocations: action.payload
      };
    }

    case LocationsActionsActionTypes.FetchLocationsLoaded: {
      return {
        ...state,
        loaded: true
      };
    }

    default:
      return state;
  }
}
