import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilterState } from 'app/models/FilterData';

interface MlsData {
  mlsId: number;
  legalName: string;
  isSIR?: boolean;
}

@Component({
  selector: 'app-mls-info-modal',
  templateUrl: './mls-info-modal.component.html',
  styleUrls: ['./mls-info-modal.component.scss']
})
export class MlsInfoModalComponent {
  @Output() claimsFilterValues = new EventEmitter<FilterState>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: MlsData) {
  }
}
