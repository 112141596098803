enum SocialFooterMediaTypes {
  'facebook' = 'facebook_link',
  'instagram' = 'instagram_link',
  'twitter' = 'twitter_link',
  'youtube' = 'youtube_link'
}

enum IconPath {
  facebook = 'fb',
  instagram = 'inst',
  twitter = 'tw',
  youtube = 'yt'
}

export enum SocialName {
  facebook = 'facebook',
  twitter = 'twitter',
  linkedin = 'linkedin',
  youtube = 'youtube',
  pinterest = 'pinterest',
  instagram = 'instagram',
  blog = 'blog'
}

export class ISocialFooterLink {
  label: string;
  url: string;
  iconPath: string;

  constructor(label: string, url: string, isSir = false) {
    this.label = ISocialFooterLink.getKeyByValue(SocialFooterMediaTypes, label);
    this.url = url;
    this.iconPath = `${isSir ? 'sir-' : ''}${ISocialFooterLink.getIconPath(this.label)}-ico.svg`;
  }

  private static getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  private static getIconPath(string) {
    return IconPath[string];
  }
}

export const SocialMediaGroup = [
  SocialFooterMediaTypes.facebook as string,
  SocialFooterMediaTypes.instagram as string,
  SocialFooterMediaTypes.twitter as string,
  SocialFooterMediaTypes.youtube as string
];
