<div class="searches-table d-flex flex-column">
  <div class="v-scroll-auto property-type-scroll">
    <div class="searches-table-head d-flex flex-row">
      <div class="col-min">Name</div>
      <div class="col-full text-start">Search Parameters</div>
      <div class="col-min">Date<mat-icon>arrow_upward</mat-icon></div>
      <div class="col-min">Default</div>
      <div class="col-min">Delete</div>
    </div>
    <div
      matTooltip="Select this Search"
      matTooltipPosition="above"
      class="searches-table-row d-flex flex-row"
      *ngFor="let item of savedSearches; trackBy: trackByFn"
      (click)="selectRow(item)"
      [ngClass]="{ active: item === selectedSearch }"
    >
      <div class="col-min d-flex justify-content-center align-items-center value">{{ item.name | titlecase }}</div>
      <div class="col-full data text-start data-locations" *ngIf="item.data">
        <div *ngIf="item.data.locationFilters?.length > 0" class="flex-column d-flex pb-3">
          <div class="param-header text-uppercase">
            Locations
          </div>
          <div class="location-list d-flex flex-row">
            <div class="param-value" *ngFor="let loc of item.data.locationFilters; let idx = index">
              <span *ngIf="loc.areaName; else areaNameLookup"
                >{{ loc.areaName }}{{ idx !== item.data.locationFilters.length - 1 ? ',&nbsp;' : '' }}</span
              >
              <ng-template #areaNameLookup>
                <span *ngIf="loc.areaID | areaIdLookup: loc.areaType | async as location">{{ location?.areaName }}</span>
                <span *ngIf="idx !== item.data.locationFilters.length - 1">,&nbsp;</span>
              </ng-template>
            </div>
          </div>
        </div>
        <div *ngFor="let filter of item.data.report | keyvalue" class="flex-column d-flex">
          <div *ngIf="SavedSearchFields[filter.key]">
            <div class="param-header">
              {{ SavedSearchFields[filter.key] }}
            </div>
            <div class="param-value pb-3">
              {{ item.data.report[filter.key] | SavedSearchLookup: filter.key }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-min data date d-flex justify-content-center align-items-center">{{ item.createdDate | date: 'M/dd/yyyy' }}</div>
      <div class="col-min data d-flex justify-content-center align-items-center">
        <div *ngIf="item.isDefault; else btn">
          <span class="default-active">Default Search</span>
          <button mat-stroked-button [color]="'primary'" (click)="setSearchDefault('-1', $event, item.mlsId)">Remove Default</button>
        </div>
        <ng-template #btn>
          <button mat-stroked-button [color]="'primary'" (click)="setSearchDefault(item.rowKey, $event, item.mlsId)">Set as Default</button>
        </ng-template>
      </div>
      <div class="col-min data d-flex justify-content-center align-items-center">
        <mat-icon (click)="deleteSavedSearch(item.rowKey)">close</mat-icon>
      </div>
    </div>
  </div>
</div>
