<div *ngIf="tileData$ | async as tileData" [ngClass]="{ graph: showGraph, large: large }" class="preview w-100 d-flex">
  <div class="banner w-100">
    <div class="d-block h-100">
      <img [src]="this.showGraph ? tileData.logoDark : tileData.logoLight" alt="Sotheby's logo" />
    </div>
  </div>
  <div class="content">
    <div [ngStyle]="!showGraph && { 'background-image': 'url(' + tileData.imageBgSquare + ')' }" class="left">
      <div *ngIf="showGraph" class="graph-box d-flex w-100 h-100">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div [ngClass]="{ 'flex-column': showGraph }" class="right">
      <div class="number-one-text">
        <div class="gold-connect"></div>
        <div
          [ngClass]="{ 'flex-row': showGraph, 'flex-column': !showGraph }"
          class="nb1-box d-flex justify-content-center align-items-center h-100 p-2"
        >
          <div [ngClass]="{ 'align-self-start': !showGraph }">
            <p class="heading">Number</p>
            <p class="numeric-one">1</p>
          </div>
          <div>
            <p class="brokerage">
              {{ tileData.data.reportHeaderEntity.groupByLevel }} by {{ tileData.data.reportHeaderEntity.msTypeName }}
            </p>
            <p class="location">{{ tileData.data.reportHeaderEntity | reportLocationName: 4 }}</p>
            <p class="date-price">
              {{ tileData.data.reportHeaderEntity.displayDateRange }} | {{ tileData.data.reportHeaderEntity.priceType }}
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="showGraph" [ngStyle]="{ 'background-image': 'url(' + tileData.imageBgWide + ')' }" class="background"></div>
    </div>
  </div>
</div>
