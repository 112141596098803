import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAgentReport } from 'app/models/IAgentReport';
import { ChartTypes } from 'app/models/IChartTypes';
import { ImageTypes } from 'app/models/ImageTypes';
import { IMarketPositionData } from 'app/models/IMarketPositionData';
import { IReportDetail } from 'app/models/IReportDetail';
import { IReportHeader } from 'app/models/IReportHeader';
import { EncryptedReportData } from 'app/models/IReportPageData';
import { ITopGroupTypeData } from 'app/models/ITopGroupTypeData';
import { DisclaimerService } from 'app/services/disclaimer.service';
import { OptionsService } from 'app/services/options.service';
import { ReportDetailService } from 'app/services/report-details.service';
import { SyncFusionConfigService } from 'app/services/sync-fusion-config.service';
import { selectMarketReportData } from 'app/store/selectors/market-position.selector';
import {
  pdfPreviewOrientation,
  pdfShowMarketPositionTable,
  pdfShowHideFirmName,
  pdfShowAgentInfo,
  pdfShowLogo
} from 'app/store/selectors/pdf.selector';
import { selectReportPageData } from 'app/store/selectors/reports.selectors';
import { IAppState } from 'app/store/state/app.state';
import fitty from 'fitty';
import { Subscription, Observable, combineLatest, map } from 'rxjs';

@Component({
  selector: 'app-c-b-r-market-export-preview',
  templateUrl: './c-b-r-market-export-preview.component.html',
  styleUrls: ['./c-b-r-market-export-preview.component.scss']
})
export class CBRMarketExportPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showLogo = true;
  @Input() hideChartNames = true;
  subs = new Subscription();
  reportChartSize = ChartTypes;
  pageData$: Observable<{
    agent: IAgentReport;
    data: IReportDetail | IReportHeader;
    imageUrl: string;
    table: ITopGroupTypeData[];
    disclaimer: string;
  }>;
  isLandscape: boolean;
  show = true;
  graph: EncryptedReportData;
  pdfOrientation$: Observable<boolean>;
  pdfShowMarketPositionTable$: Observable<boolean>;
  pdfShowName$: Observable<boolean>;
  pdfShowLogo$: Observable<boolean>;
  pdfShowAgentInfo$: Observable<boolean>;
  fittyWidth: number;
  realogyPositionIndex: number;
  reportHeaderEntity: IReportHeader;

  constructor(
    private store: Store<IAppState>,
    private opts: OptionsService,
    private disclaimerService: DisclaimerService,
    private syncFusionService: SyncFusionConfigService,
    private reportDetailService: ReportDetailService
  ) {}

  resetOrientation() {
    this.show = false;

    setTimeout(() => {
      this.show = true;
    }, 1);
  }

  ngAfterViewInit() {
    this.fittyInit();
  }

  ngOnInit(): void {
    this.pdfOrientation$ = this.store.select(pdfPreviewOrientation);
    this.pdfShowMarketPositionTable$ = this.store.select(pdfShowMarketPositionTable);
    this.pdfShowName$ = this.store.select(pdfShowHideFirmName);
    this.pdfShowAgentInfo$ = this.store.select(pdfShowAgentInfo);
    this.pdfShowLogo$ = this.store.select(pdfShowLogo);

    this.subs.add(
      combineLatest([this.pdfShowMarketPositionTable$, this.pdfShowName$]).subscribe(() => {
        this.resetOrientation();
      })
    );

    this.subs.add(
      this.pdfOrientation$.subscribe(d => {
        if (this.isLandscape !== undefined && this.isLandscape !== d) {
          this.resetOrientation();
          this.fittyInit();
        }
        this.isLandscape = d;
      })
    );

    this.pageData$ = combineLatest([
      this.store.select(selectReportPageData),
      this.store.select(selectMarketReportData),
      this.pdfShowName$,
      this.pdfShowAgentInfo$
    ]).pipe(
      map(d => {
        let disclaimer;
        const [claims, market, showFirmName] = d;

        this.realogyPositionIndex = market?.realogyPosition?.marketPosition ? market?.realogyPosition?.marketPosition - 1 : null;
        const data = market?.reportHeaderEntity;
        this.graph = this.syncFusionService.prepareMarketChatDataModel(market);
        if (!showFirmName) {
          const position = market.realogyPosition?.marketPosition;
          this.graph.reportDetail.reportGraphEntities = this.reportDetailService.remapGraphEntryNames(
            this.graph.reportDetail,
            position,
            true
          );
        }
        const agent = market?.agentReportEntity;
        const path = this.opts.getImagePathForReport();
        const logo = (claims?.reportDetail?.imagePromoEntities || market?.imagePromoEntities).find(image => {
          return image.imageSize === ImageTypes.emailLogo;
        });
        const imageUrl = path + logo.imageURL;
        const table = market?.topGroupTypes;

        disclaimer = this.disclaimerService.formatCBRDisclaimer(
          ((market as unknown) as IMarketPositionData).reportHeaderEntity,
          ((market as unknown) as IMarketPositionData).agentReportEntity,
          true
        );

        this.reportHeaderEntity = data;
        return { data, agent, imageUrl, table, disclaimer };
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private fittyInit() {
    setTimeout(() => {
      const width = this.isLandscape ? 359 : 335;
      this.fittyWidth = width - 10;
      const textFit = fitty('#my-element', { minSize: 9, maxSize: 16 });
      textFit[0]?.fit();
    }, 1);
  }

  getTableEntityName(showName: boolean, entityName: string, index: number, realogyName: string) {
    if (showName) {
      return entityName;
    } else {
      if (this.realogyPositionIndex === index || entityName === realogyName) {
        return entityName;
      } else {
        return `Competitor ${index + 1}`;
      }
    }
  }
}
