import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class UploadService {
  public progress: number;
  public message: string;

  constructor(private http: HttpClient) {}

  uploadFile(blob, componentType, reportId: string, oktaId: string) {
    const formData = new FormData(); // this will submit as a "multipart/form-data" request
    const imageName = 'image-' + componentType + '-' + reportId + '.jpg';
    formData.append('image', blob, imageName); // "image_name" is what the server will call the blob
    return this.http.post(environment.apiBaseUrl + '/api/image/upload/' + oktaId, formData, {
      headers: new HttpHeaders({ timeout: '60000' })
    });
  }
}
