import { Injectable } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../../environments/environment';
import { Tenants } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class MqOktaAuthService {
  private config = environment.oktaApp;

  constructor(private oktaAuth: OktaAuth) {
    this.oktaAuth = new OktaAuth({
      clientId: this.config.clientId,
      issuer: this.config.issuer,
      cookies: {
        secure: true
      },
      pkce: false,
      responseType: ['id_token', 'token'],
      scopes: ['openid', 'profile']
    });
  }

  public async logout() {
    const brandCode = JSON.parse(window.localStorage.getItem('userData'))?.info?.brandCode;
    localStorage.clear();
    localStorage.setItem('brandCode', brandCode);
    sessionStorage.clear();
    const token = this.oktaAuth.getAccessToken();
    await this.oktaAuth
      .signOut({
        revokeAccessToken: !!token,
        postLogoutRedirectUri: `${window.location.origin}/logout`
      })
      .then(r => r);
  }

  public login(tenantId: Tenants) {
    localStorage.clear();
    sessionStorage.clear();
    const t = window.location.pathname;
    if (t === '/logout') {
      this.oktaAuth.setOriginalUri('/');
    } else {
      this.oktaAuth.setOriginalUri(t);
    }

    return this.oktaAuth.token
      .getWithRedirect({
        idp: environment.oktaApp[tenantId].idp,
        redirectUri: environment.oktaApp[tenantId].redirectUri
      })
      .then(() => true);
  }

  setServiceNowAuthSession(isAuthenticated: boolean): void {
    window.localStorage.setItem('serviceNowIsAuthenticated', isAuthenticated + '');
  }

  getServiceNowAuthSession(): boolean {
    return window.localStorage.getItem('serviceNowIsAuthenticated') === 'true';
  }
}
