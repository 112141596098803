import { BreakpointObserver } from '@angular/cdk/layout';
import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatestWith } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { MailModalComponent } from '../components/modals/mail-modal/mail-modal.component';
import { SocialModalComponent } from '../components/modals/social-modal/social-modal.component';
import { ChartTypes } from '../models/IChartTypes';
import { IEmailData } from '../models/IEmailData';
import { IPageTypes } from '../models/IPageTypes';
import { IReportDetail } from '../models/IReportDetail';
import { ISocialData } from '../models/ISocialData';
import { ReportPageTypeShortStrEnum } from '../models/ReportTypes';
import { selectMarketShowAsPercent } from '../store/selectors/market-position.selector';
import { claimsReportId } from '../store/selectors/reports.selectors';
import { selectUser } from '../store/selectors/userData.selector';
import { IAppState } from '../store/state/app.state';
import { OptionsService } from './options.service';
import { ReportDetailService } from './report-details.service';
import { ReportService } from './report.service';
import { UploadService } from './upload.service';

@Injectable({
  providedIn: 'root'
})
export class ShareSocialAndEmailService {
  private isDesktop: boolean;

  constructor(
    private _reportDetailService: ReportDetailService,
    private dialog: MatDialog,
    private reportService: ReportService,
    private breakpointObserver: BreakpointObserver,
    private componentFactoryResolver: ComponentFactoryResolver,
    private optionService: OptionsService,
    private store: Store<IAppState>,
    private uploadService: UploadService
  ) {
    this.breakpointObserver.observe('(min-width: 576px)').subscribe(size => {
      this.isDesktop = size.matches;
    });
  }

  static formatAreaType(reportDetails): string {
    if (reportDetails.reportHeaderEntity.areaType.indexOf('County') !== -1) {
      return (
        reportDetails.reportHeaderEntity.areaLocalName.split(',')[0] +
        ' County, ' +
        reportDetails.reportHeaderEntity.areaLocalName.split(',')[1]
      );
    } else if (reportDetails.reportHeaderEntity.areaType.indexOf('City') !== -1) {
      return (
        reportDetails.reportHeaderEntity.areaLocalName.split(',')[0] + ',' + reportDetails.reportHeaderEntity.areaLocalName.split(',')[1]
      );
    } else if (reportDetails.reportHeaderEntity.areaType.indexOf('Zip') !== -1) {
      return 'Zip ' + reportDetails.reportHeaderEntity.areaLocalName;
    } else {
      return '';
    }
  }

  static fetchActionButtonConfig(pageType: IPageTypes) {
    switch (pageType) {
      case ReportPageTypeShortStrEnum.trends: {
        return {
          email: true,
          exportPdf: true,
          previewWebsite: false,
          share: false
        };
      }
      case ReportPageTypeShortStrEnum.marketPosition: {
        return {
          email: true,
          exportPdf: true,
          previewWebsite: false,
          share: false
        };
      }
      case ReportPageTypeShortStrEnum.claims: {
        return {
          email: true,
          exportPdf: true,
          previewWebsite: true,
          share: true
        };
      }
      default: {
        return {
          email: true,
          exportPdf: true,
          previewWebsite: true,
          share: true
        };
      }
    }
  }

  openEmail(reportId: string, type: IPageTypes = null): void {
    this.store
      .select(selectUser)
      .pipe(take(1))
      .subscribe(data => {
        const emailData = new IEmailData();
        emailData.oktaId = data.oktaId;
        emailData.reportid = reportId;
        emailData.senderName = data.name;
        emailData.senderEmail = data.email;
        emailData.firstEmail = data.email;
        emailData.type = type;

        this.dialog.open(MailModalComponent, {
          width: this.isDesktop ? '450px' : '100vw',
          data: emailData,
          maxWidth: this.isDesktop ? '90vw' : 'none',
          height: this.isDesktop ? 'auto' : '100vh'
        });
      });
  }

  openSocial(
    reportId: string,
    oktaId: string,
    reportDetails: IReportDetail,
    encryptedId: string,
    cbPosition: number,
    imagePath?: string
  ): void {
    const socialData = new ISocialData();
    socialData.tags =
      'coldwellbanker #' +
      reportDetails.agentReportEntity.brokerageName.toLowerCase().replace(/\s/g, '') +
      ' #marketstats #' +
      ShareSocialAndEmailService.formatAreaType(reportDetails).replace(/[\s+]|[,+]/g, '');
    socialData.description =
      'See how ' + reportDetails.agentReportEntity.brokerageName + ' ranks in ' + ShareSocialAndEmailService.formatAreaType(reportDetails);
    socialData.title = 'Check out our Stellar Market Stats ';
    socialData.image = imagePath + 'mq-uno/social/' + oktaId + '/image-wide-' + reportDetails.reportHeaderEntity.msUnoReportID + '.jpg';
    socialData.reportId = reportId;
    socialData.oktaid = oktaId;
    socialData.encryptedid = encryptedId;
    socialData.reportDetails = reportDetails;
    socialData.cbPosition = cbPosition;

    const dialogRef = this.dialog.open(SocialModalComponent, {
      width: this.isDesktop ? '400px' : '100vw',
      data: socialData,
      maxWidth: this.isDesktop ? '90vw' : 'none',
      height: this.isDesktop ? 'auto' : '100vh'
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        switch (result) {
          case 'email': {
            this.openEmail(reportId);
            break;
          }
          default: {
            break;
          }
        }
      });
  }

  openTrendsEmail(dbId: string): void {
    this.store
      .select(selectUser)
      .pipe(take(1))
      .subscribe(data => {
        const emailData = new IEmailData();
        emailData.oktaId = data.oktaId;
        emailData.reportid = dbId;
        emailData.senderName = data.name;
        emailData.senderEmail = data.email;
        emailData.firstEmail = data.email;
        emailData.type = ReportPageTypeShortStrEnum.trends;
        this.dialog.open(MailModalComponent, {
          width: this.isDesktop ? '450px' : '100vw',
          data: emailData,
          maxWidth: this.isDesktop ? '90vw' : 'none',
          height: this.isDesktop ? 'auto' : '100vh'
        });
      });
  }

  generateSocialImage() {
    let reportIdVar;
    let oktaIdVar;

    return this.store.select(claimsReportId).pipe(
      combineLatestWith(this.store.select(selectUser), this.store.select(selectMarketShowAsPercent)),
      take(1),
      switchMap(([reportId, user, percent]) => {
        reportIdVar = reportId;
        oktaIdVar = user.oktaId;
        return this.reportService.requestCBRClaimsJpg(user.oktaId, ChartTypes.wide, reportId, 0, true, 75, percent);
      }),
      switchMap(data => {
        return this.uploadService.uploadFile(data, 'wide', reportIdVar, oktaIdVar);
      })
    );
  }
}
