export enum ReportTypeEnum {
  Uno = 0,
  MarketPosition = 1,
  MarketTrends = 2,
  MarketSnapshot = 3,
  CustomReport = 4,
  ASP_Trend = 5,
  AbsorptionRate = 6
}

export enum ReportTypeLongStrEnum {
  Uno = 'Uno',
  MarketPosition = 'MarketPosition',
  MarketTrends = 'MarketTrends',
  MarketSnapshot = 'MarketSnapshot',
  CustomReport = 'CustomReport',
  ASPTrend = 'ASPTrend'
}

export enum ReportPageTypeShortStrEnum {
  claims = 'claims',
  trends = 'trends',
  marketPosition = 'marketPosition',
  snapshot = 'snapshot',
  custom = 'custom',
  asp_trend = 'asp_trend'
}
