import { BannerTypeEnums } from '../../models/IBannerData';
import { INavButton } from '../../models/INavButton';

export class IMenuState {
  sideBarOpen: boolean;
  filterMenuOpen: boolean;
  showWarningBars: WarningBanners[];
  navItems: INavButton[];
}

export const initialMenuState: IMenuState = {
  sideBarOpen: false,
  filterMenuOpen: false,
  showWarningBars: [],
  navItems: []
};

export function getInitialMenuState(): IMenuState {
  return initialMenuState;
}

export interface WarningBanners {
  [key: string]: Banner;
}

export interface Banner {
  type: BannerTypeEnums;
  show: boolean;
}
