import { Action } from '@ngrx/store';
import { IAspTrendsDataResponse } from '../state/asp-trends.state';

export enum AspTrendsActionsActionTypes {
  FetchAspTrends = '[AspTrends] Fetch AspTrends',
  FetchAspTrendsSuccess = '[AspTrends] Fetch AspTrends Success',
  FetchAspPDF = '[AspTrends] Fetch Asp PDF',
  FetchAspPDFSuccess = '[AspTrends] Fetch Asp PDF Success'
}

export class FetchAspTrends implements Action {
  readonly type = AspTrendsActionsActionTypes.FetchAspTrends;
  constructor(public payload: any) {}
}

export class FetchAspTrendsSuccess implements Action {
  readonly type = AspTrendsActionsActionTypes.FetchAspTrendsSuccess;
  constructor(public payload: IAspTrendsDataResponse) {}
}

export class FetchAspPDF implements Action {
  readonly type = AspTrendsActionsActionTypes.FetchAspPDF;
  constructor(public payload: IAspTrendsDataResponse) {}
}

export class FetchAspPDFSuccess implements Action {
  readonly type = AspTrendsActionsActionTypes.FetchAspPDFSuccess;
}

export type AspTrendsAction = FetchAspTrends | FetchAspTrendsSuccess | FetchAspPDF | FetchAspPDFSuccess;
