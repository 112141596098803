<footer *ngIf="brandData">
  <div class="social">
    <app-footer-social [data]="brandData"></app-footer-social>
  </div>
  <div class="realogy-corp">
    <app-footer-contact [data]="brandData"></app-footer-contact>
  </div>
  <div class="branding">
    <app-footer-branding [data]="brandData"></app-footer-branding>
  </div>
  <div class="disclaimer">
    <app-footer-disclaimer [data]="brandData"></app-footer-disclaimer>
  </div>
</footer>
