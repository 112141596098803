<div class="data-table">
  <div class="table-heading">
    <div class="tr">
      <div *ngFor="let i of tableHeaderArr" class="cell">{{ i }}</div>
    </div>
  </div>
  <div class="table-body">
    <div *ngFor="let i of tableRowsArr" class="tr">
      <div class="cell">{{ i.name }}</div>
      <div *ngFor="let d of i.data" class="cell">{{ d }}</div>
    </div>
  </div>
</div>
