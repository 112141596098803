import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { GalleryImage } from '../../models/GalleryImage';
import { Avatar } from '../../models/IApplicationData';
import { GalleryService } from '../../services/gallery.service';
import { ThemeService } from '../../services/theme.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserDataService } from '../../services/userData.service';
import { SetBrandCode } from '../actions/applicationData.action';
import { FetchFeaturesSuccess } from '../actions/featureData.action';
import { FetchV2FiltersFromApi } from '../actions/filtersData.action';
import { FetchUsersGalleryImages } from '../actions/gallery.action';
import { FetchMLS } from '../actions/mls.action';
import { FetchContactDataSuccess, SetBrandingTypeLocal } from '../actions/user-settings.action';
import {
  FetchUserAvatarDetails,
  FetchUserDataSuccess,
  FetchUserDefaultImage,
  FetchUserDefaultImageSuccess,
  FetchUserV2Data,
  SetUserAvatarDetails,
  UpdateUserDefaultLicense,
  UserDataActionsActionTypes
} from '../actions/userData.action';
import { selectUser } from '../selectors/userData.selector';
import { IAppState } from '../state/app.state';
import { BrandingEnum, BrandingEnumByInteger } from '../state/user-settings.state';

@Injectable()
export class UserDataEffect {
  fetchUserV2Data$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FetchUserV2Data>(UserDataActionsActionTypes.FetchUserV2Data),
        switchMap(action => this.userDataService.getUserData(action.payload)),
        map(data => {
          if (data) {
            this.userDataService.setBusinessUnit(data.isNrt ? 'RBG' : 'RFG');
            this.userDataService.processUserCBA(data.isNrt, data.brandCode);
            this.store.dispatch(new FetchFeaturesSuccess(data.features));
            this.store.dispatch(new SetBrandCode(data.brandCode));
            this.store.dispatch(new FetchUserDataSuccess(data));
            this.store.dispatch(new FetchUserAvatarDetails(data));
            this.store.dispatch(new SetBrandingTypeLocal(BrandingEnum[BrandingEnumByInteger[data.contactType]]));
            this.store.dispatch(new FetchMLS());
            this.store.dispatch(new FetchV2FiltersFromApi());
          }
        })
      ),
    { dispatch: false }
  );

  fetchAvatarData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchUserAvatarDetails>(UserDataActionsActionTypes.FetchUserAvatarDetails),
      switchMap(action => this.userDataService.verifyUserAvatarCache(action.payload.oktaId)),
      switchMap((avatar: Avatar) => {
        this.userDataService.syncUserAvatarCache(avatar);
        return [new SetUserAvatarDetails(avatar)];
      })
    )
  );

  fetchGalleryImages = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchUserDefaultImage>(UserDataActionsActionTypes.FetchUserDefaultImage),
      withLatestFrom(this.store.select(selectUser)),
      filter(d => !!d[1].oktaId),
      switchMap(data => {
        const [action, user] = data;
        return this.galleryService.getGalleryImagesForUser(user.oktaId);
      }),
      switchMap((galleryImages: GalleryImage[]) => [new FetchUserDefaultImageSuccess(galleryImages)])
    )
  );

  updateUsersDefaultLicense = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateUserDefaultLicense>(UserDataActionsActionTypes.UpdateUserDefaultLicense),
      switchMap(action => {
        return this.userSettingSvc.updateLicenseNbrToAPI(action.payload);
      }),
      switchMap(response => {
        return [
          new FetchContactDataSuccess({
            social: response.socialData,
            contact: response.contactData,
            photo: response.photoData
          }),
          new FetchUsersGalleryImages(),
          new FetchUserDefaultImage()
        ];
      }),
      catchError(err => throwError(err))
    )
  );

  constructor(
    private actions$: Actions,
    private userDataService: UserDataService,
    private store: Store<IAppState>,
    private css: ThemeService,
    private galleryService: GalleryService,
    private userSettingSvc: UserSettingsService
  ) {}
}
