import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IReportGraph } from '../../../../models/IReportGraph';
import { ReportDetailService } from '../../../../services/report-details.service';
import { selectReportPageData } from '../../../../store/selectors/reports.selectors';
import { IAppState } from '../../../../store/state/app.state';
import { ITopGroupTypeData } from '../../../../models/ITopGroupTypeData';
import { IMarketPositionData } from '../../../../models/IMarketPositionData';

@Component({
  selector: 'app-small-chart-table',
  templateUrl: './small-chart-table.component.html',
  styleUrls: ['./small-chart-table.component.scss']
})
export class SmallChartTableComponent implements OnInit {
  reportDetail$ = this.store.select(selectReportPageData);
  entityNames: { levelEntityName: string }[] = [];
  @Input() cbPosition: number;
  @Input() marketReportDetails: IMarketPositionData;
  @Input() reportType: number;

  constructor(private store: Store<IAppState>, private reportDetailSrv: ReportDetailService) {}

  ngOnInit() {
    if (this.reportType === 0) {
      this.reportDetail$.subscribe(d => {
        this.entityNames = this.reportDetailSrv.remapGraphEntryNames(d.reportDetail, this.cbPosition).map((e: IReportGraph) => {
          return { levelEntityName: e.levelEntityName };
        });
      });
    } else if (this.reportType === 1) {
      this.entityNames = this.reportDetailSrv
        .remapMarketGraphEntryNames(this.marketReportDetails, this.cbPosition)
        .map((e: ITopGroupTypeData) => {
          return { levelEntityName: e.entityName };
        });
    }
  }
}
