import { Action } from '@ngrx/store';
import { DefaultFilters } from '../../models/IFilters';
import { ILocationsData } from '../../models/ILocationsData';
import { IMarketSnapshotFormGroup } from '../../models/IMarketSnapshotFormGroup';

export enum MarketSnapshotActionsTypes {
  FetchSnapshotData = '[Snapshot] Fetch API Data',
  FetchSnapshotDataError = '[Snapshot] Fetch API Data Error',
  FetchSnapshotDataSuccess = '[Snapshot] Fetch API Data Success',
  ResetSnapshotData = '[Snapshot] Reset Snapshot Data',
  AddLocationFilter = '[Snapshot] Add Location Filter To Array',
  SetLocationFilter = '[Snapshot] Override Location Filters',
  ClearSnapshotLocationFilter = '[Snapshot] Clear Location Filters',
  UpdateUserSnapshotDefaultLocationToApi = '[Snapshot] Saving User Selected Location to DB',
  UpdateLocalSnapshotDefaultFilters = '[Snapshot] Update Local Default Filters'
}

export class SetUserSnapshotDefaultLocation implements Action {
  readonly type = MarketSnapshotActionsTypes.UpdateUserSnapshotDefaultLocationToApi;

  constructor(public payload: DefaultFilters) {}
}

export class UpdateLocalSnapshotDefaultFilters implements Action {
  readonly type = MarketSnapshotActionsTypes.UpdateLocalSnapshotDefaultFilters;

  constructor(public payload: ILocationsData) {}
}

export class FetchSnapshotData implements Action {
  readonly type = MarketSnapshotActionsTypes.FetchSnapshotData;

  constructor(public payload: IMarketSnapshotFormGroup) {}
}

export class ResetSnapshotData implements Action {
  readonly type = MarketSnapshotActionsTypes.ResetSnapshotData;
}

export class ClearSnapshotLocationFilter implements Action {
  readonly type = MarketSnapshotActionsTypes.ClearSnapshotLocationFilter;
}

export class FetchSnapshotDataError implements Action {
  readonly type = MarketSnapshotActionsTypes.FetchSnapshotDataError;
}

export class FetchSnapshotDataSuccess implements Action {
  readonly type = MarketSnapshotActionsTypes.FetchSnapshotDataSuccess;

  constructor(public payload: any) {}
}

export class AddLocationFilter implements Action {
  readonly type = MarketSnapshotActionsTypes.AddLocationFilter;

  constructor(public payload: ILocationsData) {}
}

export class SetLocationFilter implements Action {
  readonly type = MarketSnapshotActionsTypes.SetLocationFilter;

  constructor(public payload: ILocationsData) {}
}

export type MarketSnapshotAction =
  | FetchSnapshotData
  | FetchSnapshotDataError
  | FetchSnapshotDataSuccess
  | ResetSnapshotData
  | AddLocationFilter
  | SetLocationFilter
  | ClearSnapshotLocationFilter
  | UpdateLocalSnapshotDefaultFilters
  | SetUserSnapshotDefaultLocation;
