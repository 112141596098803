import { Pipe, PipeTransform } from '@angular/core';

type UrlType = 'LinkedIn' | 'Facebook' | 'Youtube' | 'Instagram' | 'Twitter' | 'Website';

@Pipe({
  name: 'urlShorten'
})
export class UrlShortenPipe implements PipeTransform {
  removeFromString(words: string[], str: string) {
    return words.reduce((result, word) => result.replace(word, ''), str);
  }

  transform(value: string, arg: UrlType) {
    const baseWords = ['https', 'http', '://', 'www.', '@', '#'];
    const resultWithoutPrefix = this.removeFromString(baseWords, value);

    switch (arg) {
      case 'LinkedIn':
        return '@' + this.removeFromString(['linkedin.com/in/'], resultWithoutPrefix);
      case 'Facebook':
        return 'fb.com/' + this.removeFromString(['facebook.com/'], resultWithoutPrefix);
      case 'Youtube':
        return resultWithoutPrefix;
      case 'Instagram':
        return '@' + this.removeFromString(['instagram.com/'], resultWithoutPrefix);
      case 'Twitter':
        return '@' + this.removeFromString(['twitter.com/'], resultWithoutPrefix);
      case 'Website':
        return resultWithoutPrefix;
      default:
        return resultWithoutPrefix;
    }
  }
}
