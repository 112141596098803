import { CustomReportAction, CustomReportActionsActionTypes } from '../actions/custom-report.action';
import { getInitialCustomReportState, ICustomReportState } from '../state/custom-report.state';

export function CustomReportReducer(state = getInitialCustomReportState(), action: CustomReportAction): ICustomReportState {
  function extracted(value: number, idx: number, arr: [number, number]) {
    const newArr: [number, number] = [...arr];
    newArr[idx] = value;
    return newArr;
  }

  switch (action.type) {
    case CustomReportActionsActionTypes.SetDataTableShowAll: {
      return {
        ...state,
        showAllData: action.payload
      };
    }
    case CustomReportActionsActionTypes.ResetCustomReportData: {
      return getInitialCustomReportState();
    }
    case CustomReportActionsActionTypes.FetchCustomReports: {
      return {
        ...state,
        loading: true,
        loaded: false,
        customReportDetails: null
      };
    }
    case CustomReportActionsActionTypes.FetchCustomReportsSuccess:
      return {
        ...state,
        customReportDetails: action.payload,
        hasError: false,
        loaded: true,
        loading: false
      };
    case CustomReportActionsActionTypes.FetchCustomReportError: {
      return {
        ...state,
        customReportDetails: null,
        hasError: true,
        loading: false,
        loaded: true
      };
    }
    case CustomReportActionsActionTypes.FetchCustomReportPdf: {
      return {
        ...state,
        pdfState: {
          ...state.pdfState,
          pending: true
        }
      };
    }
    case CustomReportActionsActionTypes.AdjustCustomReportDataTypes:
      return {
        ...state,
        customReportDataTypes: extracted(action.payload.value, action.payload.idx, state.customReportDataTypes)
      };
    case CustomReportActionsActionTypes.AdjustCustomReportDisplayAs:
      return {
        ...state,
        customReportDisplayDataAs: extracted(action.payload.value, action.payload.idx, state.customReportDisplayDataAs)
      };
    case CustomReportActionsActionTypes.ResetCustomReportDisplayAs:
      return {
        ...state,
        customReportDisplayDataAs: getInitialCustomReportState().customReportDisplayDataAs
      };

    case CustomReportActionsActionTypes.SetCustomReportGraphAxis:
      return {
        ...state,
        customReportGraphAxis: action.payload
      };
    default: {
      return state;
    }
  }
}
