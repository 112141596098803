<div #assetYouTubePlayer class="card">
  <div *ngIf="marketData?.type === 'image'; else videoPlayer" class="card-image">
    <ng-container *ngIf="isSIR; else cb">
      <app-preview-tile-sothebys [showGraph]="marketData.hasGraph"></app-preview-tile-sothebys>
    </ng-container>
    <ng-template #cb>
      <app-preview-tile *ngIf="reportData" [reportData]="reportData" [graph]="marketData.hasGraph" [large]="false"></app-preview-tile>
    </ng-template>
  </div>
  <div class="card-text">{{ marketData?.title }}</div>
  <div class="card-description">{{ this.description }}</div>
  <div *ngIf="marketData?.type === 'image'; else video" class="card-controls">
    <button (click)="openSocialModal('social')" color="primary" mat-button>Social Image</button>
    <button (click)="openSocialModal('hs')" *ngIf="showHomeSpotter" color="primary" mat-button>Homespotter Ad</button>
  </div>
</div>

<ng-template #videoPlayer>
  <div class="card-image iframe-container">
    <youtube-player [videoId]="marketData?.youTubeId" suggestedQuality="default"></youtube-player>
  </div>
</ng-template>
<ng-template #video>
  <div class="card-controls">
    <button (click)="downloadVideo()" color="primary" mat-button>Download</button>
    <button (copied)="notify()" [copy-clipboard]="this.shareLink" color="primary" id="copy" mat-button>Copy URL</button>
  </div>
</ng-template>
