<div class="d-flex d-md-none justify-content-end close-modal">
  <mat-icon (click)="closeDialog()">close</mat-icon>
</div>
<div>
  <h1 class="mail-title pb-2" mat-dialog-title>Email Report</h1>
</div>
<mat-dialog-content class="email-container">
  <div>
    <div class="pb-4">
      <span class="email-action">Who should this report go to?</span>
    </div>
    <app-email-address
      (trashClicked)="deleteFirst()"
      [emailAddress]="data.firstEmail"
      [showTrash]="addSecond"
      id="firstEmail"
    ></app-email-address>
  </div>
  <div *ngIf="addSecond">
    <app-email-address
      (trashClicked)="deleteSecond()"
      [emailAddress]="data.secondEmail"
      [showTrash]="addSecond"
      id="secondEmail"
    ></app-email-address>
  </div>
  <div *ngIf="addThird">
    <app-email-address
      (trashClicked)="deleteThird()"
      [emailAddress]="data.thirdEmail"
      [showTrash]="addThird"
      id="thirdEmail"
    ></app-email-address>
  </div>
  <div *ngIf="notAllEmailsAdded">
    <a (click)="addAnother()" class="keep-pointer">
      <mat-icon>add_circle_outline</mat-icon>
      <span>Add another recipient</span></a
    >
  </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-md-end justify-content-center flex-column flex-md-row mail-buttons" style="padding: 24px 0">
  <button (click)="closeMe()" class="button-size" mat-button>Cancel</button>
  <button (click)="email(data.type)" class="button-size" color="primary" mat-raised-button>Send</button>
</mat-dialog-actions>
