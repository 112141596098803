import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { FeatureService } from '../services/feature.service';
import { selectFeaturesLoaded } from '../store/selectors/features.selector';
import { IAppState } from '../store/state/app.state';

@Injectable()
export class FeatureGuard implements CanActivate {
  constructor(private featureService: FeatureService, private router: Router, private store: Store<IAppState>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(selectFeaturesLoaded).pipe(
      filter(v => !!v),
      mergeMap(() => {
        if (this.featureService.hasFlags(next.data.flags)) {
          return of(true);
        } else {
          this.router.navigate(['/']).then(r => r);
          return EMPTY;
        }
      })
    );
  }
}
