import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMarketTrendsDataSet, ITrendsDataPoint } from '../../../../models/IMarketTrendsGraphSet';
import { MarketTrendsChartConfiguration } from '../../../../models/MarketTrendsChartConfiguration';
import { selectTrendsGraphData } from '../../../../store/selectors/market-trends.selector';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-trend-chart-container',
  templateUrl: './trend-chart-container.component.html',
  styleUrls: ['./trend-chart-container.component.scss']
})
export class TrendChartContainerComponent implements OnInit {
  @Input() title: string;
  @Input() chart: MarketTrendsChartConfiguration;
  @Input() typographySize: 'small' | 'medium' | 'large' = 'medium';
  chartData$: Observable<{ data: IMarketTrendsDataSet }>;

  constructor(private store: Store<IAppState>) {}

  ngOnInit(): void {
    this.chartData$ = this.store.select(selectTrendsGraphData).pipe(
      map(apiData => {
        const data: IMarketTrendsDataSet = {};
        this.chart?.dataTypes.map(dataType => {
          data[dataType] = [];
          apiData.forEach(d => {
            const entry: ITrendsDataPoint = {
              yData: d[dataType] as number,
              xData: d.period
            };
            data[dataType].push(entry);
          });
        });
        return { data };
      })
    );
  }
}
