import { ILocationsData } from '../../models/ILocationsData';

export interface ILocationsState {
  locations: ILocationsData[];
  marketLocations: ILocationsData[];
  loaded: boolean;
  marketLoaded: boolean;
}

export const initialLocationsState: ILocationsState = {
  locations: [],
  marketLocations: [],
  loaded: false,
  marketLoaded: false
};

export function getInitialLocationsState(): ILocationsState {
  return initialLocationsState;
}
