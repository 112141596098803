import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ITrendsCopy } from '../../../../../models/ITrendsCopy';
import { TrendCardTypeEnumShortHand } from '../../../../../models/ITrendsTableData';
import { ThemeService } from '../../../../../services/theme.service';
import { selectTrendsCopyByKey } from '../../../../../store/selectors/market-trends.selector';
import { IAppState } from '../../../../../store/state/app.state';

@Component({
  selector: 'app-trend-chart-description',
  templateUrl: './trend-chart-description.component.html',
  styleUrls: ['./trend-chart-description.component.scss']
})
export class TrendChartDescriptionComponent implements OnInit {
  @Input() title: string;
  @Input() typographySize: 'small' | 'medium' | 'large' = 'medium';
  @Input() chartShortHand: TrendCardTypeEnumShortHand;
  @Input() isSir: boolean;
  description$: Observable<ITrendsCopy>;

  constructor(private store: Store<IAppState>, private themeSvc: ThemeService) {
    this.isSir = this.themeSvc.isSIR();
  }

  ngOnInit(): void {
    if (this.chartShortHand !== TrendCardTypeEnumShortHand.custom) {
      this.description$ = this.store.select(selectTrendsCopyByKey(TrendCardTypeEnumShortHand[this.chartShortHand]));
    }
  }
}
