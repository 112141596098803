import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { FilterService } from '../../services/filter.service';
import { FetchLocations, FetchMarketLocations } from '../actions/locations.action';
import { FetchMLS, FetchMLSSuccess, MLSActionsActionTypes, SetMLS, SetMlsSuccess } from '../actions/mls.action';

@Injectable()
export class MLSEffect {
  fetchMLS$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchMLS>(MLSActionsActionTypes.FetchMLS),
      switchMap(() => this.filterService.getMLS()),
      map(mls => new FetchMLSSuccess(mls))
    )
  );

  setMls$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SetMLS>(MLSActionsActionTypes.SetMLS),
      switchMap(action => {
        return [
          new FetchLocations(action.payload.mlsId),
          new FetchMarketLocations(action.payload.mlsId),
          new SetMlsSuccess(action.payload)
        ];
      })
    )
  );

  constructor(private _actions$: Actions, private filterService: FilterService) {}
}
