import { IReportDetail } from './IReportDetail';

export class ISocialData {
  tags: string;
  description: string;
  title: string;
  image: string;
  reportId: string;
  oktaid: string;
  encryptedid: string;
  reportDetails: IReportDetail;
  cbPosition: number;
}
