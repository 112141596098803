import { ReportTypeLongStrEnum } from './ReportTypes';

export class ExportOption {
  label: string;
  formControlName: string;
  reportPagesAllowed: (ReportTypeLongStrEnum | 'All')[];

  constructor(label: string, formControlName: string, reportPagesAllowed = null) {
    this.label = label;
    this.formControlName = formControlName;
    this.reportPagesAllowed = reportPagesAllowed;
  }
}
