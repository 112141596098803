import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, switchMap } from 'rxjs/operators';
import { IWootric } from '../../models/IWootric';
import { UserDataService } from '../../services/userData.service';
import { FetchWootric, FetchWootricSuccess, WootricActionsActionTypes } from '../actions/wootric.action';

@Injectable()
export class WootricEffect {
  fetchWootric$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchWootric>(WootricActionsActionTypes.FetchWootric),
      switchMap(() => this._userDataService.getWootricData()),
      mergeMap((data: IWootric) => [new FetchWootricSuccess(data)])
    )
  );

  constructor(private _actions$: Actions, private _userDataService: UserDataService) {}
}
