import { format } from 'date-fns';

export class IMarketTrendsFilters {
  state: TrendsFilters;

  constructor(
    oktaid: string = '',
    mlsid: string[] = [],
    city: number[] = [],
    county: number[] = [],
    zip: number[] = [],
    mlsArea: number[] = [],
    schoolDistrict: number[] = [],
    subDivision: number[] = [],
    priceMin: number = 0,
    priceMax: number = 999999999,
    propertyType: number[] = [1, 2, 9],
    lastMonth: number = new Date().getMonth() > 0 ? new Date().getMonth() : 12,
    year: number = new Date().getMonth() > 0 ? new Date().getFullYear() : new Date().getFullYear() - 1,
    endDate: string = format(new Date(year, lastMonth - 1, 1), 'MM/yyyy'),
    isReo: boolean = false
  ) {
    this.state = {
      oktaid: oktaid,
      mlsid: mlsid,
      city: city,
      county: county,
      zip: zip,
      mlsArea: mlsArea,
      schoolDistrict: schoolDistrict,
      subDivision: subDivision,
      propertyType: propertyType,
      priceMax: priceMax,
      priceMin: priceMin,
      endDate: endDate,
      isReo: isReo
    };
  }
}

export interface TrendsFilters {
  oktaid?: string;
  mlsid?: string[];
  city?: number[];
  county?: number[];
  zip?: number[];
  mlsArea?: number[];
  schoolDistrict?: number[];
  subDivision?: number[];
  propertyType?: number[] | string[];
  priceMax?: number;
  priceMin?: number;
  endDate?: string;
  isReo?: boolean;
}
