import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Store } from '@ngrx/store';
import { ICustomGraphSetting } from 'app/store/state/custom-report.state';
import { format } from 'date-fns';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ICustomChartData } from '../../../../models/ICustomChartData';
import { DataRow, ICustomDataTypeByNumEnum, ICustomDataTypeEnum, ICustomDataTypeLabelEnum } from '../../../../models/ICustomDataTypeEnum';
import { ReportPageTypeShortStrEnum } from '../../../../models/ReportTypes';
import { CustomReportService } from '../../../../services/custom-report.service';
import { ReportService } from '../../../../services/report.service';
import { ThemeService } from '../../../../services/theme.service';
import {
  AdjustCustomReportDataTypes,
  AdjustCustomReportDisplayAs,
  SetDataTableShowAll
} from '../../../../store/actions/custom-report.action';
import {
  CustomReportAxis,
  CustomReportDataDetails,
  CustomReportDataTypes,
  CustomReportError,
  CustomReportLoaded,
  CustomReportLoading,
  DisplayAllData
} from '../../../../store/selectors/custom-graph.selector';
import { selectCurrentMLS } from '../../../../store/selectors/mls.selector';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-custom-graph-page',
  templateUrl: './custom-graph-page.component.html',
  styleUrls: ['./custom-graph-page.component.scss']
})
export class CustomGraphPageComponent implements OnInit {
  data$: Observable<ICustomChartData>;
  cardAsyncObject$: Observable<{ typeArr: string[]; stringDescription: string; yAxisLabels: string[] }>;
  chartAxis$: Observable<(0 | 1)[]>;
  customReportError$: Observable<boolean>;
  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  tableData$: Observable<{ headerData: string[]; rowData: DataRow[] }>;
  dataTypes$: Observable<[number, number]>;
  showTrendLines = true;
  trendLineShowStatus = true;
  disableTrendLines = false;
  graphTitle: string;
  cardData: { typeArr: string[]; stringDescription: string; yAxisLabels: string[] };
  data: ICustomChartData;
  isThreePointChart = false;

  constructor(
    private customReportSvc: CustomReportService,
    private reportServ: ReportService,
    private themeSvc: ThemeService,
    private store: Store<IAppState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select(CustomReportLoading);
    this.loaded$ = this.store.select(CustomReportLoaded);
    this.customReportError$ = this.store.select(CustomReportError);
    this.chartAxis$ = this.store.select(CustomReportAxis);
    this.data$ = this.getData$();
    this.dataTypes$ = this.store.select(CustomReportDataTypes);

    const obv = combineLatest([this.store.select(CustomReportDataDetails), this.store.select(DisplayAllData), this.dataTypes$]);
    this.tableData$ = obv.pipe(
      map(data => {
        const [report, showAll, dataTypes] = data;
        const d = this.customReportSvc.formatTableData(report?.reportData, showAll, dataTypes);
        return { headerData: d.headerData, rowData: d.rowData };
      })
    );

    this.store.select(CustomReportDataTypes).subscribe(data => {
      const typeArr = data.map(d => ICustomDataTypeEnum[ICustomDataTypeByNumEnum[d]]);
      const stringDescription = typeArr.join(' x ');
      this.graphTitle = typeArr.join(' x ');
      this.cardData = { typeArr, stringDescription, yAxisLabels: this.customReportSvc.getYAxisLabels(data) };
    });

    this.data$.subscribe(data => {
      this.data = data;
    });
  }

  private getData$() {
    return this.customReportSvc.setChartDataData();
  }

  refreshPage() {
    window.location.reload();
  }

  exportPdf() {
    const isSir = this.themeSvc.isSIR();
    this.reportServ.exportPdfPreview(ReportPageTypeShortStrEnum.custom, isSir ? 'SIR' : 'CBR');
  }

  exportCSV() {
    this.downloadTableData(true);
  }

  exportXLS() {
    this.downloadTableData(false);
  }

  private downloadTableData(isCSV: boolean) {
    const mls = this.store.select(selectCurrentMLS);
    const reportData = this.store.select(CustomReportDataDetails);
    combineLatest([reportData, mls])
      .pipe(take(1))
      .subscribe(data => {
        const [report, mls] = data;
        const reportData = report.reportData;
        let colHeaders = ['Description'];
        let name = `Custom-Report-Data-${mls.mlsId}`;
        reportData.forEach((item, index) => {
          const date = new Date(reportData[index].period);
          const newDate = format(date, 'MMM-yy');
          colHeaders.push(newDate);
        });
        let customCSV = colHeaders.join(',') + '\r\n';
        for (const key in ICustomDataTypeByNumEnum) {
          if (ICustomDataTypeByNumEnum.hasOwnProperty(key) && !isNaN(Number(key))) {
            const element = ICustomDataTypeByNumEnum[key] as keyof typeof ICustomDataTypeByNumEnum;
            customCSV += `${ICustomDataTypeEnum[element]},`;
            reportData.forEach(x => {
              customCSV += this.roundToTwoDecimal(x[element]) + ',';
            });
            customCSV += '\r\n';
          }
        }
        if (isCSV) {
          ReportService.generateCSVLink(customCSV, name);
        } else {
          ReportService.generateJsonToXlsx(
            customCSV.split('\r\n').map(csv => {
              return csv.split(',');
            }) as any,
            name,
            true
          );
        }
      });
  }

  private roundToTwoDecimal(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  toggleAllData($event: MatCheckboxChange) {
    this.store.dispatch(new SetDataTableShowAll($event.checked));
  }

  dataPointSelectedHandler(value: ICustomGraphSetting): void {
    this.showTrendLines = this.trendLineShowStatus;
    this.disableTrendLines = false;
    this.store.dispatch(new AdjustCustomReportDataTypes(value));
    this.customReportSvc.setThreePointChartData(null);
  }

  displayDataPointSelectedHandler(value: ICustomGraphSetting): void {
    this.showTrendLines = this.trendLineShowStatus;
    this.disableTrendLines = false;
    this.store.dispatch(new AdjustCustomReportDisplayAs(value));
    this.customReportSvc.setThreePointChartData(null);
  }

  threePointHandler(dataPointIds: string[]) {
    this.showTrendLines = false;
    this.disableTrendLines = true;
    this.cardData.typeArr = dataPointIds.map(d => ICustomDataTypeEnum[d]);
    this.graphTitle = this.cardData.typeArr.join(' x ');
    this.cardData.yAxisLabels = this.customReportSvc.getYAxisLabels(dataPointIds.map(dataPointId => ICustomDataTypeByNumEnum[dataPointId]));
    this.data.chartTypesConverted = ['Column', 'Column', 'Line'];
    this.data.dataPoints[0] = this.customReportSvc.getChartDataPointsPerDataType(ICustomDataTypeByNumEnum[dataPointIds[0]], this.data);
    this.data.dataPoints[1] = this.customReportSvc.getChartDataPointsPerDataType(ICustomDataTypeByNumEnum[dataPointIds[1]], this.data);
    this.data.dataPoints[2] = this.customReportSvc.getChartDataPointsPerDataType(ICustomDataTypeByNumEnum[dataPointIds[2]], this.data);
    this.data.axisLabels = this.cardData.yAxisLabels;
    this.customReportSvc.setThreePointChartData({
      data: this.data,
      cardData: this.cardData,
      graphDataTypes: dataPointIds.map((dataPointId, index) => {
        return {
          seriesTypes: index === 2 ? 'Line' : 'Column',
          dataKey: dataPointId,
          axisLabel: this.cardData.yAxisLabels[index]
        };
      })
    });
  }

  setSelectedTrendLine(value): void {
    this.trendLineShowStatus = value.checked;
  }
}
