<div *ngIf="!data.minimal" class="mls-popup-background" [class.mls-popup-background-sir]="data.isSIR">
  <div class="sub-header-mls">Welcome to</div>
  <div class="logo"></div>
  <div class="header-MLS">Market Quest</div>
</div>
<div class="modal-container">
  <p class="title">Select an MLS</p>
  <div class="mls-info">
    <div class="info-text">{{ modalDescription }}</div>
    <mat-form-field [formGroup]="mlsForm" appearance="outline">
      <mat-label>Select MLS</mat-label>
      <mat-select
        [compareWith]="compareFn"
        [formControlName]="filtersV2Enum.mlsid"
        panelClass="mls-selection-modal-dropdown"
        disableOptionCentering
      >
        <mat-option
          *ngFor="let mls of data.mlsList"
          [matTooltipDisabled]="!this.tabletPlus"
          [matTooltipShowDelay]="300"
          [value]="mls"
          class="touch"
          matTooltip="{{ mls.legalName }}"
          matTooltipPosition="above"
          >{{ mls.legalName }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-checkbox (change)="checkboxEvent($event)" [checked]="this.saveMLS" [disableRipple]="true" color="primary">
      <span>Save this MLS as the default everytime you log in.</span>
    </mat-checkbox>
  </div>
  <div class="footer">
    <button [disabled]="!mlsForm.get(filtersV2Enum.mlsid).value" [mat-dialog-close]="this.payload" color="primary" mat-raised-button>
      Continue
    </button>
  </div>
</div>
