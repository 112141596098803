<div [formGroup]="paging" class="sort">
  <mat-form-field appearance="outline" class="card-controls-sort">
    <mat-label>Sort By</mat-label>
    <mat-select formControlName="sortAscending">
      <mat-option [value]="true">Location (0-9) (A-Z)</mat-option>
      <mat-option [value]="false">Location (Z-A) (9-0)</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div>
  <button [matMenuTriggerFor]="menu" color="primary" mat-flat-button>
    Download Data
    <span class="material-icons csv">
    download
    </span>
  </button>
  <mat-menu #menu>
    <button (click)="download('xlsx')" disableRipple="true" mat-menu-item>
      Download .xlsx
    </button>
    <button (click)="download('csv')" disableRipple="true" mat-menu-item>
      Download .csv
    </button>
  </mat-menu>
</div>
