import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { GTMService } from '../../services/gtm.service';
import { GtmActionsTypes, SnapshotGtm } from '../actions/gtm.action';
import { IAppState } from '../state/app.state';

@Injectable()
export class GtmEffect {
  gtmSnapshot$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SnapshotGtm>(GtmActionsTypes.SnapshotGtm),
        map(action => {
          this._gtmService.snapshotGTM(action.payload);
        })
      ),
    { dispatch: false }
  );

  exportClaimsSnapshot$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SnapshotGtm>(GtmActionsTypes.ExportClaimsGtm),
        map(action => {
          this._gtmService.claimsGTM(action.payload);
        })
      ),
    { dispatch: false }
  );

  exportMarketsSnapshot$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SnapshotGtm>(GtmActionsTypes.ExportMarketGtm),
        map(action => {
          this._gtmService.marketGTM(action.payload);
        })
      ),
    { dispatch: false }
  );

  constructor(private _actions$: Actions, private _store: Store<IAppState>, private _gtmService: GTMService) {}
}
