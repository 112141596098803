import { createSelector } from '@ngrx/store';
import { TrendCardTypeEnumShortHand } from '../../models/ITrendsTableData';
import { IAppState } from '../state/app.state';
import { IMarketTrendsState } from '../state/market-trends.state';

const MarketTrends = (state: IAppState) => state.marketTrends;

export const selectTrendsData = createSelector(MarketTrends, (state: IMarketTrendsState) => state.trendsReportDetails);

export const selectTrendsAgentData = createSelector(
  MarketTrends,
  (state: IMarketTrendsState) => state.trendsReportDetails.agentReportEntity
);

export const selectTrendsTableData = createSelector(MarketTrends, (state: IMarketTrendsState) => state.trendsReportDetails.trendstabledata);

export const selectTrendsTableDataType = createSelector(MarketTrends, (state: IMarketTrendsState) => state.chartDataType);

export const selectTrendsDataHeader = createSelector(
  MarketTrends,
  (state: IMarketTrendsState) => state.trendsReportDetails.markettrendsheader
);

export const selectTrendsDataLoaded = createSelector(MarketTrends, (state: IMarketTrendsState) => state.loaded);

export const selectTrendsDataHasError = createSelector(MarketTrends, (state: IMarketTrendsState) => state.hasError);

export const selectTrendsDataLoading = createSelector(MarketTrends, (state: IMarketTrendsState) => state.loading);

export const selectTrendsGraphData = createSelector(MarketTrends, (state: IMarketTrendsState) => state.trendsReportDetails.trendsgraphdata);

export const selectTrendsGraphTableData = createSelector(
  MarketTrends,
  (state: IMarketTrendsState) => state.trendsReportDetails.trendsgraphtables
);

export const selectTrendsPdfOptions = createSelector(MarketTrends, (state: IMarketTrendsState) => state.pdfState.options);

export const selectTrendsCopyByKey = (key: TrendCardTypeEnumShortHand) =>
  createSelector(MarketTrends, (state: IMarketTrendsState) =>
    state.trendsReportDetails.trendscopy?.find(page => page.key === TrendCardTypeEnumShortHand[key])
  );

export const selectTrendsReportFilters = createSelector(MarketTrends, (state: IMarketTrendsState) => state.trendsFilters.state);

export const selectTrendsCountyCityZipFilter = createSelector(MarketTrends, (state: IMarketTrendsState) => {
  return {
    city: state.trendsFilters.state.city,
    county: state.trendsFilters.state.county,
    zip: state.trendsFilters.state.zip,
    mlsArea: state.trendsFilters.state.mlsArea,
    schoolDistrict: state.trendsFilters.state.schoolDistrict,
    subDivision: state.trendsFilters.state.subDivision
  };
});
