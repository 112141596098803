import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectMarketAPIResultsEmpty, selectMarketAPIResultsReady } from 'app/store/selectors/market-position.selector';
import { cloneDeep } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SocialImageDirective } from '../../../../../directives/social-image.directive';
import { IPageTypes, PageConfig } from '../../../../../models/IPageTypes';
import { EncryptedReportData } from '../../../../../models/IReportPageData';
import { ReportPageTypeShortStrEnum } from '../../../../../models/ReportTypes';
import { GTMService } from '../../../../../services/gtm.service';
import { OptionsService } from '../../../../../services/options.service';
import { ReportDetailService } from '../../../../../services/report-details.service';
import { ReportService } from '../../../../../services/report.service';
import { ShareSocialAndEmailService } from '../../../../../services/share-social-and-email.service';
import { UserSettingsService } from '../../../../../services/user-settings.service';
import { selectAllReportPageData } from '../../../../../store/selectors/reports.selectors';
import { IAppState } from '../../../../../store/state/app.state';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent implements OnInit, OnDestroy {
  @ViewChild(SocialImageDirective) imageContainer: SocialImageDirective;
  @Input() pageType: IPageTypes;
  @Input() sirBrand;
  @Input() reportId: string;
  btnText: string;
  data: EncryptedReportData;
  loaded$: Observable<{ ready: boolean }>;
  btnConfigOpt: PageConfig;
  private dataEmpty$: Observable<boolean>;
  private subs = new Subscription();
  private isConsolidatedMLS = false;
  private reportsReady$: Observable<boolean>;

  constructor(
    private shareSocialAndEmailService: ShareSocialAndEmailService,
    private store: Store<IAppState>,
    private reportDetailService: ReportDetailService,
    private reportService: ReportService,
    private options: OptionsService,
    private userSettingsSvc: UserSettingsService,
    private gtmService: GTMService
  ) {
    this.dataEmpty$ = this.store.select(selectMarketAPIResultsEmpty);
    this.reportsReady$ = this.store.select(selectMarketAPIResultsReady);
  }

  ngOnInit() {
    this.btnConfigOpt = ShareSocialAndEmailService.fetchActionButtonConfig(this.pageType);
    this.btnText = this.pageType === ReportPageTypeShortStrEnum.claims ? 'Share Report' : 'Export Report';

    this.loaded$ = this.reportsReady$.pipe(
      map(d => {
        const ready = this.pageType === ReportPageTypeShortStrEnum.claims ? true : d;
        return { ready };
      })
    );

    this.subs.add(
      this.store.select(selectAllReportPageData).subscribe(storeData => {
        this.data = cloneDeep(storeData);
        if (this.pageType === ReportPageTypeShortStrEnum.claims) {
          this.isConsolidatedMLS = this.data.reportDetail.reportHeaderEntity.mlsId === 'COSC';
          this.data.reportDetail.reportGraphEntities = this.reportDetailService.remapGraphEntryNames(
            this.data.reportDetail,
            this.data.cbposition
          );
        }
      })
    );
  }

  openEmail() {
    this.shareSocialAndEmailService.openEmail(this.reportId, this.pageType);
  }

  openShare() {
    this.shareSocialAndEmailService.openSocial(
      this.data.msUnoReportId,
      this.data.oktaId,
      this.data.reportDetail,
      this.data.encryptedData,
      this.data.cbposition,
      this.options.baseUrl
    );
  }

  previewWebsite() {
    this.gtmService.claimsGTM('exportClaimsReport');
    window.open(`share/market/${this.data.encryptedData}`, '_blank');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  openExportPdfPreview() {
    this.reportService.exportPdfPreview(this.pageType, this.sirBrand ? 'SIR' : 'CBR');
  }
}
