import { Injectable } from '@angular/core';
import { INavButton } from '../models/INavButton';
import { SCREEN_SIZES } from '../models/IScreenSizes';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  constructor() {}

  private static bp(screenWidth) {
    switch (true) {
      case screenWidth <= 600:
        return SCREEN_SIZES.mobile;
      case screenWidth >= 600 && screenWidth <= 960:
        return SCREEN_SIZES.tablet;
      case screenWidth > 960:
        return SCREEN_SIZES.desktop;
      default:
        break;
    }
  }

  currentScreenBreakpointMatches(navButton: INavButton) {
    if (navButton.breakPoints.includes(SCREEN_SIZES.all)) {
      return true;
    }
    return navButton.breakPoints.some(width => {
      return width === ResponsiveService.bp(screen.width);
    });
  }
}
