<div>
  <div class="error-highlight"></div>
  <div class="header">
    <p class="title">MLS Access Error</p>
  </div>
  <div class="message">
    <div [ngSwitch]="this.userType">
      <ng-container *ngSwitchCase="'CBR'">
        <p>
          Please verify with your local office staff that your Agent MLS ID is entered correctly within your CBHomes Admin profile. Please
          note, that any changes made to the Agent MLS ID will take up to 24 hours to process in the Market Quest Application.
        </p>
        <p>If the issue persists, please contact the Help Desk at <a href="tel:877-678-4357">877-678-HELP (877-678-4357).</a></p>
      </ng-container>
      <ng-container *ngSwitchCase="'SIR'">
        <p>Sorry! You do not have an MLS assigned in order to use Market Quest.</p>
        <p>Please contact the Help Desk at <a href="tel:877-678-4357">877-678-HELP (877-678-4357).</a></p>
      </ng-container>
      <ng-container *ngSwitchCase="'CBA'">
        <p>
          Please verify with your local office staff that your company has signed up to the Agent MLS ID in Terradatum. Please note, that
          any changes made to the Agent MLS ID will take up to 24 hours to process in the Market Quest Application.
        </p>
        <p>
          If signed up in Terradatum and the issue persists, please visit
          <a href="https://realogy.service-now.com">https://realogy.service-now.com</a> and submit a support ticket.
        </p>
      </ng-container>
    </div>
  </div>
  <div class="footer">
    <div class="button right">
      <button *ngIf="this.userType === 'CBR'" (click)="closeTab()" color="primary" mat-raised-button>
        Back to Desk
      </button>
    </div>
  </div>
</div>
