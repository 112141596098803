<div *ngIf="footerData | async as trendsHeaderData" class="footer">
  <span class="typography-disclaimer typography-grey">
    <ng-container *ngIf="!trendsHeaderData.isSir; else sirDisclaimer">
      Based on information from {{ trendsHeaderData.legalName }} for the period {{ trendsHeaderData.displayEndDate }}. Source data is deemed
      reliable but not guaranteed. Real estate agents affiliated with Coldwell Banker Realty are independent contractor sales associates,
      not employees. ©{{ year }} Coldwell Banker. All Rights Reserved. Coldwell Banker and the Coldwell Banker logos are trademarks of
      Coldwell Banker Real Estate LLC. The Coldwell Banker® System is comprised of company owned offices which are owned by a subsidiary of
      Realogy Brokerage Group LLC and franchised offices which are independently owned and operated. The Coldwell Banker System fully
      supports the principles of the Fair Housing Act and the Equal Opportunity Act.
    </ng-container>
    <ng-template #sirDisclaimer>
      ©{{ year }} Sotheby’s International Realty. All Rights Reserved. Sotheby’s International Realty® is a registered trademark and used
      with permission. Each Sotheby’s International Realty office is independently owned and operated, except those operated by Sotheby’s
      International Realty, Inc. This material is based upon information which we consider reliable but because it has been supplied by
      third parties, we cannot represent that it is accurate or complete and it should not be relied upon as such. If your property is
      listed with a real estate broker, please disregard. It is not our intention to solicit the offerings of other real estate brokers. We
      are happy to work with them and cooperate fully. Equal Housing Opportunity.
    </ng-template>
  </span>
</div>
