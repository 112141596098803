import { ComponentType } from '@angular/cdk/overlay';
import {
  AfterViewChecked,
  Component,
  ComponentRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ExportPaneDirective } from '../../../directives/export-pane.directive';
import { ExportPreviewToggle } from '../../../store/actions/pdf.action';
import { pdfExportPreviewOpen } from '../../../store/selectors/pdf.selector';
import { IAppState } from '../../../store/state/app.state';

export interface ExportPreviewOpts {
  controlOnly: boolean;
  component: ComponentType<any>;
  isComplexTemplate?: boolean;
  showMarketPositionTable?: boolean;
  showOrientation?: boolean;
  reportType?: number;
  isSIR?: boolean;
  isClaims?: boolean;
}

export interface Conf {
  maxWidth?: string;
  maxHeight?: string;
  width?: string;
  height?: string;
  panelClass: string;
  disableClose: boolean;
  data: ExportPreviewOpts;
}

@Component({
  selector: 'app-export-preview',
  templateUrl: './export-preview.component.html',
  styleUrls: ['./export-preview.component.scss']
})
export class ExportPreviewComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild(ExportPaneDirective, { static: true }) p;

  constructor(
    @Inject(MAT_DIALOG_DATA) public opts: ExportPreviewOpts,
    private viewContainerRef: ViewContainerRef,
    private store: Store<IAppState>
  ) {}

  ngOnDestroy(): void {
    this.store.dispatch(new ExportPreviewToggle(false));
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.fitToParent(document.getElementById('zoom'), 0);
  }

  ngOnInit() {
    this.store.select(pdfExportPreviewOpen).subscribe(isOpen => {
      if (isOpen) {
        this.fillContentPanel(this.opts.component);
      }
    });
  }

  fillContentPanel(component: ComponentType<any>) {
    if (component) {
      const viewContainerRef = this.p?.viewContainerRef;
      viewContainerRef?.clear();
      const c: ComponentRef<typeof component> = viewContainerRef.createComponent(component);
      (c.instance as any).isClaims = this.opts.isClaims;
    }
  }

  ngAfterViewChecked() {
    this.fitToParent(document.getElementById('zoom'), 0);
  }

  fitToParent(element, margin) {
    const scale = this.scaleAmountNeededToFit(element, margin);
    element.style.transform = `translate(${margin}px, ${margin}px) scale(${scale})`;
  }

  scaleAmountNeededToFit(el, margin = 0) {
    const parentSize = {
      width: el.parentElement.clientWidth - margin * 2,
      height: el.parentElement.clientHeight - margin * 2
    };

    return Math.min(parentSize.width / el.clientWidth, parentSize.height / el.clientHeight);
  }
}
