import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { endOfMonth, subMonths } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BannerService } from '../../../../../services/banner.service';
import { ThemeService } from '../../../../../services/theme.service';
import { pdfExportData } from '../../../../../store/selectors/pdf.selector';
import { IAppState } from '../../../../../store/state/app.state';

@Component({
  selector: 'app-export-preview-footer',
  templateUrl: './export-preview-footer.component.html',
  styleUrls: ['./export-preview-footer.component.scss']
})
export class ExportPreviewFooterComponent implements OnInit {
  year: number;
  footerData: Observable<{ displayEndDate: string; isSir: boolean; legalName: string }>;

  constructor(private bannerSvc: BannerService, private store: Store<IAppState>, private themeSvc: ThemeService) {}

  disclaimerDates(currentDate) {
    const date = new Date(currentDate);
    const end = endOfMonth(date);
    const start = subMonths(date, 35);

    const a = [{ month: 'numeric' }, { day: 'numeric' }, { year: 'numeric' }];
    const thisYear = this.join(end, a, '/');
    const prevYear = this.join(start, a, '/');

    return `${prevYear} through ${thisYear}`;
  }

  join(time, opt, separator) {
    function format(m) {
      try {
        const f = new Intl.DateTimeFormat('en', m);
        return f.format(time);
      } catch (e) {
        console.log(e);
      }
    }

    return opt.map(format).join(separator);
  }

  ngOnInit(): void {
    this.footerData = this.store.select(pdfExportData).pipe(
      map(data => {
        const isSir = this.themeSvc.isSIR();
        const displayEndDate = this.disclaimerDates(data.displayDate);
        return { displayEndDate, isSir, legalName: data.legalName };
      })
    );
    this.year = this.bannerSvc.currentYear;
  }
}
