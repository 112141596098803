import { GalleryImage } from '../../models/GalleryImage';
import { IUser } from '../../models/IUser';

export interface IUserDataState {
  info: IUser;
  loaded: boolean;
  defaultImage: {
    loaded: boolean;
    sizes: GalleryImage[];
  };
}

export const initialUserDataState: IUserDataState = {
  info: {
    oktaId: '',
    userName: '',
    name: '',
    email: '',
    metro_Name: '',
    officeName: '',
    metro_Number: 0,
    brandCode: null,
    isNrt: false,
    roleName: '',
    avatar: undefined,
    contactType: 0,
    licenses: [],
    offices: [],
    teams: [],
    personMasterId: '',
    agentMasterId: ''
  },
  loaded: false,
  defaultImage: {
    loaded: false,
    sizes: []
  }
};

export function getInitialUserDataState(): IUserDataState {
  return initialUserDataState;
}
