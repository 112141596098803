<div class="modal-container">
  <h1 mat-dialog-title>Sign out of Market Quest</h1>
  <div mat-dialog-content>
    <p class="subtitle">Are you sure you want to sign out?</p>
  </div>
  <mat-dialog-actions class="justify-content-end d-flex btn-logout">
    <button (click)="close()" mat-button>Cancel</button>
    <button mat-flat-button color="primary" cdkFocusInitial (click)="signOut()">Sign Out</button>
  </mat-dialog-actions>
</div>
