<div class="block small-grid" [ngStyle]="{ 'background-color': backgroundColor }">
  <div class="branding-label">
    <span class="material-icons">{{ matIcon }}</span>
    <span>{{ label }}</span>
  </div>
  <ng-container *ngIf="isLocation; else filterValue">
    <ng-container *ngIf="locationValues; else allLocations">
      <ng-container *ngFor="let loc of locationValues">
        <div class="location-block d-flex flex-column">
          <span class="typography-sub typography-grey text-uppercase">{{ loc.areatype }}</span>
          <span class="typography-normal typography-black">{{ loc.locations }}</span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<ng-template #filterValue>
  <div class="typography-normal typography-black d-flex flex-column">
    <span>{{ value }}</span>
  </div>
</ng-template>
<ng-template #allLocations>
  <div class="location-block d-flex flex-column">
    <span class="typography-normal typography-black">All Locations</span>
  </div>
</ng-template>
