<div class="px-3 divider">
  <div class="typography-sub typography-grey text-uppercase">CONTACT</div>
  <div class="typography-normal typography-black">
    <div *ngIf="data.phone && data.contactType !== 1" class="icon-link">
      <span class="material-icons grey">phone</span>
      <span>{{ data.phone }}</span>
    </div>
    <div *ngIf="data.phone && data.contactType === 1" class="icon-link">
      <span class="material-icons grey">location_city</span>
      <span>{{ data.phone }}</span>
    </div>
    <div *ngIf="data.email" class="icon-link">
      <span class="material-icons grey">email</span>
      <span>{{ data.email }}</span>
    </div>
    <div *ngIf="data.websiteUrl" class="icon-link">
      <span class="material-icons grey">web_asset</span>
      <span>{{ data.websiteUrl | urlShorten: 'Website' }}</span>
    </div>
    <div *ngIf="data.address && data.brokerageName" class="icon-link address">
      <span class="material-icons grey">location_pin</span>
      <div class="d-flex flex-column">
        <span class="typography-normal typography-black">{{ data.brokerageName }}</span>
        <span class="typography-normal typography-black"> {{ data.address }}, {{ data.city }}, {{ data.stateCD }} {{ data.zip }}</span>
      </div>
    </div>
    <div *ngIf="data.otherPhone && data.contactType !== 1" class="icon-link">
      <span class="material-icons grey">location_city</span>
      <span>{{ data.otherPhone }}</span>
    </div>
    <div *ngIf="data.otherPhone && data.contactType === 1" class="icon-link">
      <span class="material-icons grey">fax</span>
      <span>{{ data.otherPhone }}</span>
    </div>
  </div>
</div>
