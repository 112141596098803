import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class DownloadService {

  constructor(private httpClient: HttpClient) {
  }

  public downloadFile(imagePath: string): Observable<Blob> {
    return this.httpClient.get(imagePath, {responseType: 'blob'});
  }
}
