import { IWootric } from '../../models/IWootric';

export interface IWootricState {
  info: IWootric;
  loaded: boolean;
}

export const initialWootricState: IWootricState = {
  info: {
    metroName: '',
    metroID: '',
    officeName: '',
    officeID: '',
    officeLocationID: '',
    oktaID: '',
    userType: '',
    emailAddress: '',
    createdAt: '',
    titles: ''
  },
  loaded: false
};

export function getInitialWootricState(): IWootricState {
  const storage: IWootricState = JSON.parse(localStorage.getItem('wootric'));
  if (storage && storage.info.oktaID) {
    return JSON.parse(localStorage.getItem('wootric'));
  }
  return initialWootricState;
}
