import { Component, Input, OnInit } from '@angular/core';
import { ICustomReportHeader } from '../../../../../models/ICustomReportHeader';

@Component({
  selector: 'app-custom-report-stats',
  templateUrl: './custom-report-stats.component.html',
  styleUrls: ['./custom-report-stats.component.scss']
})
export class CustomReportStatsComponent implements OnInit {
  @Input() stats: ICustomReportHeader;

  constructor() {}

  ngOnInit(): void {}
}
