import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent {
  @Input() noResultsMessage: string;
  @Input() buttonLabel = 'Reset Filters';
  @Input() showTimeoutIcon = false;
  @Input() showButton = true;
  @Output() ctaClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  onClick() {
    this.ctaClicked.emit(true);
  }
}
