import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Data} from '@angular/router';

@Component({
  selector: 'app-save-filters-dialog',
  templateUrl: './save-filters-dialog.component.html',
  styleUrls: ['./save-filters-dialog.component.scss']
})
export class SaveFiltersDialogComponent implements OnInit {
  reportName: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: Data) {
  }

  ngOnInit() {
    this.reportName = this.data.reportType === 0 ? '#1 Claims' : 'Market Position';
  }

}
