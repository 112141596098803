import { MarketSnapshotAction, MarketSnapshotActionsTypes } from '../actions/market-snapshot.action';
import { getInitialMarketSnapshotDataState, IMarketSnapshotState } from '../state/market-snapshot.state';

export function MarketSnapshotDataReducer(state = getInitialMarketSnapshotDataState(), action: MarketSnapshotAction): IMarketSnapshotState {
  switch (action.type) {
    case MarketSnapshotActionsTypes.FetchSnapshotData: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case MarketSnapshotActionsTypes.ResetSnapshotData: {
      return {
        ...state,
        snapshotData: null,
        loaded: false,
        hasError: false,
        defaultsLoaded: true
      };
    }

    case MarketSnapshotActionsTypes.FetchSnapshotDataSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        snapshotData: action.payload,
        hasError: false
      };
    }

    case MarketSnapshotActionsTypes.FetchSnapshotDataError: {
      return {
        ...state,
        loading: false,
        loaded: false,
        hasError: true
      };
    }

    case MarketSnapshotActionsTypes.UpdateUserSnapshotDefaultLocationToApi: {
      return {
        ...state
      };
    }

    case MarketSnapshotActionsTypes.ClearSnapshotLocationFilter: {
      return {
        ...state,
        loading: false,
        defaultsLoaded: false,
        filters: {
          ...state.filters,
          location: []
        }
      };
    }

    default:
      return state;
  }
}
