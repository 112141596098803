import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LocationDisplayEnum, LocationEnum, mpLocationTypes } from 'app/models/FilterData';
import { ILocationsData } from 'app/models/ILocationsData';
import { groupBy } from 'lodash-es';
import { ReportTypeEnum } from '../../../../models/ReportTypes';

interface LocationSection {
  type: string;
  locations: ILocationsData[];
  label?: string;
}

@Component({
  selector: 'app-filter-panel-locations',
  templateUrl: './filter-panel-locations.component.html',
  styleUrls: ['./filter-panel-locations.component.scss']
})
export class FilterPanelLocationsComponent implements OnInit, OnChanges {
  ReportTypeEnum = ReportTypeEnum;
  @Input() reportType?: ReportTypeEnum = null;
  @Input() multiSelect = true;
  @Input() locations: ILocationsData[] = [];
  @Input() locationGroup: UntypedFormGroup;
  @Output() locationsCleared: EventEmitter<ILocationsData[]> = new EventEmitter<ILocationsData[]>();
  locationBlocks: LocationSection[];
  LocationDisplayEnum = LocationDisplayEnum;
  hasSelectedLocs: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initLocationBlocks();
    this.hasSelectedLocs = this.locationGroup.get('locationFilters')?.value?.length > 0;
    this.locationGroup.get('locationFilters')?.valueChanges.subscribe((locations: ILocationsData[]) => {
      this.hasSelectedLocs = locations.length > 0;
    });
  }

  ngOnChanges(): void {
    this.initLocationBlocks();
  }

  initLocationBlocks(): void {
    const data = groupBy(this.locations, function(l) {
      return l.areaType;
    });
    const arr: LocationSection[] = [];
    mpLocationTypes.forEach(key => {
      arr.push({
        type: key,
        locations: data[LocationEnum[key]],
        label: key
      });
    });
    this.locationBlocks = arr;

    this.cdr.detectChanges();
  }

  checkboxHandler(): void {
    const selectedLocations = this.locations.filter(location => {
      return location['checked'];
    });

    this.locationGroup.get('locationFilters').patchValue(selectedLocations);
  }

  clearLocations = (): void => {
    this.locationsCleared.emit([]);
  };

  trackByFn(index) {
    return index;
  }
}
