import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IContactData, IContactResp } from '../models/IContactData';
import { selectOktaId, selectUserDataLoaded } from '../store/selectors/userData.selector';
import { IAppState } from '../store/state/app.state';
import { Branding } from '../store/state/user-settings.state';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  constructor(private http: HttpClient, private store: Store<IAppState>) {
    this.store
      .select(selectOktaId)
      .pipe(
        filter(value => !!value),
        take(1)
      )
      .subscribe(data => {
        this.oktaId = JSON.parse(JSON.stringify(data));
      });
  }

  private _oktaId: string;

  get oktaId(): string {
    return this._oktaId;
  }

  set oktaId(value: string) {
    this._oktaId = value;
  }

  getFieldsData(brandingType: 'Office' | 'Individual' | 'Team') {
    return this.store.select(selectUserDataLoaded).pipe(
      filter(v => !!v),
      take(1),
      switchMap(() => {
        return this.initializeIndividualFieldsFromAPI(brandingType);
      }),
      map(response => {
        return {
          contact: response.contactData,
          social: response.socialData,
          photo: response.photoData
        };
      })
    );
  }

  updateUserContactField(contactData: IContactData) {
    return combineLatest([this.updateContact(contactData), of(contactData)]);
  }

  getOfficeDetails(officeId: string) {
    return this.http.get<IContactResp>(`${environment.apiBaseUrl}/api/contact/GetUpdatedOffice/${this.oktaId}?officeMasterId=${officeId}`);
  }

  getTeamDetails(teamMasterId: string) {
    return this.http.get<IContactResp>(`${environment.apiBaseUrl}/api/contact/GetUpdatedTeam/${this.oktaId}?teamMasterId=${teamMasterId}`);
  }

  updateLicenseNbrToAPI(licenseNbr: string) {
    let lic = licenseNbr.split(' ');
    return this.http.get<IContactResp>(`${environment.apiBaseUrl}/api/contact/getlicensenbr/${this.oktaId}?nbr=${lic[1]}`);
  }

  private initializeIndividualFieldsFromAPI(brandingType: Branding) {
    return this.getContact(brandingType);
  }

  private getContact(type: Branding): Observable<IContactResp> {
    return this.http.get<IContactResp>(`${environment.apiBaseUrl}/api/contact/get${type}/${this.oktaId}`);
  }

  private updateContact(contactData: IContactData) {
    return this.http.post<boolean>(`${environment.apiBaseUrl}/api/contact/postContact/${this.oktaId}`, contactData);
  }

  updateContactTypeToAPI(brandingType: Branding) {
    return this.http.post<Branding>(
      `${environment.apiBaseUrl}/api/Contact/PostContactType/${this.oktaId}?contactType=${brandingType}`,
      null
    );
  }
}
