import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeaturesEnum } from '../models/IFeature';
import { FeatureService } from '../services/feature.service';

@Directive({
  selector: '[featureFlag]'
})
export class FeatureFlagDirective implements OnInit {
  @Input() featureFlag: FeaturesEnum;

  constructor(private featureSvc: FeatureService, private vcr: ViewContainerRef, private tpl: TemplateRef<any>) {}

  ngOnInit() {
    if (this.featureSvc.hasFlags(this.featureFlag)) {
      this.vcr.createEmbeddedView(this.tpl);
    }
  }
}
