import { AfterViewChecked, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IReportGraph } from '../../../../../models/IReportGraph';
import { EncryptedReportData } from '../../../../../models/IReportPageData';
import { DisclaimerService } from '../../../../../services/disclaimer.service';
import { ExportService } from '../../../../../services/export.service';
import { OptionsService } from '../../../../../services/options.service';
import { ImageTypes } from '../../../../../models/ImageTypes';
import { ReportService } from '../../../../../services/report.service';
import { ExportPreviewToggle } from '../../../../../store/actions/pdf.action';
import { pdfExportPreviewOpen, pdfOptions } from '../../../../../store/selectors/pdf.selector';
import { selectReportPageData } from '../../../../../store/selectors/reports.selectors';
import { IAppState } from '../../../../../store/state/app.state';
import { IPdfOptions } from '../../../../../store/state/pdf.state';
import { GalleryImage } from 'app/models/GalleryImage';

@Component({
  selector: 'app-sir-claims-export-preview',
  templateUrl: './s-i-r-claims-export-preview.component.html',
  styleUrls: ['./s-i-r-claims-export-preview.component.scss']
})
export class SIRClaimsExportPreviewComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() showLogo = true;
  @Input() hideChartNames = true;
  subs = new Subscription();
  pdfOpts$: Observable<IPdfOptions>;
  data$: Observable<{
    data: EncryptedReportData;
    maxValue: number;
    disclaimer: string;
    logo: string;
    graphData: {
      levelEntityName: string;
      levelEntityXtra: string;
      reportMeasureValue: number | string;
      formattedValue: number | string;
      isCb?: boolean;
      marketSharePercentage: number;
    }[];
  }>;
  scale: number;

  constructor(
    private store: Store<IAppState>,
    private disclaimer: DisclaimerService,
    private exportSvc: ExportService,
    private optionsService: OptionsService
  ) {}

  private static hideFirmName(idx: number) {
    return 'Firm ' + (10 + idx).toString(36).toUpperCase();
  }

  private static formatFirmName(opts: IPdfOptions, entity: IReportGraph, idx: number) {
    const trimmedName = ExportService.trimDataAfterParenthesis(entity.levelEntityName + entity.levelEntityXtra);
    return opts.showFirmNames ? trimmedName : !idx ? trimmedName : SIRClaimsExportPreviewComponent.hideFirmName(idx);
  }

  private static pickMaxValue(data: EncryptedReportData) {
    return data.reportDetail.reportGraphEntities.reduce(
      (max, p) => (p.reportMeasureValue > max ? p.reportMeasureValue : max),
      data.reportDetail.reportGraphEntities[0].reportMeasureValue
    );
  }

  fitColumnHeight() {
    let maxH;
    document.querySelectorAll('.series').forEach((col: HTMLElement) => {
      let isLeader = col.getAttribute('leader');
      const columnHeight = col.getAttribute('h');
      const labelHeight = col.querySelector('.series-label').clientHeight;
      col.style.height = columnHeight + '%';
      if (maxH) {
        col.style.height = Math.min(650, maxH * (parseInt(columnHeight) / 100)) + 'px';
      } else {
        col.style.height = Math.min(650, col.clientHeight - labelHeight) + 'px';
      }
      if (isLeader) {
        maxH = col.clientHeight;
      }
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ExportPreviewToggle(false));
  }

  ngAfterViewChecked() {
    this.fitColumnHeight();
  }

  ngOnInit(): void {
    this.store.select(pdfExportPreviewOpen).subscribe(isOpen => {
      if (isOpen) {
        this.fitColumnHeight();
      }
    });

    this.data$ = combineLatest([this.store.select(selectReportPageData), this.store.select(pdfOptions)]).pipe(
      map(subscription => {
        const [data, opts] = subscription;
        const maxValue = SIRClaimsExportPreviewComponent.pickMaxValue(data);
        const graphData = this.formatGraphData(data, opts);
        const disclaimer = this.disclaimer.formatDisclaimerSIR(data.reportDetail.reportHeaderEntity);
        const logo = this.getLogo(data.reportDetail?.imagePromoEntities);

        return { data, maxValue, graphData, disclaimer, logo };
      })
    );

    this.pdfOpts$ = this.store.select(pdfOptions);
  }

  private formatGraphData(data: EncryptedReportData, opts: IPdfOptions) {
    return data.reportDetail.reportGraphEntities.map((entity, idx) => {
      return {
        ...entity,
        levelEntityName: SIRClaimsExportPreviewComponent.formatFirmName(opts, entity, idx),
        formattedValue: data.reportDetail.reportHeaderEntity.msTypeName.toLowerCase().includes('unit')
          ? entity.reportMeasureValue
          : this.optionsService.numFormatter(entity.reportMeasureValue, 2)
      };
    });
  }

  getHeight(progress: number | string, total: number): number {
    return (parseFloat(progress + '') / total) * 100;
  }

  private getLogo(images: GalleryImage[]): string {
    if (images.length > 0) {
      return (
        this.optionsService.getImagePathForReport() +
        images.find(image => {
          return image.imageSize == ImageTypes['websiteDba'];
        }).imageURL
      );
    }
    return '';
  }
}
