<ng-container *ngIf="appData$ | async as data">
  <ng-container *ngIf="data.loaded">
    <ng-container *ngIf="!isSIR; else sirBlock">
      <img [src]="data.brandInfo.header_logo_image" alt="Application Logo" class="app-logo" />
      <span class="app-title">{{ data.title | uppercase }}</span>
    </ng-container>
    <ng-template #sirBlock>
      <img [src]="baseURL + 'mq-uno/webpage/marketquest-sir.svg'" alt="Market Quest Logo" class="h-100" />
      <div class="v-divider"></div>
      <img [src]="data.brandInfo.header_logo_image" alt="Application Logo" class="h-100" />
    </ng-template>
    <app-terradatum-logo *ngIf="isTerradatum" class="ps-3"></app-terradatum-logo>
  </ng-container>
</ng-container>
