import { Component, HostBinding, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserDataService } from 'app/services/userData.service';
import { filter } from 'rxjs/operators';
import { INavButton } from '../../models/INavButton';
import { NavigationService } from '../../services/navigation.service';
import { OptionsService } from '../../services/options.service';
import { ThemeService } from '../../services/theme.service';
import { ToggleSideBarNav } from '../../store/actions/menus.action';
import { selectBrandCode } from '../../store/selectors/applicationData.selector';
import { IAppState } from '../../store/state/app.state';

@Component({
  selector: 'app-mq-header',
  templateUrl: './mq-header.component.html',
  styleUrls: ['./mq-header.component.scss']
})
export class MqHeaderComponent implements OnInit {
  @HostBinding('class') classes = 'flex-shrink-0';
  private helpBtn: INavButton;
  header: boolean;
  isSIR: boolean;
  isTerradatum: boolean;

  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private optionsService: OptionsService,
    private themeService: ThemeService,
    private userDataService: UserDataService,
    private navigationService: NavigationService
  ) {
    this.helpBtn = this.navigationService.headerAvatarMenuButtons.find(btn => {
      return btn.label.toLowerCase().includes('help');
    });
  }

  ngOnInit() {
    this.store
      .select(selectBrandCode)
      .pipe(filter(v => !!v))
      .subscribe(() => {
        this.isSIR = this.themeService.isSIR();
        this.isTerradatum = this.userDataService.getBusinessUnit() === 'RFG';
      });

    this.optionsService.headUrlsExcluded = ['/share', '/logout'];
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (event) {
        this.header = !this.optionsService.headUrlsExcluded.some(url => {
          return event.urlAfterRedirects.includes(url);
        });
      }
    });
  }

  openSideBar() {
    this.store.dispatch(new ToggleSideBarNav());
  }

  goToHelp() {
    window.open(this.helpBtn.urlPath, '_blank');
  }
}
