import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilterState, FiltersV2Enum } from 'app/models/FilterData';
import { IMlsData } from 'app/models/IMlsData';
import { Subscription } from 'rxjs';

interface Data {
  mlsList: IMlsData[];
  minimal: boolean;
  selectedMls: IMlsData;
  isSIR?: boolean;
}

@Component({
  selector: 'app-mls-selection-modal',
  templateUrl: './mls-selection-modal.component.html',
  styleUrls: ['./mls-selection-modal.component.scss']
})
export class MlsSelectionModalComponent implements OnInit, OnDestroy {
  @Output() claimsFilterValues = new EventEmitter<FilterState>();
  mlsForm: UntypedFormGroup;
  filtersV2Enum = FiltersV2Enum;
  saveMLS = true;
  payload = {
    saveMLS: true,
    selectedMLS: { legalName: '', mlsId: '', databaseName: '' }
  };
  tabletPlus: boolean;
  modalDescription: string;
  private subs = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: Data, private fb: UntypedFormBuilder, private breakpointObserver: BreakpointObserver) {
    this.mlsForm = this.fb.group({
      [FiltersV2Enum.mlsid]: [data.selectedMls]
    });
    this.modalDescription = data.minimal
      ? `Select the MLS you would like to use.
      Please note you can change this later in the filters.`
      : `To begin, select the MLS you would like to use.
      Please note you can change this later in the filters.`;
    this.payload.selectedMLS = data.selectedMls;
  }

  ngOnInit() {
    this.subs.add(
      this.breakpointObserver.observe('(min-width: 960px)').subscribe(result => {
        this.tabletPlus = result.matches;
      })
    );
    this.subs.add(
      this.mlsForm.valueChanges.subscribe(result => {
        this.payload.selectedMLS = result.mlsid;
      })
    );
  }

  compareFn(mls1: IMlsData, mls2: IMlsData): boolean {
    return mls1 && mls2 ? mls1.mlsId === mls2.mlsId : mls1 === mls2;
  }

  checkboxEvent($event: MatCheckboxChange) {
    this.payload.saveMLS = $event.checked;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
