import { PdfActions, PdfActionsActionTypes } from '../actions/pdf.action';
import { getInitialPdfState, IPdfState } from '../state/pdf.state';

export function PdfReducer(state = getInitialPdfState(), action: PdfActions): IPdfState {
  switch (action.type) {
    case PdfActionsActionTypes.ExportPreviewToggle: {
      return {
        ...state,
        previewOpen: action.payload
      };
    }

    case PdfActionsActionTypes.UpdatePdfOptions: {
      return {
        ...state,
        opts: action.payload
      };
    }

    case PdfActionsActionTypes.ResetPdfOptions: {
      return {
        ...state,
        opts: getInitialPdfState().opts
      };
    }

    case PdfActionsActionTypes.FetchReportPDF: {
      return {
        ...state,
        pending: true
      };
    }
    case PdfActionsActionTypes.FetchReportPDFSuccess: {
      return {
        ...state,
        pending: false
      };
    }
    case PdfActionsActionTypes.SetExportPreviewData: {
      return {
        ...state,
        pdfPreviewData: action.payload
      };
    }
    case PdfActionsActionTypes.ResetExportPreviewData: {
      return {
        ...state,
        pdfPreviewData: null
      };
    }
    default:
      return state;
  }
}
