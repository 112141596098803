<div *appSocialBlock="data" class="contact px-3">
  <div class="typography-sub typography-grey text-uppercase pb-3">Connect with me</div>
  <div class="typography-normal typography-black">
    <div *ngIf="data.linkedInLink" class="icon-link pb-2">
      <svg height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
        <title>LinkedIn icon</title>
        <path
          d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"
        />
      </svg>
      <span>{{ data.linkedInLink | urlShorten : 'LinkedIn' }}</span>
    </div>
    <div *ngIf="data.faceBookLink" class="icon-link pb-2">
      <div class="icon">
        <svg fill="#2B2E30" height="20" viewBox="0 0 12 20" width="20" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.416 11.0625L10.9355 7.64844H7.63281V5.42188C7.63281 4.45703 8.07812 3.56641 9.5625 3.56641H11.084V0.634766C11.084 0.634766 9.71094 0.375 8.41211 0.375C5.70312 0.375 3.92188 2.04492 3.92188 5.01367V7.64844H0.878906V11.0625H3.92188V19.375H7.63281V11.0625H10.416Z"
            fill="#2B2E30"
          />
        </svg>
      </div>
      <span>{{ data.faceBookLink | urlShorten : 'Facebook'  }}</span>
    </div>
    <div *ngIf="data.instagramLink" class="icon-link pb-2">
      <svg fill="none" height="20" viewBox="0 0 17 18" width="20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5 4.60742C6.125 4.60742 4.23242 6.53711 4.23242 8.875C4.23242 11.25 6.125 13.1426 8.5 13.1426C10.8379 13.1426 12.7676 11.25 12.7676 8.875C12.7676 6.53711 10.8379 4.60742 8.5 4.60742ZM8.5 11.6582C6.97852 11.6582 5.7168 10.4336 5.7168 8.875C5.7168 7.35352 6.94141 6.12891 8.5 6.12891C10.0215 6.12891 11.2461 7.35352 11.2461 8.875C11.2461 10.4336 10.0215 11.6582 8.5 11.6582ZM13.918 4.45898C13.918 3.90234 13.4727 3.45703 12.916 3.45703C12.3594 3.45703 11.9141 3.90234 11.9141 4.45898C11.9141 5.01562 12.3594 5.46094 12.916 5.46094C13.4727 5.46094 13.918 5.01562 13.918 4.45898ZM16.7383 5.46094C16.6641 4.125 16.3672 2.9375 15.4023 1.97266C14.4375 1.00781 13.25 0.710938 11.9141 0.636719C10.541 0.5625 6.42188 0.5625 5.04883 0.636719C3.71289 0.710938 2.5625 1.00781 1.56055 1.97266C0.595703 2.9375 0.298828 4.125 0.224609 5.46094C0.150391 6.83398 0.150391 10.9531 0.224609 12.3262C0.298828 13.6621 0.595703 14.8125 1.56055 15.8145C2.5625 16.7793 3.71289 17.0762 5.04883 17.1504C6.42188 17.2246 10.541 17.2246 11.9141 17.1504C13.25 17.0762 14.4375 16.7793 15.4023 15.8145C16.3672 14.8125 16.6641 13.6621 16.7383 12.3262C16.8125 10.9531 16.8125 6.83398 16.7383 5.46094ZM14.957 13.7734C14.6973 14.5156 14.1035 15.0723 13.3984 15.3691C12.2852 15.8145 9.6875 15.7031 8.5 15.7031C7.27539 15.7031 4.67773 15.8145 3.60156 15.3691C2.85938 15.0723 2.30273 14.5156 2.00586 13.7734C1.56055 12.6973 1.67188 10.0996 1.67188 8.875C1.67188 7.6875 1.56055 5.08984 2.00586 3.97656C2.30273 3.27148 2.85938 2.71484 3.60156 2.41797C4.67773 1.97266 7.27539 2.08398 8.5 2.08398C9.6875 2.08398 12.2852 1.97266 13.3984 2.41797C14.1035 2.67773 14.6602 3.27148 14.957 3.97656C15.4023 5.08984 15.291 7.6875 15.291 8.875C15.291 10.0996 15.4023 12.6973 14.957 13.7734Z"
          fill="#2B2E30"
        />
      </svg>
      <span>{{ data.instagramLink | urlShorten : 'Instagram'  }}</span>
    </div>
    <div *ngIf="data.twitterLink" class="icon-link pb-2">
      <svg fill="none" height="16" viewBox="0 0 19 16" width="20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.0332 4.01562C17.7754 3.45898 18.4434 2.79102 18.9629 2.01172C18.2949 2.30859 17.5156 2.53125 16.7363 2.60547C17.5527 2.12305 18.1465 1.38086 18.4434 0.453125C17.7012 0.898438 16.8477 1.23242 15.9941 1.41797C15.252 0.638672 14.25 0.193359 13.1367 0.193359C10.9844 0.193359 9.24023 1.9375 9.24023 4.08984C9.24023 4.38672 9.27734 4.68359 9.35156 4.98047C6.12305 4.79492 3.22852 3.23633 1.29883 0.898438C0.964844 1.45508 0.779297 2.12305 0.779297 2.86523C0.779297 4.20117 1.44727 5.38867 2.52344 6.09375C1.89258 6.05664 1.26172 5.9082 0.742188 5.61133V5.64844C0.742188 7.54102 2.07812 9.09961 3.85938 9.4707C3.5625 9.54492 3.19141 9.61914 2.85742 9.61914C2.59766 9.61914 2.375 9.58203 2.11523 9.54492C2.59766 11.1035 4.04492 12.2168 5.75195 12.2539C4.41602 13.293 2.74609 13.9238 0.927734 13.9238C0.59375 13.9238 0.296875 13.8867 0 13.8496C1.70703 14.9629 3.74805 15.5938 5.97461 15.5938C13.1367 15.5938 17.0332 9.69336 17.0332 4.53516C17.0332 4.34961 17.0332 4.20117 17.0332 4.01562Z"
          fill="#2B2E30"
        />
      </svg>
      <span>{{ data.twitterLink | urlShorten : 'Twitter'  }}</span>
    </div>
    <div *ngIf="data.youtubeURL" class="icon-link">
      <svg fill="none" height="20" viewBox="0 0 22 15" width="20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.6855 3.01367C20.4629 2.12305 19.7578 1.41797 18.9043 1.19531C17.3086 0.75 11 0.75 11 0.75C11 0.75 4.6543 0.75 3.05859 1.19531C2.20508 1.41797 1.5 2.12305 1.27734 3.01367C0.832031 4.57227 0.832031 7.91211 0.832031 7.91211C0.832031 7.91211 0.832031 11.2148 1.27734 12.8105C1.5 13.7012 2.20508 14.3691 3.05859 14.5918C4.6543 15 11 15 11 15C11 15 17.3086 15 18.9043 14.5918C19.7578 14.3691 20.4629 13.7012 20.6855 12.8105C21.1309 11.2148 21.1309 7.91211 21.1309 7.91211C21.1309 7.91211 21.1309 4.57227 20.6855 3.01367ZM8.92188 10.918V4.90625L14.1914 7.91211L8.92188 10.918Z"
          fill="#2B2E30"
        />
      </svg>
      <span>{{ data.youtubeURL  | urlShorten : 'Youtube'  }}</span>
    </div>
  </div>
</div>
