import { FilterDataActions, FilterDataActionsActionTypes } from '../actions/filtersData.action';
import { getInitialFilterState, IFilterState } from '../state/filters.state';

export function filterDataReducer(state = getInitialFilterState(), action: FilterDataActions): IFilterState {
  switch (action.type) {
    case FilterDataActionsActionTypes.SuccessUpdateV2FilterUpdates: {
      return {
        ...state,
        filterState: {
          ...state.filterState,
          state: {
            ...state.filterState.state,
            ...action.payload
          }
        }
      };
    }

    case FilterDataActionsActionTypes.ApiResultsReceived: {
      return {
        ...state,
        defaultsLoaded: true
      };
    }

    case FilterDataActionsActionTypes.ClearClaimsFilters: {
      return {
        ...state,
        filterState: {
          ...state.filterState,
          state: {
            ...state.filterState.state,
            groupByLevel: [],
            timeFrame: [],
            msTypeName: [],
            unitType: [],
            priceType: [],
            city: [],
            zip: [],
            county: []
          }
        }
      };
    }

    case FilterDataActionsActionTypes.SetCurrentPage: {
      return {
        ...state,
        filterState: {
          ...state.filterState,
          paginationCurrPage: action.payload
        }
      };
    }

    case FilterDataActionsActionTypes.SetSortAscending: {
      return {
        ...state,
        filterState: {
          ...state.filterState,
          sortAsc: action.payload
        }
      };
    }

    case FilterDataActionsActionTypes.SetPageCardSize: {
      return {
        ...state,
        filterState: {
          ...state.filterState,
          perPage: action.payload
        }
      };
    }

    case FilterDataActionsActionTypes.RemoveSingleFilter: {
      const original = state.filterState.state[action.payload.type];
      const update =
        action.payload.type === 'priceType'
          ? !original
          : original.filter(value => {
              return value.toString() !== action.payload.value.toString();
            });

      return {
        ...state,
        filterState: {
          ...state.filterState,
          state: {
            ...state.filterState.state,
            [action.payload.type]: update
          }
        }
      };
    }

    case FilterDataActionsActionTypes.SetPendingFilterChanges: {
      return {
        ...state,
        pending: action.payload
      };
    }

    default:
      return state;
  }
}
