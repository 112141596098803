import { Action } from '@ngrx/store';
import { GalleryImage } from '../../models/GalleryImage';

export enum GalleryActionsActionTypes {
  FetchUsersGalleryImages = '[Gallery] Fetch Gallery Images',
  FetchUsersGalleryImagesSuccess = '[Gallery] Set Gallery of Images',
  SetGalleryImageAsDefaultPending = '[Gallery] Attempt to Set Image to User DB',
  SetGalleryImageAsDefaultSuccess = '[Gallery] Successfully Set Image to User DB',
  ToggleGallery = '[Gallery] Toggle Gallery Open/close',
  GalleryPendingChange = '[Gallery] Default Change Pending',
  ShowLargeGalleryImage = '[Gallery] Expanding Gallery To Show Large Image',
  CloseSlideShow = '[Gallery] SlideShow Closing'
}

export class FetchUsersGalleryImages implements Action {
  readonly type = GalleryActionsActionTypes.FetchUsersGalleryImages;
}

export class FetchUsersGalleryImagesSuccess implements Action {
  readonly type = GalleryActionsActionTypes.FetchUsersGalleryImagesSuccess;

  constructor(public payload) {}
}

export class SetGalleryImageAsDefaultPending implements Action {
  readonly type = GalleryActionsActionTypes.SetGalleryImageAsDefaultPending;

  constructor(public payload: GalleryImage) {}
}

export class SetGalleryImageAsDefaultSuccess implements Action {
  readonly type = GalleryActionsActionTypes.SetGalleryImageAsDefaultSuccess;
}

export class ToggleGallery implements Action {
  readonly type = GalleryActionsActionTypes.ToggleGallery;

  constructor(public payload?: boolean) {}
}

export class GalleryPendingChange implements Action {
  readonly type = GalleryActionsActionTypes.GalleryPendingChange;

  constructor(public payload: GalleryImage) {}
}

export class ShowLargeGalleryImage implements Action {
  readonly type = GalleryActionsActionTypes.ShowLargeGalleryImage;

  constructor(public payload: GalleryImage) {}
}

export class CloseSlideShow implements Action {
  readonly type = GalleryActionsActionTypes.CloseSlideShow;
}

export type GalleryActions =
  | FetchUsersGalleryImages
  | SetGalleryImageAsDefaultPending
  | SetGalleryImageAsDefaultSuccess
  | FetchUsersGalleryImagesSuccess
  | ToggleGallery
  | GalleryPendingChange
  | ShowLargeGalleryImage
  | CloseSlideShow;
