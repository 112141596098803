import { Action } from '@ngrx/store';
import { IChips } from '../../models/IChips';
import { IMarketReportDate } from '../../models/IMarketReportDate';
import { MarketFilters, MarketLocationSubPanel } from '../../models/IMarketReportFilters';

export enum MarketPositionDataActionsActionTypes {
  FetchMarketReports = '[Market Position] Fetch Market Report Data API Call',
  FetchMarketReportsSuccess = '[Market Position] Received Market Report Data API Call',
  FetchMarketReportsError = '[Market Position] No Data Received From Market Report Data API Call',
  UpdateMarketReportFilters = '[Market Position] Updating Filters For Market Report',
  ResetMarketReportFilters = '[Market Position] Resetting Market Filters',
  ResetMarketReportFiltersSuccess = '[Market Position] Market Filters Cleared',
  SetDatesAvailable = '[Market Position] Set Available Date Ranges',
  FetchDatesAvailable = '[Market Position] Fetch Available Date Ranges',
  RemoveSingleMarketFilter = '[V2 Chips] Remove Single Market Position Filter',
  FetchEndDate = '[Market Position] Fetch Market End Date',
  SetEndDate = '[Market Position] Set Ending Disclaimer Date',
  SetShowPercentage = '[Market Position] Set Show As Percentage Toggle',
  MPFiltersDirty = '[Market Position] Update Filters isDirty',
  UpdateMarketPositionFilters = '[Market Position] Update Results Button Pressed',
  LocationFiltersOpen = '[Market Position] Locations Filters Are Open',
  ReportFiltersOpen = '[Market Position] Reports Filters Are Open',
  LocationSubPanelsOpen = '[Market Position] Location Sub Panel Open',
  ClearMarketClaimsFilters = '[Market Position] Clear Market Position Claims Report Filters',
  MPPricesDirty = '[Market Position] Update Prices isDirty'
}

export class FetchMarketReportsError implements Action {
  readonly type = MarketPositionDataActionsActionTypes.FetchMarketReportsError;
}

export class FetchMarketReports implements Action {
  readonly type = MarketPositionDataActionsActionTypes.FetchMarketReports;

  constructor(public payload: { mlsId: string; marketFilters: MarketFilters }) {}
}

export class FetchMarketReportsSuccess implements Action {
  readonly type = MarketPositionDataActionsActionTypes.FetchMarketReportsSuccess;

  constructor(public payload: any) {}
}

export class UpdateMarketReportFilters implements Action {
  readonly type = MarketPositionDataActionsActionTypes.UpdateMarketReportFilters;

  constructor(public payload: MarketFilters) {}
}

export class ResetMarketReportFilters implements Action {
  readonly type = MarketPositionDataActionsActionTypes.ResetMarketReportFilters;

  constructor(public payload: boolean) {}
}

export class ResetMarketReportFiltersSuccess implements Action {
  readonly type = MarketPositionDataActionsActionTypes.ResetMarketReportFiltersSuccess;
}

export class SetDatesAvailable implements Action {
  readonly type = MarketPositionDataActionsActionTypes.SetDatesAvailable;

  constructor(public payload: IMarketReportDate[]) {}
}

export class FetchDatesAvailable implements Action {
  readonly type = MarketPositionDataActionsActionTypes.FetchDatesAvailable;
}

export class FetchEndDate implements Action {
  readonly type = MarketPositionDataActionsActionTypes.FetchEndDate;
}

export class SetEndDate implements Action {
  readonly type = MarketPositionDataActionsActionTypes.SetEndDate;

  constructor(public payload: any) {}
}

export class RemoveSingleMarketFilter implements Action {
  readonly type = MarketPositionDataActionsActionTypes.RemoveSingleMarketFilter;

  constructor(public payload: IChips) {}
}

export class SetShowPercentage implements Action {
  readonly type = MarketPositionDataActionsActionTypes.SetShowPercentage;

  constructor(public payload: any) {}
}

export class MPFiltersDirty implements Action {
  readonly type = MarketPositionDataActionsActionTypes.MPFiltersDirty;

  constructor(public payload: boolean) {}
}

export class UpdateMarketPositionFilters implements Action {
  readonly type = MarketPositionDataActionsActionTypes.UpdateMarketPositionFilters;

  constructor(public payload: boolean) {}
}

export class LocationFiltersOpen implements Action {
  readonly type = MarketPositionDataActionsActionTypes.LocationFiltersOpen;

  constructor(public payload: boolean) {}
}

export class ReportFiltersOpen implements Action {
  readonly type = MarketPositionDataActionsActionTypes.ReportFiltersOpen;

  constructor(public payload: boolean) {}
}

export class LocationSubPanelsOpen implements Action {
  readonly type = MarketPositionDataActionsActionTypes.LocationSubPanelsOpen;

  constructor(public payload: MarketLocationSubPanel) {}
}

export class ClearMarketClaimsFilters implements Action {
  readonly type = MarketPositionDataActionsActionTypes.ClearMarketClaimsFilters;
}

export class MPPricesDirty implements Action {
  readonly type = MarketPositionDataActionsActionTypes.MPPricesDirty;

  constructor(public payload: boolean) {}
}

export type MarketPositionAction =
  | FetchMarketReports
  | FetchMarketReportsError
  | FetchMarketReportsSuccess
  | UpdateMarketReportFilters
  | ResetMarketReportFilters
  | ResetMarketReportFiltersSuccess
  | SetDatesAvailable
  | FetchDatesAvailable
  | RemoveSingleMarketFilter
  | FetchEndDate
  | SetEndDate
  | SetShowPercentage
  | MPFiltersDirty
  | UpdateMarketPositionFilters
  | LocationFiltersOpen
  | ReportFiltersOpen
  | LocationSubPanelsOpen
  | ClearMarketClaimsFilters
  | MPPricesDirty;
