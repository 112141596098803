import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, map, retry, switchMap } from 'rxjs/operators';
import { MarketPositionService } from '../../services/market-position.service';
import {
  FetchDatesAvailable,
  FetchEndDate,
  FetchMarketReports,
  FetchMarketReportsError,
  FetchMarketReportsSuccess,
  MarketPositionDataActionsActionTypes,
  ResetMarketReportFilters,
  SetDatesAvailable,
  SetEndDate,
  UpdateMarketReportFilters
} from '../actions/market-position.action';
import { selectMarketReportFilters } from '../selectors/market-position.selector';
import { selectCurrentMLS } from '../selectors/mls.selector';
import { selectOktaId } from '../selectors/userData.selector';
import { IAppState } from '../state/app.state';

@Injectable()
export class MarketPositionEffect {
  fetchReportData$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchMarketReports>(MarketPositionDataActionsActionTypes.FetchMarketReports),
      switchMap(action => {
        return this.marketService.getMarketReportData(action.payload.mlsId, action.payload.marketFilters).pipe(
          map(reports => {
            this.store.dispatch(new UpdateMarketReportFilters(action.payload.marketFilters));
            return new FetchMarketReportsSuccess(reports);
          }),
          retry(3),
          catchError(() => {
            return of(new FetchMarketReportsError());
          })
        );
      })
    )
  );

  fetchDatesAvailable$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchDatesAvailable>(MarketPositionDataActionsActionTypes.FetchDatesAvailable),
      switchMap(() => {
        return this.marketService.getAllDateRanges();
      }),
      map(dates => new SetDatesAvailable(dates))
    )
  );

  fetchEndDate$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchEndDate>(MarketPositionDataActionsActionTypes.FetchEndDate),
      switchMap(() => {
        return this.marketService.getEndDateRange();
      }),
      map(date => new SetEndDate(date))
    )
  );

  fetchDefaultFilters$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ResetMarketReportFilters>(MarketPositionDataActionsActionTypes.ResetMarketReportFilters),
      switchMap(() => {
        return combineLatest([
          this.store.select(selectCurrentMLS),
          this.store.select(selectMarketReportFilters),
          this.store.select(selectOktaId)
        ]).pipe(
          map(combinedData => {
            const [mls, filters, oktaId] = combinedData;
            return new FetchMarketReports({
              mlsId: mls.mlsId,
              marketFilters: {
                ...filters,
                mlsid: [mls.mlsId],
                oktaId: oktaId
              }
            });
          })
        );
      })
    )
  );

  constructor(private _actions$: Actions, private marketService: MarketPositionService, private store: Store<IAppState>) {}
}
