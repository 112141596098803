<div #searchContainer class="search-container">
  <mat-panel-title class="fw-bold mat-panel-header align-items-center">
    Search Locations
    <mat-icon
      #tooltip="matTooltip"
      (click)="tooltip.toggle()"
      matTooltip="You can filter the data by locations by adding them via the search or the location filter below."
      matTooltipPosition="above"
      >info_outline
    </mat-icon>
  </mat-panel-title>
  <mat-form-field appearance="outline" class="w-100">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>{{ placeholderText || 'Search' }}</mat-label>
    <input [formControl]="searchControl" matInput (focus)="showDropdown = true" />
  </mat-form-field>
  <div class="dropdown-container" *ngIf="showDropdown && sortedLocs.length > 0">
    <div class="property-type-scroll">
      <ul>
        <li *ngFor="let locationGroup of sortedLocs">
          <span class="list-header">{{ locationGroup.type }}</span>
          <ul>
            <li *ngFor="let location of locationGroup.value">
              <mat-checkbox *ngIf="multiSelect" (change)="updateLocations()" [(ngModel)]="location.checked" class="search-input-checkbox">
                {{ location | locationDataName }}</mat-checkbox
              >
              <button *ngIf="!multiSelect" (click)="optionSelected(location)">{{ location | locationDataName }}</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
