<div class="modal-container">
  <h1 mat-dialog-title>Set Default Filter</h1>
  <div mat-dialog-content>
    <p>You can save selected filters to be automatically applied every time you use {{this.reportName}}.
    Your MLS and locations will be saved for all reports in Market Quest.</p>
    <p>Clicking this will override any previously saved default filter.</p>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button color="primary" [mat-dialog-close]="true">Set Default Filter</button>
    <button mat-button mat-dialog-close>Cancel</button>
  </div>
</div>
