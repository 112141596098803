import { Component } from '@angular/core';

@Component({
  selector: 'app-reports-template',
  templateUrl: './reports-template.component.html',
  styleUrls: ['./reports-template.component.scss']
})
export class ReportsTemplateComponent {
  constructor() {}
}
