import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IReportHeader } from 'app/models/IReportHeader';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ISocialData } from '../../../models/ISocialData';
import { GTMService } from '../../../services/gtm.service';
import { ModalService } from '../../../services/modal.service';
import { ShareSocialAndEmailService } from '../../../services/share-social-and-email.service';

@Component({
  selector: 'app-social-modal',
  templateUrl: './social-modal.component.html',
  styleUrls: ['./social-modal.component.scss']
})
export class SocialModalComponent implements OnInit {
  public reportHeaderEntity: IReportHeader;
  public baseurl: string;
  public metaLink: string;
  public shareLink: string;
  public reportValueType: string;
  public reportTransactionSide: string;
  public reportValue: number;
  @ViewChild('fbBtn') fbBtn: ElementRef;
  $ready = new Subject<boolean>();

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private dialogRef: MatDialogRef<SocialModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISocialData,
    private _snackBar: MatSnackBar,
    private gtmService: GTMService,
    private modalService: ModalService,
    private shareSvc: ShareSocialAndEmailService,
    private snackBar: MatSnackBar
  ) {
    this.reportHeaderEntity = this.data.reportDetails?.reportHeaderEntity;
    this.metaLink = baseUrl + 'social/' + this.data.encryptedid;
    this.shareLink = baseUrl + 'share/market/' + this.data.encryptedid;

    const msTypeName = this.reportHeaderEntity?.msTypeName.split(' ');
    // If msTypeName is Total Units/Volume there will only be 2 words in the split
    this.reportTransactionSide =
      msTypeName && msTypeName?.length ? (msTypeName?.length > 2 ? msTypeName[0] + ' ' + msTypeName[1] : msTypeName[0]) : '';
    this.reportValueType = msTypeName && msTypeName?.length ? (msTypeName.length > 2 ? msTypeName[2] : msTypeName[1]) : '';
    this.reportValue = this.data.reportDetails?.reportGraphEntities[this.data.cbPosition - 1].reportMeasureValue;
  }

  ngOnInit() {
    this.loadSocialImage();
  }

  loadSocialImage() {
    this.shareSvc
      .generateSocialImage()
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.$ready.next(true);
        },
        error: () => {
          this.snackBar.open('Error generating image', '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
          });
        }
      });
  }

  socialLinkGenerate() {
    this.gtmSocialClick();
  }

  private gtmSocialClick() {
    this.gtmService.claimsGTM('exportClaimsReport');
  }

  public onCopyURL() {
    navigator.clipboard.writeText(this.shareLink).then(() => {
      this.openSnackBar('URL copied to clipboard.');
    });
  }

  public closeMe() {
    this.dialogRef.close('cancel');
  }

  public openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'right'
    });
  }

  closeDialog() {
    this.modalService.close();
  }
}
