import { IAgentData } from '../../models/IAgentData';
import { IBaseHeader } from '../../models/IBaseHeader';
import { GalleryImage } from '../../models/GalleryImage';

export interface IAspTrendsFilter {
  oktaid?: string;
  mlsid?: string[];
  city?: number[];
  county?: number[];
  zip?: number[];
  mlsArea?: number[];
  schoolDistrict?: number[];
  subDivision?: number[];
  propertyType?: number[] | string[];
}

export interface IAspTrendsDataResponse {
  agentReportEntity: IAgentData;
  header: IBaseHeader;
  imagePromoEntities: GalleryImage[];
  reportData: IAspTrendsData[];
  isEnabled?: boolean;
}

export interface IAspTrendsData {
  solds: number;
  xYear: string;
  asp: number;
}
export interface IAspTrendsState {
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  pdfDownloadPending: boolean;
  aspTrendsFilters: IAspTrendsFilter;
  aspTrendsReportData: IAspTrendsDataResponse;
}
export const initialAspTrendsState: IAspTrendsState = {
  hasError: false,
  loaded: false,
  loading: false,
  pdfDownloadPending: false,
  aspTrendsFilters: undefined,
  aspTrendsReportData: undefined
};

export function getInitialAspTrendsState(): IAspTrendsState {
  return initialAspTrendsState;
}
