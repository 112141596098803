import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, forkJoin, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { GalleryImage } from '../../models/GalleryImage';
import { IUser } from '../../models/IUser';
import { GalleryService } from '../../services/gallery.service';
import { OptionsService } from '../../services/options.service';
import {
  FetchUsersGalleryImages,
  FetchUsersGalleryImagesSuccess,
  GalleryActionsActionTypes,
  SetGalleryImageAsDefaultPending,
  SetGalleryImageAsDefaultSuccess
} from '../actions/gallery.action';
import { selectUser } from '../selectors/userData.selector';
import { IAppState } from '../state/app.state';

@Injectable()
export class GalleryEffect {
  fetchGalleryImages$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchUsersGalleryImages>(GalleryActionsActionTypes.FetchUsersGalleryImages),
      switchMap(() => this.store.select(selectUser)),
      filter(d => !!d.oktaId),
      switchMap(user => {
        return forkJoin([this.galleryService.getGalleryImages(user.oktaId), of(this.optionService.getImagePathForReport())]);
      }),
      map(imagesAndPath => {
        const [images, path] = imagesAndPath;
        let newArray: GalleryImage[];

        newArray = images.map(image => {
          image.imageURL = path + image.imageURL;
          return image;
        });
        return newArray;
      }),
      map(galleryImages => new FetchUsersGalleryImagesSuccess(galleryImages))
    )
  );

  setGalleryImageAsDefault = createEffect(() =>
    this._actions$.pipe(
      ofType<SetGalleryImageAsDefaultPending>(GalleryActionsActionTypes.SetGalleryImageAsDefaultPending),
      switchMap(action => {
        return combineLatest([of(action.payload), this.store.select(selectUser)]);
      }),
      switchMap(imageAndUser => {
        const user: IUser = imageAndUser[1];
        const image: GalleryImage = imageAndUser[0];
        return this.galleryService.setDefaultImage(user.oktaId, image.msUnoBackGroundImageID);
      }),
      map(() => {
        return this.galleryService.fetchUserGallery();
      }),
      switchMap(() => {
        return [new SetGalleryImageAsDefaultSuccess()];
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private galleryService: GalleryService,
    private store: Store<IAppState>,
    private optionService: OptionsService
  ) {}
}
