import { Component, ComponentFactoryResolver, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { IReportHeader } from 'app/models/IReportHeader';
import { ReportService } from 'app/services/report.service';
import { FileSaverService } from 'ngx-filesaver';
import { combineLatestWith, Subscription, throwError } from 'rxjs';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';
import { SocialImageDirective } from '../../../../../directives/social-image.directive';
import { IAssetDownloadData } from '../../../../../models/IAssetDownloadData';
import { ChartTypes } from '../../../../../models/IChartTypes';
import { IMarketPositionData } from '../../../../../models/IMarketPositionData';
import { ModalService } from '../../../../../services/modal.service';
import {
  selectMarketReportData,
  selectMarketReportId,
  selectMarketShowAsPercent
} from '../../../../../store/selectors/market-position.selector';
import { claimsReportId } from '../../../../../store/selectors/reports.selectors';
import { selectUser } from '../../../../../store/selectors/userData.selector';
import { IAppState } from '../../../../../store/state/app.state';
import { WifHelpComponent } from '../wif-help/wif-help.component';

interface DownloadButton {
  label: string;
  type: string;
  loading?: boolean;
}
@Component({
  selector: 'app-asset-download',
  templateUrl: './asset-download.component.html',
  styleUrls: ['./asset-download.component.scss']
})
export class AssetDownloadComponent implements OnDestroy {
  buttonArray: DownloadButton[];
  chartTypes = ChartTypes;
  reportHeaderEntity: IReportHeader;
  reportValueType: string;
  reportTransactionSide: string;
  reportValue: number;
  subs = new Subscription();
  @ViewChild(SocialImageDirective, { static: true }) wif: SocialImageDirective;

  constructor(
    private modalService: ModalService,
    @Inject(MAT_DIALOG_DATA) public data: IAssetDownloadData,
    private componentFactoryResolver: ComponentFactoryResolver,
    private reportService: ReportService,
    private fileSaverService: FileSaverService,
    private store: Store<IAppState>
  ) {
    this.reportHeaderEntity = this.data.report.reportDetail.reportHeaderEntity;

    const msTypeName = this.reportHeaderEntity.msTypeName.split(' ');
    // If msTypeName is Total Units/Volume there will only be 2 words in the split
    this.reportTransactionSide = msTypeName.length > 2 ? msTypeName[0] + ' ' + msTypeName[1] : msTypeName[0];
    this.reportValueType = msTypeName.length > 2 ? msTypeName[2] : msTypeName[1];

    const value = data?.report?.reportDetail?.reportGraphEntities[data.report.cbposition - 1]?.reportMeasureValue;
    if (!!value) {
      this.reportValue = value;
    } else {
      this.store
        .select(selectMarketReportData)
        .pipe(take(1), withLatestFrom(this.store.select(selectMarketShowAsPercent)))
        .subscribe(resp => {
          const [dataObj, percent] = resp;
          const type = this.reportTransactionSide.toLowerCase() + this.reportValueType;
          this.reportValue = this.calculateMarketValue(dataObj, type, percent);
        });
    }

    let i = this.data.isSocial
      ? [
          {
            type: ChartTypes.wide,
            label: 'Wide'
          },
          {
            type: ChartTypes.tall,
            label: 'Tall'
          },
          {
            type: ChartTypes.square,
            label: 'Square'
          }
        ]
      : [
          {
            type: ChartTypes.HS_Web,
            label: 'Web'
          },
          {
            type: ChartTypes.HS_FB,
            label: 'Facebook'
          }
        ];
    if (this.data.isSIR) {
      i = i.filter(button => button.type !== ChartTypes.wide);
    }
    this.buttonArray = i;
  }

  closeDialog() {
    this.modalService.close();
  }

  calculateMarketValue(dataObj: IMarketPositionData, type: string, percent: boolean) {
    const key = type + (percent ? 'percent' : '');
    return dataObj.realogyPosition[key];
  }

  downloadImage(button: DownloadButton) {
    const size = this.chartTypes[button.type];
    button.loading = true;
    const reportId$ = this.data.reportType ? this.store.select(selectMarketReportId) : this.store.select(claimsReportId);
    this.subs.add(
      reportId$
        .pipe(
          combineLatestWith(this.store.select(selectUser), this.store.select(selectMarketShowAsPercent)),
          take(1),
          switchMap(([reportId, user, percent]) => {
            return this.data.isSIR
              ? this.reportService.requestSIRClaimsJpg(user.oktaId, `${this.data.graph ? 'graph-' : ''}${size}`, reportId, percent)
              : this.reportService.requestCBRClaimsJpg(user.oktaId, size, reportId, this.data.reportType, this.data.graph, 100, percent);
          })
        )
        .subscribe({
          next: data => {
            this.fileSaverService.save(data, `report-${size}.jpg`);
            button.loading = false;
          },
          error: err => {
            throwError(err);
            button.loading = false;
          }
        })
    );
  }

  launchHelp(isSocial: boolean) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(WifHelpComponent);
    const viewContainerRef = this.wif.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<WifHelpComponent>componentRef.instance).data = viewContainerRef;
    (<WifHelpComponent>componentRef.instance).type = isSocial ? 'social' : 'homespotter';
    (<WifHelpComponent>componentRef.instance).isSIR = this.data.isSIR;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
