import { Action } from '@ngrx/store';
import { IReportData } from '../../models/IReportData';
import { EncryptedReportData } from '../../models/IReportPageData';

export enum ReportDataActionsActionTypes {
  FetchReportData = '[Reports] Fetch Report Data API Call',
  FetchReportsNoResults = '[Reports] Report Data API Call Returned No Results',
  FetchReportDataSuccess = '[Reports] Fetched Report Data API Successful',
  SetReportPageData = '[Reports] Setting Page Details',
  ResetReportPageData = '[Reports] Reset Page Values',
  FetchFilteredReportData = '[Reports] Setting Local Filtered Reports',
  FetchFilteredReportDataSuccess = '[Reports] Setting Local Filtered Reports Successful',
  DownloadExportFileCSV = '[Reports] Downloading Filtered Reports File - CSV',
  DownloadExportFileExcel = '[Reports] Downloading Filtered Reports File - Excel'
}

export class DownloadExportFileCSV implements Action {
  readonly type = ReportDataActionsActionTypes.DownloadExportFileCSV;
}

export class DownloadExportFileExcel implements Action {
  readonly type = ReportDataActionsActionTypes.DownloadExportFileExcel;
}

export class FetchReportData implements Action {
  readonly type = ReportDataActionsActionTypes.FetchReportData;

  constructor(public payload: string) {}
}

export class FetchFilteredReportData implements Action {
  readonly type = ReportDataActionsActionTypes.FetchFilteredReportData;
}

export class FetchFilteredReportDataSuccess implements Action {
  readonly type = ReportDataActionsActionTypes.FetchFilteredReportDataSuccess;

  constructor(public payload: [IReportData[], IReportData[]]) {}
}

export class FetchReportsNoResults implements Action {
  readonly type = ReportDataActionsActionTypes.FetchReportsNoResults;
}

export class FetchReportDataSuccess implements Action {
  readonly type = ReportDataActionsActionTypes.FetchReportDataSuccess;

  constructor(public payload: IReportData[]) {}
}

export class SetReportPageData implements Action {
  readonly type = ReportDataActionsActionTypes.SetReportPageData;

  constructor(public payload: EncryptedReportData) {}
}

export class ResetReportPageData implements Action {
  readonly type = ReportDataActionsActionTypes.ResetReportPageData;

  constructor(public payload: boolean) {}
}

export type ReportDataActions =
  | FetchReportData
  | FetchReportDataSuccess
  | SetReportPageData
  | ResetReportPageData
  | FetchReportsNoResults
  | FetchFilteredReportDataSuccess
  | FetchFilteredReportData
  | DownloadExportFileExcel
  | DownloadExportFileCSV;
