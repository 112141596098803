<div class="filter-panel-container" *ngIf="controls">
  <div class="save-search-container">
    <app-saved-search-input
      [filterForm]="controls"
      [reportType]="reportType"
      (applyFilters)="applySavedSearch($event)"
    ></app-saved-search-input>
  </div>
  <div class="filter-header border-bottom">
    <span>{{ reportTitle }}</span>
    <button class="show-mobileTablet-only" mat-icon-button>
      <mat-icon (click)="closeMobileMenu()">close</mat-icon>
    </button>
  </div>
  <div class="p-3 top-filters mls-container">
    <app-mls-list-select [mlsList]="mlsList" [mlsForm]="controls"></app-mls-list-select>
  </div>
  <div class="p-3 top-filters">
    <app-search-input
      [placeholderText]="'Search by County, City, Zip Code'"
      [multiSelect]="multipleLocations"
      [locations]="locations"
      (locationChanged)="selectLocationHandler($event)"
    ></app-search-input>
    <div *ngIf="selectedFilterLocations">
      <app-chips-container
        [locations]="selectedFilterLocations"
        [truncate]="multipleLocations"
        [reportType]="reportType"
        (chipRemoved)="locationRemovedHandler($event)"
      ></app-chips-container>
    </div>
  </div>
  <mat-accordion *ngIf="controls" [multi]="false">
    <app-filter-panel-locations
      (locationsCleared)="resetLocationFilters()"
      [multiSelect]="multipleLocations"
      [locations]="locations"
      [locationGroup]="$any(controls)"
      [reportType]="reportType"
    ></app-filter-panel-locations>
    <app-filter-panel-report
      (resetClicked)="resetReportFilters()"
      [customMaxDate]="customMaxDate"
      [customDateRange]="customDateRange"
      [hideCustomDateRestriction]="hideCustomDateRestriction"
      [hideCustomMonthlyDate]="hideCustomMonthlyDate"
      [priceRanges]="priceRanges"
      [isSIR]="isSIR"
      [sortByAgent]="sortByAgent"
      [isCustomReport]="isCustomReport"
      [priceBands]="priceBands"
      [monthsBack]="monthsBack"
      [reportFilterFormGroup]="$any(controls?.get('report'))"
    ></app-filter-panel-report>
  </mat-accordion>

  <div class="filter-panel-snackbar" aria-hidden="true" *ngIf="showSnackBar">
    <p>Updates applied.</p>
  </div>

  <div class="filter-btn-container">
    <button (click)="updateResults()" class="update-button" color="primary" [disabled]="disableUpdate" mat-flat-button>
      Update
    </button>
  </div>
</div>
