import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { IWootricState } from '../state/wootric.state';

const wootric = (state: IAppState) => state.wootric;

export const wootricReady = createSelector(
  wootric,
  (state: IWootricState) => state.loaded
);

export const wootricUser = createSelector(
  wootric,
  (state: IWootricState) => state.info
);
