import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  DefaultClaimsFiltersEnum,
  DefaultMPFiltersEnum,
  DefaultV2FiltersEnum,
  FilterState,
  LocationDefaultEnum,
  locationTypes,
  mpLocationTypes,
  mpReportTypes,
  NumbersEnum
} from '../../models/FilterData';
import { DefaultFilters } from '../../models/IFilters';
import { MarketFilters } from '../../models/IMarketReportFilters';
import { FilterService } from '../../services/filter.service';
import { FilterApiResultsReceived, FilterDataActionsActionTypes, SuccessUpdateV2FilterUpdates } from '../actions/filtersData.action';
import { UpdateMarketReportFilters } from '../actions/market-position.action';
import { FetchWootric } from '../actions/wootric.action';
import { selectV2FiltersStatus } from '../selectors/filters.selector';
import { selectMarketReportFilters } from '../selectors/market-position.selector';
import { IAppState } from '../state/app.state';

@Injectable()
export class FilterDataEffect {
  fetchV2FiltersFromApi = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterDataActionsActionTypes.FetchV2FiltersFromApi),
      map(() => this.store.dispatch(new FetchWootric())),
      switchMap(() => this.filterService.getApiDefaultFilters()),
      map(filtersFromApi => {
        // this.setUpSnapshotDefaults(filtersFromApi);
        // TODO: Revisit this once the feature to save is scoped out, with all various scenarios

        const prepareLocalFiltersFromApi = (item: DefaultFilters) => {
          if (item.filterTypeID === DefaultV2FiltersEnum.priceType) {
            return item.filterValue === 'true';
          }
          if (item.filterTypeID in LocationDefaultEnum || item.filterTypeID === DefaultMPFiltersEnum.propertyType) {
            const arr = item.filterValue.split(';');
            return arr.map(v => {
              if (v === '') {
                return false;
              } else {
                return Number(v);
              }
            });
          }
          if (item.filterTypeID in NumbersEnum) {
            return Number(item.filterValue);
          }
          if (item.filterTypeID === DefaultMPFiltersEnum.dateRange) {
            const [datePeriod, startDate, endDate] = item.filterValue.split(';');
            return {
              datePeriod: datePeriod,
              startDate: startDate,
              endDate: endDate
            };
          }

          return item.filterValue.split(';');
        };

        const claimsFilters = filtersFromApi.reduce(
          (obj, item) =>
            Object.assign(
              obj,
              !!DefaultClaimsFiltersEnum[item.filterTypeID]
                ? {
                    [DefaultClaimsFiltersEnum[item.filterTypeID]]: prepareLocalFiltersFromApi(item)
                  }
                : null
            ),
          {}
        ) as FilterState;

        Object.keys(claimsFilters)
          .filter(filterType => locationTypes.includes(filterType))
          .map(f => {
            if (claimsFilters[f][0] === undefined) {
              claimsFilters[f].pop();
            }
          });

        const mpFilters = filtersFromApi.reduce(
          (obj, item) =>
            Object.assign(
              obj,
              !!DefaultMPFiltersEnum[item.filterTypeID]
                ? {
                    [DefaultMPFiltersEnum[item.filterTypeID]]: prepareLocalFiltersFromApi(item)
                  }
                : null
            ),
          {}
        ) as MarketFilters;

        Object.keys(mpFilters)
          .filter(filterType => mpLocationTypes.includes(filterType))
          .map(f => {
            if (mpFilters[f].length && mpFilters[f][0] === undefined) {
              mpFilters[f].pop();
            }
          });

        Object.keys(mpFilters)
          .filter(filterType => mpReportTypes.includes(filterType))
          .map(f => {
            if (f === 'mpGroupByLevel') {
              mpFilters['groupByLevel'] = mpFilters[f];
            }
            if (f === 'mpMsTypeName') {
              mpFilters['msTypeName'] = mpFilters[f];
            }
            if (f === 'mpUnitType') {
              mpFilters['unitType'] = mpFilters[f];
            }
          });

        return {
          claimsFilters: claimsFilters,
          mpFilters: mpFilters
        };
      }),
      switchMap(filters => {
        return [
          new SuccessUpdateV2FilterUpdates(filters.claimsFilters),
          new UpdateMarketReportFilters(filters.mpFilters),
          new FilterApiResultsReceived()
        ];
      })
    )
  );
  postFiltersV2toApi = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterDataActionsActionTypes.PendingDataBaseFilterUpdate),
        filter(v => v),
        withLatestFrom(this.store.select(selectV2FiltersStatus), this.store.select(selectMarketReportFilters)),
        map(([action, claimsFilters, marketFilters]) => {
          const arr: DefaultFilters[] = [];
          const filters: FilterState = cloneDeep(claimsFilters);
          const mpFilters: MarketFilters = cloneDeep(marketFilters);
          return this.filterService.buildFilterObject([filters, mpFilters], arr, -2);
        }),
        mergeMap((defaultFiltersArray: DefaultFilters[]) => {
          return this.filterService.updateUserFilterDefaults(defaultFiltersArray);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store<IAppState>, private filterService: FilterService) {}
}
