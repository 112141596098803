import { IContactData } from '../../models/IContactData';
import { UserSettingsAction, UserSettingsActionsActionType } from '../actions/user-settings.action';
import { getInitialUserSettingsState, IUserSettingsState } from '../state/user-settings.state';

export function UserSettingsReducer(state = getInitialUserSettingsState(), action: UserSettingsAction): IUserSettingsState {
  switch (action.type) {
    case UserSettingsActionsActionType.SetBrandingTypeLocal: {
      return {
        ...state,
        brandingType: action.payload
      };
    }

    case UserSettingsActionsActionType.FetchContactDataSuccess: {
      return {
        ...state,
        contact: action.payload.contact,
        social: action.payload.social,
        photo: action.payload.photo,
        loading: false,
        loaded: true
      };
    }

    case UserSettingsActionsActionType.FetchContactData: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case UserSettingsActionsActionType.ToggleUserContactFieldStatusSuccess: {
      let brandingType;
      if (action.payload.contactSection.includes('social')) {
        brandingType = 'social';
      } else {
        brandingType = 'contact';
      }
      const newState: IContactData[] = JSON.parse(JSON.stringify(state[brandingType]));
      let updateField = newState.findIndex(entry => entry.contactName === action.payload.contactName);
      newState[updateField] = action.payload;

      return {
        ...state,
        [brandingType]: newState
      };
    }

    case UserSettingsActionsActionType.UpdateOfficeInfo: {
      return {
        ...state,
        loading: true
      };
    }

    case UserSettingsActionsActionType.UpdateTeamInfo: {
      return {
        ...state,
        loading: true
      };
    }

    default:
      return state;
  }
}
