export interface IBannerData {
  type: BannerTypeEnums;
  message: string;
  url: string;
  show: boolean;
  closeable?: boolean;
}

export enum BannerTypeEnums {
  consolidated = 'consolidated',
  date_range = 'date_range',
  date_ytd = 'date_ytd',
  date_lq = 'date_lq',
  date_ly = 'date_ly'
}

export enum BannerLocationEnums {
  claims = 'claimsReport',
  market = 'marketReport',
  trends = 'trendsReport'
}

export interface PageBannerData {
  banners: IBannerData[];
}
