<mat-form-field appearance="outline">
  <mat-icon matPrefix>search</mat-icon>
  <mat-label>{{ placeholderText || 'Search' }}</mat-label>
  <input matInput [formControl]="searchControl" [matAutocomplete]="auto" [value]="searchControl.value">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
    <div class="property-type-scroll">
      <mat-optgroup *ngFor="let locationGroup of sortedLocs" [label]="locationGroup.type">
        <mat-option *ngFor="let location of locationGroup.value" [value]="location">
          {{location | locationDataName}}
        </mat-option>
      </mat-optgroup>
    </div>
  </mat-autocomplete>
</mat-form-field>
