import { Action } from '@ngrx/store';
import { IContactData } from '../../models/IContactData';
import { Branding } from '../state/user-settings.state';

export enum UserSettingsActionsActionType {
  SetBrandingTypeLocal = '[Settings] Set Branding Type Local',
  SetBrandingTypeRemote = '[Settings] Set Branding Type Remote',
  ToggleUserContactFieldStatus = '[Settings] Toggle Contact Field Status',
  ToggleUserContactFieldStatusSuccess = '[Settings] Toggle Contact Field Status Success',
  FetchContactData = '[Settings] Fetch Contact Data',
  FetchContactDataSuccess = '[Settings] Fetch Contact Data Success',
  UpdateOfficeInfo = '[Settings] Modify Office Info in State',
  UpdateTeamInfo = '[Settings] Modify Team Info in State'
}

export class SetBrandingTypeLocal implements Action {
  readonly type = UserSettingsActionsActionType.SetBrandingTypeLocal;

  constructor(public payload: Branding) {}
}

export class SetBrandingTypeRemote implements Action {
  readonly type = UserSettingsActionsActionType.SetBrandingTypeRemote;

  constructor(public payload: Branding) {}
}

export class ToggleUserContactFieldStatus implements Action {
  readonly type = UserSettingsActionsActionType.ToggleUserContactFieldStatus;

  constructor(public payload: IContactData) {}
}

export class ToggleUserContactFieldStatusSuccess implements Action {
  readonly type = UserSettingsActionsActionType.ToggleUserContactFieldStatusSuccess;

  constructor(public payload: IContactData) {}
}

export class UpdateOfficeInfo implements Action {
  readonly type = UserSettingsActionsActionType.UpdateOfficeInfo;

  constructor(public payload: string) {}
}

export class UpdateTeamInfo implements Action {
  readonly type = UserSettingsActionsActionType.UpdateTeamInfo;

  constructor(public payload: string) {}
}

export class FetchContactData implements Action {
  readonly type = UserSettingsActionsActionType.FetchContactData;

  constructor(public payload: Branding) {}
}

export class FetchContactDataSuccess implements Action {
  readonly type = UserSettingsActionsActionType.FetchContactDataSuccess;

  constructor(public payload: { contact: IContactData[]; social: IContactData[]; photo: IContactData[] }) {}
}

export type UserSettingsAction =
  | SetBrandingTypeRemote
  | SetBrandingTypeLocal
  | ToggleUserContactFieldStatus
  | ToggleUserContactFieldStatusSuccess
  | FetchContactDataSuccess
  | FetchContactData
  | UpdateOfficeInfo
  | UpdateTeamInfo;
