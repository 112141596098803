<mat-form-field appearance="outline">
  <mat-select
    (selectionChange)="emitDataTypeChange($event, controlIndex)"
    [value]="dataTypeValue"
    disableOptionCentering
    [disabled]="disabled"
  >
    <mat-option *ngFor="let i of dataPoints" [disabled]="i.disabled" [value]="ICustomDataTypeByNumEnum[i.dataKey]" class="touch">
      {{ i.description }}
    </mat-option>
    <mat-option *ngIf="showThreePoints">
      ------------------------------
    </mat-option>
    <mat-option *ngIf="showThreePoints" [value]="['solds', 'ai', 'ao']">
      Solds/Actives/Contracts
    </mat-option>
    <mat-option *ngIf="showThreePoints" [value]="['solds', 'ai', 'nl']">
      Solds/Actives/New Listings
    </mat-option>
    <mat-option *ngIf="showThreePoints" [value]="['ai', 'nl', 'ao']">
      Actives/New Listings/Contracts
    </mat-option>
  </mat-select>
</mat-form-field>
