import { Action } from '@ngrx/store';
import { IBreadCrumb } from '../../models/IBreadCrumb';

export enum BrowserActionsActionType {
  SetPrevUrls = '[Browser] Previous Page Path',
  RemoveLastPath = '[Browser] Remove Last Path',
  AddPath = '[Browser] Add Path',
}

export class SetPrevUrls implements Action {
  readonly type = BrowserActionsActionType.SetPrevUrls;

  constructor(public payload: IBreadCrumb[]) {
  }
}

export class RemoveLastPath implements Action {
  readonly type = BrowserActionsActionType.RemoveLastPath;
}

export class AddPath implements Action {
  readonly type = BrowserActionsActionType.AddPath;

  constructor(public payload: IBreadCrumb) {
  }
}

export type BrowserDataActions = SetPrevUrls | RemoveLastPath | AddPath;
