import { Component, Input } from '@angular/core';
import { BrandDetails } from '../../../../models/IApplicationData';

@Component({
  selector: 'app-footer-branding',
  templateUrl: './footer-branding.component.html',
  styleUrls: ['./footer-branding.component.scss']
})
export class FooterBrandingComponent {
  @Input() data: BrandDetails;

}
