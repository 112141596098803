import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Tenants } from './tenant.service';

interface FaviconsConfig {
  icons: IconsConfig;
  cacheBusting?: boolean;
}

interface IconsConfig {
  [name: string]: IconConfig;
}

interface IconConfig {
  type: string;
  href: string;
  isDefault?: boolean;
}

export const BROWSER_FAVICONS_CONFIG = new InjectionToken<FaviconsConfig>('Favicons Configuration');

@Injectable({
  providedIn: 'root'
})
export class FaviconService {

  readonly #elementId: string;
  readonly #icons: IconsConfig;
  readonly #useCacheBusting: boolean;

  constructor(@Inject(BROWSER_FAVICONS_CONFIG) config: FaviconsConfig) {
    this.#elementId = 'favicons-service-injected-node';
    this.#icons = Object.assign(Object.create(null), config.icons);
    this.#useCacheBusting = (config.cacheBusting || false);
    FaviconService.removeExternalLinkElements();
  }

  private static cacheBustHref(href: string): string {
    const augmentedHref = (href.indexOf('?') === -1)
      ? `${href}?faviconCacheBust=${Date.now()}`
      : `${href}&faviconCacheBust=${Date.now()}`;
    return (augmentedHref);

  }

  private static removeExternalLinkElements(): void {
    const linkElements = document.querySelectorAll('link[ rel ~= \'icon\' i]');
    for (const linkElement of Array.from(linkElements)) {
      linkElement.parentNode.removeChild(linkElement);
    }
  }

  public useFavicon(name: Tenants): void {
    this.activate(name);
  }

  private activate(name: string): void {
    if (!this.#icons[name]) {
      throw(new Error('Favicon for [ ' + name + ' ] not found.'));
    }
    this.setNode(this.#icons[name].type, this.#icons[name].href);
  }

  private addNode(type: string, href: string): void {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('id', this.#elementId);
    linkElement.setAttribute('rel', 'icon');
    linkElement.setAttribute('type', type);
    linkElement.setAttribute('href', href);
    document.head.appendChild(linkElement);
  }

  private removeNode(): void {
    const linkElement = document.head.querySelector('#' + this.#elementId);
    if (linkElement) {
      document.head.removeChild(linkElement);
    }
  }

  private setNode(type: string, href: string): void {
    const augmentedHref = this.#useCacheBusting
      ? FaviconService.cacheBustHref(href)
      : href;

    this.removeNode();
    this.addNode(type, augmentedHref);
  }
}
