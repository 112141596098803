import { Component, Input } from '@angular/core';
import { BrandDetails } from '../../../../models/IApplicationData';

@Component({
  selector: 'app-footer-contact',
  templateUrl: './footer-contact.component.html',
  styleUrls: ['./footer-contact.component.scss']
})
export class FooterContactComponent {
  @Input() data: BrandDetails;
}
