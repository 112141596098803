import { Injectable } from '@angular/core';
import { TrendsChartStyles, TrendsChartStylesEnum } from '../models/MarketTrendsChartConfiguration';
import { TenantCode } from './tenant.service';

type CssProps = { name: string; value: string }[];

@Injectable()
export class ThemeService {
  private _colorA700: string;

  get colorA700(): string {
    return this._colorA700;
  }

  get background(): string {
    return this._background;
  }

  set background(value: string) {
    this._background = value;
  }

  get colorA500(): string {
    return this._colorA500;
  }

  set colorA500(value: string) {
    this._colorA500 = value;
  }
  get brand(): TenantCode {
    return this._brand;
  }

  set brand(value: TenantCode) {
    this._brand = value;
  }
  get color100(): string {
    return this._color100;
  }

  set color100(value: string) {
    this._color100 = value;
  }
  constructor() {}

  get color300(): string {
    return this._color300;
  }

  set color300(value: string) {
    this._color300 = value;
  }

  get colorPrimary() {
    return this._colorPrimary;
  }

  set colorPrimary(value) {
    this._colorPrimary = value;
  }

  get colorSecondary() {
    return this._colorSecondary;
  }

  set colorSecondary(value) {
    this._colorSecondary = value;
  }

  get color50() {
    return this._color50;
  }

  set color50(value) {
    this._color50 = value;
  }

  get color200() {
    return this._color200;
  }

  set color200(value) {
    this._color200 = value;
  }

  get colorA50(): string {
    return this._colorA50;
  }

  get colorA60(): string {
    return this._colorA60;
  }

  set colorA50(value: string) {
    this._colorA50 = value;
  }

  set colorA60(value: string) {
    this._colorA60 = value;
  }

  get customChartPrimary(): string {
    return this._customChartPrimary;
  }

  set customChartPrimary(value: string) {
    this._customChartPrimary = value;
  }

  get customChartSecondary(): string {
    return this._customChartSecondary;
  }

  set customChartSecondary(value: string) {
    this._customChartSecondary = value;
  }

  get customChartTrendPrimary(): string {
    return this._customChartTrendPrimary;
  }

  set customChartTrendPrimary(value: string) {
    this._customChartTrendPrimary = value;
  }

  get customChartTrendSecondary(): string {
    return this._chartPrimary;
  }

  set customChartTrendSecondary(value: string) {
    this._chartPrimary = value;
  }

  get chartPrimary(): string {
    return this._chartPrimary;
  }

  set chartPrimary(value: string) {
    this._chartPrimary = value;
  }

  get chartSecondary(): string {
    return this._chartSecondary;
  }

  set chartSecondary(value: string) {
    this._chartSecondary = value;
  }

  get chartTertiary(): string {
    return this._chartTertiary;
  }

  set chartTertiary(value: string) {
    this._chartTertiary = value;
  }
  private _brand: TenantCode;

  private _color300: string;

  private _colorPrimary: string;

  private _colorSecondary: string;

  private _color50: string;

  private _color200: string;

  private _colorA50: string;

  private _colorA60: string;

  private _chartPrimary: string;

  private _chartSecondary: string;

  private _chartTertiary: string;

  private _customChartPrimary: string;

  private _customChartSecondary: string;

  private _customChartTrendPrimary: string;

  private _customChartTrendSecondary: string;

  private _color100: string;

  private _colorA500: string;

  private _background: string;

  set colorA700(value: string) {
    this._colorA700 = value;
  }

  private static selectBrandColors(brand: TenantCode): { name: string; value: string }[] {
    switch (brand) {
      case TenantCode.CBR: {
        return [
          { name: 'background', value: '#f9f9f9' },
          { name: 'colorPrimary', value: '#1f69ff' },
          { name: 'colorSecondary', value: '#e2f1ff' },
          { name: 'color50', value: '#e4edff' },
          { name: 'colorA50', value: '#f5f6ff' },
          { name: 'colorA60', value: '#e8f5e9' },
          { name: 'colorA500', value: '#4CAF50' },
          { name: 'colorA700', value: '#1f69ff' },
          { name: 'color100', value: '#DCDCDD' },
          { name: 'color200', value: '#4180FF' },
          { name: 'color300', value: '#f5f6ff' },
          { name: 'chartPrimary', value: '#1f69ff' },
          { name: 'chartSecondary', value: '#00B4BD' },
          { name: 'chartTertiary', value: '#bcd2ff' },
          { name: 'customChartPrimary', value: '#1f69ff' },
          { name: 'customChartSecondary', value: '#418FDE' },
          { name: 'customChartTrendPrimary', value: '#63666A' },
          { name: 'customChartTrendSecondary', value: '#A7A9AC' },
          { name: 'borderRadius', value: '10px' }
        ];
      }
      case TenantCode.SIR: {
        return [
          { name: 'background', value: '#F2F2F2' },
          { name: 'colorPrimary', value: '#002349' },
          { name: 'colorSecondary', value: '#e0e5e9' },
          { name: 'color50', value: '#E0E5E9' },
          { name: 'colorA50', value: '#F6F1E6' },
          { name: 'colorA60', value: '#F6F1E6' },
          { name: 'colorA500', value: '#B58B32' },
          { name: 'colorA700', value: '#8091A4' },
          { name: 'color100', value: '#B3BDC8' },
          { name: 'color200', value: '#264464' },
          { name: 'color300', value: '#E0E5E9' },
          { name: 'chartPrimary', value: '#002349' },
          { name: 'chartSecondary', value: '#CBAE70' },
          { name: 'chartTertiary', value: '#E0E5E9' },
          { name: 'customChartPrimary', value: '#002349' },
          { name: 'customChartSecondary', value: '#999999' },
          { name: 'customChartTrendPrimary', value: '#666666' },
          { name: 'customChartTrendSecondary', value: '#C29B40' },
          { name: 'borderRadius', value: '0px' }
        ];
      }
    }
  }

  public isSIR(): boolean {
    return this.brand === TenantCode.SIR;
  }

  public setSyncFusionPalette(chartType: TrendsChartStyles) {
    const spline = [`${this.chartPrimary}`, `${this.chartSecondary}`];
    const colLine = [`${this.chartTertiary}`, `${this.chartSecondary}`];
    switch (chartType) {
      case TrendsChartStylesEnum.Spline: {
        return spline;
      }

      case TrendsChartStylesEnum.ColumnLine: {
        return colLine;
      }

      default: {
        return [`${this.chartTertiary}`];
      }
    }
  }

  public setBrand(brand: TenantCode) {
    const brandCode = brand in TenantCode ? brand : TenantCode.CBR;
    this.setDOMProps(brandCode);
    this.setServiceProps(brandCode);
    return brandCode as TenantCode;
  }

  private setRootCssProperty(cssPropsArr: CssProps) {
    cssPropsArr.forEach(data => {
      document.documentElement.style.setProperty(`--${data.name}`, data.value);
    });
  }

  private setServiceProps(brand: TenantCode) {
    this.brand = brand;
    ThemeService.selectBrandColors(brand).map(i => {
      this[i.name] = i.value;
    });
  }

  private setDOMProps(brand: TenantCode) {
    document.body.classList.remove('CBR-theme', 'SIR-theme');
    document.body.classList.add(`${brand}-theme`);
    this.setRootCssProperty(ThemeService.selectBrandColors(brand));
  }
}
