<div class="stats-container">
  <div class="stat position">
    <div class="chip">{{marketPos}}</div>
    <div class="chip-text">In this Market</div>
  </div>
  <div class="stat">
    <div class="chip">
      {{totalMarketShare}}</div>
    <div class="chip-text">Total Market Share</div>
  </div>
  <div [ngClass]="{ 'leading' : this.isLeading }" class="stat">
    <div [ngClass]="this.isLeading ? 'leading' : 'down'" class="chip">
      <mat-icon>{{this.isLeading ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
      {{distanceTo}}
    </div>
    <div *ngIf="this.isLeading; else fromBlock" class="chip-text leading">Leading By</div>
    <ng-template #fromBlock>
      <div class="chip-text down">
        From #1 Position
      </div>
    </ng-template>
  </div>
</div>
