import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FiltersV2Enum, LocationEnum } from 'app/models/FilterData';
import { ILocationsData } from 'app/models/ILocationsData';
import { groupBy } from 'lodash-es';

interface SortedLocation {
  type: string;
  value: ILocationsData[];
}

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchInputComponent implements OnInit {
  @Input() placeholderText = 'Search';
  @Input() locations: ILocationsData[];
  @Input() multiSelect: boolean;
  @Output() locationChanged: EventEmitter<ILocationsData[]> = new EventEmitter<ILocationsData[]>();
  @ViewChild('searchContainer') searchContainer: ElementRef;
  searchControl = new UntypedFormControl();
  sortedLocs: SortedLocation[] = [];
  showDropdown = false;

  constructor(private renderer: Renderer2) {
    this.renderer.listen('window', 'click', (e: MouseEvent) => {
      if (
        this.showDropdown &&
        this.searchContainer?.nativeElement &&
        this.searchContainer.nativeElement.querySelector('.dropdown-container')
      ) {
        const searchContainerCoordinates = this.searchContainer.nativeElement.querySelector('.dropdown-container').getBoundingClientRect();
        const isClickOnTargetX = e.x >= searchContainerCoordinates.left && e.x <= searchContainerCoordinates.right;
        const isClickOnTargetY = e.y >= searchContainerCoordinates.top && e.y <= searchContainerCoordinates.bottom;
        if (!(isClickOnTargetX && isClickOnTargetY)) {
          this.searchControl.reset('');
          this.showDropdown = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.subscribe((val: string) => {
      this.sortedLocs = val.length > 2 ? this.sortLocationByTypes(this.locations, val) : [];
    });
  }

  optionSelected(location: ILocationsData) {
    if (!this.multiSelect) {
      this.searchControl.reset('');
      this.locationChanged.emit([location]);
    }

    this.showDropdown = false;
  }

  updateLocations(): void {
    if (this.multiSelect) {
      const checked = this.locations.filter(l => {
        return l.checked;
      });

      this.locationChanged.emit(checked);
    }
  }

  sortLocationByTypes(origLocationArr: ILocationsData[], searchValue: string): SortedLocation[] {
    const finalArray: SortedLocation[] = [];
    const sortedLocArr = groupBy(origLocationArr, function(l) {
      return l.areaType;
    });
    const locationTypesByOrder = [
      LocationEnum[FiltersV2Enum.county],
      LocationEnum[FiltersV2Enum.city],
      LocationEnum[FiltersV2Enum.zip],
      LocationEnum[FiltersV2Enum.mlsArea],
      LocationEnum[FiltersV2Enum.schoolDistrict],
      LocationEnum[FiltersV2Enum.subDivision]
    ];

    function sortValidAreaTypeByKey(
      areaType:
        | LocationEnum.county
        | LocationEnum.city
        | LocationEnum.zip
        | LocationEnum.mlsArea
        | LocationEnum.schoolDistrict
        | LocationEnum.subDivision
    ) {
      const i = sortedLocArr[areaType].filter(value => {
        return value.areaName.toLowerCase().includes(searchValue.toLowerCase());
      });
      if (i.length > 0) {
        finalArray.push({
          type: LocationEnum[areaType].toUpperCase(),
          value: i
        });
      }
    }

    locationTypesByOrder.map(areaType => {
      if (sortedLocArr[areaType]) {
        sortValidAreaTypeByKey(areaType);
      }
    });
    return finalArray;
  }
}
