import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IMlsData } from 'app/models/IMlsData';

@Component({
  selector: 'app-mls-list-select',
  templateUrl: './mls-list-select.component.html',
  styleUrls: ['./mls-list-select.component.scss']
})
export class MlsListSelectComponent {
  @Input() mlsList: IMlsData[];
  @Input() mlsForm: UntypedFormGroup;

  constructor() {}
}
