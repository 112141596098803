import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chip-label',
  templateUrl: './chip-label.component.html',
  styleUrls: ['./chip-label.component.scss']
})
export class ChipLabelComponent {
  @Input() label: string;
  @Input() chipClass: string;
  @Input() callbackFunction: () => void;

  constructor() {}
}
