import { FeatureDataActions, FeatureDataActionsActionTypes } from '../actions/featureData.action';
import { getInitialFeatureDataState, IFeatureDataState } from '../state/featureData.state';

export function FeatureDataReducer(state = getInitialFeatureDataState(), action: FeatureDataActions): IFeatureDataState {
  switch (action.type) {
    case FeatureDataActionsActionTypes.FetchFeaturesSuccess: {
      return {
        ...state,
        featuresList: action.payload,
        loaded: true
      };
    }

    default:
      return state;
  }
}
