import { Pipe, PipeTransform } from '@angular/core';
import { SavedSearchService } from '../services/saved-search.service';

@Pipe({
  name: 'areaIdLookup'
})
export class AreaIdLookupPipe implements PipeTransform {
  constructor(private savedSvc: SavedSearchService) {}

  async transform(value: number, areaType: number) {
    return this.savedSvc.lookupLocationByAreaIdAndType(value, areaType);
  }
}
