<div class="agent-block-container">
  <div class="agent-block-content agent-block-content-left">
    <div *ngIf="agentData$ | async as agentData">
      <div class="photo-mask" *ngIf="avatar$ | async as avatar; else otherPhoto">
        <app-avatar-image [size]="40" [avatar]="avatar" *ngIf="avatar.faces"></app-avatar-image>
      </div>
      <ng-template #otherPhoto>
        <div class="photo-mask" *ngIf="agentData.photo">
          <div class="small-agent agent_section" [ngStyle]="{ 'background-image': 'url(' + otherUrl + ')' }"></div>
        </div>
      </ng-template>
      <div class="agent_section">
        <span class="fw-bold typography-medium">{{ agentData.name }}</span>
        <div class="agent-title">
          <span *ngIf="agentData.title" class="typography-normal typography-black-grey me-2">{{ agentData.title }}</span>
        </div>
      </div>

      <div *ngIf="agentData.phone || agentData.otherPhone" class="agent_section">
        <div class="d-flex flex-column typography-normal typography-black">
          <span *ngIf="agentData.phone">{{ agentData.phone }}</span>
          <span *ngIf="agentData.otherPhone">{{ agentData.otherPhone }}</span>
          <span *ngIf="agentData.number">{{ agentData.number }}</span>
        </div>
      </div>

      <div *ngIf="agentData.email || agentData.websiteUrl" class="agent_section">
        <div class="d-flex flex-column typography-normal typography-black">
          <span *ngIf="agentData.email">{{ agentData.email }}</span>
          <span *ngIf="agentData.websiteUrl">{{ agentData.websiteUrl }}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="filterData$ | async as filterData" class="agent-block-content agent-block-content-right">
    <div class="typography-normal">
      <p>
        <strong>{{ filterData.areaLocalNames }}</strong>
      </p>
      <p>Price Range: {{ filterData.displayPrice }}</p>
      <p>Properties: {{ filterData.propertyType }}</p>
    </div>
  </div>
</div>
