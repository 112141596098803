import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ThemeService } from 'app/services/theme.service';
import { selectBrandCode } from 'app/store/selectors/applicationData.selector';
import { Subscription } from 'rxjs';
import { INavButton } from '../../../../models/INavButton';
import { NavigationService } from '../../../../services/navigation.service';
import { OptionsService } from '../../../../services/options.service';
import { ResponsiveService } from '../../../../services/responsive.service';
import { selectUser } from '../../../../store/selectors/userData.selector';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-menu-contents',
  templateUrl: './menu-contents.component.html',
  styleUrls: ['./menu-contents.component.scss']
})
export class MenuContentsComponent implements OnInit, OnDestroy {
  buttonData: INavButton[];
  user$ = this.store.select(selectUser);
  nonMobile: boolean;
  isSIR: boolean;
  private subs = new Subscription();

  constructor(
    private navigationService: NavigationService,
    private optionsService: OptionsService,
    private store: Store<IAppState>,
    private route: Router,
    private responsive: ResponsiveService,
    private breakpoint: BreakpointObserver,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.breakpoint.observe(['(min-width: 960px)', '(min-width: 600px)']).subscribe(results => {
        this.nonMobile = results.matches;
        this.fetchNavButtonData();
      })
    );

    this.subs.add(
      this.store.select(selectBrandCode).subscribe(brandCode => {
        this.isSIR = this.themeService.isSIR();
        this.fetchNavButtonData();
      })
    );
  }

  navigate(menuBtn: INavButton) {
    if (menuBtn.urlPath.includes('logout')) {
      this.optionsService.logout();
      return false;
    }
    if (menuBtn.urlExternal) {
      window.open(menuBtn.urlPath, '_blank');
    } else {
      this.route.navigateByUrl(menuBtn.urlPath).then(r => r);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private checkResponsive(navButton: INavButton) {
    return this.responsive.currentScreenBreakpointMatches(navButton);
  }

  private fetchNavButtonData() {
    this.buttonData = this.navigationService.headerAvatarMenuButtons.filter(btn => {
      let btnOk = btn.enabled;

      if (btn.label === 'Feedback' && !this.isSIR) {
        btnOk = false;
      }

      const srvOk = this.checkResponsive(btn);
      return btnOk && srvOk;
    });
  }
}
