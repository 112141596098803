import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FiltersV2TimeFrameEnum } from 'app/models/FilterData';
import { MarketFiltersV2PropertyTypeEnum, marketFiltersV2PropertyValueEnum } from 'app/models/IMarketReportFilters';
import { PriceRange } from 'app/models/IPriceRange';
import { MarketPositionPropertyTypes } from 'app/models/IPropertyType';

@Component({
  selector: 'app-filter-panel-report',
  templateUrl: './filter-panel-report.component.html',
  styleUrls: ['./filter-panel-report.component.scss']
})
export class FilterPanelReportComponent implements OnInit, OnChanges {
  @Input() reportFilterFormGroup: UntypedFormGroup;
  @Input() customDateRange = false;
  @Input() customMaxDate: Date = new Date();
  @Input() priceRanges: PriceRange[] = [];
  @Input() isSIR: boolean;
  @Input() sortByAgent = false;
  @Input() hideCustomDateRestriction: boolean;
  @Input() hideCustomMonthlyDate: boolean;
  @Input() isCustomReport = false;
  @Input() priceBands: any[];
  @Input() monthsBack: any[];
  @Output() resetClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  tabletPlus = true;
  activeReportSections = {};
  propertyTypes: MarketPositionPropertyTypes[] = [];
  filtersV2TimeEnum = FiltersV2TimeFrameEnum;

  constructor() {}

  ngOnInit(): void {
    Object.keys(MarketFiltersV2PropertyTypeEnum).map(key => {
      this.propertyTypes.push(new MarketPositionPropertyTypes(marketFiltersV2PropertyValueEnum[key], MarketFiltersV2PropertyTypeEnum[key]));
    });

    this.generateReportSections();
  }

  ngOnChanges(): void {
    this.generateReportSections();
  }

  clearReportFilters = (): void => {
    this.resetClicked.emit(true);
  };

  generateReportSections(): void {
    this.activeReportSections = {};
    Object.keys(this.reportFilterFormGroup.controls).forEach((key: string) => {
      this.activeReportSections[key] = true;
    });
  }

  calOpen() {
    if (!this.hideCustomDateRestriction) {
      setTimeout(() => {
        const endOfMonth = document.querySelectorAll('.mat-calendar-next-button.mat-button-disabled');
        endOfMonth.forEach(button => {
          const alertParent = document.createElement('div');
          alertParent.classList.add('market-eod-container');
          alertParent.innerHTML = '<span class="material-icons">info</span>';
          button.parentNode.append(alertParent);
        });
        this.updateEodStyles();
      }, 100);
    }
  }

  updateEodStyles() {
    const eodButton = document.querySelectorAll('.market-eod-container');
    eodButton.forEach(y => {
      y.classList.add('tooltip-d');
      const span = document.createElement('span');
      span.innerHTML =
        'The available dates have been restricted due to financial reporting timelines ' +
        '<a target="_blank" href="https://mycbdesk.com/help-center/market-quest/faqs/article/marketquest-v2-faqs">Learn More</a>';
      span.classList.add('tooltiptext');
      y.appendChild(span);
    });
  }
}
