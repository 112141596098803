import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-trends-skeleton',
  templateUrl: './trends-skeleton.component.html',
  styleUrls: ['./trends-skeleton.component.scss']
})
export class TrendsSkeletonComponent {
  @Input() height: number;
  constructor() {}
}
