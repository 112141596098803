import { Action } from '@ngrx/store';
import { GalleryImage } from '../../models/GalleryImage';
import { Avatar } from '../../models/IApplicationData';
import { IUser } from '../../models/IUser';

export enum UserDataActionsActionTypes {
  FetchUserV2Data = '[User v2] Init UserData API Call',
  FetchUserDataSuccess = '[User] Received UserData API Call',
  LogoutClearUserDataState = '[Logout] Clearing UserData state',
  FetchUserAvatarDetails = '[User Avatar] Fetch info from Realogy',
  SetUserAvatarDetails = '[User Avatar] Set Avatar Info',
  FetchUserDefaultImage = '[User Data] Fetch Default Image',
  FetchUserDefaultImageSuccess = '[User Data] Fetch Default Image Success',
  UpdateUserDefaultOffice = '[User Data] Set new Active Office',
  UpdateUserDefaultTeam = '[User Data] Set new Active Team',
  UpdateUserDefaultLicense = '[User Data] Set new Default License'
}

export class FetchUserV2Data implements Action {
  readonly type = UserDataActionsActionTypes.FetchUserV2Data;

  constructor(public payload: string) {}
}

export class FetchUserDataSuccess implements Action {
  readonly type = UserDataActionsActionTypes.FetchUserDataSuccess;

  constructor(public payload: IUser) {}
}

export class LogoutClearUserDataState implements Action {
  readonly type = UserDataActionsActionTypes.LogoutClearUserDataState;
}

export class FetchUserAvatarDetails implements Action {
  readonly type = UserDataActionsActionTypes.FetchUserAvatarDetails;

  constructor(public payload: IUser) {}
}

export class SetUserAvatarDetails implements Action {
  readonly type = UserDataActionsActionTypes.SetUserAvatarDetails;

  constructor(public payload: Avatar) {}
}

export class FetchUserDefaultImage implements Action {
  readonly type = UserDataActionsActionTypes.FetchUserDefaultImage;
}

export class FetchUserDefaultImageSuccess implements Action {
  readonly type = UserDataActionsActionTypes.FetchUserDefaultImageSuccess;

  constructor(public payload: GalleryImage[]) {}
}

export class UpdateUserDefaultOffice implements Action {
  readonly type = UserDataActionsActionTypes.UpdateUserDefaultOffice;

  constructor(public payload: string) {}
}

export class UpdateUserDefaultTeam implements Action {
  readonly type = UserDataActionsActionTypes.UpdateUserDefaultTeam;

  constructor(public payload: string) {}
}

export class UpdateUserDefaultLicense implements Action {
  readonly type = UserDataActionsActionTypes.UpdateUserDefaultLicense;

  constructor(public payload: string) {}
}

export type UserDataActions =
  | FetchUserV2Data
  | FetchUserDataSuccess
  | LogoutClearUserDataState
  | FetchUserAvatarDetails
  | SetUserAvatarDetails
  | FetchUserDefaultImage
  | FetchUserDefaultImageSuccess
  | UpdateUserDefaultOffice
  | UpdateUserDefaultTeam
  | UpdateUserDefaultLicense;
