import { DatePipe } from '@angular/common';
import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITrendsTableData } from 'app/models/ITrendsTableData';
import { ReportService } from 'app/services/report.service';
import { selectCurrentMLS } from 'app/store/selectors/mls.selector';
import { format, isValid, sub } from 'date-fns';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { IMarketTrendsHeader } from '../../../../models/IMarketTrendsHeader';
import { MarketTrendsChartConfiguration } from '../../../../models/MarketTrendsChartConfiguration';
import { MarketTrendsService } from '../../../../services/market-trends.service';
import { MTFiltersDirty, UpdateTrendsReportFilters } from '../../../../store/actions/market-trends.action';
import { ToggleFilterPanel } from '../../../../store/actions/menus.action';
import {
  selectTrendsDataHasError,
  selectTrendsDataHeader,
  selectTrendsDataLoaded,
  selectTrendsDataLoading,
  selectTrendsGraphData,
  selectTrendsTableData
} from '../../../../store/selectors/market-trends.selector';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-trends-report-page',
  templateUrl: './trends-report-page.component.html',
  styleUrls: ['./trends-report-page.component.scss']
})
export class TrendsReportPageComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'd-flex flex-column h-100 v-scroll-auto';
  @Input() enableExports = true;
  trendsError$: Observable<boolean>;
  trendsData$: Observable<{ chartData: MarketTrendsChartConfiguration[]; title: string }>;
  trendsLoaded$: Observable<boolean>;
  trendsLoading$: Observable<boolean>;
  skeletonReportArray = Array(3);
  subs = new Subscription();
  hasData$: Observable<boolean>;
  mlsId: string;

  constructor(private store: Store<IAppState>, private marketTrends: MarketTrendsService, private datePipe: DatePipe) {
    this.store
      .select(selectCurrentMLS)
      .pipe(filter(x => !!x))
      .pipe(take(1))
      .subscribe(mls => {
        this.mlsId = mls.mlsId;
      });
  }

  private static prepareData = (apiDate: IMarketTrendsHeader): { monthFormatted: string; title: string } => {
    if (apiDate) {
      const date = new Date(apiDate.endDate);
      if (!isValid(date)) {
        return { monthFormatted: '', title: '' };
      }
      const monthFormatted = format(date, 'MMMM');
      const yearFormatted = format(date, 'yyyy');
      const title = 'Market Trends Reports: ' + monthFormatted + ' ' + yearFormatted;
      return { title, monthFormatted };
    }
    return { monthFormatted: '', title: '' };
  };

  ngOnInit(): void {
    this.trendsLoaded$ = this.store.select(selectTrendsDataLoaded);
    this.trendsLoading$ = this.store.select(selectTrendsDataLoading);
    this.trendsError$ = this.store.select(selectTrendsDataHasError);
    const chartData = this.marketTrends.marketTrendsChartConfiguration;

    this.trendsData$ = this.store.select(selectTrendsDataHeader).pipe(
      map(headerData => {
        const { title } = TrendsReportPageComponent.prepareData(headerData);
        this.marketTrends.generateTableHeaders(headerData.endDate);
        return { chartData, title };
      })
    );

    this.setHasData();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setHasData(): void {
    this.hasData$ = this.store.select(selectTrendsTableData).pipe(
      map((trendData: ITrendsTableData[]) => {
        return Boolean(
          trendData.find((trend: ITrendsTableData) => {
            return trend.cm > 0;
          })
        );
      })
    );
  }

  toggleMenu() {
    this.store.dispatch(new ToggleFilterPanel());
  }

  clearFilters(): void {
    const currentDate = new Date();
    const maxDate = format(sub(currentDate, { months: 1 }), 'MM/yyyy');
    const defaultFilters = {
      city: [],
      county: [],
      zip: [],
      mlsArea: [],
      schoolDistrict: [],
      subDivision: [],
      propertyType: [1, 2, 9],
      priceMax: 999999999,
      priceMin: 0,
      endDate: maxDate,
      isReo: false,
      isDirty: false
    };
    this.store.dispatch(new MTFiltersDirty(true));
    this.store.dispatch(new UpdateTrendsReportFilters(defaultFilters));
    this.store.dispatch(new MTFiltersDirty(false));
  }

  refreshPage(): void {
    window.location.reload();
  }

  downloadTableData(isCSV: boolean): void {
    this.store
      .select(selectTrendsGraphData)
      .pipe(take(1))
      .subscribe(trendsTableData => {
        let trendsTableCSV =
          'Month,Year,Med. Sales Price,Avg. Sales Price,Sales to List Price,Homes Sold,Absorption Rate,Average DOM,Price Per Sqft.,Active Inventory,MSI,New Listings' +
          '\r\n';

        trendsTableData.forEach(trendsGraph => {
          trendsTableCSV += this.datePipe.transform(new Date(trendsGraph.period), 'LLLL') + ',';
          trendsTableCSV += new Date(trendsGraph.period).getFullYear() + ',';
          trendsTableCSV += trendsGraph.msp + ',';
          trendsTableCSV += trendsGraph.asp + ',';
          trendsTableCSV += trendsGraph.splp + ',';
          trendsTableCSV += trendsGraph.solds + ',';
          trendsTableCSV += trendsGraph['ar'] + ',';
          trendsTableCSV += trendsGraph.dom + ',';
          trendsTableCSV += trendsGraph.sppersf + ',';
          trendsTableCSV += trendsGraph.ai + ',';
          trendsTableCSV += trendsGraph.msi + ',';
          trendsTableCSV += trendsGraph.nl + '\r\n';
        });

        if (isCSV) {
          ReportService.generateCSVLink(trendsTableCSV, 'Trends-Data-Table-' + this.mlsId);
        } else {
          ReportService.generateJsonToXlsx(
            trendsTableCSV.split('\r\n').map(trendsCSV => {
              return trendsCSV.split(',');
            }) as any,
            'Trends-Data-Table-' + this.mlsId,
            true
          );
        }
      });
  }

  exportCSV(): void {
    this.downloadTableData(true);
  }

  exportXLS(): void {
    this.downloadTableData(false);
  }
}
