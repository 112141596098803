import { Action } from '@ngrx/store';
import { ICustomDataDetails } from '../../models/ICustomDataTypeEnum';
import { ICustomReportPostData } from '../../models/ICustomReportPostData';
import { ICustomGraphSetting } from '../state/custom-report.state';
import { ICustomReportOptions } from '../../models/ICustomReportOptions';

export enum CustomReportActionsActionTypes {
  FetchCustomReports = '[Custom Report] Fetch Report Data API Call',
  FetchCustomReportsSuccess = '[Custom Report] Fetch Report Data API Call Success',
  FetchCustomReportError = '[Custom Report] Fetch Report Data API Call Error',
  ResetCustomReportData = '[Custom Report] Reset Report Data',
  AdjustCustomReportDisplayAs = '[Custom Report] Adjust Custom Report DisplayAs',
  ResetCustomReportDisplayAs = '[Custom Report] Reset Custom Report Controls',
  AdjustCustomReportDataTypes = '[Custom Report] Adjust Custom Report Data Types',
  ResetCustomReportDataTypes = '[Custom Report] Reset Custom Report Data Types',
  SetCustomReportGraphAxis = '[Custom Report] Set Custom Report Graph Axis',
  SetDataTableShowAll = '[Custom Report] Toggle Data Table Show All',
  FetchCustomReportPdf = '[Custom Report] Fetch PDF Download',
  FetchCustomReportPdfSuccess = '[Custom Report] Fetch PDF Download Success',
  FetchCustomReportPdfError = '[Custom Report] Fetch PDF Error Occurred'
}

export class SetDataTableShowAll implements Action {
  readonly type = CustomReportActionsActionTypes.SetDataTableShowAll;
  constructor(public payload: boolean) {}
}

export class ResetCustomReportData implements Action {
  readonly type = CustomReportActionsActionTypes.ResetCustomReportData;
}

export class SetCustomReportGraphAxis implements Action {
  readonly type = CustomReportActionsActionTypes.SetCustomReportGraphAxis;
  constructor(public payload: (0 | 1)[]) {}
}

export class FetchCustomReports implements Action {
  readonly type = CustomReportActionsActionTypes.FetchCustomReports;

  constructor(public payload: ICustomReportPostData) {}
}

export class FetchCustomReportsSuccess implements Action {
  readonly type = CustomReportActionsActionTypes.FetchCustomReportsSuccess;
  constructor(public payload: ICustomDataDetails) {}
}

export class FetchCustomReportsError implements Action {
  readonly type = CustomReportActionsActionTypes.FetchCustomReportError;
}

export class AdjustCustomReportDataTypes implements Action {
  readonly type = CustomReportActionsActionTypes.AdjustCustomReportDataTypes;
  constructor(public payload: ICustomGraphSetting) {}
}

export class ResetCustomReportDataTypes implements Action {
  readonly type = CustomReportActionsActionTypes.ResetCustomReportDataTypes;
}

export class AdjustCustomReportDisplayAs implements Action {
  readonly type = CustomReportActionsActionTypes.AdjustCustomReportDisplayAs;
  constructor(public payload: ICustomGraphSetting) {}
}

export class ResetCustomReportDisplayAs implements Action {
  readonly type = CustomReportActionsActionTypes.ResetCustomReportDisplayAs;
}

export class FetchCustomReportPdfSuccess implements Action {
  readonly type = CustomReportActionsActionTypes.FetchCustomReportPdfSuccess;
}

export class FetchCustomReportPdfError implements Action {
  readonly type = CustomReportActionsActionTypes.FetchCustomReportPdfError;

  constructor(public payload: string) {}
}

export class FetchCustomReportPdf implements Action {
  readonly type = CustomReportActionsActionTypes.FetchCustomReportPdf;

  constructor(public payload: ICustomReportOptions) {}
}

export type CustomReportAction =
  | FetchCustomReports
  | FetchCustomReportsError
  | FetchCustomReportsSuccess
  | FetchCustomReportPdf
  | FetchCustomReportPdfError
  | FetchCustomReportPdfSuccess
  | AdjustCustomReportDisplayAs
  | ResetCustomReportDisplayAs
  | AdjustCustomReportDataTypes
  | SetCustomReportGraphAxis
  | ResetCustomReportData
  | ResetCustomReportDataTypes
  | SetDataTableShowAll;
