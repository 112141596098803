import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saved-search-confirmation-modal',
  templateUrl: './saved-search-confirmation-modal.component.html',
  styleUrls: ['./saved-search-confirmation-modal.component.scss']
})
export class SavedSearchConfirmationModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
