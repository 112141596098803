<div
  *ngIf="reportData"
  [ngClass]="[graph ? 'graph' : '', large ? 'large' : '']"
  [ngStyle]="{ 'background-image': 'url(' + imagePath + imageBgWide.imageURL + ')' }"
  class="preview-tile"
>
  <div class="preview-content">
    <div class="left">
      <div class="title">
        <span id="title">Ranked #{{ reportData.cbposition }}</span>
      </div>
      <div class="location" *ngIf="!reportData.reportType">
        <span id="location">
          {{ reportData.reportDetail.reportHeaderEntity | reportLocationName: 4 }}
        </span>
      </div>
      <div class="description">
        <span id="description">The Latest Numbers Say It All</span>
      </div>
    </div>
    <div *ngIf="graph" class="right">
      <app-market-quest-chart
        [chartSourceData]="reportData"
        [downloadableImageType]="large && !isMobile ? chartType.previewLarge : chartType.previewSmall"
      ></app-market-quest-chart>
    </div>
  </div>
  <div class="footer">
    <div class="logo" *ngIf="logo">
      <img [ngClass]="large ? 'large' : ''" [src]="imagePath + logo.imageURL" alt="" />
    </div>
  </div>
</div>
