import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Avatar } from '../../../../models/IApplicationData';
import { OnSetUserAvatarCSS, UserDataService } from '../../../../services/userData.service';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-avatar-image',
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.scss']
})
export class AvatarImageComponent implements OnInit, OnDestroy, OnSetUserAvatarCSS {
  @Input() size;
  @Input() avatar: Avatar;
  subs = new Subscription();
  userPhotoCSS: {};
  styleObject: { width: string; height: string };

  constructor(private store: Store<IAppState>, private userService: UserDataService) {}

  setUserAvatarCSS(avatar: Avatar, cssStyles: {}): void {
    this.userPhotoCSS = cssStyles;
  }

  ngOnInit(): void {
    this.userService.positionImage(this, this.avatar, this.size);
    this.styleObject = {
      height: this.size + 'px',
      width: this.size + 'px'
    };
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
