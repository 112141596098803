import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Data {
  isSIR?: boolean;
  isCBA?: boolean;
}

@Component({
  selector: 'app-no-mls-modal',
  templateUrl: './no-mls-modal.component.html',
  styleUrls: ['./no-mls-modal.component.scss']
})
export class NoMlsModalComponent implements OnInit {
  userType: 'SIR' | 'CBA' | 'CBR';
  constructor(@Inject(MAT_DIALOG_DATA) public data: Data) {}

  ngOnInit() {
    this.processUserType(this.data);
  }

  closeTab() {
    window.location.href = 'https://mycbdesk.com';
  }

  private processUserType(data: Data) {
    if (data?.isCBA) {
      this.userType = 'CBA';
    } else if (data?.isSIR) {
      this.userType = 'SIR';
    } else {
      this.userType = 'CBR';
    }
  }
}
