import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { IMarketPositionData } from 'app/models/IMarketPositionData';
import { selectMarketReportData } from 'app/store/selectors/market-position.selector';
import { IAppState } from 'app/store/state/app.state';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OptionsService } from '../../../../services/options.service';

interface Unit {
  percent: string;
  isLeading: string;
  notLeading: string;
}

@Component({
  selector: 'app-market-position-stats',
  templateUrl: './position-stats.component.html',
  styleUrls: ['./position-stats.component.scss']
})
export class PositionStatsComponent implements OnDestroy {
  @Input() isConsolidatedMLS: boolean;
  public isLeading = false;
  marketPositionData: IMarketPositionData;
  totalMarketShare = 'N/A';
  marketPos = 'N/A';
  distanceTo = 'N/A';
  subs = new Subscription();

  constructor(private store: Store<IAppState>, private optionsService: OptionsService) {
    this.subs.add(
      this.store
        .select(selectMarketReportData)
        .pipe(filter(data => data !== null))
        .subscribe((data: IMarketPositionData) => {
          if (!data.realogyPosition) {
            this.totalMarketShare = 'N/A';
            this.marketPos = 'N/A';
            this.distanceTo = 'N/A';
          } else {
            this.marketPositionData = cloneDeep(data);
            this.marketPos = '#' + this.marketPositionData.realogyPosition?.marketPosition.toString();
            this.isLeading = this.marketPositionData.realogyPosition?.marketPosition === 1;
            const mstypeValue: Unit = this.selectTypeUnits(this.marketPositionData.msTypeName, this.marketPositionData.isUnits);
            this.distanceTo = this.isLeading ? mstypeValue.isLeading : mstypeValue.notLeading;
            this.totalMarketShare = mstypeValue.percent.toString() + '%';
          }
        })
    );
  }

  selectTypeUnits(typeName: string, isUnits: boolean) {
    const units: Unit = {
      percent: '',
      isLeading: '',
      notLeading: ''
    };

    const typeToString = `${typeName.toLowerCase()}`;
    const topGroupArr = this.marketPositionData.topGroupTypes;
    const realogyArr = this.marketPositionData.realogyPosition;

    units.percent = (isUnits ? realogyArr[`${typeToString}UnitsPercent`] : realogyArr[`${typeToString}VolumePercent`]) || 0;

    units.isLeading = isUnits
      ? (topGroupArr[0][`${typeToString}Units`] - topGroupArr[1][`${typeToString}Units`]).toString()
      : this.optionsService.numFormatter(topGroupArr[0][`${typeToString}Volume`] - topGroupArr[1][`${typeToString}Volume`], 1);

    units.notLeading = isUnits
      ? (topGroupArr[0][`${typeToString}Units`] - realogyArr[`${typeToString}Units`]).toString()
      : this.optionsService.numFormatter(topGroupArr[0][`${typeToString}Volume`] - realogyArr[`${typeToString}Volume`], 1);

    return units;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
