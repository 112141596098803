import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Store } from '@ngrx/store';
import { ReportService } from 'app/services/report.service';
import { omit } from 'lodash-es';
import { ExportOption } from '../../../../models/ExportOption';
import { ITrendsOptionEnum } from '../../../../models/ITrendsOptions';
import { ReportTypeEnum, ReportTypeLongStrEnum } from '../../../../models/ReportTypes';
import { ExportService } from '../../../../services/export.service';
import { ModalService } from '../../../../services/modal.service';
import { FetchTrendsPdf } from '../../../../store/actions/market-trends.action';
import { FetchCustomReportPdf } from '../../../../store/actions/custom-report.action';
import { FetchReportPDF, ResetExportPreviewData, ResetPdfOptions, UpdatePdfOptions } from '../../../../store/actions/pdf.action';
import { pdfPending } from '../../../../store/selectors/pdf.selector';
import { IAppState } from '../../../../store/state/app.state';
import { IPdfOptions } from '../../../../store/state/pdf.state';
import { CustomReportService } from 'app/services/custom-report.service';

@Component({
  selector: 'app-export-preview-controls',
  templateUrl: './export-preview-controls.component.html',
  styleUrls: ['./export-preview-controls.component.scss']
})
export class ExportPreviewControlsComponent implements OnInit, OnDestroy {
  @Input() showComplex = false;
  @Input() showMarketPositionTable = false;
  @Input() showOrientation = false;
  @Input() reportType = 0;
  @Input() isSIR: boolean;
  ReportTypeEnum = ReportTypeEnum;
  public formGroup: UntypedFormGroup;
  optionArray: ExportOption[] = [];
  indeterminateEnabled = false;
  selectAllEnabled = true;
  loading: boolean;
  downloadPending = false;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<IAppState>,
    private modalService: ModalService,
    private exportSvc: ExportService,
    private reportService: ReportService,
    private customReportService: CustomReportService
  ) {
    this.formGroup = this.fb.group({
      showcover: [true],
      showtable: [true],
      showamsp: [true],
      showsplp: [true],
      shownbrsoldar: [true],
      showdom: [true],
      showspsqft: [true],
      showaimsi: [true],
      shownl: [true],
      islandscape: [true],
      showBrandLogo: [true],
      showAgentInfo: [true],
      showFirmNames: [true],
      showMarketPositionTable: [true],
      showTrendLines: [this.customReportService.getIsThreePointChart() ? false : true]
    });
    this.formGroup.setValidators(this.pdfSectionRequired());

    this.reportService.downloadSuccess().subscribe((fileName: string) => {
      if (fileName) {
        this.downloadPending = false;
        this.loading = false;
      }
    });
  }

  private static setSelectAllCheckbox<T>(uniqFormValues: T[]) {
    if (uniqFormValues.length === 1) {
      return uniqFormValues.every(Boolean);
    }
    return false;
  }

  ngOnInit(): void {
    this.optionArray = this.generateOptions(ReportTypeEnum[this.reportType] as ReportTypeLongStrEnum).filter(option => {
      if (option.formControlName === 'showTrendLines' && this.customReportService.getIsThreePointChart()) {
        return false;
      }
      return true;
    });
    this.store.select(pdfPending).subscribe(d => {
      this.loading = d;
    });

    this.formGroup.valueChanges.subscribe((d: IPdfOptions) => {
      const values = omit(d, ['islandscape', 'showBrandLogo', 'showAgentInfo', 'showFirmNames', 'showMarketPositionTable']);
      const uniqFormValues = [...new Set(Object.values(values))];
      this.indeterminateEnabled = uniqFormValues.length > 1;
      this.selectAllEnabled = ExportPreviewControlsComponent.setSelectAllCheckbox(uniqFormValues);
      this.store.dispatch(new UpdatePdfOptions(d));
    });
  }

  submit() {
    if (this.loading || this.downloadPending) {
      return;
    }

    this.downloadPending = true;
    if (this.showComplex && this.reportType === 2) {
      return !this.loading && this.store.dispatch(new FetchTrendsPdf(this.formGroup.value));
    }
    if (this.showComplex && this.reportType === 4) {
      return !this.loading && this.store.dispatch(new FetchCustomReportPdf(this.formGroup.value));
    } else {
      return (
        !this.loading &&
        this.store.dispatch(
          new FetchReportPDF({
            reportType: this.reportType,
            orientation: this.formGroup.get('islandscape').value ? 'landscape' : 'portrait',
            isSIR: this.isSIR,
            showLogo: this.formGroup.get('showBrandLogo').value,
            showAgentInfo: this.formGroup.get('showAgentInfo').value,
            showFirmName: this.formGroup.get('showFirmNames').value,
            showMarketPositionTable: this.formGroup.get('showMarketPositionTable').value
          })
        )
      );
    }
  }

  changeAll($event: MatCheckboxChange) {
    const keys = {};
    Object.keys(this.formGroup.controls).forEach(key => {
      if (key !== 'islandscape') {
        keys[key] = $event.checked;
      }
    });
    this.formGroup.patchValue(keys);
  }

  adjustOrientation(b: boolean) {
    this.formGroup.get('islandscape').patchValue(b);
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetPdfOptions());
    this.store.dispatch(new ResetExportPreviewData());
  }

  close() {
    this.modalService.close();
  }

  private generateOptions(type: ReportTypeLongStrEnum): ExportOption[] {
    return this.exportSvc.generatePreviewControlsArray(type);
  }

  private pdfSectionRequired = () => {
    return controlGroup => {
      const controls = controlGroup.controls;
      if (controls) {
        const selectedCtrl = Object.values(ITrendsOptionEnum).find(key => !!controls[key].value);
        if (!selectedCtrl) {
          return {
            atLeastOneRequired: true
          };
        }
      }
      return null;
    };
  };
}
