import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenantService } from '../tenant.service';
import { CbLoginService } from './cb-login.service';
import { getLoginService, LoginService } from './login.service';
import { SirLoginService } from './sir-login.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: LoginService,
      useFactory: getLoginService,
      deps: [TenantService, CbLoginService, SirLoginService]
    }
  ]
})
export class LoginModule { }
