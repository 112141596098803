import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { indexOf } from 'lodash-es';
import { Observable } from 'rxjs';
import { ILocationsData } from '../models/ILocationsData';
import { ReportTypeEnum, ReportTypeLongStrEnum } from '../models/ReportTypes';
import { selectSavedSearches } from '../store/selectors/saved-search.selector';
import { IAppState } from '../store/state/app.state';
import { ISavedSearch } from '../store/state/savedSearch.state';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class SavedSearchService {
  get savedSearches(): {
    Uno: ISavedSearch[];
    MarketPosition: ISavedSearch[];
    MarketTrends: ISavedSearch[];
    MarketSnapshot: ISavedSearch[];
  } {
    return this._savedSearches;
  }

  set savedSearches(value: {
    Uno: ISavedSearch[];
    MarketPosition: ISavedSearch[];
    MarketTrends: ISavedSearch[];
    MarketSnapshot: ISavedSearch[];
  }) {
    this._savedSearches = value;
  }
  private _savedSearches: {
    Uno: ISavedSearch[];
    MarketPosition: ISavedSearch[];
    MarketTrends: ISavedSearch[];
    MarketSnapshot: ISavedSearch[];
  };
  constructor(private http: HttpClient, private store: Store<IAppState>, private filterSvc: FilterService) {
    this.store.select(selectSavedSearches).subscribe(savedSearches => {
      this.savedSearches = savedSearches;
    });
  }

  deleteSavedSearch(id: string) {
    const otkaId = JSON.parse(localStorage.getItem('userData')).info.oktaId;
    return this.http.delete(`${environment.apiBaseUrl}/api/filter/DeleteUserFilter?id=${id}&oktaId=${otkaId}`);
  }

  getAllSavedSearches(): Observable<ISavedSearch[]> {
    const otkaId = JSON.parse(localStorage.getItem('userData')).info.oktaId;
    return this.http.get<ISavedSearch[]>(`${environment.apiBaseUrl}/api/filter/GetUserFilters?&oktaId=${otkaId}`);
  }

  createSavedSearch(search: ISavedSearch): Observable<string> {
    const otkaId = JSON.parse(localStorage.getItem('userData')).info.oktaId;
    const name = search.name;
    const mlsid = search.mlsId;
    const reportTypes = search.reportType;
    const isDefault = search.isDefault;
    const data = search.data;

    return this.http.post(
      `${environment.apiBaseUrl}/api/filter/PostUserFilter?oktaId=${otkaId}&name=${name}&mlsid=${mlsid}&reportTypes=${reportTypes}&isDefault=${isDefault}`,
      data,
      {
        responseType: 'text'
      }
    );
  }

  setSearchDefault(rowKey: string, mls: string, reportType: ReportTypeEnum) {
    const otkaId = JSON.parse(localStorage.getItem('userData')).info.oktaId;
    return this.http.put<ISavedSearch[]>(
      `${environment.apiBaseUrl}/api/filter/UpdateUserDefaultFilter?oktaId=${otkaId}&id=${rowKey}&mlsId=${mls}&reportType=${reportType}`,
      {}
    );
  }

  mapSavedSearchApiResponse(savedSearches: ISavedSearch[]) {
    const sortArray = {} as { [key: string]: ISavedSearch[] };
    Object.keys(ReportTypeLongStrEnum).map((key: string) => {
      sortArray[key] = [];
    });
    savedSearches.forEach((item: ISavedSearch) => {
      if (indexOf(Object.keys(ReportTypeEnum), item.reportType.toString()) < 0) {
        return;
      }
      item.data = JSON.parse((item.data as unknown) as string);
      (item.data.locationFilters || []).map((location: ILocationsData) => {
        Object.keys(location).forEach((key: string) => {
          if (key === 'areaID') {
            location[key] = Number(location[key]);
          }
          if (key === 'areaType') {
            location[key] = Number(location[key]);
          }
        });
      });
      item.createdDate = new Date(item.createdDate);
      sortArray[ReportTypeEnum[item.reportType]].push(item);
    });
    Object.keys(sortArray).map(key => {
      sortArray[key] = sortArray[key].sort((a, b) => {
        return b.createdDate.getTime() - a.createdDate.getTime();
      });
    });
    return sortArray;
  }

  lookupLocationByAreaIdAndType(areaId: number, areaType: number) {
    const locs = this.filterSvc.locations;
    return locs.find(location => location.areaID === areaId && location.areaType === areaType);
  }
}
