import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-youtube-svg',
  templateUrl: './youtube-svg.component.html',
  styleUrls: ['./youtube-svg.component.scss']
})
export class YoutubeSvgComponent {
  @HostBinding('class') classes = 'market-quest-icon ';
  @Input() size = 24;
  @Input() fillColor = '#1f69ff';

  constructor() {}
}
