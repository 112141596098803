import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FeaturesEnum, IFeature } from '../models/IFeature';
import { selectFeatures } from '../store/selectors/features.selector';
import { IAppState } from '../store/state/app.state';

@Injectable()
export class FeatureService {
  private headers: HttpHeaders;

  constructor(private _http: HttpClient, private store: Store<IAppState>) {
    this.store.select(selectFeatures).subscribe(features => {
      this.features = features;
    });
  }

  private _features: IFeature[];
  get features(): IFeature[] {
    return this._features;
  }

  set features(value: IFeature[]) {
    this._features = value;
  }

  hasFlags(flags: FeaturesEnum) {
    if (flags === undefined || flags === null) {
      return false;
    }
    return this.features.some(f => f.featureID === flags);
  }

  getAllFeaturesForOktaId(oktaId: string) {
    return this._http
      .get<IFeature[]>(environment.apiBaseUrl + `/api/feature/getfeatures/?oktaid=${oktaId}`, { headers: this.headers })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message));
  }
}
