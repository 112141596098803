import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IEmailPostBody } from '../models/IEmailData';
import { IPageTypes } from '../models/IPageTypes';

@Injectable()
export class EmailService {
  private headers: HttpHeaders;

  constructor(private _http: HttpClient) {}

  sendEmail(post: IEmailPostBody, type: IPageTypes): Observable<any> {
    let t = type ?? null;
    return this._http
      .post(`${environment.apiBaseUrl}/api/email/send${t}Report`, post, { headers: this.headers, responseType: 'text' })
      .pipe(catchError(EmailService.errorHandler));
  }

  private static errorHandler(error: HttpErrorResponse) {
    return of(error);
  }
}
