import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { addDays, subDays, subYears } from 'date-fns';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DateService, fullDate } from '../../../../../services/date.service';

@Component({
  selector: 'app-multi-date-range-selection',
  templateUrl: './multi-date-range-selection.component.html',
  styleUrls: ['./multi-date-range-selection.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DateService
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: fullDate
    }
  ]
})
export class MultiDateRangeSelectionComponent implements OnInit, OnDestroy {
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() adjustedMinDate: Date;
  @Input() lastRunDate: Date;
  @Input() startControl: UntypedFormControl = new UntypedFormControl();
  @Input() endControl: UntypedFormControl = new UntypedFormControl();
  @Input() isCustomReport = false;
  @Input() hideRadio = false;
  subs = new Subscription();

  constructor() {}

  ngOnInit(): void {
    if (this.endControl.value) {
      this.minDate = subYears(new Date(this.endControl.value), this.isCustomReport ? 2 : 1);
      this.maxDate = subDays(new Date(this.endControl.value), 1);
    }

    this.subs.add(
      this.startControl.valueChanges.pipe(filter(v => !!v)).subscribe(startDate => {
        this.adjustedMinDate = addDays(new Date(startDate), 1);
      })
    );

    this.subs.add(
      this.endControl.valueChanges.pipe(filter(v => !!v)).subscribe(endDate => {
        this.minDate = subYears(new Date(endDate), this.isCustomReport ? 2 : 1);
        this.maxDate = subDays(new Date(endDate), 1);
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
