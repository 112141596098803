export interface IFeature {
  featureID: number;
  featureName: FeaturesLabel;
}

export enum FeaturesEnum {
  Debug = 0,
  Videos,
  '#1 Claims',
  'Market Position',
  'Market Trends',
  'Market Snapshot',
  'Custom Report',
  Dashboard
}

export enum FeaturesLabel {
  Debug = 'Debug',
  Videos = 'Videos',
  '#1 Claims' = '#1 Claims',
  'Market Position' = 'Market Position',
  'Market Trends' = 'Market Trends',
  'Market Snapshot' = 'Market Snapshot',
  'Custom Report' = 'Custom Report',
  Dashboard = 'Dashboard'
}

const FeaturesList = [
  { featureID: 0, featureName: FeaturesEnum.Debug },
  { featureID: 1, featureName: FeaturesEnum.Videos },
  { featureID: 2, featureName: FeaturesEnum['#1 Claims'] },
  { featureID: 3, featureName: FeaturesEnum['Market Position'] },
  { featureID: 4, featureName: FeaturesEnum['Market Trends'] },
  { featureID: 5, featureName: FeaturesEnum['Market Snapshot'] },
  { featureID: 6, featureName: FeaturesEnum['Custom Report'] },
  { featureID: 7, featureName: FeaturesEnum.Dashboard }
];
