import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DataRow } from '../../../../../models/ICustomDataTypeEnum';
import { SharedComponentsModule } from '../../../shared/shared-components.module';

@Component({
  standalone: true,
  imports: [CommonModule, SharedComponentsModule],
  selector: 'app-custom-report-table',
  templateUrl: './custom-report-table.component.html',
  styleUrls: ['./custom-report-table.component.scss']
})
export class CustomReportTableComponent implements OnInit {
  @Input() tableRowsArr: DataRow[];
  @Input() tableHeaderArr: string[];

  constructor() {}

  ngOnInit(): void {}
}
