import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SocialImageDirective } from '../../../../directives/social-image.directive';
import { GalleryImage } from '../../../../models/GalleryImage';
import { IMarketAssetCard } from '../../../../models/IMarketAssetCard';
import { IReportDetail } from '../../../../models/IReportDetail';
import { EncryptedReportData } from '../../../../models/IReportPageData';
import { ThemeService } from '../../../../services/theme.service';
import { selectBrandCode } from '../../../../store/selectors/applicationData.selector';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-report-download-area',
  templateUrl: './report-download-area.component.html',
  styleUrls: ['./report-download-area.component.scss']
})
export class ReportDownloadAreaComponent implements OnInit, OnDestroy {
  subs = new Subscription();
  imageAssetCard: IMarketAssetCard;
  graphAssetCard: IMarketAssetCard;
  videoAssetCard: IMarketAssetCard;
  @Input() reportData: EncryptedReportData;
  isSIR: boolean;
  @ViewChild(SocialImageDirective, { static: true }) imageContainer: SocialImageDirective;
  hasVideo = false;

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private store: Store<IAppState>,
    private themeService: ThemeService
  ) {}

  private static assembleTileImageUrlPaths(base: string, tileType: 'image' | 'chart') {
    return base + `mq-uno/webpage/tile-${tileType}.jpg`;
  }

  private static setupImageAssetCard(imageURL: string, location: string, showHomeSpotter = true, isMarketPosition = false) {
    const imageData: IMarketAssetCard = {
      imageURL: imageURL,
      location: location,
      youTubeId: '',
      videoFileName: '',
      title: `${isMarketPosition ? '' : '#1 '}Image`,
      type: 'image',
      hasGraph: false,
      description: `Download your ${isMarketPosition ? 'report' : '#1 claim'} as an image to post on social media${
        showHomeSpotter ? ' or promote via HomeSpotter' : ''
      }.`
    };
    return imageData;
  }

  private static setupGraphAssetCard(imageURL: string, location: string, showHomeSpotter = true, isMarketPosition = false) {
    const data: IMarketAssetCard = {
      imageURL: imageURL,
      location: location,
      youTubeId: '',
      videoFileName: '',
      title: `${isMarketPosition ? '' : '#1 '}Graph`,
      type: 'image',
      hasGraph: true,
      description: `Download your ${isMarketPosition ? 'report' : '#1 claim'} as a graph to post on social media${
        showHomeSpotter ? ' or promote via HomeSpotter' : ''
      }.`
    };
    return data;
  }

  private static setupVideoAssetCard(videoUrls: GalleryImage[], imageURL: string, location: string) {
    const data: IMarketAssetCard = {
      imageURL: imageURL,
      youTubeId: videoUrls?.length ? videoUrls[0]?.imageURL : '',
      videoFileName: videoUrls?.length && videoUrls.length > 1 ? videoUrls[1]?.imageURL : '',
      location: location,
      title: '#1 Video',
      type: 'video',
      hasGraph: false,
      description: 'Download your #1 claim as a video to post on social media.'
    };
    return data;
  }

  ngOnInit(): void {
    this.store
      .select(selectBrandCode)
      .pipe(filter(v => !!v))
      .subscribe(() => {
        this.isSIR = this.themeService.isSIR();
      });

    this.subs.add(
      this.route.data
        .pipe(
          map(data => {
            const reportInformation: [[string, IReportDetail, string], string] = data.reportData;
            return reportInformation;
          })
        )
        .subscribe(reportInformation => {
          this.hasVideo = this.reportData.reportDetail?.videoEntities?.length > 0;

          this.videoAssetCard = ReportDownloadAreaComponent.setupVideoAssetCard(
            this.reportData.reportDetail?.videoEntities,
            '',
            this.reportData.reportDetail?.reportHeaderEntity?.areaLocalName
          );
          this.imageAssetCard = ReportDownloadAreaComponent.setupImageAssetCard(
            ReportDownloadAreaComponent.assembleTileImageUrlPaths(
              (reportInformation && reportInformation.length && reportInformation[1]) || '',
              'image'
            ),
            this.reportData.reportDetail?.reportHeaderEntity?.areaLocalName,
            !this.isSIR,
            !!this.reportData.reportType
          );
          this.graphAssetCard = ReportDownloadAreaComponent.setupGraphAssetCard(
            ReportDownloadAreaComponent.assembleTileImageUrlPaths(
              (reportInformation && reportInformation.length && reportInformation[1]) || '',
              'chart'
            ),
            this.reportData.reportDetail?.reportHeaderEntity?.areaLocalName,
            !this.isSIR,
            !!this.reportData.reportType
          );
        })
    );
  }

  copyUrlEvent() {
    this.snackBar.open('Website link has been copied to your clipboard.', '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'right'
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
