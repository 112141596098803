import { ICustomDataDetails, ICustomDataTypeByNumEnum, ICustomDisplayAsEnum } from '../../models/ICustomDataTypeEnum';
import { ICustomReportOptions } from '../../models/ICustomReportOptions';

export interface ICustomReportControl {
  dataKey: string;
  description: string;
  dataType: ICustomReportControlDataType;
  dataCategory: number;
  disabled?: boolean;
  chartType?: ICustomDisplayAsEnum;
}

type ICustomReportControlDataType = 'percent' | 'dollar' | 'int' | 'decimal';

export interface ICustomGraphSetting {
  idx: number;
  value: number;
}
interface ICustomReportPdfState {
  pending: boolean;
  options: ICustomReportOptions;
}

export interface ICustomReportState {
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  showAllData: boolean;
  customReportDetails: ICustomDataDetails;
  customReportFilters: any;
  customReportDisplayDataAs: [number, number];
  customReportDataTypes: [number, number];
  customReportGraphAxis: (0 | 1)[];
  pdfState: ICustomReportPdfState;
}

export const initialCustomReportState: ICustomReportState = {
  customReportDetails: undefined,
  customReportFilters: undefined,
  hasError: false,
  loaded: false,
  loading: false,
  showAllData: false,
  customReportDisplayDataAs: [ICustomDisplayAsEnum.Column, ICustomDisplayAsEnum.Column],
  customReportGraphAxis: [0, 1],
  customReportDataTypes: [ICustomDataTypeByNumEnum.msp, ICustomDataTypeByNumEnum.alp],
  pdfState: {
    pending: false,
    options: {
      showcover: true,
      showAgentInfo: true,
      showtable: true,
      islandscape: true
    }
  }
};

export function getInitialCustomReportState(): ICustomReportState {
  return initialCustomReportState;
}
