import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToggleSideBarNav } from '../../../store/actions/menus.action';
import { selectSideBarStatus } from '../../../store/selectors/menus.selector';
import { IAppState } from '../../../store/state/app.state';

@Component({
  selector: 'app-side-bar-nav',
  templateUrl: './side-bar-nav.component.html',
  styleUrls: ['./side-bar-nav.component.scss']
})
export class SideBarNavComponent {
  openStatus$ = this.store.select(selectSideBarStatus);

  constructor(private store: Store<IAppState>) {}

  toggleSideBarState() {
    this.store.dispatch(new ToggleSideBarNav());
  }
}
