import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MarketClaimsService {
  private _claimsFormGroup;

  get claimsFormGroup() {
    return this._claimsFormGroup;
  }

  set claimsFormGroup(value) {
    this._claimsFormGroup = value;
  }

  constructor() {}

  saveClaimsFilterSession(value: unknown) {
    this.claimsFormGroup = value;
  }

  restoreClaimsFilterSession() {
    return this.claimsFormGroup;
  }

  resetClaimsFilterSession() {
    this.claimsFormGroup = null;
  }
}
