import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginService } from './login/login.service';

@Injectable()
export class MqOktaAuthGuard implements CanActivate {
  constructor(private loginService: LoginService, private oktaAuth: OktaAuth) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.oktaAuth.isAuthenticated().then(res => {
      if (res) {
        return true;
      } else {
        this.loginService.login();
        return false;
      }
    });
  }
}
