import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';
import { BannerTypeEnums, IBannerData } from '../models/IBannerData';
import { Disclaimer } from '../models/IDisclaimer';
import { IMarketReportDate } from '../models/IMarketReportDate';
import { selectDateRanges } from '../store/selectors/market-position.selector';
import { IAppState } from '../store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  subs = new Subscription();
  private currentDate: string;
  private dates: any;
  private quarterEndDate: string;

  constructor(private store: Store<IAppState>) {
    this.subs.add(
      this.store.select(selectDateRanges).subscribe((dates: IMarketReportDate[]) => {
        this.dates = cloneDeep(dates);

        dates.map(dateType => {
          if (dateType.datePeriod === 'NQR') {
            this.quarterEndDate = dateType.startDate;
          }
        });
      })
    );

    this.data = [];
    this.currentDate = formatDate(new Date(), 'MM/dd/yyyy', 'en');
    this._currentYear = new Date(this.currentDate).getFullYear();

    const arr = [
      {
        url: 'https://mycbdesk.com/help-center/market-quest/faqs/article/marketquest-v2-faqs',
        message: 'Due to MLS restrictions, we cannot display competitor data.',
        type: BannerTypeEnums.consolidated,
        closeable: false
      }
    ];
    arr.map(d => this.data.push(new Disclaimer(d)));
  }

  private _currentYear: number;

  get currentYear(): number {
    return this._currentYear;
  }

  set currentYear(value: number) {
    this._currentYear = value;
  }

  private _data: IBannerData[];

  get data(): IBannerData[] {
    return this._data;
  }

  set data(value: IBannerData[]) {
    this._data = value;
  }

  setPageBanners(types: BannerTypeEnums[]): Disclaimer[] {
    const d: Disclaimer[] = [];
    this.data
      .filter(banner => types.includes(banner.type))
      .forEach(validBanner => {
        const vData: Disclaimer = {
          message: validBanner.message,
          type: validBanner.type,
          show: true,
          url: validBanner.url,
          closeable: validBanner?.closeable || false
        };
        d.push(new Disclaimer(vData));
      });
    return d;
  }
}
