import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SaveSearchModalComponent } from 'app/components/modals/save-search-modal/save-search-modal.component';
import { locationsLoaded } from 'app/store/selectors/locations.selector';
import { selectCurrentMLS } from 'app/store/selectors/mls.selector';
import { combineLatest, combineLatestWith, Observable, of, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ISavedSearchModalData } from '../../../../models/ISavedSearchModalData';
import { ReportTypeEnum } from '../../../../models/ReportTypes';
import { ModalService } from '../../../../services/modal.service';
import { SavedSearchService } from '../../../../services/saved-search.service';
import { ToggleFilterPanel } from '../../../../store/actions/menus.action';
import { FetchSavedSearches } from '../../../../store/actions/saved-search.action';
import { selectSavedSearches } from '../../../../store/selectors/saved-search.selector';
import { IAppState } from '../../../../store/state/app.state';
import { ISavedSearch, ISavedSearchData } from '../../../../store/state/savedSearch.state';
import { SavedSearchModalComponent } from '../../../modals/saved-search-modal/saved-search-modal.component';

@Component({
  selector: 'app-saved-search-input',
  templateUrl: './saved-search-input.component.html',
  styleUrls: ['./saved-search-input.component.scss']
})
export class SavedSearchInputComponent implements OnInit, OnDestroy {
  @Input() reportType: ReportTypeEnum;
  @Input() filterForm: UntypedFormGroup;
  @Output() applyFilters: EventEmitter<any> = new EventEmitter<any>();
  mls$ = this.store.select(selectCurrentMLS);
  savedSearches$ = this.store.select(selectSavedSearches);
  subs = new Subscription();
  searchesAvailable$: Observable<boolean> = of(true);
  isActive$: Observable<boolean> = of(true);

  constructor(private modalSvc: ModalService, private store: Store<IAppState>, private savedSearchSvc: SavedSearchService) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.retrieveSavedSearches();
    this.initSavedSearches();
    this.getAvailableSearches();
    this.checkCanSaveSearch();
  }

  retrieveSavedSearches(): void {
    this.subs.add(
      this.store
        .select(locationsLoaded)
        .pipe(filter(v => !!v))
        .subscribe(() => {
          this.store.dispatch(new FetchSavedSearches());
        })
    );
  }

  initSavedSearches(): void {
    this.subs.add(
      combineLatest([
        this.mls$,
        this.store.select(selectSavedSearches).pipe(
          filter(v => !!v),
          take(1)
        )
      ]).subscribe(([mls, searches]) => {
        if (!searches) {
          return;
        }
        const defaultSearch = (searches[ReportTypeEnum[this.reportType]] || []).find(search => {
          return search.isDefault && mls.mlsId === search.mlsId;
        });
        if (defaultSearch) {
          this.dispatchFilters(defaultSearch.data);
        }
      })
    );
  }

  getAvailableSearches(): void {
    this.searchesAvailable$ = combineLatest([this.savedSearches$, this.mls$]).pipe(
      filter(searches => {
        const [savedSearches, mlsData] = searches;
        return !!mlsData && !!savedSearches;
      }),
      map(searchList => {
        const [savedSearches, mls] = searchList;
        return (savedSearches[ReportTypeEnum[this.reportType]] || []).filter(
          search => search.reportType === this.reportType && search.mlsId === mls.mlsId
        );
      }),
      map(searches => {
        return searches.length > 0;
      })
    );
  }

  checkCanSaveSearch(): void {
    this.isActive$ = this.savedSearches$.pipe(
      combineLatestWith(this.filterForm?.statusChanges || of([])),
      map(() => {
        const filterValues: ISavedSearchData = this.filterForm?.value;
        let active = true;
        if (this.reportType === ReportTypeEnum.MarketSnapshot) {
          active = active && filterValues.locationFilters.length > 0;
        }
        return active;
      })
    );
  }

  openSaveSearchModal() {
    let searchesByReportAndMls = this.savedSearchSvc.savedSearches?.[ReportTypeEnum[this.reportType]] as ISavedSearch[];
    const data: ISavedSearchModalData = {
      title: 'Name This Search',
      subTitle: 'What would you like to name this search criteria?',
      reportType: this.reportType,
      filters: this.filterForm,
      searches: searchesByReportAndMls
    };
    this.modalSvc
      .open(SaveSearchModalComponent, {
        data,
        width: 'auto',
        height: 'auto',
        maxWidth: '100%'
      })
      .afterClosed()
      .subscribe((value: FormGroup) => {
        if (value?.value) {
          this.store.dispatch(new FetchSavedSearches());
        }
      });
  }

  openViewSearchModal() {
    const data: ISavedSearchModalData = {
      title: 'Saved Searches & Search Criteria',
      subTitle: 'Easily retrieve your saved and recent searches.',
      reportType: this.reportType,
      searches: this.savedSearchSvc.savedSearches[ReportTypeEnum[this.reportType]]
    };
    this.store.dispatch(new FetchSavedSearches());
    this.modalSvc
      .open(SavedSearchModalComponent, {
        data,
        width: '80%',
        height: '80%',
        maxWidth: '1064px'
      })
      .afterClosed()
      .subscribe((value: ISavedSearch) => {
        if (value) {
          this.store.dispatch(new ToggleFilterPanel(false));
          this.dispatchFilters(value.data);
        }
      });
  }

  dispatchFilters(filters): void {
    const mappedFilters = JSON.parse(JSON.stringify(filters));
    if (mappedFilters.report.dateRangeMulti) {
      mappedFilters.report.dateRangeMulti.endDate = new Date(filters.report.dateRangeMulti.endDate);
      mappedFilters.report.dateRangeMulti.startDate = new Date(filters.report.dateRangeMulti.startDate);
      mappedFilters.report.dateRangeMulti.monthlyEndDate = new Date(filters.report.dateRangeMulti.monthlyEndDate);
    }

    if (mappedFilters.report.dateRange) {
      mappedFilters.report.dateRange = new Date(filters.report.dateRange);
    }
    this.applyFilters.emit(mappedFilters);
  }
}
