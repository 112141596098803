import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mq-footer-sir-disclaimer',
  templateUrl: './mq-footer-sir-disclaimer.component.html',
  styleUrls: ['./mq-footer-sir-disclaimer.component.scss']
})
export class MqFooterSirDisclaimerComponent {
  @Input() disclaimerText: string;
  @Input() copyrightText: string;
  currYear: number;

  constructor() {
    this.currYear = new Date().getFullYear();
  }
}
