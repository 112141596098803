import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { INavButton } from '../models/INavButton';
import { SCREEN_SIZES } from '../models/IScreenSizes';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  leftSideNavButtonData: INavButton[] = [];
  leftSideNavButtonSubject = new BehaviorSubject(this.leftSideNavButtonData);

  constructor(private http: HttpClient) {
    this.headerAvatarMenuButtons = this.createAvatarMenuButtonsArray(NavigationService.getHeaderAvatarMenuButtonData());
  }

  private _headerAvatarMenuButtons: INavButton[];

  get headerAvatarMenuButtons(): INavButton[] {
    return this._headerAvatarMenuButtons;
  }

  set headerAvatarMenuButtons(value: INavButton[]) {
    this._headerAvatarMenuButtons = value;
  }

  private static getHeaderAvatarMenuButtonData(): INavButton[] {
    const data = [
      {
        label: 'Help Center',
        urlPath: 'https://mycbdesk.com/help-center/market-quest',
        matIcon: 'help_outline',
        enabled: true,
        breakPoints: [SCREEN_SIZES.mobile, SCREEN_SIZES.tablet],
        urlExternal: true
      },
      {
        label: 'Settings',
        urlPath: 'settings',
        matIcon: 'settings',
        enabled: true,
        breakPoints: [SCREEN_SIZES.all]
      },
      {
        label: 'Feedback',
        urlPath: 'feedback',
        matIcon: 'feedback',
        enabled: true,
        breakPoints: [SCREEN_SIZES.all]
      },
      {
        label: 'Sign Out',
        urlPath: 'logout',
        matIcon: 'logout',
        enabled: true,
        breakPoints: [SCREEN_SIZES.all]
      }
    ];
    return this.makeButtonArray(data);
  }

  getLeftSideNavButtons(oktaId: string): Observable<INavButton[]> {
    return this.http.get<INavButton[]>(`${environment.apiBaseUrl}/api/navigation/getleftsidebar/${oktaId}`);
  }

  private static makeButtonArray(data: Partial<INavButton>[]) {
    return data.map(btn => new INavButton(btn));
  }

  private createNavigationButtonsArray(data: INavButton[]): INavButton[] {
    return data.map(button => new INavButton(button));
  }

  private createAvatarMenuButtonsArray(data: INavButton[]): INavButton[] {
    return data.map(button => new INavButton(button));
  }
}
