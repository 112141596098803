import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SocialSvgModule } from '../../core/shared/social-svg/social-svg.module';
import { MqFooterSirContactComponent } from './mq-footer-sir-contact/mq-footer-sir-contact.component';
import { MqFooterSirDisclaimerComponent } from './mq-footer-sir-disclaimer/mq-footer-sir-disclaimer.component';
import { MqFooterSirSocialComponent } from './mq-footer-sir-social/mq-footer-sir-social.component';
import { MqFooterSIRComponent } from './mq-footer.component';

@NgModule({
  declarations: [
    MqFooterSIRComponent,
    MqFooterSirContactComponent,
    MqFooterSirSocialComponent,
    MqFooterSirDisclaimerComponent
  ],
  imports: [
    CommonModule,
    SocialSvgModule
  ],
  exports: [
    MqFooterSIRComponent
  ]
})
export class MqFooterSIRModule { }
