import { IAppState } from '../state/app.state';
import { createSelector } from '@ngrx/store';
import { IAspTrendsDataResponse } from '../state/asp-trends.state';

const AppData = (state: IAppState) => state;

export const aspTrendsLoading = createSelector(AppData, (state: IAppState) => {
  return state.aspTrends.loading;
});

export const aspTrendsLoaded = createSelector(AppData, (state: IAppState) => {
  return state.aspTrends.loaded;
});

export const aspTrendsError = createSelector(AppData, (state: IAppState) => {
  return state.aspTrends.hasError;
});

export const aspTrendsData = createSelector(AppData, (state: IAppState) => {
  return state.aspTrends.aspTrendsReportData;
});

export const aspTrendsChartData = createSelector(AppData, (state: IAppState) => {
  return state.aspTrends.aspTrendsReportData.reportData;
});

export const aspTrendsPDFPending = createSelector(AppData, (state: IAppState) => {
  return state.aspTrends.pdfDownloadPending;
});
