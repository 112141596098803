import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SetSortAscending } from '../../../../../../../store/actions/filtersData.action';
import { DownloadExportFileCSV, DownloadExportFileExcel } from '../../../../../../../store/actions/reports.action';
import { selectV2FilterBase } from '../../../../../../../store/selectors/filters.selector';
import { IAppState } from '../../../../../../../store/state/app.state';
import { SortChanges } from '../card-controls-container.component';

@Component({
  selector: 'app-card-controls',
  templateUrl: './card-controls.component.html',
  styleUrls: ['./card-controls.component.scss']
})
export class CardControlsComponent implements OnInit, OnDestroy {
  paging: UntypedFormGroup;
  subs = new Subscription();

  constructor(private store: Store<IAppState>, private fb: UntypedFormBuilder) {
    const filters$ = this.store.select(selectV2FilterBase);

    this.paging = this.fb.group({
      sortAscending: []
    });

    this.subs.add(
      filters$.pipe(distinctUntilChanged()).subscribe(filters => {
        this.paging.patchValue(
          {
            sortAscending: filters.sortAsc
          },
          { emitEvent: false }
        );
      })
    );
  }

  ngOnInit() {
    this.subs.add(
      this.paging.valueChanges.subscribe((values: SortChanges) => {
        this.store.dispatch(new SetSortAscending(values.sortAscending));
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  download(type: 'csv' | 'xlsx') {
    if (type === 'csv') {
      this.store.dispatch(new DownloadExportFileCSV());
    }
    if (type === 'xlsx') {
      this.store.dispatch(new DownloadExportFileExcel());
    }
    return;
  }
}
