import { format, sub } from 'date-fns';
import { MarketTrendsAction, MarketTrendsDataActionsActionTypes } from '../actions/market-trends.action';
import { getInitialMarketTrendsDataState, IMarketTrendsState, initialMarketTrendsDataState } from '../state/market-trends.state';

export function MarketTrendsDataReducer(state = getInitialMarketTrendsDataState(), action: MarketTrendsAction): IMarketTrendsState {
  switch (action.type) {
    case MarketTrendsDataActionsActionTypes.ResetTrendsPdfOptions: {
      return {
        ...state,
        pdfState: {
          ...state.pdfState,
          options: initialMarketTrendsDataState.pdfState.options
        }
      };
    }

    case MarketTrendsDataActionsActionTypes.UpdateTrendsPdfOptions: {
      return {
        ...state,
        pdfState: {
          ...state.pdfState,
          options: action.payload
        }
      };
    }

    case MarketTrendsDataActionsActionTypes.FetchTrendsReports: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case MarketTrendsDataActionsActionTypes.FetchTrendsReportsError: {
      return {
        ...state,
        hasError: true,
        loaded: false,
        loading: false
      };
    }

    case MarketTrendsDataActionsActionTypes.FetchTrendsReportsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        hasError: false,
        trendsReportDetails: {
          ...state.trendsReportDetails,
          ...action.payload
        },
        pdfState: {
          ...state.pdfState,
          pending: false
        }
      };
    }

    case MarketTrendsDataActionsActionTypes.FetchTrendsPdf: {
      return {
        ...state,
        pdfState: {
          ...state.pdfState,
          pending: true
        }
      };
    }

    case MarketTrendsDataActionsActionTypes.UpdateTrendsTableType: {
      return {
        ...state,
        chartDataType: action.payload
      };
    }

    case MarketTrendsDataActionsActionTypes.UpdateTrendsReportFilters: {
      return {
        ...state,
        trendsFilters: {
          ...state.trendsFilters,
          state: {
            ...state.trendsFilters.state,
            ...action.payload
          }
        }
      };
    }

    case MarketTrendsDataActionsActionTypes.RemoveSingleTrendsFilter: {
      const original = state.trendsFilters.state[action.payload.type];
      const update = original.filter(value => value !== action.payload.value);

      return {
        ...state,
        trendsFilters: {
          ...state.trendsFilters,
          state: {
            ...state.trendsFilters.state,
            [action.payload.type]: update
          }
        }
      };
    }

    case MarketTrendsDataActionsActionTypes.ClearTrendsReportFilters: {
      const currentDate = new Date();
      const maxDate = format(sub(currentDate, { months: 1 }), 'MM/yyyy');

      return {
        ...state,
        trendsFilters: {
          ...state.trendsFilters,
          state: {
            ...state.trendsFilters.state,
            endDate: maxDate,
            priceMin: 0,
            priceMax: 999999999,
            propertyType: [1, 2, 9]
          }
        }
      };
    }

    default:
      return state;
  }
}
