import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AspTrendsActionsActionTypes, FetchAspPDFSuccess, FetchAspTrends, FetchAspTrendsSuccess } from '../actions/asp-trends.action';
import { of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AspTrendsService } from '../../services/asp-trends.service';
import { ModalService } from '../../services/modal.service';
import { FileSaverService } from 'ngx-filesaver';
import { format } from 'date-fns';

@Injectable()
export class AspTrendsEffect {
  constructor(
    private actions$: Actions,
    private aspTrendsSvc: AspTrendsService,
    private modalService: ModalService,
    private fileSave: FileSaverService
  ) {}

  fetchAspTrends$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAspTrends>(AspTrendsActionsActionTypes.FetchAspTrends),
      switchMap(action => {
        const d = this.aspTrendsSvc.generateAspTrendsFilters(action.payload);
        return this.aspTrendsSvc.getAspTrendsData(d);
      }),
      map(data => {
        return new FetchAspTrendsSuccess(data);
      })
    )
  );

  fetchAspPDF$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAspTrends>(AspTrendsActionsActionTypes.FetchAspPDF),
      switchMap(action => {
        return this.aspTrendsSvc.fetchAspPDF(action.payload);
      }),
      map(data => {
        const fileName = `ASP-trends-report.pdf`;
        this.fileSave.save(data, fileName);
        return new FetchAspPDFSuccess();
      })
    )
  );

  fetchAspPDFSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FetchAspPDFSuccess>(AspTrendsActionsActionTypes.FetchAspPDFSuccess),
        switchMap(action => of(action)),
        map(() => this.modalService.close())
      ),
    { dispatch: false }
  );
}
