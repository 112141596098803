import { GalleryImage } from '../../models/GalleryImage';

export class IGalleryState {
  images: {
    loaded: boolean;
    list: GalleryImage[]
  };
  changePending: number;
  menuOpened: boolean;
  currentImageInSlide: GalleryImage;
  slideShowOpened: boolean;
}

export const initialGalleryState: IGalleryState = {
  images: {
    loaded: false,
    list: []
  },
  changePending: 0,
  menuOpened: false,
  currentImageInSlide: new GalleryImage(),
  slideShowOpened: false
};

export function getInitialGalleryState(): IGalleryState {
  return initialGalleryState;
}
