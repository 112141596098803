import { TrendCardTypeEnumShortHand, TrendDataTypeEnumShortHand } from './ITrendsTableData';

export class MarketTrendsChartConfiguration {
  label: string;
  shortHand: TrendCardTypeEnumShortHand;
  dataTypes: TrendDataTypeEnumShortHand[];
  chartTypes: TrendsChartStyles[];
  showPreview: boolean;
  isCustomChart?: boolean;

  constructor(
    label: string,
    dataTypes: TrendDataTypeEnumShortHand[],
    chartTypes: TrendsChartStyles[],
    shortHand: TrendCardTypeEnumShortHand,
    isCustomChart = false
  ) {
    this.label = label;
    this.dataTypes = dataTypes;
    this.chartTypes = chartTypes;
    this.shortHand = shortHand;
    this.showPreview = true;
    this.isCustomChart = isCustomChart;
  }
}

export type TrendsChartStyles = 'Spline' | 'StackingColumn' | 'Line' | 'Column' | 'ColumnLine' | 'Custom';

export enum TrendsChartStylesEnum {
  Spline = 'Spline',
  StackingColumn = 'StackingColumn',
  Line = 'Line',
  Column = 'Column',
  ColumnLine = 'ColumnLine',
  Custom = 'Custom'
}
