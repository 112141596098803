<div *ngIf="header" class="header-container">
  <div class="mobile-menu">
    <button (click)="openSideBar()" aria-label="Navigation Link" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <app-branding *ngIf="isSIR !== undefined" [isSIR]="isSIR" [isTerradatum]="isTerradatum" [routerLink]="'/'"></app-branding>
  <div class="user-menus">
    <div (click)="goToHelp()" class="help-link" *ngIf="isSIR === false">
      <mat-icon>help_outline</mat-icon>
    </div>
    <app-avatar-menu></app-avatar-menu>
  </div>
</div>
