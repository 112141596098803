<mat-chip-list [ngClass]="{ full: !truncate }" aria-label="Filter List">
  <mat-chip
    (removed)="chipRemove(chip)"
    *ngFor="let chip of ungroupedChips"
    class="chip-filter"
    [removable]="true"
    [ngClass]="{ 'no-data': !chip.hasData && reportType === reportTypes.Uno && truncate }"
    [matTooltip]="chip | ChipTooltip: reportType"
    [matTooltipDisabled]="!tabletPlus || !truncate"
    matTooltipPosition="above"
    (click)="openSnackBarToolTip(chip.hasData)"
  >
    <span *ngIf="chip.type !== 'county' && chip.type !== 'schoolDistrict'">{{ chip.name }}</span>
    <span *ngIf="chip.type === 'county'">{{ chip.name | CountyString }}</span>
    <span *ngIf="chip.type === 'schoolDistrict'">{{ chip.name | SchoolString }}</span>
    <mat-icon *ngIf="truncate" matChipRemove matSuffix>cancel</mat-icon>
  </mat-chip>
  <app-chips-grouped [data]="groupedChips" [reportType]="reportType" (chipRemoved)="chipRemove($event)"></app-chips-grouped>
</mat-chip-list>
