<div *ngIf="pdfOptions$ | async as opts" class="h-100">
  <div class="scrollable custom-scrollbar rounded w-100">
    <app-export-preview-page
      *ngIf="opts.showCover"
      [coverPage]="true"
      [isLandscape]="pdfIsLandscape$ | async"
      [showAgentInfo]="opts.showAgentInfo"
    >
      <app-export-preview-cover [showAgentInfo]="opts.showAgentInfo"></app-export-preview-cover>
    </app-export-preview-page>
    <app-export-preview-page [coverPage]="false" [isLandscape]="pdfIsLandscape$ | async" [showAgentInfo]="opts.showAgentInfo">
      <div class="custom-chart-container">
        <app-custom-chart
          *ngIf="chartData as data"
          [showTrendLines]="isThreePointChart ? false : opts.showTrendLines"
          [axis]="chartAxis$ | async"
          [chartDataObject]="data.dataPoints"
          [chartTypes]="data.chartTypesConverted"
          [dataTypes]="typesArray"
          [yAxisLabels]="data.axisLabels"
          [selectedMonth]="data.details.header.endDate"
          class="export-preview"
        ></app-custom-chart>
      </div>
    </app-export-preview-page>
  </div>
</div>
