import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BrandDetails } from '../../../models/IApplicationData';
import { selectBrandInfo } from '../../../store/selectors/applicationData.selector';
import { IAppState } from '../../../store/state/app.state';

@Component({
  selector: 'app-mq-cbr-footer',
  templateUrl: './mq-footer.component.html',
  styleUrls: ['./mq-footer.component.scss']
})
export class MqFooterCBRComponent implements OnInit {
  @HostBinding('class') classes = 'flex-shrink-0';
  brandData: BrandDetails;
  private subs = new Subscription();

  constructor(private store: Store<IAppState>) {}

  ngOnInit(): void {
    this.subs.add(
      this.store
        .select(selectBrandInfo())
        .pipe(filter(v => !!v))
        .subscribe(brandInfo => {
          this.brandData = brandInfo;
        })
    );
  }
}
