import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { select, Store } from '@ngrx/store';
import { DefaultMPFiltersConst, DefaultV2FiltersConst, DefaultV2FiltersEnum, FilterState, LocationEnum } from 'app/models/FilterData';
import { ILocationsData } from 'app/models/ILocationsData';
import { MarketFilters } from 'app/models/IMarketReportFilters';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, retry } from 'rxjs/operators';
import { DefaultFilters } from '../models/IFilters';
import { IMlsData } from '../models/IMlsData';
import { FetchLocationsLoaded } from '../store/actions/locations.action';
import { selectLocations } from '../store/selectors/locations.selector';
import { IAppState } from '../store/state/app.state';

@Injectable()
export class FilterService {
  get locations(): ILocationsData[] {
    return this._locations;
  }

  set locations(value: ILocationsData[]) {
    this._locations = value;
  }
  headers: HttpHeaders;
  private _locations: ILocationsData[];

  constructor(private http: HttpClient, public store: Store<IAppState>) {
    this.store
      .select(selectLocations)
      .pipe(filter(l => l.length > 0))
      .subscribe(locations => {
        this.store.dispatch(new FetchLocationsLoaded());
        this._locations = locations;
      });
  }

  static getUserId() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    return userData.info.oktaId;
  }

  getApiDefaultFilters(): Observable<DefaultFilters[]> {
    const oktaId = FilterService.getUserId();

    return this.http
      .get<DefaultFilters[]>(`${environment.apiBaseUrl}/api/User/${oktaId}/filtersettings`, { headers: this.headers })
      .pipe(
        map((filters: DefaultFilters[]) => {
          if (!filters) {
            filters = [{ filterTypeID: 0, filterValue: '', dbName: '' }];
          }
          return filters;
        })
      );
  }

  updateUserFilterDefaults(filters: DefaultFilters[]) {
    const oktaId = FilterService.getUserId();
    return this.http.post(`${environment.apiBaseUrl}/api/User/${oktaId}/filtersettings`, filters, {
      headers: this.headers
    });
  }

  getMLS(): Observable<IMlsData[]> {
    const oktaId = FilterService.getUserId();
    return this.http.get<IMlsData[]>(`${environment.apiBaseUrl}/api/Location/${oktaId}/getusermlses`, {
      headers: this.headers
    });
  }

  getLocations(id: string = ''): Observable<ILocationsData[]> {
    const oktaId = FilterService.getUserId();
    return this.http
      .get<ILocationsData[]>(`${environment.apiBaseUrl}/api/location/getlocations?mlsId=${id}&oktaId=${oktaId}`, {
        headers: this.headers
      })
      .pipe(retry(3), catchError(this.errorHandler));
  }

  buildFilterObject<A>(filters: [FilterState, MarketFilters], arr: DefaultFilters[], reportType) {
    if (reportType === 0) {
      // #1 Claims saved
      arr = cloneDeep(this.buildClaimsFilterObject(filters[0], arr));
    } else if (reportType === 1) {
      // Market Position saved
      arr = cloneDeep(this.buildMPFilterObject(filters[1], arr));
    } else if (reportType === -1) {
      // clear defaults
      cloneDeep(this.buildClaimsFilterObject(filters[0], arr));
      cloneDeep(this.buildMPFilterObject(filters[1], arr));
    } else if (reportType === -2) {
      // MLS Selection Modal
      arr.push({
        filterValue: filters[0].mlsid[0],
        filterTypeID: DefaultV2FiltersEnum['mlsid'],
        dbName: ''
      });
    }
    return arr;
  }

  buildClaimsFilterObject(filters, arr) {
    Object.keys(filters).map(filterStr => {
      if (DefaultV2FiltersConst.indexOf(filterStr) > -1) {
        let v = Array.isArray(filters[filterStr]) ? filters[filterStr].join(';') : filters[filterStr];
        if (typeof v === 'boolean') {
          v = `${v}`;
        }
        if (typeof v === 'number') {
          v = v.toString();
        }
        arr.push({
          filterValue: v,
          filterTypeID: DefaultV2FiltersEnum[filterStr],
          dbName: ''
        });
      }
    });
    return arr;
  }

  buildMPFilterObject(filters, arr) {
    Object.keys(filters).map(filterStr => {
      if (DefaultMPFiltersConst.indexOf(filterStr) > -1) {
        let v = Array.isArray(filters[filterStr]) ? filters[filterStr].join(';') : filters[filterStr];
        if (typeof v === 'number') {
          v = v.toString();
        }
        if (typeof v === 'object') {
          v = Object.values(v).join(';');
        }

        if (filterStr === 'groupByLevel') {
          arr.push({
            filterValue: v,
            filterTypeID: DefaultV2FiltersEnum['mpGroupByLevel'],
            dbName: ''
          });
        } else if (filterStr === 'msTypeName') {
          arr.push({
            filterValue: v,
            filterTypeID: DefaultV2FiltersEnum['mpMsTypeName'],
            dbName: ''
          });
        } else if (filterStr === 'unitType') {
          arr.push({
            filterValue: v,
            filterTypeID: DefaultV2FiltersEnum['mpUnitType'],
            dbName: ''
          });
        } else {
          arr.push({
            filterValue: v,
            filterTypeID: DefaultV2FiltersEnum[filterStr],
            dbName: ''
          });
        }
      }
    });
    return arr;
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message));
  }

  clearLocations(component: any = {}) {
    component.locations = [];
    component.counties = [];
    component.zips = [];
    component.cities = [];
    component.activeFilterList = {
      zip: [],
      city: [],
      county: []
    };
    component.claimsFilterValues.emit(component.activeFilterList);
  }

  autoCompleteOptionSelected(event: MatAutocompleteSelectedEvent, component: any, filters: any, type: string) {
    const location: ILocationsData = event.option.value;
    let locationList: any;
    locationList = cloneDeep(filters);
    locationList[LocationEnum[location.areaType]].push(location['areaID']);
    locationList[LocationEnum[location.areaType]] = [...new Set(locationList[LocationEnum[location.areaType]].filter(Boolean))];
    if (type === 'Claims') {
      component.claimsFilterValues.emit(locationList);
    } else if (type === 'Market') {
      component.marketFilterValues.emit(locationList);
    } else {
      component.trendsFilterValues.emit(locationList);
    }
    component.searchControl.setValue('');
  }
}
