<div *ngIf="pdfPreviewData | async as pdfData" class="loc-grid">
  <app-filter-tile
    [backgroundColor]="'#F9F9F9'"
    [isLocation]="true"
    [locationValues]="pdfData.areaLocalNames"
    label="Location"
    matIcon="location_pin"
  ></app-filter-tile>
  <app-filter-tile [backgroundColor]="'#F9F9F9'" [value]="pdfData.propertyType" label="Property Type" matIcon="home_work"></app-filter-tile>
  <app-filter-tile
    [backgroundColor]="'#F9F9F9'"
    [value]="pdfData.displayCombinedDate"
    label="Date Range"
    matIcon="date_range"
  ></app-filter-tile>
  <app-filter-tile
    [backgroundColor]="'#F9F9F9'"
    [value]="pdfData.displayPrice"
    label="Price Range"
    matIcon="monetization_on"
  ></app-filter-tile>
</div>
