<div *ngIf="data$ | async as mp" class="content mb-auto d-flex justify-content-between">
  <div class="left d-flex gold-border align-items-end">
    <div *ngFor="let i of mp.graphData; let idx = index" [ngStyle]="{ 'height.%': mp.arr[idx].height }" class="series d-flex">
      <div style="text-align: center; width: 115px">
        <span class="w-100">{{ i.entityName }}</span>
        <span class="d-block mb-3 w-100">
          <ng-container>{{ mp.arr[idx].value }}</ng-container>
        </span>
      </div>
      <div [ngClass]="{ leader: i.entityName === mp.data.realogyPosition?.entityName }" class="chart-col h-100 flex-shrink-0"></div>
    </div>
  </div>
  <div class="right d-flex">
    <div [ngStyle]="{ visibility: mp.opts.showBrandLogo ? 'visible' : 'hidden' }" class="logo">
      <img [src]="mp.logo" alt="Sotheby's logo" />
    </div>
    <div class="date">{{ mp.data.reportHeaderEntity.displayDateRange }}</div>
    <div class="divider my-4"></div>
    <div class="number-one">
      <div class="lower mb-4">
        <span class="market-title">Market Position Report</span>
      </div>
    </div>
    <div class="type text-uppercase">{{ mp.data.reportHeaderEntity.groupByLevel }} By {{ mp.data.reportHeaderEntity.msTypeName }}</div>
    <div [ngClass]="mp.data.hasMultipleLocations > 0 ? 'multi' : ''" class="location text-uppercase mb-2">
      {{ mp.data.reportHeaderEntity.areaLocalName || 'All Areas' }}
    </div>
    <div class="price text-uppercase">{{ mp.data.reportHeaderEntity.priceRange }}</div>
    <div
      [ngStyle]="{ visibility: mp.opts.showMarketPositionTable ? 'visible' : 'hidden' }"
      class="main-table mt-auto justify-content-end d-flex"
    >
      <div class="text-center units">
        <span>{{ mp.data.reportHeaderEntity.msTypeName }} Sold</span>
      </div>
      <div class="data-table d-flex">
        <div class="table-col first text-left">
          <p class="header-row mb-3">FIRM</p>
          <p *ngFor="let g of mp.graphData" class="mb-2 truncate">{{ g.entityName }}</p>
        </div>
        <div *ngIf="!mp.showPercent" class="table-col text-center">
          <p class="header-row mb-3">TOTAL</p>
          <p *ngFor="let g of mp.graphData; let idx = index" class="mb-2">
            {{ mp.arr[idx].value }}
          </p>
        </div>
        <div class="table-col text-center">
          <p class="header-row mb-3">% SHARE</p>
          <p *ngFor="let i of mp.graphData; let idx = index" class="mb-2">
            {{ mp.data.isUnits ? i.totalUnitsPercent : i.totalVolumePercent }}%
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="data$ | async as mp" class="footer">
  <div [innerHTML]="mp.disclaimer"></div>
</div>
