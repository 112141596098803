<ejs-chart
  (axisLabelRender)="axisLabelRender($event)"
  (pointRender)="pointRender($event)"
  (seriesRender)="seriesRender($event)"
  (textRender)="textRender($event)"
  [chartArea]="chartArea"
  [id]="chartId"
  [legendSettings]="legend"
  [palettes]="palette"
  [primaryXAxis]="primaryXAxis"
  [primaryYAxis]="primaryYAxis"
  [tooltip]="tooltip"
>
  <e-axes>
    <e-axis
      [border]="{ width: 0 }"
      [lineStyle]="{ width: 0 }"
      [majorGridLines]="{ width: 0 }"
      [majorTickLines]="{ width: 0 }"
      [rangePadding]="'Normal'"
      [visible]="true"
      name="yAxis1"
      opposedPosition="true"
      rowIndex="0"
      title=""
    >
    </e-axis>
  </e-axes>
  <e-series-collection>
    <e-series
      *ngFor="let data of chartData; let idx = index"
      [animation]="{ enable: false }"
      [cornerRadius]="cornerRadius"
      [dataSource]="chartData[idx]"
      [legendShape]="'Square'"
      [marker]="marker"
      [name]="TrendDataTypeEnum[chart.dataTypes[idx]]"
      [type]="chart.chartTypes[idx]"
      [xName]="'xData'"
      [yAxisName]="chart?.chartTypes.includes('Line') ? (idx > 0 ? 'yAxis1' : null) : null"
      [yName]="'yData'"
      width="4"
    >
    </e-series>
  </e-series-collection>
</ejs-chart>
