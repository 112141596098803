import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { format, sub } from 'date-fns';
import { map, Observable } from 'rxjs';
import { IAgentReport } from '../../../../../models/IAgentReport';
import { pdfExportAgentData, pdfExportData } from '../../../../../store/selectors/pdf.selector';
import { IAppState } from '../../../../../store/state/app.state';
import { IPdfPreviewData } from '../../../../../store/state/pdf.state';
import { Avatar } from '../../../../../models/IApplicationData';
import { UserDataService } from '../../../../../services/userData.service';

@Component({
  selector: 'app-agent-branding-block',
  templateUrl: './agent-branding-block.component.html',
  styleUrls: ['./agent-branding-block.component.scss']
})
export class AgentBrandingBlockComponent implements OnInit {
  agentData$: Observable<IAgentReport>;
  filterData$: Observable<{
    displayStartDate: string;
    displayPrice: string;
    propertyType: string;
    displayEndDate: string;
    displayCombinedDate: string;
    areaLocalNames: string;
  }>;
  avatar$: Observable<Avatar>;
  otherUrl: string;

  constructor(private store: Store<IAppState>,
    private userDataSvc: UserDataService) { }

  ngOnInit(): void {
    this.agentData$ = this.store.select(pdfExportAgentData);
    this.filterData$ = this.store.select(pdfExportData).pipe(
      map((data: IPdfPreviewData) => {
        const displayPrice = data.price;
        const d = new Date(data.displayDate);
        const displayEndDate = d.toLocaleDateString('us', {
          month: 'long',
          year: 'numeric'
        });
        const propertyType = data.propertyType.replace('property type(s) ', '');
        const displayStartDate = format(sub(d, { months: 35 }), 'MMMM yyyy');
        const displayCombinedDate = `${displayStartDate} - ${displayEndDate}`;
        let areaLocalNames = this.getAreaNameLabel(data.areaLocalNames);
        return { displayPrice, displayEndDate, displayStartDate, propertyType, displayCombinedDate, areaLocalNames };
      })
    );
    this.agentData$.subscribe(data => {
      const agentInfo = data;
      if (agentInfo?.contactType === 0) {
        this.avatar$ = this.userDataSvc.getUserAvatar(agentInfo.oktaId);
      } else {
        this.otherUrl = agentInfo?.photo.replace('original', 'main');
      }
    });
  }

  getAreaNameLabel(areaLocalNames: { areatype: string; locations: string }[]): string {
    let areaLocalName = '';

    if (areaLocalNames?.length > 0) {
      const firstLocation = areaLocalNames[0].locations.split(', ')[0];
      areaLocalName += `${areaLocalNames[0].areatype}: ${firstLocation}`;

      let count = 0;
      areaLocalNames.forEach(localName => {
        count += localName.locations.split(', ').length;
      });

      if (count > 1) {
        areaLocalName += ` + ${count - 1} more location${count - 1 <= 1 ? '' : 's'}`;
      }
    }

    return areaLocalName;
  }
}
