import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TrendCardTypeEnumShortHand } from '../../../../models/ITrendsTableData';
import { UpdateTrendsTableType } from '../../../../store/actions/market-trends.action';
import { selectTrendsTableDataType } from '../../../../store/selectors/market-trends.selector';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-trend-data-table-container',
  templateUrl: './trend-data-table-container.component.html',
  styleUrls: ['./trend-data-table-container.component.scss']
})
export class TrendDataTableContainerComponent implements OnInit {
  @Input() title: string;
  form: UntypedFormGroup;
  TrendCardTypeEnumShortHand = TrendCardTypeEnumShortHand;
  @Input() showControls = true;
  @Input() typographySize: 'small' | 'medium' | 'large' = 'medium';

  constructor(private fb: UntypedFormBuilder, private store: Store<IAppState>) {
    this.form = this.fb.group({
      formatToggle: [0]
    });
  }

  ngOnInit(): void {
    this.store.select(selectTrendsTableDataType).subscribe(val => {
      this.form.get('formatToggle').patchValue(val, { emitEvent: false });
    });
    this.form.get('formatToggle').valueChanges.subscribe((value: number) => {
      this.store.dispatch(new UpdateTrendsTableType(value));
    });
  }
}
