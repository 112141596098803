import { createSelector } from '@ngrx/store';
import { ImageTypes } from '../../models/ImageTypes';
import { IAppState } from '../state/app.state';
import { IUserDataState } from '../state/userData.state';

const userData = (state: IAppState) => state.userData;

export const selectUser = createSelector(userData, (state: IUserDataState) => state.info);

export const selectOktaId = createSelector(userData, (state: IUserDataState) => state.info.oktaId);

export const selectpersonMasterId = createSelector(userData, (state: IUserDataState) => state.info.personMasterId);

export const selectagentMasterId = createSelector(userData, (state: IUserDataState) => state.info.agentMasterId);

export const selectUserDataLoaded = createSelector(userData, (state: IUserDataState) => state.loaded);

export const selectUserDefaultImage = createSelector(userData, (state: IUserDataState) => state.defaultImage.sizes);

export const selectUserImagesLoaded = createSelector(userData, (state: IUserDataState) => state.defaultImage.loaded);

export const selectUserImageBackground = createSelector(userData, (state: IUserDataState) =>
  state.defaultImage.sizes.find(image => image.imageSize === ImageTypes.background)
);

export const selectUserTeams = createSelector(userData, (state: IUserDataState) => state.info.teams);

export const selectUserOffices = createSelector(userData, (state: IUserDataState) => state.info.offices);

export const selectUserLicenses = createSelector(userData, (state: IUserDataState) => state.info.licenses);
