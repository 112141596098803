import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ILocationsData } from 'app/models/ILocationsData';
import { IMlsData } from 'app/models/IMlsData';
import { PriceRange } from 'app/models/IPriceRange';
import { IChips } from '../../../models/IChips';
import { ReportTypeEnum } from '../../../models/ReportTypes';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ bottom: -100, opacity: 0 }), animate('.5s ease-out', style({ bottom: 100, opacity: 1 }))]),
      transition(':leave', [style({ bottom: 100, opacity: 1 }), animate('.5s ease-in', style({ bottom: -100, opacity: 0 }))])
    ])
  ]
})
export class FilterPanelComponent implements OnInit {
  @Input() reportType: ReportTypeEnum;
  @Input() locations: ILocationsData[] = [];
  @Input() controls: UntypedFormGroup;
  @Input() disableUpdate: boolean;
  @Input() mlsList: IMlsData[];
  @Input() currentSelectedMls: IMlsData;
  @Input() multipleLocations = false;
  @Input() customDateRange = false;
  @Input() customMaxDate: Date = new Date();
  @Input() hideCustomDateRestriction: boolean;
  @Input() reportTitle = 'Create Your Report';
  @Input() priceRanges: PriceRange[] = [];
  @Input() isSIR: boolean;
  @Input() isCustomReport = false;
  @Input() hideCustomMonthlyDate = false;
  @Input() priceBands: any[];
  @Input() monthsBack: any[];
  @Output() updated: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Output() clearReports: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() mobileClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() sortByAgent = false;
  selectedFilterLocations: ILocationsData[] = [];
  showSnackBar = false;
  locationFilters = {
    mlsArea: [],
    county: [],
    city: [],
    zipCode: [],
    schoolDistrict: [],
    subDivision: []
  };

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.locations = this.locationChangedHandler();
    this.controls.get('locationFilters').valueChanges.subscribe(() => {
      this.locations = this.locationChangedHandler();
    });
  }

  selectLocationHandler(selectedLocation: ILocationsData[]) {
    this.controls.get('locationFilters').patchValue(selectedLocation);
  }

  updateResults() {
    this.updated.emit(this.controls);
    this.toggleSnackBar();
  }

  locationRemovedHandler(locationChip: IChips) {
    const newValues = (this.controls.get('locationFilters').value || []).filter((location: ILocationsData) => {
      return location.areaID.toString() !== locationChip.value.toString();
    });

    this.controls.get('locationFilters').patchValue(newValues);
    this.locations = this.locationChangedHandler();
  }

  locationChangedHandler() {
    let locationArray = cloneDeep(this.locations);
    locationArray = locationArray?.map((location: ILocationsData) => {
      const match = (this.controls.get('locationFilters').value || []).find((l: ILocationsData) => {
        return l.areaID === location.areaID && l.areaType === location.areaType;
      });

      if (!this.multipleLocations) {
        location = JSON.parse(JSON.stringify(location));
      }

      location['checked'] = Boolean(match);

      return location;
    });

    this.setSelectedFilterLocations();
    return locationArray;
  }

  setSelectedFilterLocations(): void {
    const locationValues = JSON.parse(JSON.stringify(this.controls.get('locationFilters').value));
    this.selectedFilterLocations = (locationValues || []).map((location: ILocationsData) => {
      return location;
    });

    this.cdr.detectChanges();
  }

  resetReportFilters(): void {
    this.clearReports.emit(true);
  }

  resetLocationFilters(): void {
    this.controls.get('locationFilters').patchValue([]);
    this.locations = this.locationChangedHandler();
  }

  closeMobileMenu(): void {
    this.mobileClosed.emit(true);
  }

  toggleSnackBar(): void {
    this.showSnackBar = true;
    window.setTimeout(() => {
      this.showSnackBar = false;
    }, 2000);
  }

  applySavedSearch(filterValues): void {
    Object.keys(this.controls.get('report').value).forEach(reportKey => {
      this.controls
        .get('report')
        .get(reportKey)
        .setValue(filterValues.report[reportKey] || undefined);
    });

    this.controls.get('locationFilters').setValue(filterValues.locationFilters);
    this.controls.get('locationFilters').markAsDirty();
    this.updateResults();
  }
}
