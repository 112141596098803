import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { TenantCode } from '../../services/tenant.service';
import { selectBrandCode } from '../../store/selectors/applicationData.selector';
import { IAppState } from '../../store/state/app.state';

@Component({
  selector: 'app-mq-footer',
  templateUrl: './mq-footer.component.html'
})
export class MqFooterComponent implements OnInit {
  brandCode: TenantCode;
  TenantCode = TenantCode;

  constructor(private store: Store<IAppState>) { }

  ngOnInit(): void {
    this.store.select(selectBrandCode).pipe(
      filter(v => !!v)
    ).subscribe( brandCode => {
      this.brandCode = brandCode;
    });
  }

}
