<div class="contact-block d-inline-flex align-items-center">
  <div class="contact-phone d-flex" *ngIf="data && data['brand_key'] !== 'cb'">
    <span class="mobile-spacing">{{ data?.helpdesk_label }}:&nbsp;</span>
    <span class="mobile-spacing phone">{{ data?.helpdesk_phone }}</span>
  </div>
  <div class="contact-links" *ngIf="data && data['brand_key'] !== 'cb'">
    <a class="mobile-spacing mx-4" href="https://realogy.service-now.com" target="_blank">Request Help</a>
    <a class=" mobile-spacing" href="https://realogy.okta.com/enduser/settings" target="_blank">Reset Password</a>
  </div>
</div>
