<div>
  <div class="mls-popup-background" [class.mls-popup-background-sir]="data.isSIR">
    <div class="sub-header-mls">Welcome to</div>
    <div class="logo"></div>
    <div class="header-MLS">Market Quest</div>
  </div>
  <div class="header">
    <p class="title">Your Assigned MLS</p>
  </div>
  <div class="mls-info">
    <div class="abrv">{{ data.mlsId }}</div>
    <div class="full-mls">{{ data.legalName }}</div>
  </div>
  <div class="footer">
    <div class="button right">
      <button mat-raised-button color="primary" mat-dialog-close>
        Continue
      </button>
    </div>
  </div>
</div>
