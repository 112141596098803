import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IMarketPositionData } from '../models/IMarketPositionData';
import { IReportDetail } from '../models/IReportDetail';
import { IReportGraph } from '../models/IReportGraph';
import { ITopGroupTypeData } from '../models/ITopGroupTypeData';

@Injectable()
export class ReportDetailService {
  private headers: HttpHeaders;

  constructor(private _http: HttpClient) {}

  getReportDetails(msUnoReportId: string, oktaId: string) {
    return this._http
      .get<IReportDetail>(environment.apiBaseUrl + '/api/report/getreportdetails?msUnoReportId=' + msUnoReportId + '&oktaId=' + oktaId, {
        headers: this.headers
      })
      .pipe(catchError(this.errorHandler));
  }

  secondLargestValue(data: number[]) {
    const arr = [...data]; // use int arrays
    const max = Math.max.apply(null, arr); // get the max of the array
    arr.splice(arr.indexOf(max), 1); // remove max from the array
    if (!arr.length) {
      return max;
    }
    return Math.max.apply(null, arr); // get the 2nd max
  }

  errorHandler(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }

  /**
   *
   * @param reportDetails
   * @param cbPosition start with 1
   * @param forceTruncate manually shorten names
   */
  remapGraphEntryNames(reportDetails: IReportDetail, cbPosition: number, forceTruncate?: boolean): IReportGraph[] {
    const isConsolidatedMLS = reportDetails.reportHeaderEntity.mlsId === 'COSC' || forceTruncate;

    function getLevelEntityName(idx: number, entity: IReportGraph) {
      return isConsolidatedMLS
        ? idx === cbPosition
          ? entity.levelEntityName.trim() + entity.levelEntityXtra || ''
          : 'Competitor ' + idx
        : entity.levelEntityName.trim() + (entity.levelEntityXtra || '');
    }

    return reportDetails.reportGraphEntities.map((entity, idx) => {
      return {
        ...entity,
        levelEntityName: getLevelEntityName(idx + 1, entity),
        levelEntityXtra: ''
      };
    });
  }

  /**
   *
   * @param reportDetails
   * @param cbPosition start with 1
   */
  remapMarketGraphEntryNames(reportDetails: IMarketPositionData, cbPosition: number): ITopGroupTypeData[] {
    const isConsolidatedMLS = reportDetails.reportHeaderEntity.mlsId === 'COSC';

    function getLevelEntityName(idx: number, entity: ITopGroupTypeData) {
      return isConsolidatedMLS ? (idx === cbPosition ? entity.entityName.trim() || '' : 'Competitor ' + idx) : entity.entityName.trim();
    }

    return reportDetails.topGroupTypes.map((entity, idx) => {
      return {
        ...entity,
        levelEntityName: getLevelEntityName(idx + 1, entity)
      };
    });
  }
}
