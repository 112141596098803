import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'MarketQuestPhone'
})
export class MarketQuestPhonePipe implements PipeTransform {
  transform(phoneNumber: string) {
    let formattedNumber: string;
    phoneNumber = phoneNumber.replace(/[^0-9]*/g, '');
    phoneNumber = phoneNumber.substring(0, 1) === '1' ? phoneNumber.substring(1) : phoneNumber;

    const areaCode = phoneNumber.substring(0, 3);
    const front = phoneNumber.substring(3, 6);
    const end = phoneNumber.substring(6, 10);

    formattedNumber = areaCode + '-' + front + '-' + end;

    return formattedNumber;
  }
}
