import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Store } from '@ngrx/store';
import { subMonths, subYears } from 'date-fns';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DateService, monthYear } from '../../../../services/date.service';
import { selectCurrentMLSLastRanDate } from '../../../../store/selectors/mls.selector';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-time-period-multi',
  templateUrl: './time-period-multi.component.html',
  styleUrls: ['./time-period-multi.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DateService
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: monthYear
    }
  ]
})
export class TimePeriodMultiComponent implements OnInit, OnDestroy {
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  @Input() isCustomReport = false;
  @Input() hideCustomMonthlyDate = false;
  lastRunDate = new Date();
  minDate: Date;
  adjustedMinDate: Date;
  maxDate: Date;
  subs = new Subscription();

  constructor(private store: Store<IAppState>) {}

  ngOnInit(): void {
    this.subs.add(
      this.store
        .select(selectCurrentMLSLastRanDate)
        .pipe(filter(v => !!v))
        .subscribe(date => {
          this.lastRunDate = new Date(date);
          if (this.isCustomReport) {
            this.group.get('startDate').patchValue(subYears(this.lastRunDate, 1));
          } else {
            this.group.get('startDate').patchValue(subMonths(this.lastRunDate, 1));
          }
          this.group.get('endDate').patchValue(this.lastRunDate);
          this.group.get('monthlyEndDate').patchValue(this.lastRunDate);
        })
    );
  }

  closeDatePicker(eventData: Date, dp: MatDatepicker<any>) {
    this.group.get('monthlyEndDate').patchValue(eventData);
    this.group?.markAsDirty();
    dp.close();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
