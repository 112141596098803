import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-export-preview-page',
  templateUrl: './export-preview-page.component.html',
  styleUrls: ['./export-preview-page.component.scss']
})
export class ExportPreviewPageComponent {
  @Input() enabled = true;
  @Input() showAgentInfo = true;
  @Input() coverPage = false;
  @Input() showTrendLines = true;
  @Input() isLandscape;

  constructor() {}
}
