import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UrlShortenPipe } from '../../../pipes/url-shorten.pipe';
import { CustomChartComponent } from '../custom-graph/custom-chart/custom-chart.component';
import { CustomGraphNoRoutingModule } from '../custom-graph/custom-graph-no-routing.module';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { TrendsNoRouteModule } from '../trends/trends-no-route.module';
import { CBRClaimsExportPreviewComponent } from './claims-export-preview/CBR/c-b-r-claims-export-preview.component';
import { SIRClaimsExportPreviewComponent } from './claims-export-preview/SIR/s-i-r-claims-export-preview.component';
import { CustomExportPreviewComponent } from './custom-export-preview/custom-export-preview.component';
import { ExportPreviewControlsComponent } from './export-preview-controls/export-preview-controls.component';
import { ExportPreviewComponent } from './export-preview.component';
import { CBRMarketExportPreviewComponent } from './market-export-preview/CBR/c-b-r-market-export-preview/c-b-r-market-export-preview.component';
import { SIRMarketExportPreviewComponent } from './market-export-preview/SIR/s-i-r-market-export-preview.component';
import { AgentBrandingBlockComponent } from './trends-export-preview/agent-branding-block/agent-branding-block.component';
import { ConnectWithMeBlockComponent } from './trends-export-preview/export-preview-cover/cover-agent-section/connect-with-me-block/connect-with-me-block.component';
import { ContactInfoBlockComponent } from './trends-export-preview/export-preview-cover/cover-agent-section/contact-info-block/contact-info-block.component';
import { CoverAgentSectionComponent } from './trends-export-preview/export-preview-cover/cover-agent-section/cover-agent-section.component';
import { UserInfoBlockComponent } from './trends-export-preview/export-preview-cover/cover-agent-section/user-info-block/user-info-block.component';
import { CoverFilterSectionComponent } from './trends-export-preview/export-preview-cover/cover-filter-section/cover-filter-section.component';
import { FilterTileComponent } from './trends-export-preview/export-preview-cover/cover-filter-section/filter-tile/filter-tile.component';
import { CoverMainComponent } from './trends-export-preview/export-preview-cover/cover-main/cover-main.component';
import { CoverTitleComponent } from './trends-export-preview/export-preview-cover/cover-title/cover-title.component';
import { ExportPreviewCoverComponent } from './trends-export-preview/export-preview-cover/export-preview-cover.component';
import { ExportPreviewFooterComponent } from './trends-export-preview/export-preview-footer/export-preview-footer.component';
import { ExportPreviewHeaderComponent } from './trends-export-preview/export-preview-header/export-preview-header.component';
import { ExportPreviewPageComponent } from './trends-export-preview/export-preview-page/export-preview-page.component';
import { TrendsExportComponent } from './trends-export-preview/trends-export.component';

@NgModule({
  declarations: [
    TrendsExportComponent,
    ExportPreviewPageComponent,
    ExportPreviewHeaderComponent,
    ExportPreviewFooterComponent,
    ExportPreviewCoverComponent,
    AgentBrandingBlockComponent,
    SIRMarketExportPreviewComponent,
    CoverAgentSectionComponent,
    ConnectWithMeBlockComponent,
    ContactInfoBlockComponent,
    UserInfoBlockComponent,
    CoverFilterSectionComponent,
    CoverMainComponent,
    CoverTitleComponent,
    ExportPreviewComponent,
    ExportPreviewControlsComponent,
    CBRClaimsExportPreviewComponent,
    UrlShortenPipe,
    SIRClaimsExportPreviewComponent,
    CBRMarketExportPreviewComponent,
    CustomExportPreviewComponent
  ],
  imports: [
    CommonModule,
    SharedComponentsModule,
    TrendsNoRouteModule,
    FilterTileComponent,
    CustomGraphNoRoutingModule,
    CustomChartComponent
  ],
  exports: [
    TrendsExportComponent,
    ExportPreviewPageComponent,
    ExportPreviewHeaderComponent,
    ExportPreviewFooterComponent,
    ExportPreviewCoverComponent,
    AgentBrandingBlockComponent,
    SIRMarketExportPreviewComponent,
    CoverAgentSectionComponent,
    ConnectWithMeBlockComponent,
    ContactInfoBlockComponent,
    UserInfoBlockComponent,
    CoverFilterSectionComponent,
    CoverMainComponent,
    CoverTitleComponent,
    ExportPreviewComponent,
    ExportPreviewControlsComponent,
    CBRClaimsExportPreviewComponent,
    SIRClaimsExportPreviewComponent,
    UrlShortenPipe
  ]
})
export class ExportPreviewModule {}
