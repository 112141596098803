import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterBrandingComponent } from './footer-branding/footer-branding.component';
import { FooterContactComponent } from './footer-contact/footer-contact.component';
import { FooterDisclaimerComponent } from './footer-disclaimer/footer-disclaimer.component';
import { FooterSocialComponent } from './footer-social/footer-social.component';
import { MqFooterCBRComponent } from './mq-footer.component';

@NgModule({
  declarations: [
    MqFooterCBRComponent,
    FooterSocialComponent,
    FooterContactComponent,
    FooterBrandingComponent,
    FooterDisclaimerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MqFooterCBRComponent
  ]
})
export class MqFooterCBRModule { }
