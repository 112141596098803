import { Action } from '@ngrx/store';

export enum GtmActionsTypes {
  SnapshotGtm = '[GTM] Snapshot GTM Event',
  ExportClaimsGtm = '[GTM] Export Claims GTM Event',
  ExportMarketGtm = '[GTM] Export Market GTM Event'
}

export class SnapshotGtm implements Action {
  readonly type = GtmActionsTypes.SnapshotGtm;

  constructor(public payload: string) {}
}

export class ExportClaimsGtm implements Action {
  readonly type = GtmActionsTypes.ExportClaimsGtm;

  constructor(public payload: string) {}
}

export class ExportMarketGtm implements Action {
  readonly type = GtmActionsTypes.ExportMarketGtm;

  constructor(public payload: string) {}
}
