import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { format, sub } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AreaLocalName } from '../../../../../../models/IMarketTrendsHeader';
import { pdfExportData } from '../../../../../../store/selectors/pdf.selector';
import { IAppState } from '../../../../../../store/state/app.state';
import { IPdfPreviewData } from '../../../../../../store/state/pdf.state';

@Component({
  selector: 'app-cover-filter-section',
  templateUrl: './cover-filter-section.component.html',
  styleUrls: ['./cover-filter-section.component.scss']
})
export class CoverFilterSectionComponent implements OnInit {
  pdfPreviewData: Observable<{
    displayStartDate: string;
    displayPrice: string;
    propertyType: string;
    displayEndDate: string;
    displayCombinedDate: string;
    areaLocalNames: AreaLocalName[];
    displayReo: string;
  }>;

  constructor(private store: Store<IAppState>) { }

  priceFormat(priceRange) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    const priceArr = priceRange.split(' - ');
    return priceArr
      .map(p => {
        return formatter.format(p);
      })
      .join(' - ');
  }

  dateFormat(date: IPdfPreviewData) {
    var startDateStart;
    var endDate;
    if (date.displayStartDate === undefined) {
      startDateStart = sub(new Date(date.displayDate), { months: 35 });
      endDate = date.displayDate;
    } else {
      startDateStart = date.displayStartDate;
      endDate = date.displayEndDate;
    }
    const displayStartDate = new Date(startDateStart).toLocaleDateString('us', {
      month: 'long',
      year: 'numeric'
    });
    const displayEndDate = new Date(endDate).toLocaleDateString('us', {
      month: 'long',
      year: 'numeric'
    });
    const displayCombinedDate = `${displayStartDate} - ${displayEndDate}`;

    return { displayStartDate, displayEndDate, displayCombinedDate };
  }

  ngOnInit(): void {
    this.pdfPreviewData = this.store.select(pdfExportData).pipe(
      map(data => {
        const displayPrice = this.priceFormat(data.price);
        const dates = this.dateFormat(data);
        const propertyType = data.propertyType.replace('property type(s) ', '');
        const areaLocalNames = data.areaLocalNames;
        const displayReo = data.displayReo;
        const displayEndDate = dates.displayEndDate;
        const displayStartDate = dates.displayStartDate;
        const displayCombinedDate = dates.displayCombinedDate;
        return { displayPrice, displayEndDate, displayStartDate, propertyType, displayCombinedDate, areaLocalNames, displayReo };
      })
    );
  }
}
