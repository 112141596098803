import { IMarketTrendsData } from '../../models/IMarketTrendsData';
import { IMarketTrendsFilters } from '../../models/IMarketTrendsFilters';
import { ITrendsOptions } from '../../models/ITrendsOptions';

interface IMarketTrendsPdfState {
  pending: boolean;
  options: ITrendsOptions;
}

export interface IMarketTrendsState {
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  trendsReportDetails: IMarketTrendsData;
  trendsFilters: IMarketTrendsFilters;
  chartDataType: number;
  pdfState: IMarketTrendsPdfState;
}

export const initialMarketTrendsDataState: IMarketTrendsState = {
  loading: true,
  loaded: false,
  hasError: false,
  trendsReportDetails: {
    trendscopy: undefined,
    agentReportEntity: undefined,
    markettrendsheader: undefined,
    trendstabledata: [],
    trendsgraphdata: [],
    trendsgraphtables: [],
    marketTrendOptions: undefined,
    imagePromoEntities: undefined,
    videoEntities: undefined
  },
  pdfState: {
    pending: false,
    options: {
      chartdatatype: 0,
      showcover: true,
      showaimsi: true,
      showamsp: true,
      showdom: true,
      shownbrsoldar: true,
      shownl: true,
      showsplp: true,
      showspsqft: true,
      showtable: true,
      islandscape: true,
      issir: true,
      showAgentInfo: true
    }
  },
  trendsFilters: new IMarketTrendsFilters(),
  chartDataType: 0
};

export function getInitialMarketTrendsDataState(): IMarketTrendsState {
  return initialMarketTrendsDataState;
}
