import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { chunk } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MarketTrendsPagedArray } from '../../../../models/IMarketTrendsPagedArray';
import { ITrendsOptionEnum } from '../../../../models/ITrendsOptions';
import { MarketTrendsChartConfiguration } from '../../../../models/MarketTrendsChartConfiguration';
import { MarketTrendsService } from '../../../../services/market-trends.service';
import { selectTrendsDataLoaded } from '../../../../store/selectors/market-trends.selector';
import { pdfOptions, pdfPreviewOrientation } from '../../../../store/selectors/pdf.selector';
import { IAppState } from '../../../../store/state/app.state';

@Component({
  selector: 'app-trends-export',
  templateUrl: './trends-export.component.html',
  styleUrls: ['./trends-export.component.scss'],
  animations: [
    trigger('showHide', [
      transition(':enter', [style({ opacity: 0 }), animate('.1s', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('.1s', style({ opacity: 0 }))])
    ])
  ]
})
export class TrendsExportComponent implements OnInit {
  loaded$: Observable<boolean>;
  pdfIsLandscape$: Observable<boolean>;
  pdfPreviewData$: Observable<{
    arr: MarketTrendsChartConfiguration[];
    isLandscape: boolean;
    showTable: boolean;
    pagedArray: MarketTrendsPagedArray;
    showCover: boolean;
    showAgentInfo: boolean;
  }>;
  private cardDataArray: MarketTrendsChartConfiguration[];

  constructor(private store: Store<IAppState>, private marketTrends: MarketTrendsService) {}

  ngOnInit(): void {
    this.pdfIsLandscape$ = this.store.select(pdfPreviewOrientation);
    this.loaded$ = this.store.select(selectTrendsDataLoaded);
    this.cardDataArray = this.marketTrends.marketTrendsChartConfiguration;
    this.pdfPreviewData$ = this.store.select(pdfOptions).pipe(
      map(pdfOpts => {
        const arr = this.cardDataArray.filter(card => {
          return pdfOpts[ITrendsOptionEnum[card.shortHand]];
        });
        const showTable = pdfOpts.showtable;
        const isLandscape = pdfOpts.islandscape;
        const pagedArray: MarketTrendsPagedArray = this.arrangeChartPages(arr, pdfOpts.islandscape, showTable);
        const showCover = pdfOpts.showcover;
        const showAgentInfo = pdfOpts.showAgentInfo;
        return { arr, showTable, pagedArray, isLandscape, showCover, showAgentInfo };
      })
    );
  }

  arrangeChartPages(array: MarketTrendsChartConfiguration[], isLandscape: boolean, showTable: boolean): MarketTrendsPagedArray {
    if (!array.length) {
      return { pages: [] };
    }
    const tempData = {
      pages: []
    };
    let firstPage;
    if (isLandscape) {
      tempData.pages = chunk(array, 1);
    } else {
      if (showTable) {
        firstPage = array.shift();
        tempData.pages.push([firstPage]);
      }
      const remainingPages = chunk(array, 2);
      remainingPages.forEach(p => tempData.pages.push(p));
    }
    return tempData;
  }
}
