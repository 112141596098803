import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAgentReport } from '../../../../../../models/IAgentReport';
import { pdfExportAgentData } from '../../../../../../store/selectors/pdf.selector';
import { IAppState } from '../../../../../../store/state/app.state';

@Component({
  selector: 'app-cover-agent-section',
  templateUrl: './cover-agent-section.component.html',
  styleUrls: ['./cover-agent-section.component.scss']
})
export class CoverAgentSectionComponent implements OnInit {
  agentData$: Observable<{ socialBlockExists: boolean; data: IAgentReport }>;

  constructor(private store: Store<IAppState>) {}

  ngOnInit(): void {
    this.agentData$ = this.store.select(pdfExportAgentData).pipe(
      map(data => {
        const socialBlockExists = this.checkSocialBlock(data);
        return { data, socialBlockExists };
      })
    );
  }

  checkSocialBlock(d: IAgentReport) {
    const links = Object.keys(d).filter(key => key.toLocaleLowerCase().includes('link'));
    return links.some(key => {
      return d[key];
    });
  }
}
