import { Component, Input } from '@angular/core';
import { Disclaimer } from '../../../../models/IDisclaimer';

@Component({
  selector: 'app-disclaimer-banner-container',
  templateUrl: './disclaimer-banner-container.component.html',
  styleUrls: ['./disclaimer-banner-container.component.scss']
})
export class DisclaimerBannerContainerComponent {
  @Input() data: Disclaimer[];

  constructor() {}
}
