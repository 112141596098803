export class IMarketFiltersV2 {
  state: MarketFilters;
  pricesDirty: boolean;

  constructor(
    oktaId: string = '',
    nrtCoLocalId: number = 1,
    city: number[] = [],
    county: number[] = [],
    zip: number[] = [],
    mlsid: string[] = [],
    timeFrame: string[] = [],
    groupByLevel: string[] = ['Brokerage'],
    msTypeName: string[] = ['Total'],
    unitType: string[] = ['Volume'],
    dateRange: IDateRange = { datePeriod: '', startDate: '', endDate: '' },
    mlsArea: number[] = [],
    returnRows: number = 5,
    priceMin: number = 0,
    priceMax: number = 999999999,
    propertyType: number[] = [1, 2, 9],
    schoolDistrict: number[] = [],
    subDivision: number[] = [],
    isReo: boolean = false,
    pricesDirty = false
  ) {
    this.state = {
      oktaId: oktaId,
      dateRange: dateRange,
      priceMax: priceMax,
      priceMin: priceMin,
      propertyType: propertyType,
      mlsArea: mlsArea,
      schoolDistrict: schoolDistrict,
      subDivision: subDivision,
      city: city,
      zip: zip,
      county: county,
      mlsid: mlsid,
      returnRows: returnRows,
      groupByLevel: groupByLevel,
      msTypeName: msTypeName,
      unitType: unitType,
      nrtCoLocalId: nrtCoLocalId,
      isReo: isReo
    };
    this.pricesDirty = pricesDirty;
  }
}

export interface MarketFilters {
  oktaId?: string;
  nrtCoLocalId?: number;
  city?: number[];
  county?: number[];
  zip?: number[];
  mlsArea?: number[];
  schoolDistrict?: number[];
  subDivision?: number[];
  propertyType?: any[];
  dateRange?: IDateRange;
  priceMax?: number;
  priceMin?: number;
  mlsid?: string[];
  returnRows?: number;
  groupByLevel?: string[];
  msTypeName?: string[];
  unitType?: string[];
  isReo?: boolean;
}

export enum MarketFiltersV2Enum {
  mlsid = 'mlsid',
  timeFrame = 'timeFrame',
  groupByLevel = 'groupByLevel',
  msTypeName = 'msTypeName',
  unitType = 'unitType',
  city = 'city',
  county = 'county',
  zip = 'zip',
  propertyType = 'propertyType',
  dateRange = 'dateRange',
  priceMin = 'priceMin',
  priceMax = 'priceMax',
  nrtCoLocalId = 'nrtCoLocalId',
  isReo = 'isReo'
}

export enum MarketFiltersDateRangeV2Enum {
  LQ = 'Last Quarter',
  YTD = 'Year To Date',
  LY = 'Last Year',
  Custom = 'Custom'
}

export enum MarketFiltersDateRangeAlternateV2Enum {
  'Last Quarter' = 'LQ',
  'Year To Date' = 'YTD',
  'Last Year' = 'LY',
  'Custom' = 'Custom'
}

export enum MarketFiltersV2PropertyTypeEnum {
  singleFamily = 'Single Family', // 1
  townHome = 'Townhome', // 2
  condominium = 'Condominium', // 9
  multiFamily = 'Multi-family', // 4
  mobile = 'Mobile', // 3
  commercial = 'Commercial', // 6
  lotLandFarm = 'Lot/Land/Farm' // 5  7
}

export enum TrendsFiltersPropertyTypeEnum {
  singleFamily = 'Single-Family', // 1
  multiFamily = 'Multi-Family', // 4
  townHome = 'Townhome' // 2
}

export const marketFiltersV2PropertyValueEnum = {
  singleFamily: 1, // 1
  multiFamily: 4, // 4
  townHome: 2, // 2
  condominium: 9, // 9
  mobile: 3, // 3
  commercial: 6, // 6
  lotLandFarm: 99 // Adds 5 & 7
};

export const marketFiltersV2PropertyKeyEnum = {
  1: 'Single Family', // 1
  2: 'Townhome', // 2
  9: 'Condominium', // 9
  4: 'Multi-family', // 4
  3: 'Mobile', // 3
  6: 'Commercial', // 6
  5: 'Lot/Land/Farm', // 5  7
  7: 'Lot/Land/Farm', // 5  7,
  99: 'Lot/Land/Farm'
};

export interface IDateRange {
  datePeriod: string;
  startDate: string;
  endDate: string;
}

export interface MarketLocationSubPanel {
  city: boolean;
  county: boolean;
  zip: boolean;
  mlsArea: boolean;
  schoolDistrict: boolean;
  subDivision: boolean;
}
