import { Injectable } from '@angular/core';
import { ExportOption } from '../models/ExportOption';
import { ReportTypeLongStrEnum } from '../models/ReportTypes';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(private themeSvc: ThemeService) {}

  static trimDataAfterParenthesis = (data: string) => {
    if (data.includes('(')) {
      return data.substring(0, data.indexOf('(') - 1);
    }
    return data;
  };

  generatePreviewControlsArray(pageType: ReportTypeLongStrEnum): ExportOption[] {
    const isSir = this.themeSvc.isSIR();
    return [
      new ExportOption('Cover Page', 'showcover', [ReportTypeLongStrEnum.MarketTrends, ReportTypeLongStrEnum.CustomReport]),
      new ExportOption('Show Agent Info', 'showAgentInfo', ['All']),
      new ExportOption('Overview Table', 'showtable', [ReportTypeLongStrEnum.MarketTrends]),
      new ExportOption('Average & Median Sales Prices', 'showamsp', [ReportTypeLongStrEnum.MarketTrends]),
      new ExportOption('Sales Price / List Price Ratio', 'showsplp', [ReportTypeLongStrEnum.MarketTrends]),
      new ExportOption('Number of Properties Sold & Absorption Rate', 'shownbrsoldar', [ReportTypeLongStrEnum.MarketTrends]),
      new ExportOption('Average Days on Market', 'showdom', [ReportTypeLongStrEnum.MarketTrends]),
      new ExportOption('Average Sales Price per sq. ft.', 'showspsqft', [ReportTypeLongStrEnum.MarketTrends]),
      new ExportOption('Inventory & MSI', 'showaimsi', [ReportTypeLongStrEnum.MarketTrends]),
      new ExportOption('New Listings', 'shownl', [ReportTypeLongStrEnum.MarketTrends]),
      new ExportOption('Brand logo', 'showBrandLogo', [
        ReportTypeLongStrEnum.Uno,
        ReportTypeLongStrEnum.MarketPosition,
        ReportTypeLongStrEnum.MarketSnapshot
      ]),
      new ExportOption(`Display ${isSir ? 'firm' : 'competitor'}`, 'showFirmNames', [
        ReportTypeLongStrEnum.Uno,
        ReportTypeLongStrEnum.MarketPosition,
        ReportTypeLongStrEnum.MarketSnapshot
      ]),
      new ExportOption('Data table', 'showMarketPositionTable', [ReportTypeLongStrEnum.MarketPosition]),
      new ExportOption('Show Trend Lines', 'showTrendLines', [ReportTypeLongStrEnum.CustomReport])
    ]
      .filter(x => {
        // check if the option should be on a given page.
        return x.reportPagesAllowed.includes(pageType) || x.reportPagesAllowed.includes('All');
      })
      .filter(x => {
        // filter out SIR disabled options
        const disabledSirOptions = ['showAgentInfo'];
        return isSir ? !disabledSirOptions.some(disableOpt => disableOpt === x.formControlName) : true;
      });
  }
}
