<div [ngClass]="difference < 0 ? 'down' : ( difference === 0 ? 'even' : 'up')" [ngSwitch]="type">
  <div *ngSwitchCase="0">
    <mat-icon>
      {{ difference < 0 ? 'arrow_downward' : (difference === 0 ? 'horizontal_rule' : 'arrow_upward') }}
    </mat-icon>
  </div>
  <div *ngSwitchCase="1">
    <span>{{value}}%</span>
  </div>
  <div *ngSwitchCase="2">
    <span class="standard">{{formattedVal}}</span>
  </div>
</div>
