import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MqFooterCBRModule } from './CBR/mq-footer-cbr.module';
import { MqFooterComponent } from './mq-footer.component';
import { MqFooterSIRModule } from './SIR/mq-footer-sir.module';

@NgModule({
  declarations: [
    MqFooterComponent
  ],
  imports: [
    CommonModule,
    MqFooterCBRModule,
    MqFooterSIRModule
  ],
  exports: [
    MqFooterCBRModule,
    MqFooterSIRModule,
    MqFooterComponent
  ]
})
export class MqFooterModule {
}
