<div class="action-buttons">
  <button *ngIf="sirBrand === false; else minimalMenu" [matMenuTriggerFor]="menu" color="primary" disableRipple="true" mat-flat-button>
    {{ btnText }}
    <mat-icon>share</mat-icon>
  </button>
  <ng-template #minimalMenu>
    <button (click)="openExportPdfPreview()" *ngIf="(loaded$ | async)?.ready" color="primary" mat-flat-button>
      Export Report
      <mat-icon>share</mat-icon>
    </button>
  </ng-template>
  <mat-menu #menu [class]="'report-share'">
    <button *ngIf="btnConfigOpt.previewWebsite" (click)="previewWebsite()" disableRipple="true" mat-menu-item>
      <app-action-button fallbackLabel="Preview Website" iconName="web_asset"></app-action-button>
    </button>
    <button *ngIf="btnConfigOpt.share" (click)="openShare()" disableRipple="true" mat-menu-item>
      <app-action-button fallbackLabel="Social Media" iconName="share"></app-action-button>
    </button>
    <button *ngIf="btnConfigOpt.exportPdf" (click)="openExportPdfPreview()" disableRipple="true" mat-menu-item>
      <app-action-button fallbackLabel="Export as PDF" iconName="picture_as_pdf"></app-action-button>
    </button>
    <button *ngIf="btnConfigOpt.email" (click)="openEmail()" disableRipple="true" mat-menu-item>
      <app-action-button fallbackLabel="Email" iconName="email" [outlined]="true"></app-action-button>
    </button>
  </mat-menu>
</div>
<ng-template appSocialDownload></ng-template>
