import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { IReportModalData } from '../../../../models/IReportModalData';

@Component({
  selector: 'app-downloadable-image',
  templateUrl: './downloadable-image.component.html',
  styleUrls: ['./downloadable-image.component.scss']
})
export class DownloadableImageComponent implements OnInit, OnDestroy {
  @Input() data: IReportModalData;
  @Output() ready = new EventEmitter<boolean>();
  logoReady = new Subject();
  chartReady = new Subject();
  subs = new Subscription();

  ngOnInit() {
    this.subs.add(
      forkJoin([this.logoReady, this.chartReady]).subscribe(res => {
        if (res.every(status => status)) {
          this.ready.emit(true);
        }
      })
    );
    if (!this.data?.withChart) {
      this.chartReady.next(true);
      this.chartReady.complete();
    }
  }

  logoLoaded() {
    this.logoReady.next(true);
    this.logoReady.complete();
  }

  chartOk() {
    this.chartReady.next(true);
    this.chartReady.complete();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
