<ng-container *ngIf="trendsLoading$ | async">
  <div class="flex-grow-1">
    <div class="trends-container d-flex flex-column flex-grow-1">
      <app-trends-skeleton [height]="700" *ngFor="let i of skeletonReportArray"></app-trends-skeleton>
    </div>
  </div>
</ng-container>
<div class="tablet-filters">
  <button (click)="toggleMenu()" class="mobile-filter-toggle-btn" aria-label="Edit Report Filters">
    <mat-icon>filter_list</mat-icon>
    Create Your Report
  </button>
</div>
<div *ngIf="trendsLoaded$ | async" class="trends-container d-flex flex-column flex-grow-1">
  <ng-container *ngIf="(hasData$ | async) && (trendsData$ | async).chartData; else noData">
    <app-trend-card
      [exportReport]="enableExports"
      [title]="(trendsData$ | async).title"
      (exportCSV)="exportCSV()"
      (exportXLS)="exportXLS()"
    >
      <ng-container>
        <app-trend-data-table-container></app-trend-data-table-container>
      </ng-container>
    </app-trend-card>
    <app-trend-card *ngFor="let chart of (trendsData$ | async).chartData" [title]="chart.label">
      <ng-container>
        <app-trend-chart-container [chart]="chart"></app-trend-chart-container>
      </ng-container>
    </app-trend-card>
  </ng-container>
  <ng-template #noData>
    <app-no-results [noResultsMessage]="'Sorry, no MLS data found!'" [showTimeoutIcon]="false" (ctaClicked)="clearFilters()">
      Please try using different filter combinations.
    </app-no-results>
  </ng-template>
</div>

<ng-container *ngIf="trendsError$ | async">
  <app-no-results
    [noResultsMessage]="'Sorry, we\'ve timed out!'"
    [buttonLabel]="'Refresh Page'"
    [showTimeoutIcon]="true"
    (ctaClicked)="refreshPage()"
  >
    The database connection has timed out, please refresh the page. If you need further assistance, please contact the Help Desk at
    <a href="tel:8776784357">877-678-HELP (877-678-4357).</a>
  </app-no-results>
</ng-container>
