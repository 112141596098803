import { Pipe, PipeTransform } from '@angular/core';
import { replace } from 'lodash-es';
import { FiltersV2Enum, LocationEnum } from '../models/FilterData';
import { ILocationsData } from '../models/ILocationsData';

@Pipe({
  name: 'locationDataName'
})
export class LocationDataNamePipe implements PipeTransform {
  transform(value: ILocationsData) {
    if (value.areaType === LocationEnum.county) {
      return !value.areaName.toLowerCase().includes(FiltersV2Enum.county) ?
        replace(value.areaName, ',', ' County,') :
        value.areaName;
    }
    return value.areaName;
  }
}
