import { Action } from '@ngrx/store';
import { FilterState, LocationSubPanel } from '../../models/FilterData';
import { IChips } from '../../models/IChips';

export enum FilterDataActionsActionTypes {
  ResetCustomFilter = '[CustomFilter] Clearing Custom Filter state',
  ClearClaimsFilters = '[#1 Claims Local Filters] Clear Claims Filters',
  FetchV2FiltersFromApi = '[#1 Claims Remote Filters] Fetch Filters from API',
  PendingDataBaseFilterUpdate = '[#1 Claims Remote Filters] Filter Update Pending',
  ApiResultsReceived = '[#1 Claims Remote Filters] Filter Update Received',
  SuccessUpdateV2FilterUpdates = '[#1 Claims Local Filters] Filter State Updated',
  ToggleV2FilterMenu = '[#1 Claims Filter Menu] Toggle Menu',
  SetCurrentPage = '[#1 Claims Pagination] Setting New Current Page',
  SetSortAscending = '[#1 Claims Pagination] Change Sort Order',
  SetPageCardSize = '[#1 Claims Pagination] Change Max Cards Per Page',
  RemoveSingleFilter = '[#1 Claims Chips] Remove Single Filter',
  UpdateClaimsFilters = '[#1 Claims Filters] Update Results Button Pressed',
  ReportFiltersOpen = '[#1 Claims Filters] Reports Filters Are Open',
  LocationSubPanelsOpen = '[#1 Claims Filters] Location Sub Panel Change ',
  SetPendingFilterChanges = '[#1 Claims Filters] Set Pending Filter Changes'
}

export class SetPendingFilterChanges implements Action {
  readonly type = FilterDataActionsActionTypes.SetPendingFilterChanges;
  constructor(public payload: boolean) {}
}

export class FilterApiResultsReceived implements Action {
  readonly type = FilterDataActionsActionTypes.ApiResultsReceived;
}

export class PendingDataBaseFilterUpdate implements Action {
  readonly type = FilterDataActionsActionTypes.PendingDataBaseFilterUpdate;
}

export class ResetCustomFilter implements Action {
  readonly type = FilterDataActionsActionTypes.ResetCustomFilter;
}

export class SuccessUpdateV2FilterUpdates implements Action {
  readonly type = FilterDataActionsActionTypes.SuccessUpdateV2FilterUpdates;

  constructor(public payload: FilterState) {}
}

export class ClearClaimsFilters implements Action {
  readonly type = FilterDataActionsActionTypes.ClearClaimsFilters;
}

export class FetchV2FiltersFromApi implements Action {
  readonly type = FilterDataActionsActionTypes.FetchV2FiltersFromApi;
}

export class ToggleV2FilterMenu implements Action {
  readonly type = FilterDataActionsActionTypes.ToggleV2FilterMenu;
}

export class SetCurrentPage implements Action {
  readonly type = FilterDataActionsActionTypes.SetCurrentPage;

  constructor(public payload: number) {}
}

export class SetSortAscending implements Action {
  readonly type = FilterDataActionsActionTypes.SetSortAscending;

  constructor(public payload: boolean) {}
}

export class SetPageCardSize implements Action {
  readonly type = FilterDataActionsActionTypes.SetPageCardSize;

  constructor(public payload: number) {}
}

export class RemoveSingleFilter implements Action {
  readonly type = FilterDataActionsActionTypes.RemoveSingleFilter;

  constructor(public payload: IChips) {}
}

export class UpdateClaimsFilters implements Action {
  readonly type = FilterDataActionsActionTypes.UpdateClaimsFilters;

  constructor(public payload: boolean) {}
}

export class ReportFiltersOpen implements Action {
  readonly type = FilterDataActionsActionTypes.ReportFiltersOpen;

  constructor(public payload: boolean) {}
}

export class LocationSubPanelsOpen implements Action {
  readonly type = FilterDataActionsActionTypes.LocationSubPanelsOpen;

  constructor(public payload: LocationSubPanel) {}
}

export type FilterDataActions =
  | PendingDataBaseFilterUpdate
  | ResetCustomFilter
  | ClearClaimsFilters
  | FetchV2FiltersFromApi
  | SuccessUpdateV2FilterUpdates
  | FilterApiResultsReceived
  | ToggleV2FilterMenu
  | SetCurrentPage
  | SetSortAscending
  | SetPageCardSize
  | RemoveSingleFilter
  | UpdateClaimsFilters
  | ReportFiltersOpen
  | LocationSubPanelsOpen
  | SetPendingFilterChanges;
