<div class="trends-card d-flex flex-column">
  <div *ngIf="showHeader" class="header d-flex">
    <div [ngClass]="isSir ? 'color-primary' : ''" class="title">{{ title || '' }}</div>
    <div *ngIf="exportReport" class="export">
      <button [matMenuTriggerFor]="menu" color="primary" mat-flat-button>
        Download Data
        <mat-icon> download</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="exportXLSClick()" mat-menu-item>Download .xlsx</button>
        <button (click)="exportCSVClick()" mat-menu-item>Download .csv</button> </mat-menu
      >&nbsp;
      <button [matMenuTriggerFor]="base" color="primary" mat-flat-button>
        Share Report
        <mat-icon>share</mat-icon>
      </button>
    </div>
    <div *ngIf="directPDF" class="export">
      <button color="primary" mat-flat-button [disabled]="directPdfPending$ | async" [matMenuTriggerFor]="aspBase">
        <div class="button-state d-flex justify-content-center align-items-center">
          <div class="me-2">Export Report</div>
          <ng-container *ngIf="directPdfPending$ | async; else ready">
            <mat-progress-spinner [color]="'accent'" diameter="20" mode="indeterminate"></mat-progress-spinner>
          </ng-container>
          <ng-template #ready>
            <mat-icon>share</mat-icon>
          </ng-template>
        </div>
      </button>

      <mat-menu #aspBase>
        <button disableRipple="true" mat-menu-item (click)="downloadDirectPdf()" [disabled]="directPdfPending$ | async">
          <mat-icon>file_download</mat-icon>
          Export as PDF
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="body">
    <ng-content select="ng-container"></ng-content>
  </div>
</div>
<mat-menu #base>
  <button (click)="exportPdf()" disableRipple="true" mat-menu-item>
    <mat-icon>file_download</mat-icon>
    Export as PDF
  </button>
  <button disableRipple="true" mat-menu-item>
    <app-action-button (click)="openEmail()" fallbackLabel="Email" iconName="mail_outline"></app-action-button>
  </button>
</mat-menu>
