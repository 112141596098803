import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { SavedSearchFields } from 'app/models/SavedSearchFields';
import { mergeMap } from 'rxjs/operators';
import { ISavedSearchModalData } from '../../../../models/ISavedSearchModalData';
import { ModalService } from '../../../../services/modal.service';
import { SavedSearchService } from '../../../../services/saved-search.service';
import { FetchSavedSearches } from '../../../../store/actions/saved-search.action';
import { IAppState } from '../../../../store/state/app.state';
import { ISavedSearch } from '../../../../store/state/savedSearch.state';
import { SavedSearchConfirmationModalComponent } from '../saved-search-confirmation-modal/saved-search-confirmation-modal.component';

@Component({
  selector: 'app-saved-searches-table',
  templateUrl: './saved-searches-table.component.html',
  styleUrls: ['./saved-searches-table.component.scss']
})
export class SavedSearchesTableComponent {
  @Input() savedSearches: ISavedSearch[] = [];
  @Input() data: ISavedSearchModalData = null;
  @Output() selectedTableRow = new EventEmitter<ISavedSearch>();
  selectedSearch: ISavedSearch;
  SavedSearchFields = SavedSearchFields;
  trackByFn(index, search) {
    return search.rowKey;
  }

  constructor(private store: Store<IAppState>, private modalSvc: ModalService, private savedSvc: SavedSearchService) {}

  selectRow(item: ISavedSearch) {
    this.selectedSearch = this.selectedSearch === item ? null : item;
    this.selectedTableRow.emit(this.selectedSearch);
  }

  deleteSavedSearch(id: string) {
    this.modalSvc
      .open(SavedSearchConfirmationModalComponent, {
        width: '25%',
        height: '25%'
      })
      .afterClosed()
      .pipe(
        mergeMap(result => {
          if (!result) {
            return;
          }
          return this.savedSvc.deleteSavedSearch(id);
        })
      )
      .subscribe({
        next: () => {
          this.store.dispatch(new FetchSavedSearches());
        },
        error: err => {
          console.error(err);
        }
      });
  }

  setSearchDefault(rowKey: string, $event: MouseEvent, mls: string) {
    $event.stopPropagation();
    this.savedSvc.setSearchDefault(rowKey, mls, this.data.reportType).subscribe({
      next: () => {
        this.store.dispatch(new FetchSavedSearches());
      },
      error: err => {
        console.error(err);
      }
    });
  }
}
