import { Action } from '@ngrx/store';
import { IPdfPreviewData } from '../state/pdf.state';

export enum PdfActionsActionTypes {
  UpdatePdfOptions = '[PDF] Update Options in Preview',
  ResetPdfOptions = '[PDF] Reset Options in Preview',
  FetchReportPDF = '[PDF] Fetch Report PDF',
  FetchReportPDFSuccess = '[PDF] Fetch Report PDF Success',
  ExportPreviewToggle = '[PDF] Export Preview Window Toggle',
  SetExportPreviewData = '[PDF] Set Export Preview Data',
  ResetExportPreviewData = '[PDF] Reset Export Preview Data'
}

export class SetExportPreviewData implements Action {
  readonly type = PdfActionsActionTypes.SetExportPreviewData;

  constructor(public payload: IPdfPreviewData) {}
}

export class ResetExportPreviewData implements Action {
  readonly type = PdfActionsActionTypes.ResetExportPreviewData;
}

export class ExportPreviewToggle implements Action {
  readonly type = PdfActionsActionTypes.ExportPreviewToggle;

  constructor(public payload: boolean) {}
}

export class UpdatePdfOptions implements Action {
  readonly type = PdfActionsActionTypes.UpdatePdfOptions;

  constructor(public payload: any) {}
}

export class ResetPdfOptions implements Action {
  readonly type = PdfActionsActionTypes.ResetPdfOptions;

  constructor() {}
}

export class FetchReportPDFSuccess implements Action {
  readonly type = PdfActionsActionTypes.FetchReportPDFSuccess;
}

export class FetchReportPDF implements Action {
  readonly type = PdfActionsActionTypes.FetchReportPDF;

  constructor(
    public payload: {
      orientation: 'landscape' | 'portrait' | null;
      reportType: number;
      isSIR?: boolean;
      showLogo?: boolean;
      showFirmName?: boolean;
      showAgentInfo?: boolean;
      showMarketPositionTable?: boolean;
    }
  ) {}
}

export type PdfActions =
  | UpdatePdfOptions
  | ResetPdfOptions
  | FetchReportPDF
  | FetchReportPDFSuccess
  | ExportPreviewToggle
  | SetExportPreviewData
  | ResetExportPreviewData;
