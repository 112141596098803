export enum DataLayerObject {
  metro = 'metro',
  office = 'office',
  metroName = 'metroName',
  metroID = 'metroID',
  officeName = 'officeName',
  officeID = 'officeID',
  officeLocationID = 'officeLocationID',
  oktaID = 'oktaID',
  userType = 'userType',
  emailAddress = 'emailAddress',
  createdAt = 'createdAt',
  titles = 'titles',
  brand = 'brand',
  businessUnit = 'businessUnit',
  personMasterId = 'personMasterId',
  agentMasterId = 'agentMasterId'
}
