import { ReportDataActions, ReportDataActionsActionTypes } from '../actions/reports.action';
import { getInitialNo1ClaimsReportDataState, INo1ClaimsReportDataState } from '../state/no1ClaimsReports.state';

export function no1ClaimsReportsDataReducer(
  state = getInitialNo1ClaimsReportDataState(),
  action: ReportDataActions
): INo1ClaimsReportDataState {
  switch (action.type) {
    case ReportDataActionsActionTypes.FetchReportDataSuccess: {
      return {
        ...state,
        reports: action.payload,
        loaded: true,
        loading: false,
        noResults: false
      };
    }

    case ReportDataActionsActionTypes.FetchReportsNoResults: {
      return {
        ...state,
        loaded: true,
        loading: false,
        noResults: true
      };
    }

    case ReportDataActionsActionTypes.FetchReportData: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case ReportDataActionsActionTypes.SetReportPageData: {
      return {
        ...state,
        details: action.payload
      };
    }

    case ReportDataActionsActionTypes.ResetReportPageData: {
      return {
        ...state,
        details: {
          ...state.details,
          loading: action.payload
        }
      };
    }

    case ReportDataActionsActionTypes.FetchFilteredReportData: {
      return {
        ...state,
        filteredSet: {
          pagedData: [],
          filteredData: [],
          loaded: false
        }
      };
    }

    case ReportDataActionsActionTypes.FetchFilteredReportDataSuccess: {
      const [filteredValues, pagedValues] = action.payload;
      return {
        ...state,
        filteredSet: {
          filteredData: filteredValues,
          pagedData: pagedValues,
          loaded: true
        },
        noResults: !filteredValues.length
      };
    }

    default:
      return state;
  }
}
