import { SavedSearchActions, SavedSearchActionsActionTypes } from '../actions/saved-search.action';
import { getInitialSavedSearchState, ISavedSearchState } from '../state/savedSearch.state';

export function SavedSearchReducer(state = getInitialSavedSearchState(), action: SavedSearchActions): ISavedSearchState {
  switch (action.type) {
    case SavedSearchActionsActionTypes.FetchSavedSearches:
      return {
        ...state,
        loading: true,
        loaded: false,
        isError: false
      };
    case SavedSearchActionsActionTypes.FetchSavedSearchesFailure:
      return {
        ...state,
        searchesArray: null,
        isError: true,
        loading: false,
        loaded: true
      };
    case SavedSearchActionsActionTypes.FetchSavedSearchesSuccess:
      return {
        ...state,
        searchesArray: {
          ...action.payload
        },
        loaded: true,
        loading: false,
        isError: false
      };
    case SavedSearchActionsActionTypes.SaveSavedSearch:
      return {
        ...state
      };

    default:
      return state;
  }
}
