import { IReportData } from '../../models/IReportData';
import { EncryptedReportData } from '../../models/IReportPageData';

export interface INo1ClaimsReportDataState {
  reports: IReportData[];
  filteredSet: {
    filteredData: IReportData[];
    pagedData: IReportData[];
    loaded: boolean;
  };
  noResults: boolean;
  loading: boolean;
  loaded: boolean;
  details: EncryptedReportData;
}

export const initialNo1ClaimReportDataState: INo1ClaimsReportDataState = {
  reports: [],
  filteredSet: {
    filteredData: [],
    pagedData: [],
    loaded: false
  },
  loading: false,
  loaded: false,
  noResults: false,
  details: {
    oktaId: null,
    msUnoReportId: '',
    reportDetail: undefined,
    encryptedData: undefined,
    loading: false,
    imagePath: undefined,
    cbposition: 1
  }
};

export function getInitialNo1ClaimsReportDataState(): INo1ClaimsReportDataState {
  return initialNo1ClaimReportDataState;
}
