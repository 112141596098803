import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SocialBlockDirective } from '../../../directives/social-block.directive';
import { PagedArrayFilterPipe } from '../../../pipes/paged-array-filter.pipe';
import { TrendDataFormatPipe } from '../../../pipes/trend-data-format.pipe';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { TrendCardComponent } from './trend-card/trend-card.component';
import { ChartComponent } from './trend-chart-container/chart/chart.component';
import { TrendChartContainerComponent } from './trend-chart-container/trend-chart-container.component';
import { TrendChartDescriptionComponent } from './trend-chart-container/trend-chart-description/trend-chart-description.component';
import { TrendDataTableContainerComponent } from './trend-data-table-container/trend-data-table-container.component';
import { DynamicCellComponent } from './trend-data-table-container/trend-table/dynamic-cell/dynamic-cell.component';
import { TrendTableComponent } from './trend-data-table-container/trend-table/trend-table.component';
import { TrendsReportPageComponent } from './trends-report-page/trends-report-page.component';
import { TrendsSkeletonComponent } from './trends-report-page/trends-skeleton/trends-skeleton.component';

@NgModule({
  declarations: [
    TrendDataTableContainerComponent,
    TrendChartDescriptionComponent,
    TrendTableComponent,
    TrendChartContainerComponent,
    TrendDataTableContainerComponent,
    DynamicCellComponent,
    TrendDataFormatPipe,
    TrendsReportPageComponent,
    SocialBlockDirective,
    PagedArrayFilterPipe
  ],
  imports: [CommonModule, SharedComponentsModule, TrendCardComponent, ChartComponent, TrendsSkeletonComponent],
  exports: [
    TrendDataTableContainerComponent,
    TrendChartDescriptionComponent,
    TrendTableComponent,
    TrendChartContainerComponent,
    TrendDataTableContainerComponent,
    DynamicCellComponent,
    TrendDataFormatPipe,
    ChartComponent,
    TrendsReportPageComponent,
    SocialBlockDirective,
    PagedArrayFilterPipe
  ],
  providers: []
})
export class TrendsNoRouteModule {}
