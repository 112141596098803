import { GalleryImage } from './GalleryImage';
import { IAgentReport } from './IAgentReport';
import { ICustomReportHeader } from './ICustomReportHeader';

export enum ICustomDisplayAsEnum {
  'Column',
  'Line'
}

export enum ICustomDataTypeEnum {
  'msp' = 'Median Sales Price',
  'asp' = 'Average Sold Price',
  'mlp' = 'Median List Price',
  'alp' = 'Average List Price',
  'nl' = 'New Listings',
  'ai' = 'Inventory',
  'solds' = 'Number of Properties Sold',
  'expired' = 'Expired or Cancelled',
  'ao' = 'Accepted Offers (AO)',
  'aopernl' = 'Accepted Offers / New Listings',
  'aoperai' = 'Accepted Offers / Inventory',
  'dom' = 'Days on Market (DOM)',
  'msi' = 'Months Supply Inv (MSI)',
  'sppersf' = 'Price per Square Foot',
  'splp' = 'Sales Price / List Price Ratio',
  'asp3' = '3 Month Average Sales Price',
  'alp3' = '3 Month Average List Price',
  'sppersf3' = '3 Month Price per Square Foot',
  'splp3' = '3 Month Sales Price / List Price Ratio'
}

export enum ICustomDataTypeLabelEnum {
  'msp' = 'Price',
  'asp' = 'Price',
  'mlp' = 'Price',
  'alp' = 'Price',
  'nl' = 'Units',
  'ai' = 'Units',
  'solds' = 'Units',
  'expired' = 'Units',
  'ao' = 'Units',
  'aopernl' = 'Percentage',
  'aoperai' = 'Percentage',
  'dom' = 'Days',
  'msi' = 'Months',
  'sppersf' = 'Dollars',
  'splp' = 'Percentage',
  'asp3' = 'Price',
  'alp3' = 'Price',
  'sppersf3' = 'Dollars',
  'splp3' = 'Percentage'
}

export enum ICustomDataTypeByNumEnum {
  'msp' = 0,
  'asp' = 1,
  'mlp' = 2,
  'alp' = 3,
  'nl' = 4,
  'ai' = 5,
  'solds' = 6,
  'expired' = 7,
  'ao' = 8,
  'aopernl' = 9,
  'aoperai' = 10,
  'dom' = 11,
  'msi' = 12,
  'sppersf' = 13,
  'splp' = 14,
  'asp3' = 15,
  'alp3' = 16,
  'sppersf3' = 17,
  'splp3' = 18
}

export interface CustomReportDbData {
  period: string;
  msp: number;
  asp: number;
  mlp: number;
  alp: number;
  nl: number;
  ai: number;
  solds: number;
  expired: number;
  ao: number;
  aopernl: number;
  aoperai: number;
  dom: number;
  msi: number;
  sppersf: number;
  splp: number;
  asp3: number;
  alp3: number;
  sppersf3: number;
  splp3: number;
  stateCode: string;
}

export interface DataRow {
  name: string;
  data: string[];
}

export interface CustomReportExportDataTypes {
  seriesTypes: string;
  dataKey: string;
  axisLabel: string;
}

export interface ICustomDataDetails {
  agentData: IAgentReport;
  images: GalleryImage[];
  header: ICustomReportHeader;
  reportData: CustomReportDbData[];
  dataTypes: CustomReportExportDataTypes[];
}
