<section class="download-container">
  <div class="text">
    <div class="header">Download Marketing Assets</div>
    <div class="description">
      Select your favorite assets to promote the power of
      {{ isSIR ? "Sotheby's International Realty" : 'Coldwell Banker' }} on all social media platforms.
    </div>
  </div>
  <div class="tile-container">
    <app-marketing-assets-card
      [marketData]="imageAssetCard"
      [showHomeSpotter]="!isSIR"
      [targetSocialContainer]="imageContainer"
      [reportData]="reportData"
    ></app-marketing-assets-card>
    <app-marketing-assets-card
      [marketData]="graphAssetCard"
      [showHomeSpotter]="!isSIR"
      [targetSocialContainer]="imageContainer"
      [reportData]="reportData"
    ></app-marketing-assets-card>
    <app-marketing-assets-card
      (copyUrlActionSuccess)="copyUrlEvent()"
      *ngIf="hasVideo"
      [marketData]="videoAssetCard"
      [reportData]="reportData"
    ></app-marketing-assets-card>
  </div>
</section>
<ng-template appSocialDownload></ng-template>
