import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrandDetails } from '../../../models/IApplicationData';
import { OptionsService } from '../../../services/options.service';
import { selectAllAppData } from '../../../store/selectors/applicationData.selector';
import { IAppState } from '../../../store/state/app.state';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss']
})
export class BrandingComponent implements OnInit {
  @Input() isSIR = false;
  @Input() isTerradatum = false;
  appData$: Observable<{ brandInfo: BrandDetails; title: string; loaded: boolean }>;
  baseURL: string;

  constructor(private store: Store<IAppState>, private option: OptionsService) {}

  ngOnInit(): void {
    this.baseURL = this.option.baseUrl;
    this.appData$ = this.store.select(selectAllAppData).pipe(
      map(data => {
        const brand = data.default_brand;
        const brandInfo = data.brands[this.isSIR ? 'sothebys' : brand];
        const title = data.application.name;
        const loaded = !!data.default_brand;
        return { brandInfo, title, loaded };
      })
    );
  }
}
