import { Injectable } from '@angular/core';
import { IAgentReport } from '../models/IAgentReport';
import { IBaseHeader } from '../models/IBaseHeader';
import { IReportHeader } from '../models/IReportHeader';

@Injectable({
  providedIn: 'root'
})
export class DisclaimerService {
  constructor() {}

  private static formatPrice(priceRange) {
    if (!priceRange) {
      return 'all price ranges';
    }
    if (priceRange.indexOf('All') !== -1) {
      return 'all price ranges';
    } else if (priceRange.indexOf('$1M+') !== -1) {
      return 'price ranges of $1 million or more';
    } else {
      return 'price range from ' + priceRange;
    }
  }

  private static formatPropertyType(reportHeaderEntity) {
    if (reportHeaderEntity.propertyType) {
      return `for ` + reportHeaderEntity.propertyType;
    } else {
      return '';
    }
  }

  private static formatAreaType(areaType: string, areaLocalName: string) {
    if (!areaType) {
      return areaLocalName || 'All Locations';
    }
    if (areaType.indexOf('County') !== -1) {
      return DisclaimerService.getCountyString(areaLocalName);
    } else if (areaType.indexOf('City') !== -1) {
      return areaLocalName;
    } else if (areaType.indexOf('Zip') !== -1) {
      return 'the Zip Code: ' + areaLocalName;
    } else {
      return '';
    }
  }

  private static getCountyString(countyName) {
    const county = countyName.split(',')[0];
    const state = countyName.split(',')[1];
    countyName = county + ' County,' + state;
    return countyName;
  }

  formatCBRDisclaimer(reportHeaderEntity: IReportHeader | IBaseHeader, agentReportEntity: IAgentReport, isMarket: boolean) {
    let prefix;
    let suffix;
    const date = new Date();
    const dateReportPulled = date.toLocaleDateString();
    const year = new Date().getFullYear();
    const price = isMarket ? (reportHeaderEntity as IBaseHeader).priceRange : (reportHeaderEntity as IReportHeader).priceType;

    if (reportHeaderEntity.msTypeName.toLowerCase().includes('unit')) {
      prefix =
        `Based on total number of closed units in ` +
        `${DisclaimerService.formatAreaType(reportHeaderEntity.propertyType, reportHeaderEntity.areaLocalName)} in ` +
        `${DisclaimerService.formatPrice(price)}` +
        ` ${DisclaimerService.formatPropertyType(reportHeaderEntity)} as reported ` +
        `by ${reportHeaderEntity.legalName} on ${dateReportPulled} for the period ` +
        `of ${reportHeaderEntity.displayDateRange}. One unit equals one side of a transaction (buyer or ` +
        `seller). Source data is deemed reliable but not guaranteed. Real estate agents affiliated ` +
        `with ${agentReportEntity.brokerageName} are independent contractor sales associates, not ` +
        `employees.`;
    } else {
      prefix =
        `Based on closed sales volume information from ${reportHeaderEntity.legalName} ` +
        `for ${DisclaimerService.formatAreaType(reportHeaderEntity.propertyType, reportHeaderEntity.areaLocalName)} ` +
        `in ${DisclaimerService.formatPrice(price)} ` +
        ` ${DisclaimerService.formatPropertyType(reportHeaderEntity)} as reported on ${dateReportPulled} for the period ` +
        `of ${reportHeaderEntity.displayDateRange}, calculated by multiplying the number of buyer and/or ` +
        `seller sides by sales price. Source data is deemed reliable but not guaranteed. Real estate agents affiliated ` +
        `with ${agentReportEntity.brokerageName} are independent contractor sales associates, not ` +
        `employees.`;
    }

    suffix =
      `&copy;${year} Coldwell Banker. All Rights Reserved. Coldwell Banker and the Coldwell Banker logos are trademarks of ` +
      `Coldwell Banker Real Estate LLC. The Coldwell Banker&reg; System is comprised of company owned offices which are owned ` +
      `by a subsidiary of Realogy Brokerage Group LLC and franchised offices which are independently owned and operated. The ` +
      `Coldwell Banker System fully supports the principles of the Fair Housing Act and the Equal Opportunity Act. `;

    return prefix + ' ' + suffix;
  }

  public formatDisclaimerSIR(reportHeaderEntity) {
    let prefix: string;
    let suffix: string;
    const date = new Date();
    const dateReportPulled = date.toLocaleDateString();
    const year = new Date().getFullYear();

    if (reportHeaderEntity.msTypeName.toLowerCase().includes('unit')) {
      prefix =
        `Based on total number of closed units in ` +
        `${DisclaimerService.formatAreaType(reportHeaderEntity.propertyType, reportHeaderEntity.areaLocalName)} ` +
        `in ${DisclaimerService.formatPrice(reportHeaderEntity.priceType)}` +
        ` ${DisclaimerService.formatPropertyType(reportHeaderEntity)} as reported ` +
        `by ${reportHeaderEntity.legalName} on ${dateReportPulled} for the period ` +
        `of ${reportHeaderEntity.displayDateRange}. One unit equals one side of a transaction (buyer or ` +
        `seller). Source data is deemed reliable but not guaranteed.`;
    } else {
      prefix =
        `Based on closed sales volume information from ${reportHeaderEntity.legalName} ` +
        `for ${DisclaimerService.formatAreaType(reportHeaderEntity.propertyType, reportHeaderEntity.areaLocalName)} in ` +
        `${DisclaimerService.formatPrice(reportHeaderEntity.priceType)} ` +
        ` ${DisclaimerService.formatPropertyType(reportHeaderEntity)} as reported on ${dateReportPulled} for the period ` +
        `of ${reportHeaderEntity.displayDateRange}, calculated by multiplying the number of buyer and/or ` +
        `seller sides by sales price. Source data is deemed reliable but not guaranteed.`;
    }
    suffix =
      '<br/><br/>&copy;' +
      `${year} Sotheby's International Realty. All rights reserved.  ` +
      `Sotheby’s International Realty® is a registered trademark. Each Sotheby’s International Realty office is
    independently owned and operated, except those operated by Sotheby’s International Realty, Inc. This material is
    based upon information which we consider reliable but because it has been supplied by third parties, we cannot
    represent that it is accurate or complete, and it should not be relied upon as such. If your property is listed
    with a real estate broker, please disregard. It is not our intention to solicit the offerings of other real estate
    brokers. We are happy to work with them and cooperate fully. Equal Housing Opportunity.`;

    return prefix + ' ' + suffix;
  }
}
