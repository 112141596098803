<div
  *ngIf="data"
  [ngStyle]="{ 'background-image': 'url(' + this.data.imageData.imageURL + ')' }"
  [ngClass]="[data.size, data.withChart ? 'chart' : '']"
  class="off-screen download-image-container"
  id="ratio-graphic"
>
  <div class="ranked-header">
    <div class="ranked-header_text">
      <p class="ranked-main">Ranked</p>
      <p class="ranked-main-number"><span class="ranked-pound-sign">#</span>1</p>
      <p class="ranked-secondary">
        {{ data.reportDetail.reportHeaderEntity | reportLocationName }}
      </p>
      <p *ngIf="data?.size !== 'HS_FB' && data?.size !== 'HS_Web'" class="ranked-subtitle">The Latest Numbers Say It All</p>
    </div>
    <div *ngIf="data.withChart" class="ranked-header_chart">
      <app-market-quest-chart
        (chartReady)="chartOk()"
        *ngIf="data.size"
        [downloadableImageType]="data.size"
        [chartSourceData]="data"
      ></app-market-quest-chart>
    </div>
  </div>
  <div class="ranked-footer">
    <div class="ranked-disclaimer">
      <span [innerHTML]="data.disclaimer"></span>
    </div>
    <div class="ranked-logo">
      <img (load)="logoLoaded()" alt="{{ this.data.reportDetail.agentReportEntity.brokerageName }}" src="{{ data.logoData.imageURL }}" />
    </div>
  </div>
</div>
