import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wif-help',
  templateUrl: './wif-help.component.html',
  styleUrls: ['./wif-help.component.scss']
})
export class WifHelpComponent {
  @Input() type: 'homespotter' | 'social';
  @Input() data: any;
  @Input() isSIR: boolean;

  closeHelp() {
    this.data.clear();
  }
}
