import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { IPdfState } from '../state/pdf.state';

const pdf = (state: IAppState) => state.pdf;
export const pdfOptions = createSelector(pdf, (state: IPdfState) => state.opts);
export const pdfShowLogo = createSelector(pdf, (state: IPdfState) => state.opts.showBrandLogo);
export const pdfPreviewOrientation = createSelector(pdf, (state: IPdfState) => state.opts.islandscape);
export const pdfShowHideFirmName = createSelector(pdf, (state: IPdfState) => state.opts.showFirmNames);
export const pdfShowAgentInfo = createSelector(pdf, (state: IPdfState) => state.opts.showAgentInfo);
export const pdfShowMarketPositionTable = createSelector(pdf, (state: IPdfState) => state.opts.showMarketPositionTable);
export const pdfPending = createSelector(pdf, (state: IPdfState) => state.pending);
export const pdfExportPreviewOpen = createSelector(pdf, (state: IPdfState) => state.previewOpen);
export const pdfExportData = createSelector(pdf, (state: IPdfState) => state.pdfPreviewData);
export const pdfExportAgentData = createSelector(pdf, (state: IPdfState) => state.pdfPreviewData.agentData);
