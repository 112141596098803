import { IMarketFiltersV2 } from '../../models/IMarketReportFilters';
import { MarketPositionAction, MarketPositionDataActionsActionTypes } from '../actions/market-position.action';
import { getInitialMarketPositionDataState, IMarketPositionState } from '../state/market-position.state';

export function MarketPositionDataReducer(state = getInitialMarketPositionDataState(), action: MarketPositionAction): IMarketPositionState {
  switch (action.type) {
    case MarketPositionDataActionsActionTypes.FetchMarketReports: {
      return {
        ...state,
        loaded: false,
        loading: true,
        marketReportDetails: null
      };
    }

    case MarketPositionDataActionsActionTypes.FetchMarketReportsSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        marketReportDetails: action.payload,
        error: false
      };
    }

    case MarketPositionDataActionsActionTypes.FetchMarketReportsError: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        marketReportDetails: null
      };
    }

    case MarketPositionDataActionsActionTypes.SetDatesAvailable: {
      return {
        ...state,
        dates: action.payload
      };
    }

    case MarketPositionDataActionsActionTypes.ResetMarketReportFilters: {
      const defaultMarketFilters = new IMarketFiltersV2();
      return {
        ...state,
        marketFilters: {
          ...state.marketFilters,
          state: {
            ...defaultMarketFilters.state
          }
        }
      };
    }

    case MarketPositionDataActionsActionTypes.UpdateMarketReportFilters: {
      return {
        ...state,
        marketFilters: {
          ...state.marketFilters,
          state: {
            ...state.marketFilters.state,
            ...action.payload
          }
        }
      };
    }

    case MarketPositionDataActionsActionTypes.RemoveSingleMarketFilter: {
      const original = state.marketFilters.state[action.payload.type];
      const update = original.filter(value => value !== action.payload.value);

      return {
        ...state,
        marketFilters: {
          ...state.marketFilters,
          state: {
            ...state.marketFilters.state,
            [action.payload.type]: update
          }
        }
      };
    }

    case MarketPositionDataActionsActionTypes.SetShowPercentage: {
      return {
        ...state,
        showPercent: action.payload
      };
    }

    case MarketPositionDataActionsActionTypes.MPPricesDirty: {
      return {
        ...state,
        marketFilters: {
          ...state.marketFilters,
          pricesDirty: action.payload
        }
      };
    }

    case MarketPositionDataActionsActionTypes.ClearMarketClaimsFilters: {
      return {
        ...state,
        marketFilters: {
          ...state.marketFilters,
          state: {
            ...state.marketFilters.state,
            groupByLevel: ['Brokerage'],
            msTypeName: ['Total'],
            unitType: ['Volume'],
            dateRange: { datePeriod: '', startDate: '', endDate: '' },
            priceMin: 0,
            priceMax: 999999999,
            propertyType: [1, 2, 9],
            city: [],
            county: [],
            zip: [],
            mlsArea: [],
            schoolDistrict: [],
            subDivision: []
          }
        }
      };
    }

    default:
      return state;
  }
}
