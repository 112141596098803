import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateService, monthYear } from 'app/services/date.service';
import { subMonths } from 'date-fns';

@Component({
  selector: 'app-time-period',
  templateUrl: './time-period.component.html',
  styleUrls: ['./time-period.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DateService
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: monthYear
    }
  ]
})
export class TimePeriodComponent implements OnInit {
  @Input() control: UntypedFormControl;
  maxDate$: Date;

  constructor() {}

  ngOnInit(): void {
    this.initValues();
  }

  initValues() {
    const d = new Date();
    this.maxDate$ = subMonths(d, 1);
    this.control?.patchValue(subMonths(d, 1));
  }

  closeDatePicker(eventData: Date, dp: MatDatepicker<any>) {
    this.control?.patchValue(eventData);
    this.control?.markAsDirty();
    dp.close();
  }
}
