import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TenantCode } from '../../services/tenant.service';
import { ThemeService } from '../../services/theme.service';
import { UserDataService } from '../../services/userData.service';
import { ApplicationDataActionsActionTypes, SetBrandCode, SetBrandCodeSuccess } from '../actions/applicationData.action';
import { IAppState } from '../state/app.state';

@Injectable()
export class ApplicationDataEffect {
  setCssBrand = createEffect(() =>
    this.actions$.pipe(
      ofType<SetBrandCode>(ApplicationDataActionsActionTypes.SetBrandCode),
      switchMap(action => {
        return of(this.css.setBrand(action.payload));
      }),
      switchMap((brand: TenantCode) => [new SetBrandCodeSuccess(brand)])
    )
  );

  constructor(
    private actions$: Actions,
    private userDataService: UserDataService,
    private store: Store<IAppState>,
    private css: ThemeService
  ) {}
}
