import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocationEnum } from 'app/models/FilterData';
import { selectUser } from 'app/store/selectors/userData.selector';
import { IAppState } from 'app/store/state/app.state';
import { format, subMonths } from 'date-fns';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash-es';
import { combineLatestWith, switchMap, map, Observable, take, withLatestFrom, merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ICustomChartData } from '../models/ICustomChartData';
import {
  CustomReportDbData,
  DataRow,
  ICustomDataDetails,
  ICustomDataTypeByNumEnum,
  ICustomDataTypeEnum,
  ICustomDataTypeLabelEnum,
  ICustomDisplayAsEnum,
  CustomReportExportDataTypes
} from '../models/ICustomDataTypeEnum';
import { ICustomReportPostData } from '../models/ICustomReportPostData';
import { IMarketTrendsDataSet, ITrendsDataPoint } from '../models/IMarketTrendsGraphSet';
import {
  CustomReportDataDetails,
  CustomReportDataTypes,
  CustomReportDisplayAs,
  DisplayAllData
} from '../store/selectors/custom-graph.selector';
import { ICustomReportControl } from '../store/state/custom-report.state';
import { selectOktaId } from '../store/selectors/userData.selector';
import { ICustomReportOptions } from '../models/ICustomReportOptions';

@Injectable({
  providedIn: 'root'
})
export class CustomReportService {
  private readonly dataTypes$: Observable<[number, number]>;
  private readonly chartTypes$: Observable<[number, number]>;
  private readonly displayAllData$: Observable<boolean>;
  private isThreePointChart = false;
  private threePointChartData: any;

  constructor(
    private http: HttpClient,
    private store: Store<IAppState>,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe
  ) {
    this.dataTypes$ = this.store.select(CustomReportDataTypes);
    this.chartTypes$ = this.store.select(CustomReportDisplayAs);
    this.displayAllData$ = this.store.select(DisplayAllData);
  }

  getCustomReportTypes(): Observable<ICustomReportControl[]> {
    return this.http.get<ICustomReportControl[]>(`${environment.apiBaseUrl}/api/report/getCustomReportDataTypes`);
  }

  getCustomReportData(mls: string, data: any) {
    return this.http.post<ICustomDataDetails>(`${environment.apiBaseUrl}/api/Report/postcustomreport`, data.postData);
  }

  getIsThreePointChart(): boolean {
    return this.isThreePointChart;
  }

  postCustomReportDataToDb(data: ICustomDataDetails) {
    const data2 = cloneDeep(data);
    return this.dataTypes$.pipe(
      combineLatestWith(this.chartTypes$),
      take(1),
      map((exportData: [[number, number], [number, number]]) => {
        return this.prepareDataArrayForExport(exportData);
      }),
      switchMap(dataTypeExport => {
        data2.dataTypes = dataTypeExport;
        return this.store.select(selectOktaId);
      }),
      switchMap(id => {
        Object.keys(data2).forEach(k => data2[k] == null && delete data2[k]);

        if (this.isThreePointChart) {
          data2.dataTypes = this.threePointChartData.graphDataTypes;
        }
        return this.http.post(`${environment.apiBaseUrl}/api/Report/${id}/PostCustomReportToDb`, data2, {
          responseType: 'text'
        });
      })
    );
  }

  fetchCustomReportPdf(data: ICustomDataDetails, options: ICustomReportOptions) {
    return this.postCustomReportDataToDb(data).pipe(
      withLatestFrom(this.store.select(selectOktaId)),
      switchMap(observable => {
        const [data, oktaId] = observable;
        return this.http.post(`${environment.apiBaseUrl}/api/export/PostCustomPDF/${data}?&oktaId=${oktaId}`, options, {
          responseType: 'blob'
        });
      })
    );
  }
  generateCustomReportPostData(data: ICustomReportPostData) {
    const userData = this.store.select(selectUser);
    const priceMax = data?.report.priceRange?.maxPrice || 999999999;
    const priceMin = data?.report.priceRange?.minPrice || 0;

    return userData.pipe(
      take(1),
      map(user => {
        const isCustom = data.report.dateRangeMulti.isCustom;
        const endDate = isCustom ? data.report.dateRangeMulti.endDate : data.report.dateRangeMulti.monthlyEndDate;
        const startDate = isCustom
          ? data.report.dateRangeMulti.startDate
          : subMonths(new Date(data.report.dateRangeMulti.monthlyEndDate), 36);
        const isReo = data.report.isReo === undefined ? false : data.report.isReo;

        let postData: any = {
          city: [],
          county: [],
          dateRange: {
            startDate: format(startDate || new Date(), 'MM/dd/yyyy'),
            endDate: format(endDate || new Date(), 'MM/dd/yyyy')
          },
          mlsArea: [],
          mlsid: [data.mls],
          priceMax: parseInt(priceMax.toString()?.replace(/[^0-9.]*/g, ''), 10) || 999999999,
          priceMin: parseInt(priceMin.toString()?.replace(/[^0-9.]*/g, ''), 10) || 0,
          propertyType: data.report.propType,
          schoolDistrict: [],
          subDivision: [],
          oktaId: user.oktaId,
          zip: [],
          isReo: [isReo]
        };
        if (data.locationFilters && data.locationFilters[0]) {
          data.locationFilters.forEach(locationFilter => {
            postData[LocationEnum[locationFilter.areaType]].push(locationFilter.areaID.toString());
          });
        }
        return { mls: data.mls, postData, data };
      })
    );
  }

  formatDataPoint(dataPoint: number, type: keyof typeof ICustomDataTypeByNumEnum) {
    switch (type) {
      case 'msp':
      case 'asp':
      case 'mlp':
      case 'alp':
      case 'asp3':
      case 'alp3':
      case 'sppersf':
      case 'sppersf3':
        return this.currencyPipe.transform(dataPoint, 'USD', 'symbol', '1.0-0');
    }
    switch (type) {
      case 'splp':
      case 'splp3':
      case 'aopernl':
      case 'aoperai':
        return this.decimalPipe.transform(dataPoint, '1.0-0') + '%';
    }
    switch (type) {
      case 'msi':
        return this.decimalPipe.transform(dataPoint, '1.0-2');
    }
    return this.decimalPipe.transform(dataPoint, '1.0-0');
  }

  prepareDataArrayForExport(data: [[number, number], [number, number]]) {
    const [dataTypes, seriesType] = data;
    let dataArr: CustomReportExportDataTypes[] = [];
    let seriesConverted = seriesType.map(t => ICustomDisplayAsEnum[t]);
    let dataKeyConverted = dataTypes.map(x => ICustomDataTypeByNumEnum[x]);
    let axisConvert = dataKeyConverted.map(x => ICustomDataTypeLabelEnum[x]);
    if (seriesConverted[0] === seriesConverted[1]) {
      seriesConverted = ['Column', 'Column'];
    }
    for (let x = 0; x < 2; x++) {
      let exportType: CustomReportExportDataTypes = {
        seriesTypes: seriesConverted[x],
        dataKey: dataKeyConverted[x],
        axisLabel: axisConvert[x]
      };
      dataArr.push(exportType);
    }
    return dataArr;
  }

  prepareDataArrayForChart(dataTypes: [number, number], dataDetails: ICustomDataDetails) {
    let dataArr: IMarketTrendsDataSet = {};
    dataTypes.map(dataType => {
      const convertDataTypeEnum = ICustomDataTypeByNumEnum[dataType];
      dataArr[convertDataTypeEnum] = [];
      dataDetails?.reportData?.forEach(d => {
        const entry: ITrendsDataPoint = {
          yData: d[convertDataTypeEnum] as number,
          xData: d.period
        };
        dataArr[convertDataTypeEnum].push(entry);
      });
    });
    return Object.keys(dataArr).map(key => {
      return dataArr[key];
    });
  }

  getChartDataPointsPerDataType(dataType: number, data): { yData: number; xData: string }[] {
    let dataArr: IMarketTrendsDataSet = {};
    const convertDataTypeEnum = ICustomDataTypeByNumEnum[dataType];
    dataArr[convertDataTypeEnum] = [];
    return data?.details?.reportData?.map(d => {
      return {
        yData: d[convertDataTypeEnum] as number,
        xData: d.period
      };
    });
  }

  formatTableData(data: CustomReportDbData[], showAllData: boolean, dataTypes: [number, number]) {
    let rowData = this.formatRowData(data, showAllData, dataTypes);
    let headerData = this.formatColHeaderData(data);
    return { headerData, rowData };
  }

  prepareCustomChartProps(data: [ICustomDataDetails, [number, number], [number, number]]) {
    const [dataDetails, dataTypes, chartTypes] = data;
    const details = cloneDeep(dataDetails);
    const dataPoints = this.prepareDataArrayForChart(dataTypes, details);
    let chartTypesConverted = chartTypes.map(t => ICustomDisplayAsEnum[t]);
    if (chartTypesConverted[0] === chartTypesConverted[1]) {
      chartTypesConverted = ['Column', 'Column'];
    }
    const axisLabels = this.getYAxisLabels(dataTypes);
    return { details, dataPoints, chartTypesConverted, axisLabels };
  }

  setChartDataData(): Observable<ICustomChartData> {
    return this.store.select(CustomReportDataDetails).pipe(
      filter(d => !!d),
      combineLatestWith(this.dataTypes$, this.chartTypes$),
      map((data: [ICustomDataDetails, [number, number], [number, number]]) => {
        return this.prepareCustomChartProps(data);
      })
    );
  }

  private formatColHeaderData(data: CustomReportDbData[]) {
    if (!data || data.length === 0) {
      return [];
    }
    const headerData = ['Description'];
    for (let i = 0; i < data.length; i++) {
      const dateToFormat = (data[i].period || '').split('.').join('-');
      let newDate = format(dateToFormat ? new Date(dateToFormat) : new Date(), 'MMM yy');
      headerData.push(newDate);
    }
    return headerData;
  }

  private formatRowData(data: CustomReportDbData[], showAll: boolean, dataTypes: [number, number]) {
    let arr: DataRow[] = [];
    let allDataTypes = Object.keys(ICustomDataTypeByNumEnum).filter(k => isNaN(Number(k)));
    let filteredArrayOfTypes;
    if (!data || data.length === 0) {
      return arr;
    }
    filteredArrayOfTypes = showAll ? allDataTypes : allDataTypes.filter(t => dataTypes.includes(ICustomDataTypeByNumEnum[t]));
    return this.sortDataTypes(data, filteredArrayOfTypes);
  }

  private sortDataTypes(data: CustomReportDbData[], keys: string[]) {
    const dataArr: DataRow[] = [];
    keys.map((key: keyof typeof ICustomDataTypeByNumEnum) => {
      if (ICustomDataTypeByNumEnum.hasOwnProperty(key)) {
        dataArr.push({
          name: ICustomDataTypeEnum[key],
          data: data?.map(x => this.formatDataPoint(x[key], key))
        });
      }
    });
    return dataArr;
  }

  getYAxisLabels(dataTypeEnums: number[]): string[] {
    return dataTypeEnums.map(x => {
      const key = ICustomDataTypeByNumEnum[x],
        prefix = ICustomDataTypeEnum[key],
        suffix = ICustomDataTypeLabelEnum[key];
      return `${prefix}  (${suffix})`;
    });
  }

  setThreePointChartData(data?: any): void {
    if (data) {
      this.isThreePointChart = true;
      this.threePointChartData = data;
    } else {
      this.isThreePointChart = false;
      this.threePointChartData = null;
    }
  }

  getThreePointChartData() {
    return this.threePointChartData;
  }
}
