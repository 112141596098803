import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';
import { addHours, formatISO, isBefore, parseISO } from 'date-fns';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { filter, pairwise } from 'rxjs/operators';
import { LogoutModalComponent } from '../components/modals/logout-modal/logout-modal.component';
import { BrandDetails } from '../models/IApplicationData';
import { IBreadCrumb } from '../models/IBreadCrumb';
import { ISocialFooterLink, SocialMediaGroup } from '../models/ISocialFooterLink';
import { SetPrevUrls } from '../store/actions/browser.action';
import { IAppState } from '../store/state/app.state';
import { IApplicationDataState } from '../store/state/applicationData.state';
import { ModalService } from './modal.service';

@Injectable()
export class OptionsService {
  constructor(
    private http: HttpClient,
    private route: Router,
    private modalService: ModalService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private store: Store<IAppState>
  ) {
    this.route.events
      .pipe(
        filter(e => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((event: any[]) => {
        const prev = event[0].urlAfterRedirects;

        if (prev.toLowerCase().includes('dashboard')) {
          this.store.dispatch(new SetPrevUrls([new IBreadCrumb('Dashboard', 'dashboard')]));
        }
        if (prev.toLowerCase().includes('claims')) {
          this.store.dispatch(new SetPrevUrls([new IBreadCrumb('#1 Claims', 'claims')]));
        }
        if (prev.toLowerCase().includes('trends')) {
          this.store.dispatch(new SetPrevUrls([new IBreadCrumb('Trends', 'trends')]));
        }
        if (prev.toLowerCase().includes('snapshot')) {
          this.store.dispatch(new SetPrevUrls([new IBreadCrumb('Snapshot', 'snapshot')]));
        }
        if (prev.toLowerCase().includes('view')) {
          const urlSegments: string[] = prev.split('/');
          const reportId = urlSegments[urlSegments.length - 1];
          this.store.dispatch(new SetPrevUrls([new IBreadCrumb('#1 Claims', 'claims'), new IBreadCrumb('Report', 'report', reportId)]));
        }
        if (prev.toLowerCase().includes('market')) {
          this.store.dispatch(new SetPrevUrls([new IBreadCrumb('Market Position', 'marketposition')]));
        }
      });
  }

  private _headUrlsExcluded: string[];

  get headUrlsExcluded(): string[] {
    return this._headUrlsExcluded;
  }

  set headUrlsExcluded(value: string[]) {
    this._headUrlsExcluded = value;
  }

  private _avatarInfo: any;

  get avatarInfo(): any {
    return this._avatarInfo;
  }

  set avatarInfo(value: any) {
    this._avatarInfo = value;
  }

  private _baseUrl: string;

  get baseUrl(): string {
    return this._baseUrl;
  }

  set baseUrl(value: string) {
    this._baseUrl = value;
  }

  private _brandInfo: IApplicationDataState;

  get brandInfo(): IApplicationDataState {
    return this._brandInfo;
  }

  set brandInfo(value: IApplicationDataState) {
    this._brandInfo = value;
  }

  getImagePathForReport(): string {
    return this.baseUrl;
  }

  verifyBrandInfoCache() {
    this.storage.remove('branding');
    const storageBranding: IApplicationDataState = this.storage.get('branding_data');
    if (!storageBranding?.default_brand) {
      return false;
    }
    const expiryDate = parseISO(storageBranding.expiry);
    if (!isBefore(Date.now(), expiryDate)) {
      return false;
    }
    this.brandInfo = storageBranding;
    return true;
  }

  getBrandingFromApi() {
    this.storage.remove('branding');
    const MqAppKey = 'f454c34d-c776-4e9b-ac08-90aa74e1e08f';
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    return this.http.get<IApplicationDataState>(`https://rsc.realogy.com/api/v1/application?app_key=${MqAppKey}`, { headers });
  }

  syncBranding(value: IApplicationDataState) {
    const ttl = { expiry: formatISO(addHours(Date.now(), 3)) };
    const valueWithExpiry = { ...value, ...ttl };
    this.brandInfo = valueWithExpiry;
    this.storage.set('branding_data', valueWithExpiry);
  }

  logout = () => {
    this.modalService.open(LogoutModalComponent, {
      width: '512px',
      position: { top: '222px' }
    });
  };

  extractSocialLinkInfo(brandInfo: BrandDetails, isSir?: boolean) {
    const data = [];
    Object.keys(brandInfo).map(brandApiRespKey => {
      if (SocialMediaGroup.includes(brandApiRespKey)) {
        const key = new ISocialFooterLink(brandApiRespKey, brandInfo[brandApiRespKey], isSir);
        key.iconPath = `${this.getImagePathForReport()}mq-uno/webpage/${key.iconPath}`;
        data.push(key);
      }
    });
    return data;
  }

  numFormatter(num, digits) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    num = this.toFixed(num / si[i].value, digits);
    return '$' + num.toString().replace(rx, '$1') + si[i].symbol;
  }

  toFixed(num, fixed) {
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }
}
