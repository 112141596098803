<div [formGroup]="formGroup" class="fdc main">
  <div class="d-flex justify-content-between title align-items-center px-3">
    <span>Export Options</span>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="panel rounded">
    <div class="fdc mx-3 divide pb-3 mb-3">
      <span class="export">Select which sections you want to include in export.</span>
      <mat-checkbox
        (change)="changeAll($event)"
        *ngIf="reportType === ReportTypeEnum.MarketTrends"
        [checked]="selectAllEnabled"
        [indeterminate]="indeterminateEnabled"
        class=""
        >All Sections
      </mat-checkbox>
    </div>
    <div class="mx-3">
      <div class="fdc">
        <mat-checkbox *ngFor="let chk of optionArray" [formControlName]="chk.formControlName" disableRipple>{{ chk.label }}</mat-checkbox>
      </div>
    </div>
  </div>
  <div *ngIf="showOrientation" class="button-group px-3">
    <span class="fw-bold">Select report orientation.</span>
    <button (click)="adjustOrientation(true)" [ngClass]="formGroup.get('islandscape').value ? 'active' : 'inactive'" mat-stroked-button>
      <mat-icon>crop_landscape</mat-icon>
      Landscape
    </button>
    <button (click)="adjustOrientation(false)" [ngClass]="formGroup.get('islandscape').value ? 'inactive' : 'active'" mat-stroked-button>
      <mat-icon>crop_portrait</mat-icon>
      Portrait
    </button>
  </div>
  <div class="button-group px-2">
    <button class="cancel-btn" mat-dialog-close="true" mat-flat-button>Cancel</button>
    <button (click)="submit()" [disabled]="!formGroup.valid" color="primary" mat-flat-button>
      <span *ngIf="!loading && !downloadPending">Export</span>
      <mat-progress-spinner
        *ngIf="loading || downloadPending"
        [color]="'primary'"
        class="spinner"
        diameter="20"
        mode="indeterminate"
      ></mat-progress-spinner>
    </button>
  </div>
</div>
