import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { Banner, IMenuState } from '../state/menu.state';

const Menus = (state: IAppState) => state.menus;

export const selectSideBarStatus = createSelector(Menus, (state: IMenuState) => state.sideBarOpen);

export const selectFilterOpenStatus = createSelector(Menus, (state: IMenuState) => state.filterMenuOpen);
export const selectBannerStatus = (banner: Banner) =>
  createSelector(Menus, (state: IMenuState) => {
    return state.showWarningBars[banner.type].find(b => b.type === banner.type) as Banner;
  });
export const selectBannersByType = (banner: Banner) =>
  createSelector(Menus, (state: IMenuState) => state.showWarningBars[banner.type] || []);
export const selectNavItems = createSelector(Menus, (state: IMenuState) => state.navItems);
