<div class="p-0">
  <span class="grid-right mat-panel-title">Time Period</span>
</div>
<div [formGroup]="group" class="px-3 py-4 border-bottom panel-section">
  <mat-radio-group formControlName="isCustom" *ngIf="!hideCustomMonthlyDate; else noMonthly">
    <mat-radio-button [value]="false">
      <span class="d-flex align-items-center justify-content-between"
        >Monthly
        <mat-icon
          #tooltip="matTooltip"
          (click)="tooltip.toggle()"
          matTooltip="Monthly stats are compared to previous 3 months’ average."
          matTooltipPosition="above"
          >info_outline
        </mat-icon>
      </span>
    </mat-radio-button>
    <mat-form-field appearance="outline" class="pb-3">
      <mat-label>mm/yyyy</mat-label>
      <input
        (click)="picker.open()"
        [formControlName]="'monthlyEndDate'"
        [matDatepicker]="$any(picker)"
        [max]="lastRunDate"
        [readonly]="true"
        class="date"
        matInput
      />
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker (monthSelected)="closeDatePicker($event, picker)" startView="multi-year"></mat-datepicker>
    </mat-form-field>
    <div class="d-flex align-items-center justify-content-center">
      <span class="sep">Or</span>
    </div>
    <app-multi-date-range-selection
      [startControl]="$any(group.controls['startDate'])"
      [endControl]="$any(group.controls['endDate'])"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [lastRunDate]="lastRunDate"
      [adjustedMinDate]="adjustedMinDate"
      [isCustomReport]="isCustomReport"
    ></app-multi-date-range-selection>
  </mat-radio-group>
  <ng-template #noMonthly>
    <app-multi-date-range-selection
      [hideRadio]="hideCustomMonthlyDate"
      [startControl]="$any(group.controls['startDate'])"
      [endControl]="$any(group.controls['endDate'])"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [lastRunDate]="lastRunDate"
      [adjustedMinDate]="adjustedMinDate"
      [isCustomReport]="isCustomReport"
    ></app-multi-date-range-selection>
  </ng-template>
</div>
