// Modules
import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Modules
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OKTA_CONFIG, OktaAuthGuard, OktaAuthModule, OktaCallbackComponent } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import {
  BarSeriesService,
  CategoryService,
  ChartAnnotationService,
  ColumnSeriesService,
  DataLabelService,
  DateTimeService,
  ExportService,
  LegendService,
  LineSeriesService,
  RangeColumnSeriesService,
  ScrollBarService,
  SplineSeriesService,
  StackingColumnSeriesService,
  TooltipService,
  TrendlinesService
} from '@syncfusion/ej2-angular-charts';
import { SharerMethod } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';

// Environments
import { environment } from '../environments/environment';

// Components
import { AppComponent } from './app.component';
import { ExportPreviewModule } from './components/core/export-preview/export-preview.module';
import { SharedComponentsModule } from './components/core/shared/shared-components.module';
import { EmailAddressComponent } from './components/email-address/email-address.component';

import { LogoutModalComponent } from './components/modals/logout-modal/logout-modal.component';
import { MailModalComponent } from './components/modals/mail-modal/mail-modal.component';
import { MlsInfoModalComponent } from './components/modals/mls-modals/mls-info-modal/mls-info-modal.component';
import { MlsSelectionModalComponent } from './components/modals/mls-modals/mls-selection-modal/mls-selection-modal.component';
import { NoMlsModalComponent } from './components/modals/mls-modals/no-mls-modal/no-mls-modal.component';
import { SocialModalComponent } from './components/modals/social-modal/social-modal.component';
import { MqHeaderModule } from './components/mq-header/mq-header.module';
import { AuthInterceptor, DEFAULT_TIMEOUT } from './interceptors/auth.interceptor';
import { FeaturesEnum } from './models/IFeature';
import { CountyStringPipe } from './pipes/countyString.pipe';
import { ReportLocationNamePipe } from './pipes/report-location-name.pipe';
import { SchoolStringPipe } from './pipes/schoolDistrictString.pipe';
import { FeatureGuard } from './resolvers/feature.guard';
import { PublicReportResolver } from './resolvers/public-report-resolver';
import { ReportPageResolver } from './resolvers/report-page.resolver';
import { CardSetService } from './services/card-set.service';
import { DataLayerObjectService } from './services/data-layer.service';
import { DownloadService } from './services/download.service';
import { EmailService } from './services/email.service';
import { EncryptService } from './services/encrypt.service';
import { ErrorService } from './services/error.service';
import { BROWSER_FAVICONS_CONFIG } from './services/favicon.service';
import { FeatureService } from './services/feature.service';
import { FilterService } from './services/filter.service';
import { GalleryService } from './services/gallery.service';

// Services
import { GTMService } from './services/gtm.service';
import { LoginModule } from './services/login/login.module';
import { MarketClaimsService } from './services/market-claims.service';
import { MarketPositionService } from './services/market-position.service';
import { MarketSnapshotService } from './services/market-snapshot.service';
import { MarketTrendsService } from './services/market-trends.service';
import { ModalService } from './services/modal.service';
import { MqOktaAuthGuard } from './services/mq-okta-auth.guard';
import { MqOktaAuthService } from './services/mq-okta-auth.service';
import { OptionsService } from './services/options.service';
import { RedirectGuard } from './services/redirect.guard';
import { RedirectHomeGuard } from './services/redirecthome.guard';
import { ReportDetailService } from './services/report-details.service';
import { ReportService } from './services/report.service';
import { ShareSocialAndEmailService } from './services/share-social-and-email.service';
import { SyncFusionConfigService } from './services/sync-fusion-config.service';
import { TenantService } from './services/tenant.service';
import { ThemeService } from './services/theme.service';
import { UploadService } from './services/upload.service';
import { UserDataService } from './services/userData.service';
import { ApplicationDataEffect } from './store/effects/applicationData.effect';
import { CustomReportEffect } from './store/effects/custom-report.effect';
import { FeatureDataEffect } from './store/effects/featureData.effect';
import { FilterDataEffect } from './store/effects/filtersData.effect';
import { GalleryEffect } from './store/effects/gallery.effect';
import { GtmEffect } from './store/effects/gtm.effect';
import { LocationsEffect } from './store/effects/locations.effect';
import { MarketPositionEffect } from './store/effects/market-position.effect';
import { MarketSnapshotEffect } from './store/effects/market-snapshot.effect';
import { MarketTrendsEffect } from './store/effects/market-trends.effect';
import { MenuEffect } from './store/effects/menu.effect';
import { MLSEffect } from './store/effects/mls.effect';
import { PdfEffect } from './store/effects/pdf.effect';
import { ReportDataEffect } from './store/effects/reports.effect';
import { SavedSearchEffect } from './store/effects/saved-search.effect';
import { UserSettingsEffect } from './store/effects/user-settings.effect';
import { UserDataEffect } from './store/effects/userData.effect';
import { WootricEffect } from './store/effects/wootric.effect';
import { metaReducers, reducers } from './store/reducers';
import { SaveSearchModalComponent } from './components/modals/save-search-modal/save-search-modal.component';
import { AspTrendsEffect } from './store/effects/asp-trends.effect';
import { ServiceNowChatBubbleComponent } from './service-now-chat-bubble/service-now-chat-bubble.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('../app/pages/layouts/default-with-nav/default-with-nav.module').then(m => m.DefaultWithNavModule),
    canActivate: [MqOktaAuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('../app/pages/layouts/default-no-nav/default-no-nav.module').then(m => m.DefaultNoNavModule)
  },
  {
    path: 'share',
    data: {
      public: true
    },
    loadChildren: () => import('../app/pages/layouts/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'implicit/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'report/:id',
    component: RedirectGuard,
    canActivate: [RedirectGuard],
    data: { externalUrl: '/social' }
  },
  {
    path: 'debug',
    data: {
      flags: FeaturesEnum.Debug
    },
    canActivate: [MqOktaAuthGuard, FeatureGuard],
    loadChildren: () => import('./pages/debug-page/debug.module').then(m => m.DebugModule)
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

export function initBaseImageUrl(http: HttpClient, options: OptionsService) {
  return () => {
    return new Promise<void>(resolve => {
      options.baseUrl = environment.imagePath;
      return resolve();
    });
  };
}

const config = environment.oktaApp;
const oktaConfig = {
  issuer: config.issuer,
  clientId: config.clientId,
  pkce: config.pkce,
  redirectUri: window.location.origin + '/implicit/callback',
  tokenManager: {
    storage: 'sessionStorage'
  }
};
const oktaAuth = new OktaAuth(oktaConfig);

@NgModule({
  declarations: [
    AppComponent,
    MailModalComponent,
    EmailAddressComponent,
    NoMlsModalComponent,
    MlsSelectionModalComponent,
    LogoutModalComponent,
    MlsInfoModalComponent,
    SocialModalComponent,
    SaveSearchModalComponent,
    ServiceNowChatBubbleComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    OktaAuthModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy'
    }),
    SharedComponentsModule,
    ShareButtonsModule.withConfig({
      sharerMethod: SharerMethod.Window
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
    EffectsModule.forRoot([
      UserDataEffect,
      FeatureDataEffect,
      FilterDataEffect,
      ReportDataEffect,
      WootricEffect,
      MLSEffect,
      MenuEffect,
      LocationsEffect,
      GalleryEffect,
      GtmEffect,
      MarketPositionEffect,
      MarketTrendsEffect,
      MarketSnapshotEffect,
      ApplicationDataEffect,
      AspTrendsEffect,
      UserSettingsEffect,
      PdfEffect,
      SavedSearchEffect,
      CustomReportEffect
    ]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MqHeaderModule,
    LoginModule,
    ExportPreviewModule
  ],
  providers: [
    OktaAuthGuard,
    GTMService,
    DataLayerObjectService,
    FeatureService,
    ThemeService,
    FilterService,
    CardSetService,
    ErrorService,
    GalleryService,
    ReportDetailService,
    EncryptService,
    EmailService,
    OptionsService,
    ReportService,
    UploadService,
    ShareSocialAndEmailService,
    ReportPageResolver,
    PublicReportResolver,
    MarketPositionService,
    MarketClaimsService,
    MarketTrendsService,
    CurrencyPipe,
    PercentPipe,
    DecimalPipe,
    ReportLocationNamePipe,
    ModalService,
    MqOktaAuthGuard,
    FeatureGuard,
    MarketSnapshotService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_TIMEOUT,
      useValue: 60000
    },
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth }
    },
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          realogy: {
            type: 'image/ico',
            href: './assets/favicon/favicon_cbr.ico'
          },
          sothebysrealty: {
            type: 'image/ico',
            href: './assets/favicon/favicon_sothebys.ico'
          }
        },
        cacheBusting: true
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initBaseImageUrl,
      multi: true,
      deps: [HttpClient, OptionsService]
    },
    ExportService,
    CategoryService,
    DataLabelService,
    DateTimeService,
    ScrollBarService,
    ColumnSeriesService,
    LineSeriesService,
    BarSeriesService,
    SplineSeriesService,
    ChartAnnotationService,
    RangeColumnSeriesService,
    StackingColumnSeriesService,
    LegendService,
    TrendlinesService,
    TooltipService,
    MatIconRegistry,
    RedirectGuard,
    RedirectHomeGuard,
    DownloadService,
    SyncFusionConfigService,
    MqOktaAuthService,
    UserDataService,
    CountyStringPipe,
    SchoolStringPipe,
    TenantService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
