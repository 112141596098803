import { IBreadCrumb } from '../../models/IBreadCrumb';

export class IBrowserState {
  prevPath: IBreadCrumb[];
}

export const initialBrowserState: IBrowserState = {
  prevPath: []
};

export function getInitialBrowserState(): IBrowserState {
  return initialBrowserState;
}
