<div class="asset-download-container d-flex flex-column">
  <div class="d-flex justify-content-end close-modal">
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </div>
  <div>
    <h1 class="modal-title pb-3" mat-dialog-title>{{ data?.title }}</h1>
  </div>
  <div class="description">
    <span>
      {{
        data?.isSocial
          ? 'Choose from various image sizes to download and share on your social media!'
          : "Choose your image size based on the ad you'd like to run on homespotter."
      }}
    </span>
  </div>
  <div class="preview-image">
    <div class="h-100">
      <ng-container *ngIf="data.isSIR; else cb">
        <app-preview-tile-sothebys [large]="true" [showGraph]="data.graph"></app-preview-tile-sothebys>
      </ng-container>
      <ng-template #cb>
        <app-preview-tile [reportData]="data.report" [graph]="data.graph" [large]="true"></app-preview-tile>
      </ng-template>
    </div>
  </div>
  <div (click)="launchHelp(data?.isSocial)" class="wif-help">
    <mat-icon>info</mat-icon>
    Which image format should I use?
  </div>
  <div class="download-button-controls">
    <button
      (click)="downloadImage(button)"
      *ngFor="let button of buttonArray"
      color="primary"
      mat-stroked-button
      [attr.data-claim-id]="this.data.report.msUnoReportId"
      [attr.data-mls-id]="this.reportHeaderEntity.mlsId"
      [attr.data-date-range]="this.reportHeaderEntity.displayDateRange"
      [attr.data-transaction-side]="this.reportTransactionSide"
      [attr.data-area-name]="this.reportHeaderEntity.areaLocalName"
      [attr.data-area-type]="this.reportHeaderEntity.areaType"
      [attr.data-level]="this.reportHeaderEntity.groupByLevel"
      [attr.data-price-type]="this.reportHeaderEntity.priceType"
      [attr.data-value]="this.reportValue"
      [attr.data-value-type]="this.reportValueType"
      [attr.data-asset-type]="this.data.title"
      [class.disabled]="button.loading"
    >
      <span *ngIf="!button.loading">{{ button.label }}</span>
      <mat-progress-spinner
        *ngIf="button.loading"
        [color]="'primary'"
        class="spinner"
        diameter="20"
        mode="indeterminate"
      ></mat-progress-spinner>
    </button>
  </div>
</div>
<ng-template appSocialDownload></ng-template>
