import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { MlsService } from '../../services/mls.service';
import { SavedSearchService } from '../../services/saved-search.service';
import { FetchSavedSearches, FetchSavedSearchesSuccess, SavedSearchActionsActionTypes } from '../actions/saved-search.action';
import { IAppState } from '../state/app.state';

@Injectable()
export class SavedSearchEffect {
  constructor(
    private _actions$: Actions,
    private savedSearchSvc: SavedSearchService,
    private store: Store<IAppState>,
    private mlsSvc: MlsService
  ) {}

  fetchSavedSearches$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchSavedSearches>(SavedSearchActionsActionTypes.FetchSavedSearches),
      switchMap(() => this.savedSearchSvc.getAllSavedSearches()),
      map(savedSearches => {
        let sortArray = this.savedSearchSvc.mapSavedSearchApiResponse(savedSearches);
        Object.keys(sortArray).map((reportType: 'Uno' | 'MarketPosition' | 'MarketTrends' | 'MarketSnapshot') => {
          const currentMls = this.mlsSvc.currentMls;
          sortArray[reportType] = sortArray[reportType].map(item => {
            if (item.mlsId === currentMls.mlsId) {
              for (let location of item.data.locationFilters || []) {
                const foundLoc = this.savedSearchSvc.lookupLocationByAreaIdAndType(location.areaID, location.areaType);
                if (foundLoc) {
                  location.hasData = reportType === 'Uno' ? foundLoc.hasData : true;
                }
              }
            }
            return item;
          });
        });
        return new FetchSavedSearchesSuccess(sortArray);
      })
    )
  );
}
