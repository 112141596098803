<svg class="terradatum-logo" width="42" height="16" viewBox="0 0 42 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M3.96094 3.93359C3.96094 4.57682 3.8138 5.09505 3.51953 5.48828C3.22526 5.88151 2.82682 6.07812 2.32422 6.07812C1.8112 6.07812 1.40755 5.91536 1.11328 5.58984V7.625H0.390625V1.77344H1.05078L1.08594 2.24219C1.38021 1.8776 1.78906 1.69531 2.3125 1.69531C2.82031 1.69531 3.22135 1.88672 3.51562 2.26953C3.8125 2.65234 3.96094 3.1849 3.96094 3.86719V3.93359ZM3.23828 3.85156C3.23828 3.375 3.13672 2.9987 2.93359 2.72266C2.73047 2.44661 2.45182 2.30859 2.09766 2.30859C1.66016 2.30859 1.33203 2.5026 1.11328 2.89062V4.91016C1.32943 5.29557 1.66016 5.48828 2.10547 5.48828C2.45182 5.48828 2.72656 5.35156 2.92969 5.07812C3.13542 4.80208 3.23828 4.39323 3.23828 3.85156Z"
    fill="#231F20"
  />
  <path
    d="M4.69141 3.84766C4.69141 3.43359 4.77214 3.0612 4.93359 2.73047C5.09766 2.39974 5.32422 2.14453 5.61328 1.96484C5.90495 1.78516 6.23698 1.69531 6.60938 1.69531C7.1849 1.69531 7.64974 1.89453 8.00391 2.29297C8.36068 2.69141 8.53906 3.22135 8.53906 3.88281V3.93359C8.53906 4.34505 8.45964 4.71484 8.30078 5.04297C8.14453 5.36849 7.91927 5.6224 7.625 5.80469C7.33333 5.98698 6.9974 6.07812 6.61719 6.07812C6.04427 6.07812 5.57943 5.87891 5.22266 5.48047C4.86849 5.08203 4.69141 4.55469 4.69141 3.89844V3.84766ZM5.41797 3.93359C5.41797 4.40234 5.52604 4.77865 5.74219 5.0625C5.96094 5.34635 6.2526 5.48828 6.61719 5.48828C6.98438 5.48828 7.27604 5.34505 7.49219 5.05859C7.70833 4.76953 7.81641 4.36589 7.81641 3.84766C7.81641 3.38411 7.70573 3.00911 7.48438 2.72266C7.26562 2.43359 6.97396 2.28906 6.60938 2.28906C6.2526 2.28906 5.96484 2.43099 5.74609 2.71484C5.52734 2.9987 5.41797 3.40495 5.41797 3.93359Z"
    fill="#231F20"
  />
  <path
    d="M13.1875 5.00391L14 1.77344H14.7227L13.4922 6H12.9062L11.8789 2.79688L10.8789 6H10.293L9.06641 1.77344H9.78516L10.6172 4.9375L11.6016 1.77344H12.1836L13.1875 5.00391Z"
    fill="#231F20"
  />
  <path
    d="M17.2148 6.07812C16.6419 6.07812 16.1758 5.89062 15.8164 5.51562C15.457 5.13802 15.2773 4.63411 15.2773 4.00391V3.87109C15.2773 3.45182 15.3568 3.07812 15.5156 2.75C15.6771 2.41927 15.901 2.16146 16.1875 1.97656C16.4766 1.78906 16.7891 1.69531 17.125 1.69531C17.6745 1.69531 18.1016 1.8763 18.4062 2.23828C18.7109 2.60026 18.8633 3.11849 18.8633 3.79297V4.09375H16C16.0104 4.51042 16.1315 4.84766 16.3633 5.10547C16.5977 5.36068 16.8945 5.48828 17.2539 5.48828C17.5091 5.48828 17.7253 5.4362 17.9023 5.33203C18.0794 5.22786 18.2344 5.08984 18.3672 4.91797L18.8086 5.26172C18.4544 5.80599 17.9232 6.07812 17.2148 6.07812ZM17.125 2.28906C16.8333 2.28906 16.5885 2.39583 16.3906 2.60938C16.1927 2.82031 16.0703 3.11719 16.0234 3.5H18.1406V3.44531C18.1198 3.07812 18.0208 2.79427 17.8438 2.59375C17.6667 2.39062 17.4271 2.28906 17.125 2.28906Z"
    fill="#231F20"
  />
  <path
    d="M21.7461 2.42188C21.6367 2.40365 21.5182 2.39453 21.3906 2.39453C20.9167 2.39453 20.5951 2.59635 20.4258 3V6H19.7031V1.77344H20.4062L20.418 2.26172C20.6549 1.88411 20.9909 1.69531 21.4258 1.69531C21.5664 1.69531 21.6732 1.71354 21.7461 1.75V2.42188Z"
    fill="#231F20"
  />
  <path
    d="M24.0898 6.07812C23.5169 6.07812 23.0508 5.89062 22.6914 5.51562C22.332 5.13802 22.1523 4.63411 22.1523 4.00391V3.87109C22.1523 3.45182 22.2318 3.07812 22.3906 2.75C22.5521 2.41927 22.776 2.16146 23.0625 1.97656C23.3516 1.78906 23.6641 1.69531 24 1.69531C24.5495 1.69531 24.9766 1.8763 25.2812 2.23828C25.5859 2.60026 25.7383 3.11849 25.7383 3.79297V4.09375H22.875C22.8854 4.51042 23.0065 4.84766 23.2383 5.10547C23.4727 5.36068 23.7695 5.48828 24.1289 5.48828C24.3841 5.48828 24.6003 5.4362 24.7773 5.33203C24.9544 5.22786 25.1094 5.08984 25.2422 4.91797L25.6836 5.26172C25.3294 5.80599 24.7982 6.07812 24.0898 6.07812ZM24 2.28906C23.7083 2.28906 23.4635 2.39583 23.2656 2.60938C23.0677 2.82031 22.9453 3.11719 22.8984 3.5H25.0156V3.44531C24.9948 3.07812 24.8958 2.79427 24.7188 2.59375C24.5417 2.39062 24.3021 2.28906 24 2.28906Z"
    fill="#231F20"
  />
  <path
    d="M26.4023 3.85156C26.4023 3.20312 26.556 2.68229 26.8633 2.28906C27.1706 1.89323 27.5729 1.69531 28.0703 1.69531C28.5651 1.69531 28.957 1.86458 29.2461 2.20312V0H29.9688V6H29.3047L29.2695 5.54688C28.9805 5.90104 28.5781 6.07812 28.0625 6.07812C27.5729 6.07812 27.1732 5.8776 26.8633 5.47656C26.556 5.07552 26.4023 4.55208 26.4023 3.90625V3.85156ZM27.125 3.93359C27.125 4.41276 27.224 4.78776 27.4219 5.05859C27.6198 5.32943 27.8932 5.46484 28.2422 5.46484C28.7005 5.46484 29.0352 5.25911 29.2461 4.84766V2.90625C29.0299 2.50781 28.6979 2.30859 28.25 2.30859C27.8958 2.30859 27.6198 2.44531 27.4219 2.71875C27.224 2.99219 27.125 3.39714 27.125 3.93359Z"
    fill="#231F20"
  />
  <path
    d="M36.6562 3.93359C36.6562 4.57943 36.5078 5.09896 36.2109 5.49219C35.9141 5.88281 35.5156 6.07812 35.0156 6.07812C34.4818 6.07812 34.069 5.88932 33.7773 5.51172L33.7422 6H33.0781V0H33.8008V2.23828C34.0924 1.8763 34.4948 1.69531 35.0078 1.69531C35.5208 1.69531 35.9232 1.88932 36.2148 2.27734C36.5091 2.66536 36.6562 3.19661 36.6562 3.87109V3.93359ZM35.9336 3.85156C35.9336 3.35938 35.8385 2.97917 35.6484 2.71094C35.4583 2.44271 35.1849 2.30859 34.8281 2.30859C34.3516 2.30859 34.0091 2.52995 33.8008 2.97266V4.80078C34.0221 5.24349 34.3672 5.46484 34.8359 5.46484C35.1823 5.46484 35.4518 5.33073 35.6445 5.0625C35.8372 4.79427 35.9336 4.39062 35.9336 3.85156Z"
    fill="#231F20"
  />
  <path
    d="M38.9062 4.94141L39.8906 1.77344H40.6641L38.9648 6.65234C38.7018 7.35547 38.2839 7.70703 37.7109 7.70703L37.5742 7.69531L37.3047 7.64453V7.05859L37.5 7.07422C37.7448 7.07422 37.9349 7.02474 38.0703 6.92578C38.2083 6.82682 38.3216 6.64583 38.4102 6.38281L38.5703 5.95312L37.0625 1.77344H37.8516L38.9062 4.94141Z"
    fill="#231F20"
  />
  <path d="M4.63281 10.2617H2.89062V15H1.71875V10.2617H0V9.3125H4.63281V10.2617Z" fill="#231F20" />
  <path
    d="M6.82422 15.0781C6.20443 15.0781 5.69922 14.888 5.30859 14.5078C4.92057 14.1276 4.72656 13.6211 4.72656 12.9883V12.8789C4.72656 12.4544 4.80859 12.0755 4.97266 11.7422C5.13672 11.4062 5.36849 11.1484 5.66797 10.9688C5.97005 10.7865 6.3138 10.6953 6.69922 10.6953C7.27734 10.6953 7.73177 10.8776 8.0625 11.2422C8.39583 11.6068 8.5625 12.1237 8.5625 12.793V13.2539H5.87109C5.90755 13.5299 6.01693 13.7513 6.19922 13.918C6.38411 14.0846 6.61719 14.168 6.89844 14.168C7.33333 14.168 7.67318 14.0104 7.91797 13.6953L8.47266 14.3164C8.30339 14.556 8.07422 14.7435 7.78516 14.8789C7.49609 15.0117 7.17578 15.0781 6.82422 15.0781ZM6.69531 11.6094C6.47135 11.6094 6.28906 11.6849 6.14844 11.8359C6.01042 11.987 5.92188 12.2031 5.88281 12.4844H7.45312V12.3945C7.44792 12.1445 7.38021 11.9518 7.25 11.8164C7.11979 11.6784 6.9349 11.6094 6.69531 11.6094Z"
    fill="#231F20"
  />
  <path
    d="M11.582 11.832C11.4284 11.8112 11.293 11.8008 11.1758 11.8008C10.7487 11.8008 10.4688 11.9453 10.3359 12.2344V15H9.20703V10.7734H10.2734L10.3047 11.2773C10.5312 10.8893 10.8451 10.6953 11.2461 10.6953C11.3711 10.6953 11.4883 10.7122 11.5977 10.7461L11.582 11.832Z"
    fill="#231F20"
  />
  <path
    d="M14.5039 11.832C14.3503 11.8112 14.2148 11.8008 14.0977 11.8008C13.6706 11.8008 13.3906 11.9453 13.2578 12.2344V15H12.1289V10.7734H13.1953L13.2266 11.2773C13.4531 10.8893 13.7669 10.6953 14.168 10.6953C14.293 10.6953 14.4102 10.7122 14.5195 10.7461L14.5039 11.832Z"
    fill="#231F20"
  />
  <path
    d="M17.3828 15C17.3307 14.8984 17.293 14.7721 17.2695 14.6211C16.9961 14.9258 16.6406 15.0781 16.2031 15.0781C15.7891 15.0781 15.4453 14.9583 15.1719 14.7188C14.901 14.4792 14.7656 14.1771 14.7656 13.8125C14.7656 13.3646 14.931 13.0208 15.2617 12.7812C15.5951 12.5417 16.0755 12.4206 16.7031 12.418H17.2227V12.1758C17.2227 11.9805 17.1719 11.8242 17.0703 11.707C16.9714 11.5898 16.8138 11.5312 16.5977 11.5312C16.4076 11.5312 16.2578 11.5768 16.1484 11.668C16.0417 11.7591 15.9883 11.8841 15.9883 12.043H14.8594C14.8594 11.7982 14.9349 11.5716 15.0859 11.3633C15.237 11.1549 15.4505 10.9922 15.7266 10.875C16.0026 10.7552 16.3125 10.6953 16.6562 10.6953C17.1771 10.6953 17.5898 10.8268 17.8945 11.0898C18.2018 11.3503 18.3555 11.7174 18.3555 12.1914V14.0234C18.3581 14.4245 18.4141 14.7279 18.5234 14.9336V15H17.3828ZM16.4492 14.2148C16.6159 14.2148 16.7695 14.1784 16.9102 14.1055C17.0508 14.0299 17.1549 13.9297 17.2227 13.8047V13.0781H16.8008C16.2357 13.0781 15.9349 13.2734 15.8984 13.6641L15.8945 13.7305C15.8945 13.8711 15.944 13.987 16.043 14.0781C16.1419 14.1693 16.2773 14.2148 16.4492 14.2148Z"
    fill="#231F20"
  />
  <path
    d="M19.0469 12.8555C19.0469 12.1966 19.194 11.6719 19.4883 11.2812C19.7852 10.8906 20.1901 10.6953 20.7031 10.6953C21.1146 10.6953 21.4544 10.849 21.7227 11.1562V9H22.8555V15H21.8359L21.7812 14.5508C21.5 14.9023 21.138 15.0781 20.6953 15.0781C20.1979 15.0781 19.7982 14.8828 19.4961 14.4922C19.1966 14.099 19.0469 13.5534 19.0469 12.8555ZM20.1758 12.9375C20.1758 13.3333 20.2448 13.6367 20.3828 13.8477C20.5208 14.0586 20.7214 14.1641 20.9844 14.1641C21.3333 14.1641 21.5794 14.0169 21.7227 13.7227V12.0547C21.582 11.7604 21.3385 11.6133 20.9922 11.6133C20.4479 11.6133 20.1758 12.0547 20.1758 12.9375Z"
    fill="#231F20"
  />
  <path
    d="M26.1797 15C26.1276 14.8984 26.0898 14.7721 26.0664 14.6211C25.793 14.9258 25.4375 15.0781 25 15.0781C24.5859 15.0781 24.2422 14.9583 23.9688 14.7188C23.6979 14.4792 23.5625 14.1771 23.5625 13.8125C23.5625 13.3646 23.7279 13.0208 24.0586 12.7812C24.3919 12.5417 24.8724 12.4206 25.5 12.418H26.0195V12.1758C26.0195 11.9805 25.9688 11.8242 25.8672 11.707C25.7682 11.5898 25.6107 11.5312 25.3945 11.5312C25.2044 11.5312 25.0547 11.5768 24.9453 11.668C24.8385 11.7591 24.7852 11.8841 24.7852 12.043H23.6562C23.6562 11.7982 23.7318 11.5716 23.8828 11.3633C24.0339 11.1549 24.2474 10.9922 24.5234 10.875C24.7995 10.7552 25.1094 10.6953 25.4531 10.6953C25.974 10.6953 26.3867 10.8268 26.6914 11.0898C26.9987 11.3503 27.1523 11.7174 27.1523 12.1914V14.0234C27.1549 14.4245 27.2109 14.7279 27.3203 14.9336V15H26.1797ZM25.2461 14.2148C25.4128 14.2148 25.5664 14.1784 25.707 14.1055C25.8477 14.0299 25.9518 13.9297 26.0195 13.8047V13.0781H25.5977C25.0326 13.0781 24.7318 13.2734 24.6953 13.6641L24.6914 13.7305C24.6914 13.8711 24.7409 13.987 24.8398 14.0781C24.9388 14.1693 25.0742 14.2148 25.2461 14.2148Z"
    fill="#231F20"
  />
  <path
    d="M29.3711 9.73438V10.7734H30.0938V11.6016H29.3711V13.7109C29.3711 13.8672 29.401 13.9792 29.4609 14.0469C29.5208 14.1146 29.6354 14.1484 29.8047 14.1484C29.9297 14.1484 30.0404 14.1393 30.1367 14.1211V14.9766C29.9154 15.0443 29.6875 15.0781 29.4531 15.0781C28.6615 15.0781 28.2578 14.6784 28.2422 13.8789V11.6016H27.625V10.7734H28.2422V9.73438H29.3711Z"
    fill="#231F20"
  />
  <path
    d="M33.2539 14.5703C32.9753 14.9089 32.5898 15.0781 32.0977 15.0781C31.6445 15.0781 31.2982 14.9479 31.0586 14.6875C30.8216 14.4271 30.7005 14.0456 30.6953 13.543V10.7734H31.8242V13.5039C31.8242 13.944 32.0247 14.1641 32.4258 14.1641C32.8086 14.1641 33.0716 14.0312 33.2148 13.7656V10.7734H34.3477V15H33.2852L33.2539 14.5703Z"
    fill="#231F20"
  />
  <path
    d="M36.2578 10.7734L36.293 11.2461C36.5924 10.8789 36.9974 10.6953 37.5078 10.6953C38.0521 10.6953 38.4258 10.9102 38.6289 11.3398C38.9258 10.9102 39.349 10.6953 39.8984 10.6953C40.3568 10.6953 40.6979 10.8294 40.9219 11.0977C41.1458 11.3633 41.2578 11.7643 41.2578 12.3008V15H40.125V12.3047C40.125 12.0651 40.0781 11.8906 39.9844 11.7812C39.8906 11.6693 39.7253 11.6133 39.4883 11.6133C39.1497 11.6133 38.9154 11.7747 38.7852 12.0977L38.7891 15H37.6602V12.3086C37.6602 12.0638 37.612 11.8867 37.5156 11.7773C37.4193 11.668 37.2552 11.6133 37.0234 11.6133C36.7031 11.6133 36.4714 11.7461 36.3281 12.0117V15H35.1992V10.7734H36.2578Z"
    fill="#231F20"
  />
</svg>
