import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IMlsData } from '../models/IMlsData';
import { selectCurrentMLS } from '../store/selectors/mls.selector';
import { IAppState } from '../store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class MlsService {
  get currentMls(): IMlsData {
    return this._currentMls;
  }

  set currentMls(value: IMlsData) {
    this._currentMls = value;
  }
  constructor(private store: Store<IAppState>) {
    this.store.select(selectCurrentMLS).subscribe({
      next: mls => {
        this.currentMls = mls;
      }
    });
  }

  private _currentMls: IMlsData;
  private _mlsList: IMlsData[];

  get mlsList(): IMlsData[] {
    return this._mlsList;
  }

  set mlsList(value: IMlsData[]) {
    this._mlsList = value;
  }

  getMlsById(mlsId: string): IMlsData {
    return this.mlsList.find(mlsEntity => mlsEntity.mlsId === mlsId);
  }
}
