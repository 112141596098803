import { Action } from '@ngrx/store';
import { IChips } from '../../models/IChips';
import { MarketLocationSubPanel } from '../../models/IMarketReportFilters';
import { IMarketTrendsData } from '../../models/IMarketTrendsData';
import { TrendsFilters } from '../../models/IMarketTrendsFilters';
import { ITrendsOptions } from '../../models/ITrendsOptions';

export enum MarketTrendsDataActionsActionTypes {
  FetchTrendsLocations = '[Market Trends] Getting Locations For Filters',
  FetchTrendsLocationsSuccess = '[Market Trends] Received Locations For Filters',
  FetchTrendsReports = '[Market Trends] Fetch Trends Report Data API Call',
  FetchTrendsReportsSuccess = '[Market Trends] Received Trends Report Data API Call',
  FetchTrendsReportsError = '[Market Trends] No Data Received From Trends Report Data API Call',
  UpdateTrendsReportFilters = '[Market Trends] Updating Filters For Trends Report',
  ResetTrendsReportFilters = '[Market Trends] Resetting Trends Filters',
  ResetTrendsReportFiltersSuccess = '[Market Trends] Trends Filters Cleared',
  FetchEndDate = '[Market Trends] Fetch Trends End Date',
  SetEndDate = '[Market Trends] Set Ending Disclaimer Date',
  UpdateTrendsPdfOptions = '[Market Trends] Updating Options for Trends Report PDF',
  ResetTrendsPdfOptions = '[Market Trends] Reset Options for Trends Report PDF',
  ResetTrendsOptions = '[Market Trends] Resetting Trends Options',
  ResetTrendsOptionsSuccess = '[Market Trends] Trends Options Cleared',
  MTFiltersDirty = '[Market Trends] Update Filters isDirty',
  UpdateTrendsFiltersPressed = '[Market Trends] Update Results Button Pressed',
  TrendsLocationFiltersOpen = '[Market Trends] Locations Filters Are Open',
  TrendsReportFiltersOpen = '[Market Trends] Reports Filters Are Open',
  UpdateTrendsTableType = '[Market Trends] Update Table Type',
  FetchTrendsPdf = '[Market Trends] Fetch PDF Download',
  FetchTrendsPdfSuccess = '[Market Trends] Fetch PDF Download Success',
  FetchTrendsPdfError = '[Market Trends] Fetch PDF Error Occurred',
  TrendsLocationSubPanelsOpen = '[Market Trends] Location Sub Panel Open',
  ClearTrendsReportFilters = '[Market Trends] Clear Market Trends Report Filters',
  MTPricesDirty = '[Market Trends] Update Prices isDirty',
  RemoveSingleTrendsFilter = '[Market Trends Chips] Remove Single Market Trend Filter'
}

export class FetchTrendsPdfSuccess implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.FetchTrendsPdfSuccess;
}

export class FetchTrendsPdfError implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.FetchTrendsPdfError;

  constructor(public payload: string) {}
}

export class FetchTrendsPdf implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.FetchTrendsPdf;

  constructor(public payload: ITrendsOptions) {}
}

export class UpdateTrendsTableType implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.UpdateTrendsTableType;

  constructor(public payload: number) {}
}

export class FetchTrendsLocations implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.FetchTrendsLocations;

  constructor(public payload: number) {}
}

export class FetchTrendsLocationsSuccess implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.FetchTrendsLocationsSuccess;

  constructor(public payload: any) {}
}

export class FetchTrendsReportsError implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.FetchTrendsReportsError;
}

export class FetchTrendsReports implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.FetchTrendsReports;

  constructor(public payload: { trendsFilters: TrendsFilters; mlsId: string }) {}
}

export class FetchTrendsReportsSuccess implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.FetchTrendsReportsSuccess;

  constructor(public payload: IMarketTrendsData) {}
}

export class UpdateTrendsReportFilters implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.UpdateTrendsReportFilters;

  constructor(public payload: TrendsFilters) {}
}

export class ResetTrendsReportFilters implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.ResetTrendsReportFilters;
}

export class ResetTrendsReportFiltersSuccess implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.ResetTrendsReportFiltersSuccess;
}

export class FetchEndDate implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.FetchEndDate;
}

export class SetEndDate implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.SetEndDate;

  constructor(public payload: any) {}
}

export class MTFiltersDirty implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.MTFiltersDirty;

  constructor(public payload: boolean) {}
}

export class MTPricesDirty implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.MTPricesDirty;

  constructor(public payload: boolean) {}
}

export class UpdateTrendsPdfOptions implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.UpdateTrendsPdfOptions;

  constructor(public payload: ITrendsOptions) {}
}

export class ResetTrendsPdfOptions implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.ResetTrendsPdfOptions;
}

export class ResetTrendsOptions implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.ResetTrendsOptions;
}

export class ResetTrendsOptionsSuccess implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.ResetTrendsOptionsSuccess;
}

export class TrendsLocationFiltersOpen implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.TrendsLocationFiltersOpen;

  constructor(public payload: boolean) {}
}

export class TrendsReportFiltersOpen implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.TrendsReportFiltersOpen;

  constructor(public payload: boolean) {}
}

export class TrendsLocationSubPanelsOpen implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.TrendsLocationSubPanelsOpen;

  constructor(public payload: MarketLocationSubPanel) {}
}

export class UpdateTrendsFiltersPressed implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.UpdateTrendsFiltersPressed;

  constructor(public payload: Object) {}
}

export class ClearTrendsReportFilters implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.ClearTrendsReportFilters;
}

export class RemoveSingleTrendsFilter implements Action {
  readonly type = MarketTrendsDataActionsActionTypes.RemoveSingleTrendsFilter;

  constructor(public payload: IChips) {}
}

export type MarketTrendsAction =
  | UpdateTrendsTableType
  | FetchTrendsReports
  | FetchTrendsReportsError
  | FetchTrendsReportsSuccess
  | UpdateTrendsReportFilters
  | ResetTrendsReportFilters
  | ResetTrendsReportFiltersSuccess
  | FetchTrendsLocations
  | FetchTrendsLocationsSuccess
  | FetchEndDate
  | SetEndDate
  | MTFiltersDirty
  | ResetTrendsOptionsSuccess
  | TrendsLocationFiltersOpen
  | TrendsReportFiltersOpen
  | FetchTrendsPdfSuccess
  | FetchTrendsPdf
  | TrendsLocationSubPanelsOpen
  | UpdateTrendsFiltersPressed
  | MTPricesDirty
  | ClearTrendsReportFilters
  | RemoveSingleTrendsFilter
  | FetchTrendsPdfError
  | UpdateTrendsPdfOptions
  | ResetTrendsOptions
  | ResetTrendsPdfOptions;
