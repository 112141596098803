import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { IFilterState } from '../state/filters.state';

const FilterData = (state: IAppState) => state.filterData;

export const selectV2FiltersStatus = createSelector(
  FilterData,
  (state: IFilterState) => state.filterState.state
);

export const selectV2FilterBase = createSelector(
  FilterData,
  (state: IFilterState) => state.filterState
);

export const selectCurrentPaginationPage = createSelector(
  FilterData,
  (state: IFilterState) => state.filterState.paginationCurrPage
);

export const selectCountyCityZipFilter = createSelector(
  FilterData,
  (state: IFilterState) => {
    return {
      'city': state.filterState.state.city,
      'county': state.filterState.state.county,
      'zip': state.filterState.state.zip
    };
  }
);

export const filtersLoaded = createSelector(
  FilterData,
  (state: IFilterState) => state.defaultsLoaded
);

export const mlsIdFilter = createSelector(
  FilterData,
  (state: IFilterState) => state.filterState.state.mlsid
);

export const cardsPerPageFilter = createSelector(
  FilterData,
  (state: IFilterState) => state.filterState.perPage
);

export const cardsAlphaSortFilter = createSelector(
  FilterData,
  (state: IFilterState) => state.filterState.sortAsc
);
