<div *ngIf="pageData$ | async as reportData" class="d-flex justify-content-center">
  <div [ngClass]="isLandscape ? 'landscape' : 'portrait'" class="body-container">
    <div class="main">
      <div class="main--letterhead justify-content-between">
        <div class="main--letterhead__left d-flex">
          <div class="main--left__listing-info text-left">
            <p class="primary">{{ reportData.agent.brokerageName }}</p>
            <p class="primary">{{ 'Market position report' }}</p>
            <div [style.width.px]="fittyWidth" class="fitty-container">
              <p class="secondary" id="my-element">
                {{ (reportHeaderEntity | reportLocationName) || 'All Locations' }}
              </p>
            </div>
          </div>
        </div>
        <div
          [class.hidden]="!(pdfShowAgentInfo$ | async)"
          [class.flex-row]="isLandscape"
          [class.flex-column]="!isLandscape"
          class="main--letterhead__right d-flex"
        >
          <div [ngClass]="isLandscape ? 'order-2' : 'order-0'" class="main--right__agent-photo">
            <div class="photo-bg">
              <div class="wrapper">
                <img [src]="reportData.agent.photo" alt="agent photo" class="photo" />
              </div>
            </div>
          </div>
          <div class="main--right__agent-info order-1">
            <div>
              <p class="agent-info--name">
                {{ reportData.agent.name }}
              </p>
              <p *ngIf="reportData.agent.title">
                {{ reportData.agent.title }}
              </p>
            </div>
            <div *ngIf="reportData.agent.number">
              <p class="agent-info--contact">{{ reportData.agent.number }}</p>
            </div>
            <div *ngIf="reportData.agent.phone">
              <p class="agent-info--contact">{{ reportData.agent.phone }}</p>
            </div>
            <div *ngIf="reportData.agent.email">
              <p class="agent-info--contact">{{ reportData.agent.email }}</p>
            </div>
            <div *ngIf="reportData.agent.websiteUrl">
              <p class="agent-info--contact">{{ reportData.agent.websiteUrl }}</p>
            </div>
            <div *ngIf="reportData.agent.address">
              <p class="agent-info--contact">{{ reportData.agent.address }}</p>
            </div>
            <div *ngIf="reportData.agent.city">
              <p class="agent-info--contact">{{ reportData.agent.city }}, {{ reportData.agent.stateCD }}</p>
            </div>
            <div *ngIf="reportData.agent.otherPhone">
              <p class="agent-info--contact">{{ reportData.agent.otherPhone }}</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="graph" class="market-table chart">
        <app-market-quest-chart
          *ngIf="show"
          [previewChart]="true"
          [chartSourceData]="graph"
          [downloadableImageType]="reportChartSize.exportPreview"
          [hideFirmNames]="pdfShowName$ | async"
          [hideTitle]="false"
          id="element"
        ></app-market-quest-chart>
      </div>
      <div *ngIf="pdfShowMarketPositionTable$ | async" class="market-table table">
        <table class="tg">
          <thead>
            <tr>
              <th class="tg-1">#</th>
              <th class="tg-1">NAME</th>
              <th class="tg-1">LIST #</th>
              <th class="tg-1">LIST $</th>
              <th class="tg-1">SELL #</th>
              <th class="tg-1">SELL $</th>
              <th class="tg-1">TOTAL #</th>
              <th class="tg-1">TOTAL $</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let d of reportData.table; let idx = index">
              <tr>
                <td class="tg-1">{{ idx + 1 }}</td>
                <td class="tg-1">
                  {{ getTableEntityName(pdfShowName$ | async, d.entityName, idx, reportData.agent.brokerageName) }}
                </td>
                <td class="tg-1">{{ d.listUnits }}</td>
                <td class="tg-1">${{ d.listVolume | number }}</td>
                <td class="tg-1">{{ d.buyUnits }}</td>
                <td class="tg-1">${{ d.buyVolume | number }}</td>
                <td class="tg-1">{{ d.totalUnits }}</td>
                <td class="tg-1">${{ d.totalVolume | number }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="footer d-flex flex-column">
        <div *ngIf="reportData.imageUrl && (pdfShowLogo$ | async)" class="footer--dba-logo py-2">
          <img [src]="reportData.imageUrl" alt="logo" />
        </div>
        <div class="footer--disclaimer d-flex">
          <p>{{ reportData.disclaimer }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
