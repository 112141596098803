import { Action } from '@ngrx/store';

export enum SavedSearchActionsActionTypes {
  FetchSavedSearches = '[Saved Search] Fetch Saved Searches Data API Call',
  FetchSavedSearchesSuccess = '[Saved Search] Fetched Saved Searches Data API Successful',
  FetchSavedSearchesFailure = '[Saved Search] Fetched Saved Searches Data API Failure',
  SaveSavedSearch = '[Saved Search] Save Saved Search',
  DeleteSavedSearch = '[Saved Search] Delete Saved Search'
}

export class FetchSavedSearches implements Action {
  readonly type = SavedSearchActionsActionTypes.FetchSavedSearches;

  constructor(public payload: number = 0) {}
}

export class FetchSavedSearchesSuccess implements Action {
  readonly type = SavedSearchActionsActionTypes.FetchSavedSearchesSuccess;

  constructor(public payload: any) {}
}

export class FetchSavedSearchesFailure implements Action {
  readonly type = SavedSearchActionsActionTypes.FetchSavedSearchesFailure;
}

export class SaveSavedSearch implements Action {
  readonly type = SavedSearchActionsActionTypes.SaveSavedSearch;

  constructor(public payload: any) {}
}

export class DeleteSavedSearch implements Action {
  readonly type = SavedSearchActionsActionTypes.DeleteSavedSearch;

  constructor(public payload: any) {}
}

export type SavedSearchActions =
  | FetchSavedSearches
  | FetchSavedSearchesSuccess
  | FetchSavedSearchesFailure
  | SaveSavedSearch
  | DeleteSavedSearch;
