<mat-form-field [formGroup]="mlsForm" appearance="outline">
  <mat-label>Assigned MLS</mat-label>
  <mat-select disableOptionCentering [formControlName]="'mls'" *ngIf="mlsList">
    <mat-option
      *ngFor="let each of mlsList"
      [matTooltipShowDelay]="300"
      [value]="each.mlsId"
      class="touch"
      matTooltip="{{ each.legalName }}"
      matTooltipPosition="above"
      >{{ each.legalName }}</mat-option
    >
  </mat-select>
</mat-form-field>
