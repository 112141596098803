import { Component, Input, OnInit } from '@angular/core';
import { BrandDetails } from '../../../../models/IApplicationData';
import { ISocialFooterLink } from '../../../../models/ISocialFooterLink';
import { OptionsService } from '../../../../services/options.service';

@Component({
  selector: 'app-footer-social',
  templateUrl: './footer-social.component.html',
  styleUrls: ['./footer-social.component.scss']
})
export class FooterSocialComponent implements OnInit {
  brandGroup: ISocialFooterLink[] = [];
  @Input() data: BrandDetails;

  constructor(private optionsServ: OptionsService) {
  }

  ngOnInit(): void {
    this.brandGroup = this.optionsServ.extractSocialLinkInfo(this.data);
  }

  goTo(url: string) {
    window.open(url, '_blank');
  }
}
