import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { IChips } from '../../../../../models/IChips';
import { ReportTypeEnum } from '../../../../../models/ReportTypes';

@Component({
  selector: 'app-chips-ungrouped',
  templateUrl: './chips-ungrouped.component.html',
  styleUrls: ['./chips-ungrouped.component.scss']
})
export class ChipsUngroupedComponent implements OnInit, OnDestroy, OnChanges {
  @Input() reportType: ReportTypeEnum; // 0 for #1 claims, 1 for market position
  @Input() truncate: boolean;
  @Input() data: IChips[];
  @Output() chipRemoved: EventEmitter<IChips> = new EventEmitter<IChips>();
  tabletPlus: boolean;
  subs = new Subscription();
  groupedChips: IChips[] = [];
  ungroupedChips: IChips[] = [];
  reportTypes = ReportTypeEnum;

  constructor(private breakpointObserver: BreakpointObserver, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.subs.add(
      this.breakpointObserver.observe('(min-width: 960px)').subscribe(result => {
        this.tabletPlus = result.matches;
      })
    );

    this.initChips();
  }

  ngOnChanges(): void {
    this.initChips();
  }

  initChips(): void {
    this.groupedChips = [];
    this.ungroupedChips = [];

    this.data?.forEach((chip: IChips) => {
      if (chip.isGrouped) {
        this.groupedChips.push(chip);
      } else {
        this.ungroupedChips.push(chip);
      }
    });
  }

  chipRemove(chip: IChips) {
    this.chipRemoved.emit(chip);
  }

  openSnackBarToolTip(hasData: boolean) {
    if (!this.tabletPlus && !hasData) {
      this.snackBar.open('This location does not have #1 claims.', '', {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
