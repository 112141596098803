import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISavedSearchModalData } from 'app/models/ISavedSearchModalData';
import { SavedSearchService } from 'app/services/saved-search.service';
import { ISavedSearch } from 'app/store/state/savedSearch.state';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-save-search-modal',
  templateUrl: './save-search-modal.component.html',
  styleUrls: ['./save-search-modal.component.scss']
})
export class SaveSearchModalComponent implements OnInit {
  searches: ISavedSearch[] = [];
  saveSearchForm = new FormGroup({
    searchName: new FormControl(''),
    default: new FormControl(false)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISavedSearchModalData,
    private savedSearchService: SavedSearchService,
    private snackBar: MatSnackBar
  ) {
    this.searches = this.data.searches || [];
  }

  ngOnInit(): void {
    this.initSearchForm();
  }

  initSearchForm(): void {
    this.saveSearchForm.get('searchName').setValidators(ValidateUrl(this.searches));
    if (this.searches.filter(d => d.mlsId === this.data.filters.value.mls).length === 0) {
      this.saveSearchForm.get('default').setValue(true);
    }
  }

  saveSearchHandler(): void {
    const filterValues = this.data.filters.value;
    this.savedSearchService
      .createSavedSearch({
        name: this.saveSearchForm.get('searchName').value,
        reportType: this.data.reportType,
        mlsId: filterValues.mls,
        isDefault: this.saveSearchForm.get('default').value,
        data: {
          mls: filterValues.mls,
          locationFilters: filterValues.locationFilters || [],
          report: filterValues.report || {}
        }
      })
      .subscribe({
        next: () => {
          this.snackBar.open('Search criteria has been saved.', '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
          });
        },
        error: err => {
          throwError(err);
        }
      });
  }
}

export function ValidateUrl(searches: ISavedSearch[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const hasExisting = searches.find((search: ISavedSearch) => {
      return search.name.trim().toLowerCase() === control.value.trim().toLowerCase();
    });

    if (hasExisting) {
      return { invalid: 'This Search Name Already Exists.' };
    }

    if (!control.value.trim()) {
      return { invalid: 'Search Name is required.' };
    }

    return null;
  };
}
