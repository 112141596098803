import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GalleryImage } from '../../../../../models/GalleryImage';
import { ChartTypes } from '../../../../../models/IChartTypes';
import { ImageTypes } from '../../../../../models/ImageTypes';
import { EncryptedReportData } from '../../../../../models/IReportPageData';
import { OptionsService } from '../../../../../services/options.service';
import { IAppState } from '../../../../../store/state/app.state';

@Component({
  selector: 'app-preview-tile',
  templateUrl: './preview-tile.component.html',
  styleUrls: ['./preview-tile.component.scss']
})
export class PreviewTileComponent implements OnInit, OnDestroy {
  @Input() large = false;
  @Input() graph = false;
  @Input() reportData: EncryptedReportData;
  imageBgWide: GalleryImage;
  logo: GalleryImage;
  chartType = ChartTypes;
  isMobile: boolean;
  subs = new Subscription();
  imagePath: string;

  constructor(private store: Store<IAppState>, private breakpointObserver: BreakpointObserver, private optionSvc: OptionsService) {
    this.subs.add(
      this.breakpointObserver.observe('(min-width: 425px)').subscribe(size => {
        this.isMobile = !size.matches;
      })
    );
  }

  ngOnInit() {
    this.imagePath = this.optionSvc.getImagePathForReport();
    this.imageBgWide = this.reportData?.reportDetail?.imagePromoEntities.find(image => {
      return image.imageSize === ImageTypes['wide'];
    });
    this.logo = this.reportData?.reportDetail?.imagePromoEntities.find(image => {
      return image.imageSize === ImageTypes['logo'];
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
