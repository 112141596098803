<div *ngIf="loaded$ | async" class="h-100 d-flex flex-column">
  <div class="d-flex justify-content-between saved-header-container">
    <div class="d-flex flex-column">
      <div class="pb-2 fw-bold">
        <span class="med-text">{{ this.data.title }}</span>
      </div>
      <div>
        <span class="saved-subtitle">{{ this.data.subTitle }}</span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div *ngIf="mls$ | async as mls" class="med-text fw-bold">
        <span>{{ mls.brokerCompanyName }}</span>
      </div>
    </div>
  </div>
  <app-saved-searches-table
    *ngIf="savedSavedSearches$ | async"
    [savedSearches]="savedSavedSearches$ | async"
    (selectedTableRow)="setSelectedSearch($event)"
    [data]="data"
  ></app-saved-searches-table>
  <div class="btn-group flex-column align-items-end">
    <div>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [mat-dialog-close]="selectedSearch"
        [disabled]="!selectedSearch || !(savedSavedSearches$ | async)?.length"
      >
        Run Search
      </button>
    </div>
  </div>
</div>
