import { Injectable } from '@angular/core';
import { isString } from 'lodash-es';
import { FilterState, FiltersV2Enum } from '../models/FilterData';
import { IReportData } from '../models/IReportData';

@Injectable({
  providedIn: 'root'
})
export class CardSetService {
  private locationKeys: string[] = [FiltersV2Enum.zip, FiltersV2Enum.city, FiltersV2Enum.county, FiltersV2Enum.locations];

  constructor() {}

  private static stripCaseAndSpacing(str: string) {
    return isString(str) ? str.replace(/\s/g, '').toLowerCase() || '' : str;
  }

  private static checkPriceFilter(filters: FilterState, key: string, report: IReportData) {
    if (filters[key]?.filter && filters[key].filter(Boolean).length < 1) {
      return true;
    }

    if (filters[key]) {
      return report[key].toUpperCase() === filters[key].toUpperCase();
    }

    return true;
  }

  private static checkClaimsFilter(report: IReportData, key: string, filters: FilterState) {
    if (filters[key].filter(Boolean).length < 1 || typeof filters[key] === 'number') {
      return true;
    }
    return CardSetService.stripCaseAndSpacing(report[key === 'unitType' ? 'msTypeName' : key]).includes(
      CardSetService.stripCaseAndSpacing(filters[key][0])
    );
  }

  private static checkMlsFilter(filters: FilterState, report: IReportData) {
    const mlsId = filters.mlsid;
    return filters.mlsid ? CardSetService.stripCaseAndSpacing(report['mlsid']) === CardSetService.stripCaseAndSpacing(mlsId[0]) : true;
  }

  filterReportSet(reportSet: IReportData[], filters: FilterState, sortAsc = true): IReportData[] {
    const filterKeys = Object.keys(filters);
    return reportSet
      .filter(report => {
        return filterKeys.every(key => {
          if (key === 'priceType') {
            return CardSetService.checkPriceFilter(filters, key, report);
          }

          if (Array.isArray(filters[key])) {
            if (key.localeCompare(FiltersV2Enum.locations) === 0) {
              return this.checkLocationFilter(filters, report);
            }
            if (this.locationKeys.indexOf(key) < 0) {
              return key.localeCompare('mlsid') === 0
                ? CardSetService.checkMlsFilter(filters, report)
                : CardSetService.checkClaimsFilter(report, key, filters);
            }
          }
          return true;
        });
      })
      .sort((a, b) => {
        return (a.areaLocalName < b.areaLocalName ? -1 : 1) * (sortAsc ? 1 : -1);
      });
  }

  private checkLocationFilter(filters: FilterState, report: IReportData) {
    if (
      this.locationKeys.every(key => {
        return !filters[key].length || !filters[key][0];
      })
    ) {
      return true;
    } else {
      return this.locationKeys.some(key => {
        return filters[key].indexOf(Number(report.areaLocalID)) > -1 && report.areaType.toLowerCase() === key;
      });
    }
  }
}
