import { AppInfo, Brand } from '../../models/IApplicationData';
import { TenantCode } from '../../services/tenant.service';

export interface IApplicationDataState {
  application: AppInfo;
  brands: Brand;
  selectedBrandCode: TenantCode;
  brandLoaded: boolean;
  default_brand: string;
  settings: any[];
  expiry?: string;
}

export const initialApplicationState: IApplicationDataState = {
  application: {
    app_key: '',
    app_logo: '',
    name: ''
  },
  brands: {},
  default_brand: '',
  settings: [],
  selectedBrandCode: null,
  brandLoaded: false
};

export function getInitialApplicationState(): IApplicationDataState {
  return initialApplicationState;
}
