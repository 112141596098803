import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CBRMarketExportPreviewComponent } from 'app/components/core/export-preview/market-export-preview/CBR/c-b-r-market-export-preview/c-b-r-market-export-preview.component';
import { format } from 'date-fns';
import { environment } from 'environments/environment';
import { Dictionary } from 'lodash';
import { cloneDeep, isEqual, split, upperFirst } from 'lodash-es';
import { FileSaverService } from 'ngx-filesaver';
import { BehaviorSubject, combineLatest, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, take } from 'rxjs/operators';
import { utils, WorkBook, WorkSheet, writeFile } from 'xlsx';
import { CBRClaimsExportPreviewComponent } from '../components/core/export-preview/claims-export-preview/CBR/c-b-r-claims-export-preview.component';
import { SIRClaimsExportPreviewComponent } from '../components/core/export-preview/claims-export-preview/SIR/s-i-r-claims-export-preview.component';
import { CustomExportPreviewComponent } from '../components/core/export-preview/custom-export-preview/custom-export-preview.component';
import { Conf, ExportPreviewComponent } from '../components/core/export-preview/export-preview.component';
import { SIRMarketExportPreviewComponent } from '../components/core/export-preview/market-export-preview/SIR/s-i-r-market-export-preview.component';
import { TrendsExportComponent } from '../components/core/export-preview/trends-export-preview/trends-export.component';
import { FilterData } from '../models/FilterData';
import { ChartTypes } from '../models/IChartTypes';
import { ImageTypes } from '../models/ImageTypes';
import { IMarketPositionData } from '../models/IMarketPositionData';
import { IMlsData } from '../models/IMlsData';
import { IPageTypes } from '../models/IPageTypes';
import { IReportData } from '../models/IReportData';
import { IReportDetail } from '../models/IReportDetail';
import { IReportGraph } from '../models/IReportGraph';
import { EncryptedReportData } from '../models/IReportPageData';
import { ReportPageTypeShortStrEnum } from '../models/ReportTypes';
import { ExportClaimsGtm, ExportMarketGtm } from '../store/actions/gtm.action';
import { ExportPreviewToggle, SetExportPreviewData } from '../store/actions/pdf.action';
import { FetchReportData } from '../store/actions/reports.action';
import { CustomReportDataDetails } from '../store/selectors/custom-graph.selector';
import { selectV2FilterBase } from '../store/selectors/filters.selector';
import { selectMarketReportData, selectMarketReportId, selectMarketShowAsPercent } from '../store/selectors/market-position.selector';
import { selectTrendsData } from '../store/selectors/market-trends.selector';
import { filteredData$, selectAllReports, selectReportPageData } from '../store/selectors/reports.selectors';
import { selectUser } from '../store/selectors/userData.selector';
import { IAppState } from '../store/state/app.state';
import { IPdfPreviewData } from '../store/state/pdf.state';
import { CardSetService } from './card-set.service';
import { ModalService } from './modal.service';
import { TenantCode } from './tenant.service';

@Injectable()
export class ReportService {
  downloadCompletedSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  downloadCompleted: Observable<string> = this.downloadCompletedSubject.asObservable();
  nullCount: number = 0;

  constructor(
    private _http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private store: Store<IAppState>,
    private modalService: ModalService,
    private cardSetService: CardSetService,
    private fileSaver: FileSaverService
  ) {
    this.store
      .select(selectAllReports)
      .pipe(distinctUntilChanged((value1, value2) => isEqual(value1, value2)))
      .subscribe(d => {
        this.reports$ = cloneDeep(d);
      });

    this.store
      .select(selectV2FilterBase)
      .pipe(distinctUntilChanged((value1, value2) => isEqual(value1, value2)))
      .subscribe(d => {
        this.filters$ = cloneDeep(d);
      });
  }

  private _reports$: IReportData[];

  get reports$(): IReportData[] {
    return this._reports$;
  }

  set reports$(value: IReportData[]) {
    this._reports$ = value;
  }

  private _filters$: FilterData;

  get filters$(): FilterData {
    return this._filters$;
  }

  set filters$(value: FilterData) {
    this._filters$ = value;
  }

  static generateJsonToXlsx(data: (Dictionary<unknown> | Dictionary<IReportData[keyof IReportData]>)[], mlsId: string, aoa = false) {
    ReportService.convertToXlsx(data, mlsId, aoa);
  }

  static generateCSVLink(data: string, fileName: string) {
    let blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });
    let downloadLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      downloadLink.setAttribute('target', '_blank');
    }
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', `${fileName.toUpperCase()}-` + format(new Date(), 'MM_dd_yyyy') + `.csv`);
    downloadLink.style.visibility = 'hidden';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private static convertToCSV(objArray: IReportData[]) {
    const items = objArray;
    const replacer = (key, value) => (value === null ? '' : value);
    const columnsToRemove = ['msUnoReportID', 'areaLocalID', 'propertyType', 'dbName'];
    let header = Object.keys(items[0]).filter(val => {
      return !columnsToRemove.includes(val);
    });
    return [
      header.map(h => upperFirst(h)).join(','), // header row first
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n');
  }

  private static convertToXlsx(data: (Dictionary<unknown> | Dictionary<IReportData[keyof IReportData]>)[], fileName: string, aoa = false) {
    let ws: WorkSheet;
    if (aoa) {
      ws = utils.aoa_to_sheet(data as any);
    } else {
      ws = utils.json_to_sheet(data);
    }
    const wb: WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');
    writeFile(wb, `${fileName.toUpperCase()}-` + format(new Date(), 'MM_dd_yyyy') + `.xlsx`);
  }

  adjustChartLayoutByData(data: IReportGraph[], size: ChartTypes) {
    let arr = cloneDeep(data);
    const cb = arr[0];
    arr =
      size !== 'mobile' && size !== 'tall'
        ? arr
        : arr.sort((a, b) => {
            return a.reportMeasureValue - b.reportMeasureValue;
          });
    if (size === 'mobile' || size === 'tall') {
      arr = arr.filter(item => item !== cb);
      arr.push(cb);
    }
    return arr;
  }

  getReportsForUserByMls(mlsId: string) {
    return this.store.select(selectUser).pipe(
      take(1),
      mergeMap(userClaims => {
        return this._http.get<IReportData[]>(`${environment.apiBaseUrl}/api/Report/GetReportsByMls/${mlsId}?oktaId=${userClaims.oktaId}`, {
          headers: new HttpHeaders({ timeout: '60000' })
        });
      }),
      catchError(err => throwError(err))
    );
  }

  getCountyString(countyName: string = '') {
    countyName = countyName || '';
    if (countyName) {
      const county = countyName.split(',')[0];
      const state = countyName.split(',')[1];
      countyName = county + ' County,' + state;
    }
    return countyName;
  }

  formatLevel(level: string = '') {
    if (level.indexOf('Office') !== -1) {
      return 'OFFICE';
    } else if (level.indexOf('Brand') !== -1) {
      return 'BRAND';
    } else if (level.indexOf('Broker') !== -1) {
      return 'BROKERAGE';
    } else {
      return '';
    }
  }

  selectReportsByPage(cardsOnPage: number, page: number, array: IReportData[]) {
    return array.slice((page - 1) * cardsOnPage, page * cardsOnPage);
  }

  setupPageData(data: [[string, IReportDetail, string], string]) {
    const [pageData, pathUrl] = data;
    const [decryptedValue, details, encrypted] = pageData;
    return {
      oktaId: decryptedValue?.split('|')[0] || '',
      msUnoReportId: decryptedValue?.split('|')[1] || '',
      reportDetail: cloneDeep(details),
      encryptedData: cloneDeep(encrypted),
      loading: false,
      imagePath: pathUrl,
      cbposition: 1
    };
  }

  trimLabels(text: string, screenSize: ChartTypes) {
    const desktop = [ChartTypes.desktop, ChartTypes.tall, ChartTypes.square, ChartTypes.exportPreview].includes(screenSize);
    const tablet = [ChartTypes.tablet, ChartTypes.wide, ChartTypes.HS_FB].includes(screenSize);
    const reportPage = [ChartTypes.reportPage, ChartTypes.reportPageSm].includes(screenSize);
    const maxChar = reportPage ? 15 : desktop ? 16 : tablet ? 12 : 10;
    let wordCount = 0;
    const tempText = text.replace('-', ' - ');
    let wordArray = split(tempText, ' ', maxChar);
    wordArray = wordArray.map(word => {
      wordCount += word.length;
      if (wordCount >= maxChar) {
        if (wordArray?.indexOf(word) < 1) {
          return word + (word.length > 0 ? '<br>' : '');
        }
        wordCount = word.length;
        return (word.length > 0 ? '<br>' : '') + word;
      } else {
        return word;
      }
    });

    const areMultiLines = wordArray.some(word => word.includes('<br>'));

    if (areMultiLines) {
      let lineCount = 1;
      let lastLineIdx = 0;
      wordArray.map((word, idx) => {
        if (word.includes('<br>')) {
          lineCount++;
          lastLineIdx = lineCount < 4 ? idx : lastLineIdx;
        }
      });

      for (let i = lastLineIdx + 1; i < wordArray.length; i++) {
        wordArray[lastLineIdx] = wordArray[lastLineIdx].concat(' ', wordArray[i].replace('<br>', ''));
      }
      wordArray.splice(lastLineIdx + 1);

      const secondLastIdx = lastLineIdx - 1;
      if (wordArray[lastLineIdx].includes('<br>')) {
        wordArray[lastLineIdx] =
          wordArray[lastLineIdx].length > maxChar + 4 ? wordArray[lastLineIdx].substring(0, maxChar + 4) + '...' : wordArray[lastLineIdx];
      } else {
        wordArray[secondLastIdx] = wordArray[secondLastIdx] + wordArray[lastLineIdx];
        wordArray.pop();
        wordArray[secondLastIdx] = wordArray[secondLastIdx].substring(0, maxChar + 4) + '...';
      }
    }
    const trimTextAfterParens = (text: string) => {
      const idx = text.indexOf('(');
      if (idx !== -1) {
        return text.substring(0, idx);
      }
      return text;
    };
    return trimTextAfterParens(wordArray.join(' '));
  }

  requestPdf(
    propertyId: string,
    oktaId: string,
    templateStr: string,
    type: number = 0,
    showLogo = true,
    showFirmName = true,
    showMarketPositionTable = true,
    showAgentInfo = true,
    showPercent = false
  ) {
    return this._http.get(
      `${environment.apiBaseUrl}/api/export/GetReportPDFPerType/${propertyId}?&oktaId=${oktaId}` +
        `&template=${templateStr}&reportType=${type}&showLogo=${showLogo}&showFirmName=${showFirmName}` +
        `&showMarketPositionTable=${showMarketPositionTable}&showAgentInfo=${showAgentInfo}&showPercent=${showPercent}`,
      {
        responseType: 'blob'
      }
    );
  }

  requestSIRClaimsJpg(oktaId: string, template: string, reportId: string, showPercent = false) {
    return this._http.get(
      `${environment.apiBaseUrl}/api/export/GetSirClaimsAsset/${reportId}` +
        `?oktaId=${oktaId}&template=${template}&showPercent=${showPercent}`,
      {
        responseType: 'blob'
      }
    );
  }

  requestCBRClaimsJpg(
    oktaId: string,
    size: ChartTypes,
    reportId: string,
    reportType: 0 | 1,
    isChart: boolean = true,
    quality = 100,
    showPercent = false
  ) {
    return this._http.get(
      `${environment.apiBaseUrl}/api/Export/GetCBClaimsAsset/${reportId}` +
        `?oktaId=${oktaId}&size=${size}&reportType=${reportType}&isChart=${isChart}&quality=${quality}&showPercent=${showPercent}`,
      {
        responseType: 'blob'
      }
    );
  }

  checkCurrentReportsByMls(mls: IMlsData) {
    this.store
      .select(selectAllReports)
      .pipe(take(1))
      .subscribe(filteredReports => {
        const foundReports = filteredReports.some(report => report.mlsid === mls.mlsId);
        if (!foundReports) {
          this.store.dispatch(new FetchReportData(mls.mlsId));
        }
      });
  }

  generateFilteredReportSet(reports: IReportData[], filters$: any): IReportData[] {
    return reports[0]?.msUnoReportID >= 0
      ? this.cardSetService.filterReportSet(
          reports.filter(v => v.mlsid === filters$.state.mlsid[0]),
          filters$.state,
          filters$.sortAsc
        )
      : reports;
  }

  exportPdfPreview(componentType: IPageTypes, brand: 'CBR' | 'SIR') {
    const selectedPreviewComponent = comp => {
      switch (comp) {
        case 'trends':
          return TrendsExportComponent;
        case 'claims':
          return brand === 'SIR' ? SIRClaimsExportPreviewComponent : CBRClaimsExportPreviewComponent;
        case 'marketPosition':
          return brand === 'SIR' ? SIRMarketExportPreviewComponent : CBRMarketExportPreviewComponent;
        case 'custom':
          return CustomExportPreviewComponent;
        default:
          break;
      }
    };
    const reportTypeSwitch = comp => {
      switch (comp) {
        case 'claims':
          return 0;
        case 'marketPosition':
          return 1;
        case 'trends':
          return 2;
        case 'snapshot':
          return 3;
        case 'custom':
          return 4;
        default:
          break;
      }
    };
    this.generateComplexPreviewData(componentType);
    const showTable = () => {
      return !(componentType === 'claims');
    };
    const config: Conf = {
      maxWidth: 'none',
      width: '100%',
      height: '100%',
      panelClass: 'export',
      disableClose: false,
      data: {
        controlOnly: false,
        component: selectedPreviewComponent(componentType),
        isComplexTemplate: componentType === 'trends' || componentType === 'custom',
        showMarketPositionTable: showTable(),
        showOrientation: !(brand === TenantCode.SIR),
        reportType: reportTypeSwitch(componentType),
        isSIR: brand === 'SIR',
        isClaims: componentType === 'claims'
      }
    };
    const configSmall: Conf = {
      maxWidth: 'none',
      maxHeight: '100vh',
      width: '375px',
      panelClass: 'smallPreview',
      disableClose: false,
      data: {
        controlOnly: true,
        component: null,
        isComplexTemplate: componentType === 'trends' || componentType === 'custom',
        showMarketPositionTable: showTable(),
        showOrientation: !(brand === TenantCode.SIR),
        reportType: reportTypeSwitch(componentType),
        isSIR: brand === 'SIR',
        isClaims: componentType === 'claims'
      }
    };
    this.modalService
      .open(ExportPreviewComponent, window.innerWidth > 1200 ? config : configSmall)
      .afterOpened()
      .subscribe(() => {
        this.store.dispatch(new ExportPreviewToggle(true));
      });
  }

  generateComplexPreviewData(type: IPageTypes) {
    if (type === ReportPageTypeShortStrEnum.trends) {
      this.store
        .select(selectTrendsData)
        .pipe(take(1))
        .subscribe(trendsData => {
          var dataObj: IPdfPreviewData = {
            agentData: trendsData.agentReportEntity,
            displayDate: trendsData.markettrendsheader.endDate,
            price: trendsData.markettrendsheader.priceRange,
            logo: trendsData.imagePromoEntities.find(v => v.imageSize === ImageTypes.emailLogo),
            areaLocalNames: trendsData.markettrendsheader.areaLocalNameArray,
            legalName: trendsData.markettrendsheader.legalName,
            propertyType: trendsData.markettrendsheader.propertyType,
            reportTitle: 'Market Trends Report',
            displayReo: trendsData.markettrendsheader.reoDisplay
          };
          if (!dataObj.agentData.linkedInLink) {
            this.nullCount++;
          }
          if (!dataObj.agentData.faceBookLink) {
            this.nullCount++;
          }
          if (!dataObj.agentData.instagramLink) {
            this.nullCount++;
          }
          dataObj = {
            ...dataObj,
            agentData: {
              oktaId: dataObj.agentData.oktaId,
              photo: dataObj.agentData.photo,
              name: dataObj.agentData.name,
              number: dataObj.agentData.number,
              phone: dataObj.agentData.phone,
              email: dataObj.agentData.email,
              title: dataObj.agentData.title,
              otherPhone: dataObj.agentData.otherPhone,
              address: dataObj.agentData.address,
              city: dataObj.agentData.city,
              stateCD: dataObj.agentData.stateCD,
              zip: dataObj.agentData.zip,
              imageURL: dataObj.agentData.imageURL,
              brokerageName: dataObj.agentData.brokerageName,
              websiteUrl: dataObj.agentData.websiteUrl,
              faceBookLink: dataObj.agentData.faceBookLink,
              linkedInLink: dataObj.agentData.linkedInLink,
              twitterLink: this.nullCount == 0 ? '' : dataObj.agentData.twitterLink,
              youtubeURL: this.nullCount == 0 || this.nullCount == 1 ? '' : dataObj.agentData.youtubeURL,
              pinterestLink: dataObj.agentData.pinterestLink,
              instagramLink: dataObj.agentData.instagramLink,
              isNRT: dataObj.agentData.isNRT,
              brandCode: dataObj.agentData.brandCode,
              contactType: dataObj.agentData.contactType
            }
          };
          this.store.dispatch(new SetExportPreviewData(dataObj));
        });
    } else if (type === ReportPageTypeShortStrEnum.custom) {
      this.store
        .select(CustomReportDataDetails)
        .pipe(take(1))
        .subscribe(customData => {
          const dataObj: IPdfPreviewData = {
            agentData: customData.agentData,
            displayDate: customData.header.endDate,
            displayStartDate: customData.header.startDate,
            displayEndDate: customData.header.endDate,
            price: customData.header.priceRange,
            logo: customData.images.find(v => v.imageSize === ImageTypes.emailLogo),
            areaLocalNames: customData.header.areaLocalName,
            legalName: customData.header.legalName,
            propertyType: customData.header.propertyType,
            reportTitle: 'Custom Market Report',
            displayReo: customData.header.displayReo
          };
          this.store.dispatch(new SetExportPreviewData(dataObj));
        });
    }
  }

  downloadPdf(d: {
    orientation: 'landscape' | 'portrait' | null;
    reportType: number;
    isSIR?: boolean;
    showLogo?: boolean;
    showFirmName?: boolean;
    showAgentInfo?: boolean;
    showMarketPositionTable?: boolean;
  }) {
    let oktaId: string;
    let claims: EncryptedReportData;
    let market: IMarketPositionData;
    let percent: boolean;

    const lp = !d.isSIR ? `${d.reportType === 0 ? 'Claims' : 'Market'}ReportTemplate-${d.orientation}` : `sir-report-pdf`;
    this.store
      .select(selectUser)
      .pipe(
        take(1),
        switchMap(user => {
          oktaId = user.oktaId;
          return combineLatest([
            this.store.select(selectReportPageData),
            this.store.select(selectMarketReportData),
            this.store.select(selectMarketShowAsPercent)
          ]);
        }),
        take(1),
        switchMap(report => {
          [claims, market, percent] = report;
          if (d.reportType === 1) {
            return this.store.select(selectMarketReportId);
          } else {
            return of(claims.msUnoReportId);
          }
        }),
        take(1),
        switchMap((id: string) => {
          return this.requestPdf(
            id,
            oktaId,
            lp,
            d.reportType,
            d.showLogo,
            d.showFirmName,
            d.showMarketPositionTable,
            d.showAgentInfo,
            percent
          );
        }),
        take(1)
      )
      .subscribe({
        next: data => {
          let fileName = null;
          if (d.reportType === 1) {
            this.store.dispatch(new ExportMarketGtm('exportMarketPositionReport'));
            fileName = `MarketReport-N1-${market.reportHeaderEntity.displayDateRange}.pdf`;
            this.fileSaver.save(data, fileName);
            this.setDownloadSuccess(fileName);
          }
          if (d.reportType === 0) {
            this.store.dispatch(new ExportClaimsGtm('exportClaimsReport'));
            fileName = `${claims.reportDetail.reportHeaderEntity.areaLocalName}-N1-${claims.reportDetail.reportHeaderEntity.displayDateRange}.pdf`;
            this.fileSaver.save(data, fileName);
            this.setDownloadSuccess(fileName);
          }
        },
        error: err => {
          console.log(err);
          this.downloadCompletedSubject.next('Download Error');
        }
      });
  }

  setDownloadSuccess(fileName: string): void {
    this.downloadCompletedSubject.next(fileName);
  }

  downloadSuccess(): Observable<string> {
    return this.downloadCompleted;
  }

  generateJsonToCsv() {
    return this.store.select(filteredData$).pipe(
      map(data => {
        const mlsId = data[0].mlsid;
        return [ReportService.convertToCSV(data), mlsId];
      })
    );
  }
}
