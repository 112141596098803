import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AreaLocalName } from '../../../../../../../models/IMarketTrendsHeader';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-filter-tile',
  templateUrl: './filter-tile.component.html',
  styleUrls: ['./filter-tile.component.scss']
})
export class FilterTileComponent {
  @Input() matIcon: string;
  @Input() label: string;
  @Input() value: string;
  @Input() locationValues: AreaLocalName[];
  @Input() isLocation = false;
  @Input() backgroundColor = 'transparent';

  constructor() {}
}
