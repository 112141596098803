import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CountyString'
})
export class CountyStringPipe implements PipeTransform {
  transform(countyName: string) {
    const county = countyName.split(',')[0];
    const state = countyName.split(',')[1];

    if (state !== undefined) {
      countyName = county + ' County,' + state;
    } else {
      countyName = county + ' County';
    }

    return countyName;
  }
}
