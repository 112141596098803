import { Action } from '@ngrx/store';
import { IMlsData } from '../../models/IMlsData';

export enum MLSActionsActionTypes {
  FetchMLS = '[MLS] Fetch MLS Data API Call',
  FetchMLSSuccess = '[MLS] Fetched MLS Data API Successful',
  SetMLS = '[MLS] Set New Selected MLS',
  SetMlsSuccess = '[MLS] MLS Set Successfully'
}

export class FetchMLS implements Action {
  readonly type = MLSActionsActionTypes.FetchMLS;
}

export class FetchMLSSuccess implements Action {
  readonly type = MLSActionsActionTypes.FetchMLSSuccess;

  constructor(public payload: IMlsData[]) {
  }
}

export class SetMLS implements Action {
  readonly type = MLSActionsActionTypes.SetMLS;

  constructor(public payload: IMlsData) {
  }
}

export class SetMlsSuccess implements Action {
  readonly type = MLSActionsActionTypes.SetMlsSuccess;

  constructor(public payload: IMlsData) {
  }
}

export type MLSActions = FetchMLS | FetchMLSSuccess | SetMLS | SetMlsSuccess;
