import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { ReportService } from '../../services/report.service';
import { FetchReportPDF, FetchReportPDFSuccess, PdfActionsActionTypes } from '../actions/pdf.action';

@Injectable()
export class PdfEffect {
  fetchPDF$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchReportPDF>(PdfActionsActionTypes.FetchReportPDF),
      map(p => this.report.downloadPdf(p.payload)),
      switchMap(() => {
        return [new FetchReportPDFSuccess()];
      })
    )
  );

  constructor(private _actions$: Actions, private report: ReportService) {}
}
