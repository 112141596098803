import { SCREEN_SIZES } from './IScreenSizes';

export class INavButton {
  label: string;
  matIcon: string;
  urlPath: string;
  urlExternal? = false;
  enabled = true;
  breakPoints: SCREEN_SIZES[] = [SCREEN_SIZES.all];

  constructor(data: Partial<INavButton>) {
    Object.assign(this, data);
  }
}
