import { ILocationsData } from '../../models/ILocationsData';
import { ReportTypeEnum, ReportTypeLongStrEnum } from '../../models/ReportTypes';

export interface Filter {
  [key: string]: string;
}

export interface ISavedSearch {
  name: string;
  rowKey?: string;
  mlsId: string;
  isDefault?: boolean;
  reportType: ReportTypeEnum;
  createdDate?: Date;
  data: ISavedSearchData;
}

export interface ISavedSearchData {
  mls: string;
  report: Filter[];
  locationFilters: ILocationsData[];
}

export interface ISavedSearchState {
  searchesArray: {
    [value in ReportTypeLongStrEnum]: ISavedSearch[];
  };
  loading: boolean;
  loaded: boolean;
  isError: boolean;
}

export const initialSavedSearchState: ISavedSearchState = {
  searchesArray: null,
  loading: false,
  loaded: false,
  isError: false
};

export function getInitialSavedSearchState(): ISavedSearchState {
  return initialSavedSearchState;
}
