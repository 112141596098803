import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {
  error: any;
  hasError = false;

  constructor() {

  }

  setError(_error) {
    this.error = _error;
    this.hasError = true;
  }

  getError() {
    return this.error;
  }

  checkForError() {
    return this.hasError;
  }

}
