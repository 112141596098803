export interface ITrendsOptions {
  chartdatatype: number;
  showtable: boolean;
  showamsp: boolean;
  showsplp: boolean;
  shownbrsoldar: boolean;
  showdom: boolean;
  showspsqft: boolean;
  showaimsi: boolean;
  shownl: boolean;
  showcover: boolean;
  islandscape: boolean;
  issir: boolean;
  showAgentInfo: boolean;
}

export enum ITrendsOptionEnum {
  'showcover' = 'showcover',
  'overview' = 'showtable',
  'amsp' = 'showamsp',
  'splp' = 'showsplp',
  'nbrsoldar' = 'shownbrsoldar',
  'dom' = 'showdom',
  'spsqft' = 'showspsqft',
  'aimsi' = 'showaimsi',
  'nl' = 'shownl'
}
