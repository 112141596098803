import { Component, Input } from '@angular/core';
import { ISocialFooterLink } from '../../../../models/ISocialFooterLink';

@Component({
  selector: 'app-mq-footer-sir-social',
  templateUrl: './mq-footer-sir-social.component.html',
  styleUrls: ['./mq-footer-sir-social.component.scss']
})
export class MqFooterSirSocialComponent {
  baseUrl: string;
  @Input() socialLinks: ISocialFooterLink[];

  constructor() {}

  goTo(url: string) {
    window.open(url, '_blank');
  }
}
