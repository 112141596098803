import { Injectable } from '@angular/core';
import { cloneDeep, merge } from 'lodash-es';
import { ChartTypes } from '../models/IChartTypes';
import { IMarketPositionData } from '../models/IMarketPositionData';
import { IReportGraph } from '../models/IReportGraph';
import { IReportHeader } from '../models/IReportHeader';
import { EncryptedReportData } from '../models/IReportPageData';
import { ISyncFusion } from '../models/ISyncFusion';
import {
  AxisModel,
  ChartAreaModel,
  CornerRadiusModel,
  LegendSettingsModel,
  MarkerSettingsModel,
  TooltipSettingsModel
} from '@syncfusion/ej2-angular-charts';

@Injectable()
export class SyncFusionConfigService {
  [ChartTypes.base]: ISyncFusion = {
    title: '',
    cornerRadius: {
      topRight: 0,
      topLeft: 0
    },
    chartArea: {
      border: {
        width: 0
      }
    },
    titleStyle: {
      fontFamily: 'Roboto-Medium, Arial, sans-serif',
      fontWeight: '500',
      color: '#ffffff',
      textOverflow: 'Wrap'
    },
    marker: {
      dataLabel: {
        visible: true,
        position: 'Outer',
        font: {
          fontFamily: 'Roboto-Medium, Arial, sans-serif',
          color: '#ffffff'
        }
      }
    },
    primaryXAxis: {
      valueType: 'Category',
      labelIntersectAction: 'MultipleRows',
      labelStyle: {
        fontFamily: 'Roboto, Arial, sans-serif',
        color: '#ffffff',
        textAlignment: 'Center'
      },
      lineStyle: {
        color: '#ffffff'
      },
      majorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      titleStyle: {
        fontFamily: 'Roboto-Medium, Arial, sans-serif',
        color: '#ffffff',
        textOverflow: 'Wrap'
      }
    },
    primaryYAxis: {
      desiredIntervals: 0,
      labelFormat: '',
      labelStyle: {
        size: '0'
      },
      lineStyle: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      majorGridLines: {
        width: 0
      }
    }
  };

  [ChartTypes.reportPage]: ISyncFusion = {
    ...this[ChartTypes.base],
    cornerRadius: {
      topRight: 8,
      topLeft: 8
    },
    titleStyle: {
      fontFamily: 'Roboto, Arial, sans-serif',
      fontWeight: '700',
      size: '24',
      color: '#2B2E30',
      textAlignment: 'Near'
    },
    subTitleStyle: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      size: '16',
      color: '#000',
      textAlignment: 'Near',
      textOverflow: 'None'
    },
    marker: {
      dataLabel: {
        font: {
          fontFamily: 'Roboto, Arial, sans-serif',
          color: '#2B2E30',
          size: '16'
        }
      }
    },
    primaryXAxis: {
      titlePadding: 32,
      labelStyle: {
        color: '#000',
        textOverflow: 'Wrap'
      },
      lineStyle: {
        color: '#000',
        width: 1
      },
      titleStyle: {
        fontFamily: 'Roboto, Arial, sans-serif',
        color: '#2B2E30',
        size: '20',
        textAlignment: 'Near',
        fontWeight: '700'
      }
    },
    primaryYAxis: {
      desiredIntervals: 7,
      majorGridLines: {
        color: '#E9E9E9',
        width: 1
      },
      titleStyle: {
        size: '14px'
      }
    }
  };

  [ChartTypes.reportPageSm]: ISyncFusion = merge(cloneDeep(this[ChartTypes.reportPage]), {
    cornerRadius: {
      topLeft: 0,
      topRight: 0
    },
    margin: {
      right: 0,
      left: 0,
      top: 0,
      bottom: 0
    },
    titleStyle: {
      size: '16'
    },
    subTitleStyle: {
      size: '14'
    },
    marker: {
      dataLabel: {
        position: 'Outer',
        labelIntersectAction: 'None',
        font: {
          size: '12',
          color: '#2B2E30'
        }
      }
    },
    primaryXAxis: {
      labelStyle: {
        color: '#2B2E30'
      }
    },
    primaryYAxis: {
      desiredIntervals: 0,
      majorGridLines: {
        width: 0
      },
      titleStyle: {
        opacity: 0
      },
      rangePadding: 'Auto'
    }
  } as ISyncFusion);

  [ChartTypes.desktop]: ISyncFusion = {
    ...this[ChartTypes.base],
    titleStyle: {
      size: '24px'
    },
    margin: {
      right: 34,
      left: 34
    },
    marker: {
      dataLabel: {
        font: {
          size: '24px'
        },
        border: {
          width: 1,
          color: 'transparent'
        }
      }
    },
    primaryXAxis: {
      labelStyle: {
        size: '14px'
      },
      titleStyle: {
        size: '22px'
      },
      lineStyle: {
        width: 3
      }
    }
  };

  [ChartTypes.tablet]: ISyncFusion = {
    ...this[ChartTypes.base],
    titleStyle: {
      size: '12px'
    },
    margin: {
      right: 34,
      left: 34
    },
    marker: {
      dataLabel: {
        font: {
          size: '16px'
        }
      }
    },
    primaryXAxis: {
      labelStyle: {
        size: '10px'
      },
      titleStyle: {
        size: '12px'
      },
      lineStyle: {
        width: 3
      }
    }
  };

  [ChartTypes.mobile]: ISyncFusion = {
    ...this[ChartTypes.base],
    titleStyle: {
      size: '14px'
    },
    margin: {
      right: 16,
      left: 16
    },
    marker: {
      dataLabel: {
        font: {
          size: '12px'
        }
      }
    },
    primaryXAxis: {
      labelStyle: {
        size: '12px',
        textOverflow: 'Wrap'
      },
      lineStyle: {
        width: 3
      }
    },
    primaryYAxis: {
      majorGridLines: {
        width: 0
      }
    }
  };

  [ChartTypes.wide]: ISyncFusion = {
    ...this[ChartTypes.base],
    margin: {
      right: 0,
      left: 0,
      top: 0,
      bottom: 0
    },
    marker: {
      dataLabel: {
        font: {
          size: '18px'
        }
      }
    },
    titleStyle: {
      size: '14px'
    },
    primaryXAxis: {
      labelStyle: {
        size: '12px'
      },
      titleStyle: {
        size: '14px'
      },
      lineStyle: {
        width: 3
      }
    }
  };

  [ChartTypes.tall]: ISyncFusion = {
    ...this[ChartTypes.base],
    margin: {
      top: 94,
      bottom: 50
    },
    primaryXAxis: {
      titleStyle: {
        size: '26px'
      },
      labelStyle: {
        size: '24px'
      },
      lineStyle: {
        width: 3
      }
    },
    titleStyle: {
      size: '26px'
    },
    marker: {
      dataLabel: {
        font: {
          size: '30px'
        }
      }
    }
  };

  [ChartTypes.square]: ISyncFusion = {
    ...this[ChartTypes.base],
    primaryYAxis: {
      titleStyle: {
        size: '20px'
      }
    },
    primaryXAxis: {
      titleStyle: {
        size: '20px'
      },
      labelStyle: {
        size: '18px'
      },
      lineStyle: {
        width: 3
      }
    },
    titleStyle: {
      size: '20px'
    },
    marker: {
      dataLabel: {
        font: {
          size: '24px'
        }
      }
    }
  };

  [ChartTypes.HS_FB]: ISyncFusion = {
    ...this[ChartTypes.base],
    margin: {
      right: 0,
      left: 0,
      top: 0,
      bottom: 0
    },
    primaryXAxis: {
      titleStyle: {
        size: '18px'
      },
      labelStyle: {
        size: '12px'
      },
      lineStyle: {
        width: 3
      }
    },
    titleStyle: {
      size: '14px'
    },
    marker: {
      dataLabel: {
        font: {
          size: '16px'
        }
      }
    }
  };

  [ChartTypes.HS_Web]: any = {
    ...this[ChartTypes.base],
    primaryXAxis: {
      titleStyle: {
        size: '7px'
      },
      labelStyle: {
        size: '7px'
      }
    },
    titleStyle: {
      size: '9px'
    },
    marker: {
      dataLabel: {
        font: {
          size: '8px'
        }
      }
    }
  };

  [ChartTypes.previewSmall]: ISyncFusion = {
    ...this[ChartTypes.base],
    margin: {
      right: 0,
      left: 0,
      top: 0,
      bottom: 0
    },
    titleStyle: {
      size: '6px'
    },
    primaryXAxis: {
      labelStyle: {
        size: '0px'
      },
      titleStyle: {
        size: '6px'
      },
      lineStyle: {
        width: 1
      }
    },
    marker: {
      dataLabel: {
        font: {
          size: '5px'
        }
      }
    }
  };

  [ChartTypes.previewLarge]: ISyncFusion = {
    ...this[ChartTypes.base],
    margin: {
      right: 0,
      left: 0,
      top: 0,
      bottom: 0
    },
    titleStyle: {
      size: '.6em'
    },
    primaryXAxis: {
      labelStyle: {
        size: '0px'
      },
      titleStyle: {
        size: '.6em'
      },
      lineStyle: {
        width: 1
      }
    },
    marker: {
      dataLabel: {
        font: {
          size: '.6em'
        }
      }
    }
  };

  [ChartTypes.aspTrends]: ISyncFusion = {
    legend: {
      visible: false
    },
    chartArea: {
      border: {
        width: 0
      }
    },
    marker: {
      dataLabel: {
        template: '<div>${point.y}</div><div>SOLDS HERE</div>',
        visible: true,
        position: 'Top',
        font: {
          color: '#115097'
        },
        border: {
          width: 1
        },
        margin: {
          right: 8,
          left: 8,
          top: 16,
          bottom: 8
        }
      }
    },
    primaryXAxis: {
      valueType: 'Category',
      labelStyle: {
        fontFamily: 'Helvetica Neue, Arial, sans-serif',
        color: '#727476',
        size: '12'
      },
      lineStyle: {
        color: '#E9E9E9',
        width: 1
      },
      majorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      }
    },
    primaryYAxis: {
      title: '',
      labelStyle: {
        fontFamily: 'Roboto, sans-serif',
        color: '#727476',
        size: '12',
        textOverflow: 'None'
      },
      lineStyle: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      majorGridLines: {
        color: '#E9E9E9',
        width: 1
      }
    },
    cornerRadius: {
      topRight: 0,
      topLeft: 0
    },
    tooltip: {
      enable: false
    }
  };

  [ChartTypes.exportPreview]: ISyncFusion = {
    ...this[ChartTypes.base],
    margin: {
      right: 0,
      left: 0
    },
    primaryXAxis: {
      valueType: 'Category',
      lineStyle: {
        width: 1,
        color: '#000'
      },
      majorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      titleStyle: {
        fontFamily: 'GeometosNeueBold, Arial, sans-serif',
        textOverflow: 'Wrap',
        fontWeight: '800',
        size: '8px',
        color: '#000'
      },
      labelStyle: {
        size: '12px',
        color: '#000',
        fontFamily: 'Helvetica Neue, Arial, sans-serif'
      }
    },
    primaryYAxis: {
      valueType: 'Category',
      minimum: 1,
      lineStyle: {
        width: 0
      },
      titleStyle: {
        size: '9px',
        color: '#000'
      }
    },
    chartArea: {
      border: {
        width: 0
      }
    },
    titleStyle: {
      fontFamily: 'GeometosNeueBold, Arial, sans-serif',
      fontWeight: '800',
      size: '8px',
      color: '#000'
    },
    marker: {
      dataLabel: {
        visible: true,
        position: 'Outer',
        font: {
          fontWeight: '800',
          fontFamily: 'Helvetica Neue, Arial, sans-serif',
          color: '#000'
        }
      }
    }
  };

  getConfig(size: ChartTypes) {
    const base = cloneDeep(this[ChartTypes.base]);
    const override = cloneDeep(this[size]);
    return merge(base, override);
  }

  prepareMarketChatDataModel(data: IMarketPositionData): EncryptedReportData {
    return {
      msUnoReportId: '',
      oktaId: data?.agentReportEntity?.oktaId,
      reportDetail: {
        agentReportEntity: data?.agentReportEntity,
        reportHeaderEntity: data?.reportHeaderEntity as IReportHeader,
        imagePromoEntities: data?.imagePromoEntities,
        reportGraphEntities: this.getMarketGraphData(data)
      },
      encryptedData: data,
      loading: null,
      imagePath: null,
      cbposition: data?.realogyPosition?.marketPosition
    };
  }

  private getMarketGraphData(data: IMarketPositionData) {
    const reportGraphEntities: IReportGraph[] = new Array(0);
    (data?.topGroupTypes || []).forEach(x => {
      const reportGraph: IReportGraph = new IReportGraph();
      switch (data.msTypeName?.toLowerCase()) {
        case 'list':
          reportGraph.reportMeasureValue = data.isUnits ? x.listUnits : x.listVolume;
          reportGraph.marketSharePercentage = data.isUnits ? x.listUnitsPercent : x.listVolumePercent;
          break;
        case 'buy':
          reportGraph.reportMeasureValue = data.isUnits ? x.buyUnits : x.buyVolume;
          reportGraph.marketSharePercentage = data.isUnits ? x.buyUnitsPercent : x.buyVolumePercent;
          break;
        default:
          reportGraph.reportMeasureValue = data.isUnits ? x.totalUnits : x.totalVolume;
          reportGraph.marketSharePercentage = data.isUnits ? x.totalUnitsPercent : x.totalVolumePercent;
          break;
      }
      reportGraph.levelEntityName = x.entityName;
      reportGraph.levelEntityXtra = '';
      reportGraphEntities.push(reportGraph);
    });
    return reportGraphEntities;
  }
}
