import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMlsData } from '../../../models/IMlsData';
import { ISavedSearchModalData } from '../../../models/ISavedSearchModalData';
import { ReportTypeEnum } from '../../../models/ReportTypes';
import { selectCurrentMLS } from '../../../store/selectors/mls.selector';
import { selectSavedSearches, selectSavedSearchesLoaded } from '../../../store/selectors/saved-search.selector';
import { IAppState } from '../../../store/state/app.state';
import { ISavedSearch } from '../../../store/state/savedSearch.state';

@Component({
  selector: 'app-saved-search-modal',
  templateUrl: './saved-search-modal.component.html',
  styleUrls: ['./saved-search-modal.component.scss']
})
export class SavedSearchModalComponent implements OnInit {
  loaded$: Observable<boolean>;
  mls$: Observable<IMlsData>;
  selectedSearch: ISavedSearch = null;
  savedSavedSearches$: Observable<ISavedSearch[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISavedSearchModalData,
    public dialogRef: MatDialogRef<SavedSearchModalComponent>,
    private store: Store<IAppState>
  ) {}

  ngOnInit(): void {
    this.mls$ = this.store.select(selectCurrentMLS);
    this.loaded$ = this.store.select(selectSavedSearchesLoaded);
    this.savedSavedSearches$ = combineLatest([this.mls$, this.store.select(selectSavedSearches)]).pipe(
      map(([mls, data]) => {
        if (!data) {
          return [];
        }
        return data[ReportTypeEnum[this.data.reportType]].filter(data => {
          return data.mlsId === mls.mlsId;
        });
      })
    );
  }

  close() {
    this.dialogRef.close();
  }

  setSelectedSearch($event: ISavedSearch) {
    this.selectedSearch = $event;
  }
}
