import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { IMarketSnapshotState } from '../state/market-snapshot.state';

const MarketSnapshot = (state: IAppState) => state.marketSnapshot;

export const selectSnapshotLoadedState = createSelector(
  MarketSnapshot,
  (state: IMarketSnapshotState) => state.loaded
);

export const selectSnapshotLoadingState = createSelector(
  MarketSnapshot,
  (state: IMarketSnapshotState) => state.loading
);

export const selectSnapshotHasErrorState = createSelector(
  MarketSnapshot,
  (state: IMarketSnapshotState) => state.hasError
);

export const selectSnapshotData = createSelector(
  MarketSnapshot,
  (state: IMarketSnapshotState) => state.snapshotData
);
