import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginService } from './login/login.service';

@Injectable()
export class RedirectHomeGuard implements CanActivate {
  constructor(private router: Router, private loginService: LoginService, private oktaAuth: OktaAuth) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.oktaAuth.isAuthenticated().then(res => {
      if (res) {
        if (route.fragment === null || route.fragment.length === 0) {
          this.router.navigateByUrl('/').then();
          return false;
        }
        return true;
      }
      this.loginService.login();
      return false;
    });
  }
}
