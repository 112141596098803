import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { EncryptService } from '../services/encrypt.service';
import { ReportDetailService } from '../services/report-details.service';

@Injectable()
export class PublicReportResolver implements Resolve<any> {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reportDetailSvc: ReportDetailService,
    private encryptService: EncryptService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const encryptedId = route.params.id;
    return this.encryptService.getDecryptedValue(encryptedId).pipe(
      map(decryptedString => {
        const [reportId, oktaId, isCard] = decryptedString.split('|');
        return {
          reportId,
          oktaId,
          isCard
        };
      })
    );
  }
}
