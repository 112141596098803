import { MenusAction, MenusActionTypes } from '../actions/menus.action';
import { Banner, getInitialMenuState, IMenuState } from '../state/menu.state';

export function MenusReducer(state = getInitialMenuState(), action: MenusAction): IMenuState {
  switch (action.type) {
    case MenusActionTypes.ToggleSideBarNav: {
      return {
        ...state,
        sideBarOpen: !state.sideBarOpen
      };
    }
    case MenusActionTypes.ToggleFilterPanel: {
      return {
        ...state,
        filterMenuOpen: action.payload != null ? action.payload : !state.filterMenuOpen
      };
    }

    case MenusActionTypes.SetSideBarNav: {
      return {
        ...state,
        sideBarOpen: action.payload
      };
    }

    case MenusActionTypes.PushWarningBanner: {
      const banner: Banner = Object.assign({}, action.payload);
      banner.show = true;
      return {
        ...state,
        showWarningBars: {
          ...state.showWarningBars,
          [action.payload.type]: [banner]
        }
      };
    }

    case MenusActionTypes.ToggleWarningBanner: {
      const banner: Banner = Object.assign({}, action.payload);
      banner.show = !banner.show;
      return {
        ...state,
        showWarningBars: {
          ...state.showWarningBars,
          [action.payload.type]: [banner]
        }
      };
    }
    case MenusActionTypes.FetchNavItemsSuccess: {
      return {
        ...state,
        navItems: action.payload
      };
    }
    default:
      return state;
  }
}
