import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { InstagramSvgComponent } from './instagram-svg/instagram-svg.component';
import { LinkedinSvgComponent } from './linkedin-svg/linkedin-svg.component';
import { PinterestSvgComponent } from './pinterest-svg/pinterest-svg.component';
import { TwitterSvgComponent } from './twitter-svg/twitter-svg.component';
import { YoutubeSvgComponent } from './youtube-svg/youtube-svg.component';
import { FacebookSvgComponent } from './facebook-svg/facebook-svg.component';
import { BlogSvgComponentComponent } from './blog-svg-component/blog-svg-component.component';

@NgModule({
  declarations: [
    TwitterSvgComponent,
    PinterestSvgComponent,
    LinkedinSvgComponent,
    YoutubeSvgComponent,
    InstagramSvgComponent,
    FacebookSvgComponent,
    BlogSvgComponentComponent
  ],
  exports: [
    TwitterSvgComponent,
    PinterestSvgComponent,
    LinkedinSvgComponent,
    YoutubeSvgComponent,
    InstagramSvgComponent,
    FacebookSvgComponent,
    BlogSvgComponentComponent
  ],
  imports: [CommonModule, MatIconModule]
})
export class SocialSvgModule {}
