import { cloneDeep } from 'lodash-es';
import { UserDataActions, UserDataActionsActionTypes } from '../actions/userData.action';
import { getInitialUserDataState, IUserDataState } from '../state/userData.state';

export function userDataReducer(state = getInitialUserDataState(), action: UserDataActions): IUserDataState {
  switch (action.type) {
    case UserDataActionsActionTypes.FetchUserV2Data: {
      return {
        ...state,
        loaded: false
      };
    }

    case UserDataActionsActionTypes.FetchUserDataSuccess: {
      return {
        ...state,
        info: action.payload,
        loaded: true
      };
    }

    case UserDataActionsActionTypes.LogoutClearUserDataState: {
      return getInitialUserDataState();
    }

    case UserDataActionsActionTypes.SetUserAvatarDetails: {
      return {
        ...state,
        info: {
          ...state.info,
          avatar: action.payload
        }
      };
    }

    case UserDataActionsActionTypes.FetchUserDefaultImageSuccess: {
      return {
        ...state,
        defaultImage: {
          loaded: true,
          sizes: action.payload
        }
      };
    }

    case UserDataActionsActionTypes.UpdateUserDefaultOffice: {
      const officeArr = cloneDeep(state.info.offices);
      officeArr.forEach(off => {
        off.isPrimary = off.masterId === action.payload;
      });
      return {
        ...state,
        info: {
          ...state.info,
          offices: officeArr
        }
      };
    }

    case UserDataActionsActionTypes.UpdateUserDefaultTeam: {
      const teamArr = cloneDeep(state.info.teams);
      teamArr.forEach(team => {
        team.isPrimary = team.masterId === action.payload;
      });
      return {
        ...state,
        info: {
          ...state.info,
          teams: teamArr
        }
      };
    }

    case UserDataActionsActionTypes.UpdateUserDefaultLicense: {
      const licenseArr = cloneDeep(state.info.licenses);
      licenseArr.forEach(lic => {
        lic.isDefault = lic.licenseNbr === action.payload;
      });
      return {
        ...state,
        info: {
          ...state.info,
          licenses: licenseArr
        }
      };
    }

    default:
      return state;
  }
}
