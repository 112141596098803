import { IPageTypes } from './IPageTypes';

export class IEmailData {
  oktaId: string;
  reportid: string;
  senderName: string;
  senderEmail: string;
  firstEmail: string;
  secondEmail: string;
  thirdEmail: string;
  type?: IPageTypes;
}

export interface IEmailPostBody {
  emailData: IEmailPostData;
}

interface IEmailPostData {
  OktaId: string;
  ReportId: string;
  FromName: string;
  FromAddress: string;
  ToAddresses: string[];
}
