import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { IBannerData } from '../../../models/IBannerData';
import { PushWarningBanner, ToggleWarningBanner } from '../../../store/actions/menus.action';
import { selectBannersByType, selectBannerStatus } from '../../../store/selectors/menus.selector';
import { IAppState } from '../../../store/state/app.state';
import { Banner } from '../../../store/state/menu.state';

@Component({
  selector: 'app-disclaimer-banner',
  templateUrl: './disclaimer-banner.component.html',
  styleUrls: ['./disclaimer-banner.component.scss']
})
export class DisclaimerBannerComponent implements OnInit, OnDestroy {
  @Input() data: IBannerData;
  bannerStatus$: Observable<Banner>;
  private bannerConfig: Banner;
  private subs = new Subscription();

  constructor(private store: Store<IAppState>) {}

  ngOnInit(): void {
    this.bannerConfig = {
      show: null,
      type: this.data?.type
    };
    this.subs.add(
      this.store.select(selectBannersByType(this.bannerConfig)).subscribe(banners => {
        const match = banners.find(b => b.type === this.bannerConfig.type);
        if (match) {
          this.bannerConfig = cloneDeep(match);
        } else {
          this.store.dispatch(new PushWarningBanner(this.bannerConfig));
        }
      })
    );
    this.bannerStatus$ = this.store.select(selectBannerStatus(this.bannerConfig));
  }

  goToHelpPage(url: string) {
    window.open(`${url}`, '_blank');
  }

  hideBanner() {
    this.store.dispatch(new ToggleWarningBanner(this.bannerConfig));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
