import { Component, Input } from '@angular/core';
import { IAgentReport } from '../../../../../../../models/IAgentReport';

@Component({
  selector: 'app-connect-with-me-block',
  templateUrl: './connect-with-me-block.component.html',
  styleUrls: ['./connect-with-me-block.component.scss']
})
export class ConnectWithMeBlockComponent {
  @Input() data: IAgentReport;

  constructor() {}
}
