import { CommonModule, DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AxisModel,
  ChartAreaModel,
  CornerRadiusModel,
  IAxisLabelRenderEventArgs,
  IPointRenderEventArgs,
  ISeriesRenderEventArgs,
  ITextRenderEventArgs,
  LegendSettingsModel,
  MarkerSettingsModel,
  TooltipSettingsModel
} from '@syncfusion/ej2-angular-charts';
import { format, getYear } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { debounce, fromEvent, Subscription, timer } from 'rxjs';
import { IMarketTrendsDataSet, ITrendsDataPoint } from '../../../../../models/IMarketTrendsGraphSet';
import { TrendDataTypeEnum } from '../../../../../models/ITrendsTableData';
import { MarketTrendsChartConfiguration, TrendsChartStyles } from '../../../../../models/MarketTrendsChartConfiguration';
import { ChartService } from '../../../../../services/chart.service';
import { MarketTrendsService } from '../../../../../services/market-trends.service';
import { ThemeService } from '../../../../../services/theme.service';
import { selectTrendsDataHeader } from '../../../../../store/selectors/market-trends.selector';
import { IAppState } from '../../../../../store/state/app.state';
import { SharedComponentsModule } from '../../../shared/shared-components.module';

@Component({
  standalone: true,
  imports: [SharedComponentsModule, CommonModule],
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy, OnChanges {
  @Input() chart: MarketTrendsChartConfiguration;
  @Input() dataPoints: IMarketTrendsDataSet;
  subs = new Subscription();
  chartId: string;
  chartData: ITrendsDataPoint[][];
  legend: LegendSettingsModel;
  chartArea: ChartAreaModel;
  primaryXAxis: AxisModel;
  primaryYAxis: AxisModel;
  cornerRadius: CornerRadiusModel;
  tooltip: TooltipSettingsModel = {
    enable: false
  };
  marker: MarkerSettingsModel;
  palette: string[];
  TrendDataTypeEnum = TrendDataTypeEnum;
  showColumnMarkers = false;
  debouncedResize$ = fromEvent(window, 'resize');

  private year = 0;
  private currMonth: string;

  constructor(
    private marketTrendsService: MarketTrendsService,
    private store: Store<IAppState>,
    private decimalPipe: DecimalPipe,
    private themeService: ThemeService,
    private cdr: ChangeDetectorRef,
    private chartSvc: ChartService
  ) {
    this.chartId = MarketTrendsService.randomId();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.chart) {
      this.generateChart();
    }
  }

  ngOnInit(): void {
    this.subs.add(
      this.debouncedResize$.pipe(debounce(() => timer(500))).subscribe(() => {
        this.generateChart();
      })
    );

    this.generateChart();
    this.legend = this.chartSvc.setUpLegend(this.legend, this.chart);
    this.tooltip = this.chartSvc.setupTooltip(this.tooltip);

    this.store.select(selectTrendsDataHeader).subscribe(d => {
      this.chartData = [];
      if (d) {
        this.currMonth = format(new Date(d.endDate), 'MMMM');
      }
      Object.values(this.dataPoints).map(value => {
        this.formatDates(value);
      });
    });
  }

  generateChart(): void {
    this.showColumnMarkers = window.innerWidth >= 960;
    this.fetchChartConfig(this.chart?.chartTypes, this.chart?.isCustomChart);
    this.setUpResponsiveDataMarkers();
    this.cdr.detectChanges();
  }

  private isMobile() {
    return window.innerWidth <= 600;
  }

  axisLabelRender($event: IAxisLabelRenderEventArgs) {
    const result = this.chartSvc.checkAxisLabels($event, this.isMobile(), this.currMonth, this.year);
    $event = result.$event;
    this.year = result.newCurrentYear;
    return $event;
  }

  pointRender($event: IPointRenderEventArgs) {
    return this.chartSvc.pointRenderInit($event, this.currMonth, this.themeService.isSIR(), this.themeService.chartPrimary);
  }

  textRender($event: ITextRenderEventArgs) {
    return this.chartSvc.textRenderInit($event, this.currMonth);
  }

  seriesRender($event: ISeriesRenderEventArgs): void {
    const custom = this.chart?.isCustomChart;
    const isSir = this.themeService.isSIR();
    return this.chartSvc.seriesRenderInit($event, this.showColumnMarkers, this.chartId, this.currMonth, isSir, custom);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private checkColumn() {
    return this.showColumnMarkers && this.chart?.chartTypes.includes('Column');
  }

  private setUpResponsiveDataMarkers() {
    if (this.checkColumn()) {
      window.setTimeout(() => {
        this.chartSvc.generateColumnMarkers(
          this.chartData[this.chart?.chartTypes.indexOf('Column')],
          this.chartId,
          this.currMonth,
          this.themeService.isSIR(),
          this.chart?.isCustomChart
        );
      }, 10);
    }
  }

  private formatDates(value: ITrendsDataPoint[]) {
    const val = cloneDeep(value);
    let year = 0;
    val.map(v => {
      const d = new Date(v.xData);
      const month = format(d, 'MMMM').substring(0, 3);
      year = getYear(d);
      v.xData = `${month} ${year}`;
    });
    this.chartData.push(val);
  }

  private fetchChartConfig(size: TrendsChartStyles[], customChart = false) {
    const config = this.chartSvc.fetchChartConfig(size, customChart);
    return Object.assign(this, config);
  }
}
