import { MLSActions, MLSActionsActionTypes } from '../actions/mls.action';
import { getInitialMLSState, IMLSState } from '../state/mls.state';

export function MLSReducer(
  state = getInitialMLSState(),
  action: MLSActions
): IMLSState {
  switch (action.type) {
    case MLSActionsActionTypes.FetchMLSSuccess: {
      return {
        ...state,
        mlsid: action.payload,
        loaded: true,
        loading: false
      };
    }

    case MLSActionsActionTypes.FetchMLS: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case MLSActionsActionTypes.SetMlsSuccess: {
      return {
        ...state,
        selectedMLS: action.payload
      };
    }

    default:
      return state;
  }
}
