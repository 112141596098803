import { cloneDeep } from 'lodash-es';
import { GalleryImage } from '../../models/GalleryImage';
import { GalleryActions, GalleryActionsActionTypes } from '../actions/gallery.action';
import { getInitialGalleryState, IGalleryState } from '../state/gallery.state';

export function GalleryReducer(state = getInitialGalleryState(), action: GalleryActions): IGalleryState {
  switch (action.type) {
    case GalleryActionsActionTypes.FetchUsersGalleryImages: {
      return {
        ...state
      };
    }

    case GalleryActionsActionTypes.FetchUsersGalleryImagesSuccess: {
      return {
        ...state,
        images: {
          loaded: true,
          list: action.payload
        }
      };
    }

    case GalleryActionsActionTypes.SetGalleryImageAsDefaultPending: {
      const images = cloneDeep(state.images);
      const foundIndex = images.list.findIndex(x => x.msUnoBackGroundImageID === action.payload.msUnoBackGroundImageID);
      images.list.map(image => (image.isActive = false));
      images.list[foundIndex] = action.payload;

      return {
        ...state,
        images: {
          ...state.images,
          list: images.list
        }
      };
    }

    case GalleryActionsActionTypes.ToggleGallery: {
      return {
        ...state,
        menuOpened: action.payload !== undefined ? action.payload : !state.menuOpened,
        changePending: 0
      };
    }

    case GalleryActionsActionTypes.GalleryPendingChange: {
      return {
        ...state,
        changePending: action.payload.msUnoBackGroundImageID
      };
    }

    case GalleryActionsActionTypes.ShowLargeGalleryImage: {
      return {
        ...state,
        currentImageInSlide: action.payload,
        menuOpened: false,
        slideShowOpened: state.slideShowOpened || true
      };
    }

    case GalleryActionsActionTypes.CloseSlideShow: {
      return {
        ...state,
        currentImageInSlide: new GalleryImage(),
        slideShowOpened: false
      };
    }

    default:
      return state;
  }
}
