<div [ngClass]="isLandscape ? 'landscape' : 'portrait'" class="page">
  <div class="page-content">
    <app-export-preview-header [coverPage]="coverPage"></app-export-preview-header>
    <div [ngClass]="{ divider: !coverPage, cover: coverPage }" class="content">
      <app-agent-branding-block *ngIf="coverPage ? false : showAgentInfo"></app-agent-branding-block>
      <ng-content></ng-content>
      <ng-content select=".chart-container"></ng-content>
    </div>
    <app-export-preview-footer *ngIf="!coverPage"></app-export-preview-footer>
  </div>
</div>
