import { IMlsData } from '../../models/IMlsData';

export interface IMLSState {
  mlsid: IMlsData[];
  selectedMLS: IMlsData;
  loading: boolean;
  loaded: boolean;
}

export const initialMLSState: IMLSState = {
  mlsid: [],
  selectedMLS: null,
  loading: false,
  loaded: false
};

export function getInitialMLSState(): IMLSState {
  return initialMLSState;
}
