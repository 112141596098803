import { DecimalPipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { format, sub } from 'date-fns';
import { environment } from 'environments/environment';
import { cloneDeep, merge, uniq } from 'lodash-es';
import { throwError } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { FiltersV2Enum } from '../models/FilterData';
import { IMarketTrendsData } from '../models/IMarketTrendsData';
import { TrendsFilters } from '../models/IMarketTrendsFilters';
import { ISyncFusion } from '../models/ISyncFusion';
import { ITrendsOptions } from '../models/ITrendsOptions';
import {
  CardTypesEnum,
  FormatTypes,
  ITrendsTableData,
  TableAdjustableDataColumns,
  TableStaticDataColumns,
  TrendCardTypeEnumShortHand,
  TrendDataFormatTypes,
  TrendDataTypeEnum,
  TrendDataTypeEnumShortHand
} from '../models/ITrendsTableData';
import { MarketTrendsChartConfiguration, TrendsChartStyles, TrendsChartStylesEnum } from '../models/MarketTrendsChartConfiguration';
import { selectTrendsReportFilters } from '../store/selectors/market-trends.selector';
import { selectOktaId } from '../store/selectors/userData.selector';
import { IAppState } from '../store/state/app.state';
import { ThemeService } from './theme.service';

export interface TableHeaderColumn {
  name: string;
  tooltip: string;
}

@Injectable()
export class MarketTrendsService {
  [TrendsChartStylesEnum.Column]: ISyncFusion = {};
  base: ISyncFusion = {
    title: '',
    chartArea: {
      opacity: 0,
      border: {
        width: 0
      }
    },
    cornerRadius: {
      topRight: 2,
      topLeft: 2
    },
    marker: {
      dataLabel: {
        labelIntersectAction: 'None',
        visible: false,
        border: {
          width: 1
        },
        position: 'Top',
        angle: -90,
        enableRotation: true,
        font: {
          fontFamily: 'Roboto'
        }
      }
    },
    primaryXAxis: {
      valueType: 'Category',
      labelIntersectAction: 'None',
      labelStyle: {
        fontFamily: 'Roboto Condensed, Arial, sans-serif',
        color: '#727476',
        size: '12',
        textOverflow: 'Trim'
      },
      maximumLabels: 36,
      lineStyle: {
        color: '#E9E9E9',
        width: 1
      },
      majorGridLines: {
        width: 0
      },
      majorTickLines: {
        width: 0
      }
    },
    primaryYAxis: {
      labelStyle: {
        fontFamily: 'Roboto Condensed, Arial, sans-serif',
        color: '#727476',
        size: '12',
        textOverflow: 'None'
      },
      lineStyle: {
        width: 0
      },
      majorTickLines: {
        width: 0
      },
      majorGridLines: {
        color: '#E9E9E9',
        width: 1
      }
    },
    legend: {
      position: 'Bottom',
      shapeHeight: 4,
      shapeWidth: 29,
      opacity: 1,
      alignment: 'Center',
      toggleVisibility: false,
      textStyle: {
        fontFamily: 'Roboto Condensed, Arial, sans-serif',
        size: '12',
        color: '#727476'
      }
    },
    tooltip: {
      enable: true,
      fill: '#727476',
      opacity: 1,
      textStyle: {
        color: '#fff',
        size: '12',
        fontFamily: 'Roboto'
      },
      enableMarker: false,
      header: '',
      format: '<b>${point.y} <br/> ${point.x}</b>'
    },
    palette: ['#BCD2FF']
  };
  [TrendsChartStylesEnum.Spline]: ISyncFusion = {
    marker: {
      visible: true,
      dataLabel: {
        visible: false
      }
    },
    primaryYAxis: {
      rangePadding: 'Round'
    },
    palette: ['#1F69FF', '#00B4BD']
  };
  [TrendsChartStylesEnum.StackingColumn]: ISyncFusion = {
    palette: ['#6296FF', '#8FB4FF', '#BCD2FF']
  };
  [TrendsChartStylesEnum.Line]: ISyncFusion = {
    palette: ['#00B4BD', '#BCD2FF']
  };
  [TrendsChartStylesEnum.ColumnLine]: ISyncFusion = {
    palette: ['#BCD2FF', '#00B4BD']
  };
  [TrendsChartStylesEnum.Custom]: ISyncFusion = {
    palette: ['#00B4BD', '#8FB4FF', '#BCD2FF']
  };
  private headers: HttpHeaders;
  private locationKeys: string[] = [
    FiltersV2Enum.city,
    FiltersV2Enum.county,
    FiltersV2Enum.zip,
    FiltersV2Enum.mlsArea,
    FiltersV2Enum.schoolDistrict,
    FiltersV2Enum.subDivision
  ];

  constructor(
    private _http: HttpClient,
    private decimalPipe: DecimalPipe,
    private store: Store<IAppState>,
    private themeService: ThemeService
  ) {
    this.marketTrendsChartConfiguration = MarketTrendsService.createChartDataSetConfiguration();

    this.store.select(selectTrendsReportFilters).subscribe(data => {
      this.filters = cloneDeep(data);
    });
  }

  private _trendsFormGroup: any;

  get trendsFormGroup(): any {
    return this._trendsFormGroup;
  }

  set trendsFormGroup(value: any) {
    this._trendsFormGroup = value;
  }

  private _filters: TrendsFilters;

  get filters(): TrendsFilters {
    return this._filters;
  }

  set filters(value: TrendsFilters) {
    this._filters = value;
  }

  private _tableHeaderColumns: TableHeaderColumn[];

  get tableHeaderColumns(): TableHeaderColumn[] {
    return this._tableHeaderColumns;
  }

  set tableHeaderColumns(val: TableHeaderColumn[]) {
    this._tableHeaderColumns = val;
  }

  private _marketTrendsChartConfiguration: MarketTrendsChartConfiguration[];

  get marketTrendsChartConfiguration(): MarketTrendsChartConfiguration[] {
    return this._marketTrendsChartConfiguration;
  }

  set marketTrendsChartConfiguration(val: MarketTrendsChartConfiguration[]) {
    this._marketTrendsChartConfiguration = val;
  }

  static getDataAffix(num: number) {
    switch (num) {
      case 0: {
        return '_arrow';
      }

      case 1: {
        return '_percent';
      }

      case 2: {
        return '';
      }

      default: {
        return '';
      }
    }
  }

  static randomId() {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substring(2, 10);
  }

  createCustomChartConfiguration(label: string, dataTypes: TrendDataTypeEnumShortHand[], chartTypes: TrendsChartStyles[], shortHand: any) {
    return new MarketTrendsChartConfiguration(label, dataTypes, chartTypes, shortHand, true);
  }

  private static createChartDataSetConfiguration() {
    const i: MarketTrendsChartConfiguration[] = [];
    i.push(
      new MarketTrendsChartConfiguration(
        CardTypesEnum['Average & Median Sales Price'],
        [TrendDataTypeEnumShortHand.asp, TrendDataTypeEnumShortHand.msp],
        ['Spline', 'Spline'],
        TrendCardTypeEnumShortHand.amsp
      )
    );
    i.push(
      new MarketTrendsChartConfiguration(
        CardTypesEnum['Sales Price / List Price Ratio'],
        [TrendDataTypeEnumShortHand.splp],
        ['Spline'],
        TrendCardTypeEnumShortHand.splp
      )
    );
    i.push(
      new MarketTrendsChartConfiguration(
        CardTypesEnum['Number of Properties Sold & Absorption Rate'],
        [TrendDataTypeEnumShortHand.solds, TrendDataTypeEnumShortHand.ar],
        ['Column', 'Line'],
        TrendCardTypeEnumShortHand.nbrsoldar
      )
    );
    i.push(
      new MarketTrendsChartConfiguration(
        CardTypesEnum['Average Days on Market'],
        [TrendDataTypeEnumShortHand.dom],
        ['Column'],
        TrendCardTypeEnumShortHand.dom
      )
    );
    i.push(
      new MarketTrendsChartConfiguration(
        CardTypesEnum['Average Sales Price per Square Foot'],
        [TrendDataTypeEnumShortHand.sppersf],
        ['Spline'],
        TrendCardTypeEnumShortHand.spsqft
      )
    );
    i.push(
      new MarketTrendsChartConfiguration(
        CardTypesEnum['Inventory & MSI'],
        [TrendDataTypeEnumShortHand.ai, TrendDataTypeEnumShortHand.msi],
        ['Column', 'Line'],
        TrendCardTypeEnumShortHand.aimsi
      )
    );
    i.push(
      new MarketTrendsChartConfiguration(
        CardTypesEnum['New Listings'],
        [TrendDataTypeEnumShortHand.nl],
        ['Column'],
        TrendCardTypeEnumShortHand.nl
      )
    );
    return i;
  }

  formatTrendsTableArray(trendsData: ITrendsTableData[], type: number) {
    const arrOrder = [
      TrendDataTypeEnum.alp,
      TrendDataTypeEnum.mlp,
      TrendDataTypeEnum.asp,
      TrendDataTypeEnum.msp,
      TrendDataTypeEnum.splp,
      TrendDataTypeEnum.solds,
      TrendDataTypeEnum.dom,
      TrendDataTypeEnum.sppersf,
      TrendDataTypeEnum.ai,
      TrendDataTypeEnum.msi,
      TrendDataTypeEnum.nl,
      TrendDataTypeEnum.ar
    ];
    const outArr: ITrendsTableData[] = [];
    const affix = MarketTrendsService.getDataAffix(type);
    trendsData.forEach(dataPoint => {
      const out: ITrendsTableData = { cm: 0, cmly: 0, datatype: '', l3m: 0, lm: 0, ytd: 0, formattype: undefined };
      out.formattype = FormatTypes[TrendDataTypeEnum[dataPoint.datatype.toLowerCase()]];
      out.datatype = TrendDataTypeEnum[dataPoint.datatype.toLowerCase()];
      TableStaticDataColumns.map(col => {
        out[col] = dataPoint[col];
      });
      TableAdjustableDataColumns.map(col => {
        out[col] = dataPoint[col + affix];
      });
      outArr.push(out);
    });

    function arrSort(target, sortArr) {
      const arr = target as ITrendsTableData[];
      const indices: string[] = Object.values(sortArr);

      return indices.map(function(key) {
        return arr.find((i: ITrendsTableData) => i.datatype === key) || null;
      });
    }

    return !!outArr.length ? arrSort(outArr, arrOrder).filter(v => !!v) : [];
  }

  formatByType(val: string | number, type: TrendDataFormatTypes) {
    switch (type) {
      case TrendDataFormatTypes.Dollar: {
        return `$ ${this.decimalPipe.transform(val, '1.0-0')}`;
      }

      case TrendDataFormatTypes.Percent: {
        return `${val}%`;
      }

      case TrendDataFormatTypes.Numeric: {
        return `${this.decimalPipe.transform(val, '1.0-0')}`;
      }

      default: {
        return val;
      }
    }
  }

  getMarketTrendsData(trendsFilters: TrendsFilters, mlsId: string) {
    const formattedTrendsFilters = cloneDeep(trendsFilters);
    Object.entries(trendsFilters).map(filter => {
      if (Object.values(this.locationKeys).includes(filter[0])) {
        formattedTrendsFilters[filter[0]] = cloneDeep(trendsFilters[filter[0]].map(String));
      }
    });

    formattedTrendsFilters.propertyType = cloneDeep(((trendsFilters.propertyType as number[]) || []).map(String));
    formattedTrendsFilters.endDate = cloneDeep(trendsFilters.endDate);
    return this._http
      .post<IMarketTrendsData>(`${environment.apiBaseUrl}/api/Report/${mlsId}/postmtreport`, formattedTrendsFilters, {
        headers: this.headers
      })
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getMarketTrendsDataById(oktaId: string, trendsId: string) {
    return this._http.get<IMarketTrendsData>(`${environment.apiBaseUrl}/api/Report/GetMTReport/${trendsId}?oktaId=${oktaId}`, {
      headers: this.headers
    });
  }

  getTrendsConfig(type: TrendsChartStyles[], isCustom = false) {
    let t = uniq(type);
    this.setPalettes(t.length > 1 ? TrendsChartStylesEnum.ColumnLine : t[0]);

    if (isCustom) {
      t = [TrendsChartStylesEnum.Custom];
    } else if (t.length > 1) {
      t = [TrendsChartStylesEnum.ColumnLine];
    }
    const base = cloneDeep(this.base);
    const override = cloneDeep(this[t[0]]);
    return merge(base, override);
  }

  generateTableHeaders(value: string) {
    const d = new Date(value);
    const displayMonthShort = format(d, 'MMM');
    const displayLastYear = format(sub(d, { years: 1 }), 'yyyy');
    const displayLastMonth = format(sub(d, { months: 1 }), 'MMMM');
    const displayL3mShortStart = format(sub(d, { months: 3 }), 'MMM');
    const displayL3mShortEnd = format(sub(d, { months: 1 }), 'MMM');

    this.tableHeaderColumns = [
      {
        name: 'Overview',
        tooltip: ''
      },
      {
        name: `YTD`,
        tooltip: ''
      },
      {
        name: `${format(d, 'MMMM')}`,
        tooltip: ''
      },
      {
        name: `${displayLastMonth}`,
        tooltip: 'Last Month'
      },
      {
        name: `Avg. ${displayL3mShortStart}. - ${displayL3mShortEnd}.`,
        tooltip: 'Last 3 Months'
      },
      {
        name: `${displayMonthShort}. ${displayLastYear}`,
        tooltip: 'Same Month Previous Year'
      }
    ];
  }

  fetchTrendsPdf(data: IMarketTrendsData, options: ITrendsOptions) {
    return this.postTrendsDataToDb(data).pipe(
      withLatestFrom(this.store.select(selectOktaId)),
      switchMap(observable => {
        const [data, oktaId] = observable;
        return this._http.post(`${environment.apiBaseUrl}/api/export/PostTrendsPDF/${data}?&oktaId=${oktaId}`, options, {
          responseType: 'blob'
        });
      })
    );
  }

  postTrendsDataToDb(data: IMarketTrendsData) {
    return this.store.select(selectOktaId).pipe(
      switchMap(id => {
        const d = cloneDeep(data);
        Object.keys(d).forEach(k => d[k] == null && delete d[k]);
        return this._http.post(`${environment.apiBaseUrl}/api/Report/${id}/PostMTReportToDB`, d, {
          responseType: 'text'
        });
      })
    );
  }

  saveTrendsFilterSession(value: unknown) {
    this.trendsFormGroup = value;
  }

  restoreTrendsFilterSession() {
    return this.trendsFormGroup;
  }

  resetTrendsFilterSession() {
    this.trendsFormGroup = null;
  }

  private setPalettes(type: TrendsChartStyles) {
    if (this[TrendsChartStylesEnum[type]]) {
      this[TrendsChartStylesEnum[type]].palette = this.themeService.setSyncFusionPalette(type);
    }
    this.base.marker.dataLabel.font.color = this.themeService.chartPrimary;
  }
}
