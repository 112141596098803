import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ReportService } from 'app/services/report.service';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { FileSaverService } from 'ngx-filesaver';
import { of } from 'rxjs';
import { catchError, map, retry, switchMap, withLatestFrom } from 'rxjs/operators';
import { GTMService } from '../../services/gtm.service';
import { MarketTrendsService } from '../../services/market-trends.service';
import { ModalService } from '../../services/modal.service';
import { ThemeService } from '../../services/theme.service';
import {
  FetchTrendsPdf,
  FetchTrendsPdfError,
  FetchTrendsPdfSuccess,
  FetchTrendsReports,
  FetchTrendsReportsError,
  FetchTrendsReportsSuccess,
  MarketTrendsDataActionsActionTypes,
  UpdateTrendsReportFilters
} from '../actions/market-trends.action';
import { selectTrendsData, selectTrendsTableDataType } from '../selectors/market-trends.selector';
import { IAppState } from '../state/app.state';

@Injectable()
export class MarketTrendsEffect {
  fetchReportData$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchTrendsReports>(MarketTrendsDataActionsActionTypes.FetchTrendsReports),
      switchMap(action => {
        return this.trendsService.getMarketTrendsData(action.payload.trendsFilters, action.payload.mlsId).pipe(
          map(reports => {
            this.store.dispatch(new UpdateTrendsReportFilters(action.payload.trendsFilters));
            const nullData = Object.keys(reports).every(key => reports[key] === null);
            return nullData ? new FetchTrendsReportsError() : new FetchTrendsReportsSuccess(reports);
          }),
          retry(1),
          catchError(() => {
            return of(new FetchTrendsReportsError());
          })
        );
      })
    )
  );

  fetchTrendsPdf$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchTrendsPdf>(MarketTrendsDataActionsActionTypes.FetchTrendsPdf),
      map(action => action),
      withLatestFrom(this.store.select(selectTrendsData), this.store.select(selectTrendsTableDataType)),
      switchMap(data => {
        const [action, trendsData, type] = data;
        const opts = cloneDeep(action.payload);
        opts.chartdatatype = type;
        opts.issir = this.themeSvc.isSIR();
        return this.trendsService.fetchTrendsPdf(trendsData, opts).pipe(
          map(pdfData => {
            const append = format(new Date(trendsData.markettrendsheader.endDate), 'MMM-yyyy');
            const fileName = `market-trends-report-${append}.pdf`;
            this.gtmService.trendsExportGTM('exportMarketTrendsReport');
            this.fileSaver.save(pdfData, fileName);
            this.reportService.setDownloadSuccess(fileName);
            this.gtmService.trendsExportSuccess(opts);
            return new FetchTrendsPdfSuccess();
          }),
          catchError(err => {
            this.reportService.setDownloadSuccess('Download Error');
            return of(new FetchTrendsPdfError(err.message));
          })
        );
      })
    )
  );

  fetchTrendsPdfSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<FetchTrendsPdfSuccess>(MarketTrendsDataActionsActionTypes.FetchTrendsPdfSuccess),
        switchMap(action => of(action)),
        map(() => this.modalService.close())
      ),
    { dispatch: false }
  );

  fetchTrendsPdfError$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<FetchTrendsPdfError>(MarketTrendsDataActionsActionTypes.FetchTrendsPdfError),
        map(() =>
          this.snackBar.open('An Error Has Occurred', '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private _actions$: Actions,
    private store: Store<IAppState>,
    private snackBar: MatSnackBar,
    private gtmService: GTMService,
    private modalService: ModalService,
    private trendsService: MarketTrendsService,
    private fileSaver: FileSaverService,
    private reportService: ReportService,
    private themeSvc: ThemeService
  ) {}
}
