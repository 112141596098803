import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectOktaId } from 'app/store/selectors/userData.selector';
import { IAppState } from 'app/store/state/app.state';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { INavButton } from '../../../models/INavButton';
import { NavigationService } from '../../../services/navigation.service';
import { FetchNavItems } from '../../../store/actions/menus.action';
import { selectNavItems } from '../../../store/selectors/menus.selector';

@Component({
  selector: 'app-nav-button-bar',
  templateUrl: './nav-button-bar.component.html',
  styleUrls: ['./nav-button-bar.component.scss']
})
export class NavButtonBarComponent implements OnInit {
  navItems$: Observable<INavButton[]>;

  constructor(private navigationService: NavigationService, private store: Store<IAppState>) {}

  ngOnInit(): void {
    this.store
      .select(selectOktaId)
      .pipe(
        filter(v => !!v),
        take(1)
      )
      .subscribe(user => {
        this.fetchNavButtonData(user);
      });
    this.navItems$ = this.store.select(selectNavItems).pipe(
      map(btnArr => {
        return btnArr.filter(btn => btn.enabled);
      })
    );
  }

  private fetchNavButtonData(oktaId: string) {
    this.store.dispatch(new FetchNavItems(oktaId));
  }
}
