import { createSelector } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { ILocationsState } from '../state/locations.state';

const Locations = (state: IAppState) => state.locations;

export const selectLocations = createSelector(
  Locations,
  (state: ILocationsState) => state.locations
);

export const selectReportLocations = createSelector(
  Locations,
  (state: ILocationsState) => state.marketLocations
);

export const locationsLoaded = createSelector(
  Locations,
  (state: ILocationsState) => state.loaded
);

export const marketLocationsLoaded = createSelector(
  Locations,
  (state: ILocationsState) => state.marketLoaded
);
