import { BannerTypeEnums, IBannerData } from './IBannerData';

export class Disclaimer implements IBannerData {
  message: string;
  type: BannerTypeEnums;
  url: string;
  show: boolean;
  closeable: boolean;

  constructor(data: Partial<IBannerData>) {
    Object.assign(this, data);
  }
}
